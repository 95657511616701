/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useState, useContext} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { omit } from 'lodash';
import { AuthContext } from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import FileUpload from '../global/FileUpload';
import {parseJSONSafely, updateAttachmentData, createUserTypeAndRoleObject} from "../../utils";

const {REACT_APP_FILE_MANAGER_API} = process.env;

const validation = (instByCodeCount) => {
    let validation = Yup.object().shape({
        installation_code_exist: Yup.boolean().default(instByCodeCount > 0),
        installation_code: Yup.string()
            .matches(/^[A-Z0-9]{3}$/, 'Must be exactly 3 Uppercase Characters')
            .label("installation_code")
            .required("Installation Code is required")
            .when('installation_code_exist', {
                is: true,
                then: Yup.string().test("installation_code", "Installation code already exists", function (value) {
                    const { path, createError } = this;
                    return createError({ path, message: "Installation code already exists" });
                }),
            }),
        dh_id: Yup.string().label("dh_id").required("Duty Holder is required"),
        installation_title: Yup.string().label("installation_title").required("Installation Name is required"),
        installation_type: Yup.string().label("installation_type").required("Installation Type is required"),
        installation_business_state: Yup.string().label("installation_business_state").required("Business State is required"),
        installation_other_services: Yup.string().label("installation_other_services").required("Other Services  is required"),
    });
    return validation;
}

const CREATE_INSTALLATION = gql`
    mutation AddInstallation($inst: CreateInstallation!) {
        createInstallation(inst: $inst) {
            _id
            success
            status_code
            message
        }
    }
`;

const UPDATE_INSTALLATION = gql`
    mutation UpdateInstallation($id: String!, $inst: UpdateInstallationInput!, $userId: String!) {
        updateInstallation(_id: $id, inst: $inst, userId: $userId) {
            installation_code
            ag_id{
                _id
                ag_title
            }
            installation_title 
            installation_class_no
            installation_imo_no
            installation_flag_state
            installation_type
            installation_business_state
            installation_abs_class_notations
            installation_additional_notations
            installation_other_services
            installation_notes
            success
            status_code
            message
        }
    }
`;

const INSTALLATION_BY_CODE_QUERY = gql`
query InstallationsByCode($code: String!){
    installationsByCode(code: $code)
}`;

const AddEditInstallation = (props) => {
    
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
    const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);
    const history = useHistory();

    const [showHelp, setShowHelp] = useState(false);

    const [instByCodeCount, setInstByCodeCount] = useState(undefined);
    const [enteredCode, setEnteredCode] = useState(undefined);

    const [createInstallation, {data: createInstallationData}] = useMutation(CREATE_INSTALLATION);
    const [updateInstallation, {data: updateInstallationData}] = useMutation(UPDATE_INSTALLATION);
    const [installationsByCode, {data: instByCodeData}] = useLazyQuery(INSTALLATION_BY_CODE_QUERY);

    const { formMode, id, assetGroupId, installationId = "" } = useParams();
    const { location: { state = "" }} = props ? props : {};
    
    const [formInitValues, setFormInitValues] = useState({
        installation_code:"",
        dh_id: id,
        ag_id: assetGroupId,
        installation_title:"", 
        installation_class_no:"",
        installation_imo_no:"",
        installation_flag_state:"",
        installation_type:"",
        installation_business_state:"",
        installation_abs_class_notations:"",
        installation_additional_notations:"",
        installation_other_services:"",
        installation_notes:"",
        installation_attachments:"[]"
    });

    const INSTALLATION_QUERY = gql`
    query Installation($installationId: ID!){
        installation(_id: $installationId) {
            installation_code
            dh_id{
                _id
                dh_name
            }
            ag_id{
                ag_title
            }
            installation_title 
            installation_class_no
            installation_imo_no
            installation_flag_state
            installation_type
            installation_business_state
            installation_abs_class_notations
            installation_additional_notations
            installation_other_services
            installation_notes
            installation_attachments
        }
    }
    `;

    const {data: InstallationData} = useQuery(INSTALLATION_QUERY, {
        variables: {installationId},
        errorPolicy: 'all'
    });

    useEffect(() => {
    if (authContext && authContext.user) {
        let result = createUserTypeAndRoleObject(authContext);
        setUserTypeAndRoleObject(result);
    }
    }, [authContext]);

    useEffect(() => {
        if (isInitialPageLoad) {
            setIsInitialPageLoad(false);
            return;
        }
        history.push('/');
    }, [navContext]);

    useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
    }, [userTypeAndRoleObject]);
    
    useEffect(() => {
        if (InstallationData && InstallationData.installation) {
            let installation = InstallationData.installation;
            setFormInitValues({
                installation_code: installation.installation_code,
                dh_id: installation.dh_id._id,
                installation_class_no: installation.installation_class_no,
                installation_imo_no: installation.installation_imo_no,
                installation_flag_state: installation.installation_flag_state,
                installation_type: installation.installation_type,
                installation_business_state: installation.installation_business_state,
                installation_abs_class_notations: installation.installation_abs_class_notations,
                installation_additional_notations: installation.installation_additional_notations,
                installation_other_services: installation.installation_other_services,
                installation_title: installation.installation_title,
                installation_notes: installation.installation_notes,
                installation_attachments: installation.installation_attachments ? installation.installation_attachments : "[]"
            })
        }
    }, [InstallationData]);

    useEffect(() => {
        if (createInstallationData && createInstallationData.createInstallation) {
            const { success, message } = createInstallationData.createInstallation;
            if (!success) {
                alert(message);
            }
            if (success) {
                if (assetGroupId !== undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installations`);
                } else {
                    history.push(`/dutyholder/${id}/installations`);
                }
            }
        }
    }, [createInstallationData]);
    
    useEffect(() => {
        if (updateInstallationData && updateInstallationData.updateInstallation) {
            const { success, message } = updateInstallationData.updateInstallation;
            if (!success) {
                alert(message);
            }
            if (success) {
                if (assetGroupId !== undefined) {
                    history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installations`);
                } else {
                    history.push(`/dutyholder/${id}/installations`);
                }
            }
        }
    }, [updateInstallationData]);

    useEffect(() => {
        if (instByCodeData) {
          if (formMode === "edit" && enteredCode === formInitValues.installation_code) {
            setInstByCodeCount(0);
          }else{
            setInstByCodeCount(instByCodeData.installationsByCode);
          }
        }
      }, [instByCodeData]);

    const handleInstCodeChange = (event) => {
        let code = event.target.value;
        setEnteredCode(code);
        installationsByCode({ variables: { code: code }, errorPolicy: 'all' });
    }

    const displayBackLink = (state, id, assetGroupId) => {
        return (
            <Link to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installations` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
                state: state && state.archivedStatus
            }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
        )
    }

    let tempInstllAttachFileList = [];
    const handleInstllAttachFilesDescription = (item) => {
        let index = tempInstllAttachFileList.findIndex(f => f.name === item.name);
        if (index >= 0) {
            tempInstllAttachFileList[index] = item;
        } else {
            tempInstllAttachFileList.push(item);
        }
    };

    return(
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Installation <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>  

                {showHelp &&
                <div>
                    <p>Enter the details into the required fields, including adding attachments by clicking on 'Attach Files'.</p>
                    <p>Once complete, click '<span className="capitalize">{formMode}</span> Installation' which will initiate a notification for review.</p>
                </div>
                }                         
            </div>

            <Formik
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={validation(instByCodeCount)}
                onSubmit={(values, actions) => {
                    const tempValues = { ...values };

                    tempValues.installation_attachments = updateAttachmentData(tempValues.installation_attachments, tempInstllAttachFileList);
                    tempValues.installation_added_id = authContext.user._id;

                    if (formMode === 'add') {
                        createInstallation({ variables: { inst: tempValues } })
                    }

                    if (formMode === 'edit') {
                        updateInstallation({ variables: { id: installationId, inst: tempValues, userId: authContext.user._id} })
                    }

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>

                        <div className="my-4">
                            {displayBackLink(state, id, assetGroupId)}
                        </div>

                        <div>
                            <label htmlFor="installation_code" className="block mb-1 text-blue-900 font-semibold">Installation Code (3 Letters)*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="installation_code"
                                id="installation_code"
                                placeholder="Installation Code"
                                onChange={(event) => {handleInstCodeChange(event); handleChange(event)} }
                                onBlur={handleBlur}
                                value={values.installation_code}
                            />
                            <FormError touched={touched.installation_code} message={errors.installation_code} />
                        </div>

                        <div>
                            <label htmlFor="installation_title" className="block mb-1 text-blue-900 font-semibold">Installation Name*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="installation_title"
                                id="installation_title"
                                placeholder="Installation Name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.installation_title}
                            />
                            <FormError touched={touched.installation_title} message={errors.installation_title} />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="installation_class_no" className="block mb-1 text-blue-900 font-semibold">Class No.</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="installation_class_no"
                                id="installation_class_no"
                                placeholder="Class No."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.installation_class_no}
                            />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="installation_imo_no" className="block mb-1 text-blue-900 font-semibold">IMO No.</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="installation_imo_no"
                                id="installation_imo_no"
                                placeholder="IMO No."
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.installation_imo_no}
                            />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="installation_flag_state" className="block mb-1 text-blue-900 font-semibold">Flag State</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="installation_flag_state"
                                id="installation_flag_state"
                                placeholder="Flag State"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.installation_flag_state}
                            />
                        </div>

                        <div>
                            <label htmlFor="installation_type" className="block mb-1 text-blue-900 font-semibold">Installation Type*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="installation_type"
                                id="installation_type"
                                placeholder="Installation Type"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.installation_type}
                            />
                            <FormError touched={touched.installation_type} message={errors.installation_type} />
                        </div>

                        <div>
                            <label htmlFor="installation_business_state" className="block mb-1 text-blue-900 font-semibold">Business State*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="installation_business_state"
                                id="installation_business_state"
                                placeholder="Business State"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.installation_business_state}
                            />
                            <FormError touched={touched.installation_business_state} message={errors.installation_business_state} />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="installation_abs_class_notations" className="block mb-1 text-blue-900 font-semibold">Class Notations</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="installation_abs_class_notations"
                                id="installation_abs_class_notations"
                                placeholder="Class Notations"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.installation_abs_class_notations}
                            />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="installation_additional_notations" className="block mb-1 text-blue-900 font-semibold">Additional Notations</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="installation_additional_notations"
                                id="installation_additional_notations"
                                placeholder="Additional Notations"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.installation_additional_notations}
                            />
                        </div>

                        <div>
                            <label htmlFor="installation_other_services" className="block mb-1 text-blue-900 font-semibold">Other Services*</label>
                            <textarea
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="installation_other_services"
                                id="installation_other_services"
                                placeholder="Other Services"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.installation_other_services}
                            />
                            <FormError touched={touched.installation_other_services} message={errors.installation_other_services} />
                        </div>

                        <div className="mb-4">
                            <label htmlFor="installation_notes" className="block mb-1 text-blue-900 font-semibold">Notes</label>
                            <textarea
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="installation_notes"
                                id="installation_notes"
                                placeholder="Notes"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.installation_notes}
                            />
                        </div>

                        <div>
                            <label htmlFor="installation_attachments" className="block mb-1 text-blue-900 font-semibold">Attachments</label>
                            {authContext.user && authContext.user.user_home_dir &&
                                <FileUpload
                                    onAttach={(files) => setFieldValue('installation_attachments', JSON.stringify(files))}
                                    id='fileManager'
                                    buttonText='Attach Files'
                                    apiURL={REACT_APP_FILE_MANAGER_API}
                                    toolbarText='Attach'
                                    homeDir={`/${authContext.user.user_home_dir}/`}
                                    showDescription={true}
                                    handleFileDescription={handleInstllAttachFilesDescription}
                                    tempFileList={tempInstllAttachFileList}
                                    {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.installation_attachments) ? parseJSONSafely(values.installation_attachments) : [] })}
                                />
                            }
                            <FormError touched={touched.installation_attachments} message={errors.installation_attachments} />
                        </div>

                        <button type="submit" className="mt-3 button-red capitalize">{formMode} Installation</button>

                    </form>
                )}
            </Formik>

        </div>
    )
  
}

export default AddEditInstallation;
