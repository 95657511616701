/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';
import "react-datepicker/dist/react-datepicker.css";
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';
import Sanitiser from "../global/sanitiser";
import { Link, useParams } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from "../../context/authContext";
import PageSpinner from '../global/pageSpinner';
import Table from '../global/Table';
import {NavContext} from '../../context/navContext';
import {
    STATUS_SYSTEM_VARIABLE_TYPE_ID,
    NATURE_SYSTEM_VARIABLE_TYPE_ID,
    ANSWERS_SYSTEM_VARIABLE_TYPE_ID,
    NOTIFICATION_STATUS_PENDING,
    FINDING_LEVEL_SYSTEM_VARIABLE_TYPE_ID,
    DH_FINDING_TYPE,
    INST_FINDING_TYPE,
    MULTI_VA_FINDING_TYPE,
    NOTIFICATION_STATUS_APPROVED,
} from '../../constants/';

import {
    parseJSONSafely, 
    formatDateToLocaleDateString, 
    valueFromInfoMatrixLevel,
    getFirstElementOrObject
} from "../../utils";

import { formatPreviousFindings, createStatusFrom } from "./FindingManagement";
import { lowerCase } from 'lodash';

var htmlToPdfmake = require("html-to-pdfmake");

const { REACT_APP_FILE_MANAGER_API } = process.env;

const FINDING_QUERY = gql`
query FindingQuery($param: FindingQueryInput!){
    findingQuery(filterParam: $param){
      _id
      dh_id{ 
          _id 
          dh_name 
      }
      ag_id{ 
          _id 
          ag_title
      }
      installation_id{
           _id 
           installation_title
      }
      barrier_id{ 
          _id 
          bar_title 
      }
      sece_id{
           _id 
           sece_title 
           sece_ref
           sece_client_ref
      }
      sece_ids{
        _id
        sece_title
        sece_ref
        sece_client_ref
      }
      va_id{ 
          _id 
          va_type
          va_client_ref
          va_short_desc 
          va_long_desc
      }
      va_ids{ 
        _id 
        va_type
        va_client_ref
        va_short_desc 
        va_long_desc
      }
      vs_id{
        _id 
      }
      report_id{
        _id
      }
      report_ids{
        _id
        sece_id{
            _id
            sece_title
            sece_ref
            sece_client_ref
            sece_order_no
            sece_archived
            sece_status
        }
        va_id{
            _id
            va_type
            va_short_desc
            va_client_ref
            va_long_desc
        }
      }

      finding_nature_ids{
        _id 
        sysvar_title
      }

      finding_current_status{ 
          _id 
          sysvar_title
      }
      finding_level{ 
          _id 
          sysvar_title
      }
      finding_level_summary    
      finding_infomatrix_level
      finding_integrity{
        _id 
        sysvar_title
      }
      finding_legislative{
        _id 
        sysvar_title
      }
      finding_rogi{
        _id 
        sysvar_title
      }
      finding_pw_process{
        _id 
        sysvar_title
      }

      finding_equip_number
      finding_equip_type

      finding_synopsis
      finding_summary
      finding_action
      finding_type

      finding_work_order_raised{
        _id 
        sysvar_title
      }
      finding_work_order_raised_desc
      finding_contingency_actions{
        _id 
        sysvar_title
      }

      finding_response_date
      finding_completion_date

      finding_closure_date
      finding_abs_responsible{
        _id
        user_fname
        user_lname 
      }
      finding_dh_responsible{
        _id 
        user_fname
        user_lname
      }

      linked_install_fnds{ 
        _id
        finding_ref
        finding_synopsis
        finding_added_date
        finding_closure_date
        finding_infomatrix_level
        finding_current_status{
            _id
            sysvar_title
        }
      }
      linked_dh_fnds{
        _id
        finding_ref
        finding_synopsis
        finding_added_date
        finding_closure_date
        finding_infomatrix_level
        finding_current_status{
            _id
            sysvar_title
        }
      }
      finding_doc_update_required{
        _id
        sysvar_title
      }
      finding_ps_reference
      finding_ps_reference_attachments
      finding_attachments

      fnd_attmnt_dest_path
      fnd_ps_ref_attmnt_dest_path
      fnd_resp_attmnt_dest_path

      finding_responses

      finding_action_party{
        _id
        user_fname
        user_lname
      }
      finding_auth{
        _id
        user_fname
        user_lname
      }
      finding_ref

      finding_status

      finding_type

      finding_days_open
      finding_days_since_last_response

      finding_date
      finding_added_date
  }
}`;

const FINDINGS_BY_DH_QUERY = gql`
query findingsByDhId($dhId:ID!, $instId:String!, $seceIdList:[ID], $fndId:ID){
    findingsByDhId(dhId: $dhId, instId:$instId, seceIdList: $seceIdList, fndId: $fndId){
      _id
      finding_ref
      finding_synopsis
      finding_added_date
      finding_closure_date

      finding_current_status{
        sysvar_title
      }

      finding_infomatrix_level
      finding_level{
        sysvar_title
      }

      finding_action_party{
        user_fname
        user_lname
      }

      finding_added_id{
          user_fname
          user_lname
          user_type {
            _id
            user_type_display_name
          }
      }
    }
}`;

const FINDINGS_BY_INST_QUERY = gql`
query findingsByInstId($instId: ID!, $seceIdList: [ID], $fndId: ID){
    findingsByInstId(instId: $instId, seceIdList :$seceIdList, fndId: $fndId){
      _id
      finding_ref
      finding_synopsis
      finding_added_date
      finding_closure_date

      finding_current_status{
        sysvar_title
      }

      finding_infomatrix_level
      finding_level{
        sysvar_title
      }

      finding_action_party{
        user_fname
        user_lname
      }

      finding_added_id{
          user_fname
          user_lname
          user_type {
            _id
            user_type_display_name
          }
      }
    }
}`;

const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
            _id
            sysvar_title
            sysvar_archived
            sysvartype_id{
                _id
                sysvartype_title
            }
        }
}`;

const REF_ACT_SEARCH = gql`
    query ReferenceActivitySearch($param: ReferenceActivitySearchInput!){
        referenceActivitySearch(param: $param){
            _id
            ra_ref
            ra_title
            ra_desc
        }
}`;

const VA_BY_ID_QUERY = gql`
    query VerificationActivity($vaId: ID!){
        verificationActivity(_id: $vaId){
            _id
            va_ref
            va_client_ref
            va_short_desc
            va_long_desc
            sece_id{
                _id
            }
            ps_id {
                _id
                ps_ref
                ps_title
                ps_desc
                ps_criteria
            }
        }
}`;

const VAS_BY_ID_QUERY = gql`
query VasByIdList($idList: [ID]!){
    vasByIdList(idList: $idList){
        _id
        va_client_ref
        va_short_desc
        va_long_desc
        va_archived
        va_status
        dh_id{
            _id
            dh_name
        }
        ag_id{
            _id
            ag_title
          }
        installation_id{
            _id
            installation_title
        }
        bar_id{
            _id
            bar_title
        }
        sece_id {
          _id  
          sece_client_ref
          sece_title
        }
        ps_id {
            _id  
            ps_ref
            ps_title
            ps_desc
            ps_criteria
        }
    }
}`;

function ViewFinding(props) {
    const [findingType, setFindingType] = useState("");
    const [shouldSpin, setShouldSpin] = useState(true);

    const [statusList, setStatusList] = useState([]);

    const [verActivityObject, setVerActivityObject] = useState({});
    const [verActivityList, setVerActivityList] = useState([]);
    const [vaPerfStand, setVaPerfStand] = useState({});

    //const [reportList, setReportList] = useState([]);
    const [scheduledVaList, setScheduledVaList] = useState([]);

    const [dutyHolderObject, setDutyHolderObject] = useState({});
    const [assetGroupObject, setAssetGroupObject] = useState({});
    const [barrierObject, setBarrierObject] = useState({});
    const [installationObject, setInstallationObject] = useState({});
    //const [seceObject, setSeceObject] = useState({});
    const [seceList, setSeceList] = useState([]);
    const [authorFullName, setAuthorFullName] = useState("");

    const [linkedInstallFindingList, setLinkedInstallFindingList] = useState([]);
    const [linkedDhFindingList, setLinkedDhFindingList] = useState([]);

    const [previousInstallFindingList, setPreviousInstallFindingList] = useState([]);
    const [previousDhFindingList, setPreviousDhFindingList] = useState([]);

    const [findingResponseList, setFindingResponseList] = useState([]);
    //const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [findingObject, setFindingObject] = useState({});
    //const [navOrigin, setNavOrigin] = useState("");
    const [isFromNotificationPage, setIsFromNotificationPage] = useState(false);
    const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

    const { findingId = "" } = useParams(); //form add or edit state passed from link
    const { location: {search = "" } = {} } = props ? props : {};

    //const history = useHistory();
    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);

    const [systemVariablesBySysVarTypeIds, { error: sysError, data: sysVarsData }] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);
    const [findingsByDhId, { loading: fndByDhLoading, error: fndByDhError, data: findingsByDhIdData }] = useLazyQuery(FINDINGS_BY_DH_QUERY);
    const [findingsByInstId, { loading: fndByInstLoading, error: fndByInstError, data: findingsByInstIdData }] = useLazyQuery(FINDINGS_BY_INST_QUERY);

    const [referenceActivitySearch, { data: refActData }] = useLazyQuery(REF_ACT_SEARCH);
    const [verificationActivity, { data: vaQueryData }] = useLazyQuery(VA_BY_ID_QUERY);
    const [vasByIdList, { data: scheduledVAData }] = useLazyQuery(VAS_BY_ID_QUERY);

    const [findingQuery, { error: fndError, data: findingData }] = useLazyQuery(FINDING_QUERY);

    const sysVarTypeIds = [STATUS_SYSTEM_VARIABLE_TYPE_ID,
        NATURE_SYSTEM_VARIABLE_TYPE_ID,
        ANSWERS_SYSTEM_VARIABLE_TYPE_ID,
        FINDING_LEVEL_SYSTEM_VARIABLE_TYPE_ID];

    const prevFndColumn = React.useMemo(
        () => [
            {
                Header: 'Finding Ref.',
                accessor: 'fnd_ref',
            },
            {
                Header: 'Finding Level',
                accessor: 'fnd_level',
            },
            {
                Header: 'Date Raised',
                accessor: 'fnd_date_raised',
            },
            {
                Header: 'Synopsis',
                accessor: 'fnd_synopsis',
            },
            {
                Header: 'Action Party',
                accessor: 'fnd_action_party',
            },
            {
                Header: 'Author',
                accessor: 'fnd_author',
            },
            {
                Header: 'Closure Date',
                accessor: 'fnd_closure_date',
            },
            {
                Header: 'Current Status',
                accessor: 'fnd_current_status',
            },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: ({ value }) => (
                    <div className="text-right">
                        <Link
                            to={{
                                pathname: `/reporting/finding/view/${value.id}`,
                                search: "?origin=view-finding"
                            }}
                            className="button-red mb-1 mr-1 text-xs"
                            target={"_blank"}
                        >
                            View
                        </Link>
                    </div>
                ),
            },
        ],
        []
    )

    useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        } catch (error) {
            window.scrollTo(0, 0);
        };

        //let searchQueryParams = new URLSearchParams(search);
        //let origin = searchQueryParams.get("origin");
        //setNavOrigin(origin);

        setPreviousInstallFindingList([]); //Temp Measure
        setPreviousDhFindingList([]); //Temp Measure 
        systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });
    }, []);

    useEffect(() => {
        if (authContext && authContext.user) {
            //let result = createUserTypeAndRoleObject(authContext);
            //setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        if (isInitialPageLoad) {
          setIsInitialPageLoad(false);
          return;
        }
        //history.push('/');
      }, [navContext]);

    useEffect(() => {
        if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
            formatSysVarDataAndUpdateStates(sysVarsData.systemVariablesBySysVarTypeIds,
                setStatusList,
                STATUS_SYSTEM_VARIABLE_TYPE_ID
            );

            if ((findingId !== "")) {

                let searchQueryParams = new URLSearchParams(search);
                let notificationId = searchQueryParams.get("notification_id");

                if(notificationId){
                    setIsFromNotificationPage(true)
                }

                let payload = {
                    finding_id: findingId,
                    notification_id: notificationId
                }
                findingQuery({ variables: { param: { ...payload } }, errorPolicy: 'all' });
            };
        }
    }, [sysVarsData]);

    useEffect(() => {
        if (findingData && findingData.findingQuery) {
            let finding = findingData.findingQuery;
            setFindingType(finding.finding_type);

            let dutyHolder = finding.dh_id ? finding.dh_id : {};
            let assetGroup = finding.ag_id ? finding.ag_id : {};
            let installation = finding.installation_id ? finding.installation_id : {};
            let barrier = finding.barrier_id ? finding.barrier_id : {};
            let sece = finding.sece_id ? finding.sece_id : {};
            let seces = finding.sece_ids ? finding.sece_ids : [];

            let verAct = finding.va_id ? finding.va_id : {};
            let vaList = finding.va_ids ? finding.va_ids : [];
            let vaIdList = vaList.map(v => v._id);
            setVerActivityList(vaList);

            const { _id: vsId = "" } = finding.vs_id ? finding.vs_id : {};

            const { _id: reportId = "" } = finding.report_id ? finding.report_id : {};

            //let report_ids = finding.report_ids;
            //setReportList(report_ids);

            let fndId = finding._id ? finding._id : "";
            let dutyHolderId = dutyHolder._id ? dutyHolder._id : "";
            let assetGroupId = assetGroup._id ? assetGroup._id : "";
            let installationId = installation._id ? installation._id : "";
            let barrierId = barrier._id ? barrier._id : "";
            let seceId = sece._id ? sece._id : "";
            let seceIds = seces.map(se => se._id);

            const { user_fname: authFName, user_lname: authLName } = finding.finding_auth ? finding.finding_auth : {};
            let findingAuth = `${authFName} ${authLName}`;

            let findingAttachments = finding.finding_ps_reference_attachments ? finding.finding_ps_reference_attachments : "[]";
            let parsedFindingAttachments = parseJSONSafely(findingAttachments);
            let attachments = finding.finding_attachments ? finding.finding_attachments : "[]";
            let parsedAttachments = parseJSONSafely(attachments);

            let formated_fnd_responses = "[]";

            if(finding.finding_responses !== null){
                formated_fnd_responses =finding.finding_responses && finding.finding_responses.replace(/\s+/g, ' ');
            }

            let findingResponses = parseJSONSafely(formated_fnd_responses) !== null ? parseJSONSafely(formated_fnd_responses) : [];

            let dhUser = finding.finding_dh_responsible;
            let absUser = finding.finding_abs_responsible;

            let dhResponsible = dhUser ? (dhUser.user_fname + " " + dhUser.user_lname) : "N/A";
            let absResponsible = absUser ? (absUser.user_fname + " " + absUser.user_lname) : "N/A";

            let linkedInstFndList = finding.linked_install_fnds ? formatPreviousFindings(finding.linked_install_fnds, 150) : [];
            let linkedDhFndList = finding.linked_dh_fnds ? formatPreviousFindings(finding.linked_dh_fnds, 150) : [];

            let fndNatureList = finding.finding_nature_ids ? finding.finding_nature_ids : [];
            let fndNature = fndNatureList[0];
            let findingNature = fndNature ? fndNature.sysvar_title : "N/A";

            //let nature = natureList.find(n => n._id === finding.finding_nature);
            //let findingNature = nature ? nature.sysvar_title : "";

            let integrity = finding.finding_integrity;
            let findingIntegrity = integrity ? integrity.sysvar_title : "N/A";

            let legislative = finding.finding_legislative;
            let findingLegislative = legislative ? legislative.sysvar_title : "N/A";

            let paperworkProcess = finding.finding_pw_process;
            let findingPaperWorkProcess = paperworkProcess ? paperworkProcess.sysvar_title : "N/A";

            let rogi = finding.finding_rogi;
            let findingRogi = rogi ? rogi.sysvar_title : "N/A";

            const { sysvar_title: findingWorkOrderRaised = "N/A" } = finding.finding_work_order_raised ? finding.finding_work_order_raised : {};

            const { sysvar_title: findingContingencyActions = "N/A" } = finding.finding_contingency_actions ? finding.finding_contingency_actions : {};

            const { sysvar_title: findingDocUpdateRequired = "N/A" } = finding.finding_doc_update_required ? finding.finding_doc_update_required : {};

            let infomatrixValue = valueFromInfoMatrixLevel(finding.finding_infomatrix_level);

            if (finding.finding_type === DH_FINDING_TYPE) {
                infomatrixValue = finding.finding_level_summary;
            }

            let initValue = {
                report_id: reportId,
                finding_id: fndId,
                finding_ref: finding.finding_ref ? finding.finding_ref : "N/A",
                finding_status: finding.finding_current_status && finding.finding_current_status.sysvar_title ? finding.finding_current_status.sysvar_title : "N/A",
                //finding_level: finding.finding_level.sysvar_title && finding.finding_level.sysvar_title ? finding.finding_level.sysvar_title : "N/A",
                finding_level: infomatrixValue,

                finding_dh: dutyHolderId,
                finding_ag: assetGroupId,
                finding_installation: installationId,
                finding_barrier: barrierId,
                finding_sece: seceId,
                finding_seces: seceIds,

                finding_vs_id: vsId,

                finding_nature: findingNature,
                finding_equipment_no: finding.finding_equip_number ? finding.finding_equip_number : "N/A",
                finding_equipment_type: finding.finding_equip_type ? finding.finding_equip_type : "N/A",
                finding_integrity: findingIntegrity,
                finding_legislative: findingLegislative,
                finding_paperwork_process: findingPaperWorkProcess,
                finding_rogi: findingRogi,

                finding_close_date: finding.finding_closure_date ? new Date(finding.finding_closure_date) : "",
                finding_synopsis: finding.finding_synopsis ? finding.finding_synopsis : "",
                finding_summary: finding.finding_summary ? finding.finding_summary : "",
                finding_action: finding.finding_action ? finding.finding_action : "",
                finding_work_order_raised: findingWorkOrderRaised,
                finding_work_order_raised_desc: finding.finding_work_order_raised_desc ? finding.finding_work_order_raised_desc : "",
                finding_contingency_actions: findingContingencyActions,
                finding_response_date: finding.finding_response_date ? new Date(finding.finding_response_date) : new Date(),
                finding_completion_date: finding.finding_completion_date ? new Date(finding.finding_completion_date) : new Date(),

                finding_action_party: finding.finding_action_party ? (finding.finding_action_party.user_fname + " " + finding.finding_action_party.user_lname) : "N/A",
                finding_dh_responsible: dhResponsible,
                finding_abs_responsible: absResponsible,
                finding_doc_update_required: findingDocUpdateRequired,

                finding_ps_reference: finding.finding_ps_reference ? finding.finding_ps_reference : "",

                finding_ps_reference_attachments: parsedFindingAttachments,
                finding_attachments: parsedAttachments,

                fnd_attmnt_dest_path: finding.fnd_attmnt_dest_path ? finding.fnd_attmnt_dest_path : "",
                fnd_ps_ref_attmnt_dest_path: finding.fnd_ps_ref_attmnt_dest_path ? finding.fnd_ps_ref_attmnt_dest_path : "",
                fnd_resp_attmnt_dest_path: finding.fnd_resp_attmnt_dest_path ? finding.fnd_resp_attmnt_dest_path : "",

                finding_added_date: finding.finding_added_date,
                workflow_status: modifyFindingStatus(finding.finding_status),

                finding_type: finding.finding_type,

                finding_days_open: finding.finding_days_open,
                finding_days_since_last_response: finding.finding_days_since_last_response
            }

            // if (reportId !== "") {
            //     findingSearch({ variables: { param: { report_id: reportId } }, errorPolicy: 'all' });
            // };

            setAuthorFullName(findingAuth);

            setDutyHolderObject(dutyHolder);
            setAssetGroupObject(assetGroup);
            setInstallationObject(installation);
            setBarrierObject(barrier);
            //setSeceObject(sece);
            setSeceList(seces);
            setVerActivityObject(verAct);

            setLinkedInstallFindingList(linkedInstFndList)
            setLinkedDhFindingList(linkedDhFndList);

            setFindingResponseList(findingResponses);

            setFindingObject(initValue);

            if (finding.finding_type === MULTI_VA_FINDING_TYPE) {
                vasByIdList({ variables: { idList: vaIdList }, errorPolicy: 'all' });
            }

            findingsByDhId({ variables: { dhId: dutyHolderId, instId: installationId, seceIdList: seceIds, fndId: fndId }, errorPolicy: 'all' });
            findingsByInstId({ variables: { instId: installationId, seceIdList: seceIds, fndId: fndId }, errorPolicy: 'all' });

            setShouldSpin(false);
        }
    }, [findingData]);

    useEffect(() => {
        if (scheduledVAData && scheduledVAData.vasByIdList) {
            let scheduledVaList = scheduledVAData.vasByIdList;
            setScheduledVaList(scheduledVaList);
        }
    }, [scheduledVAData]);

    // useEffect(() => {
    //     if (findingSearchData && findingSearchData.findingSearch) {
    //         let findings = findingSearchData.findingSearch;
    //         let asbFindings = findings.filter(fnd => fnd.finding_added_id.user_type._id === USER_TYPE_ABS_ID);
    //         let dhFindings = findings.filter(fnd => fnd.finding_added_id.user_type._id === USER_TYPE_DUTY_HOLDER_ID);
    //         setLinkedInstallFindingList(asbFindings);
    //         setLinkedDhFindingList(dhFindings);
    //     }
    // }, [findingSearchData]);

    // useEffect(() => {
    //     if (prevFindingData && prevFindingData.previousFindings) {
    //         let findings = prevFindingData.previousFindings;
    //         let asbFindings = findings.filter(fnd => fnd.finding_added_id.user_type._id === USER_TYPE_ABS_ID);
    //         let dhFindings = findings.filter(fnd => fnd.finding_added_id.user_type._id === USER_TYPE_DUTY_HOLDER_ID);
    //         setPreviousInstallFindingList(asbFindings);
    //         setPreviousDhFindingList(dhFindings);
    //     }
    // }, [prevFindingData]);

    useEffect(() => {
        if (findingsByDhIdData && findingsByDhIdData.findingsByDhId) {
            let formatedPrevFndList = formatPreviousFindings(findingsByDhIdData.findingsByDhId);
            setPreviousDhFindingList(formatedPrevFndList);
        }
    }, [findingsByDhIdData]);

    useEffect(() => {
        if (findingsByInstIdData && findingsByInstIdData.findingsByInstId) {
            let formatedPrevInstFndList = formatPreviousFindings(findingsByInstIdData.findingsByInstId);
            setPreviousInstallFindingList(formatedPrevInstFndList);
        }
    }, [findingsByInstIdData]);

    useEffect(() => {
        if (verActivityObject && verActivityObject._id && verActivityObject._id !== "") {
            referenceActivitySearch({ variables: { param: { va_id: verActivityObject._id } }, errorPolicy: 'all' });
            verificationActivity({ variables: { vaId: verActivityObject._id }, errorPolicy: 'all' });
        }
    }, [verActivityObject]);

    useEffect(() => {
        if (refActData && refActData.referenceActivitySearch) {

        }
    }, [refActData]);

    useEffect(() => {
        if (vaQueryData && vaQueryData.verificationActivity) {
            let va = vaQueryData.verificationActivity;
            const { ps_id = {} } = va ? va : {};
            setVaPerfStand(ps_id);
        }
    }, [vaQueryData]);

    const toggleModal = () => {
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'File Name',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Description',
                accessor: 'description', // accessor is the "key" in the data
            },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: () => (<div></div>)
            },
        ],
        []
    )

    var docDefinition = {
        pageSize: 'A4',
        pageMargins: [40, 100, 40, 24],
        header: function (currentPage, pageCount) {

            var headerObj;
            if (currentPage % 2) {
                headerObj = {
                    table: {
                        headerRows: 0,
                        widths: ['75%', '25%'],
                        body: [
                            [
                                {
                                    border: [false, false, true, false],
                                    fillColor: '#002a4e',
                                    stack: [
                                        { text: 'View Finding', font: 'Montserrat', fontSize: 10, color: '#fff' },
                                        [{
                                            canvas: [
                                                {
                                                    type: 'rect',
                                                    x: 0,
                                                    y: 0,
                                                    w: 30,
                                                    h: 0.5,
                                                    r: 0,
                                                    lineWidth: 1,
                                                    lineColor: 'red',
                                                }
                                            ]
                                        }],
                                        { text: 'Finding Details', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                                    ]
                                },
                                {
                                    border: [true, false, false, false],
                                    fillColor: '#b40c1e',
                                    image: logoImage,
                                    width: 100
                                },
                            ]
                        ],
                    },
                    layout: {
                        vLineColor: function (i, node) { return 'white'; },
                        vLineWidth: function (i) { return 4; },
                        paddingLeft: function (i, node) { return 30; },
                        paddingRight: function (i, node) { return 10; },
                        paddingTop: function (i, node) { return 20; },
                        paddingBottom: function (i, node) { return 10; },
                    }
                };
            } else {
                headerObj = {
                    table: {
                        headerRows: 0,
                        widths: ['25%', '75%'],
                        body: [
                            [
                                {
                                    border: [false, false, true, false],
                                    fillColor: '#b40c1e',
                                    image: logoImage,
                                    width: 100
                                },
                                {
                                    border: [true, false, false, false],
                                    fillColor: '#002a4e',
                                    stack: [
                                        { text: 'View Finding', font: 'Montserrat', fontSize: 10, color: '#fff' },
                                        [{
                                            canvas: [
                                                {
                                                    type: 'rect',
                                                    x: 0,
                                                    y: 0,
                                                    w: 30,
                                                    h: 0.5,
                                                    r: 0,
                                                    lineWidth: 1,
                                                    lineColor: 'red',
                                                }
                                            ]
                                        }],
                                        { text: 'Finding Details', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                                    ]
                                },
                            ]
                        ],
                    },
                    layout: {
                        vLineColor: function (i, node) { return 'white'; },
                        vLineWidth: function (i) { return 4; },
                        paddingLeft: function (i, node) { return 30; },
                        paddingRight: function (i, node) { return 10; },
                        paddingTop: function (i, node) { return 20; },
                        paddingBottom: function (i, node) { return 10; },
                    }
                };
            }

            return headerObj;
        },
        footer: function (currentPage, pageCount) {

            var footerObj;

            if (currentPage % 2) {
                footerObj = {
                    table: {
                        widths: [30, '*', 100, 28],
                        body: [
                            [
                                { text: ' ', border: [false, false, false, false] },
                                { text: 'View Finding', style: 'footerText', border: [false, false, false, false] },
                                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                                { text: ' ', border: [true, false, false, false] }
                            ]
                        ]
                    },
                    layout: {
                        vLineColor: function (i, node) { return '#b40c1e'; },
                    }
                }
            } else {
                footerObj = {
                    table: {
                        widths: [30, 100, '*', 28],
                        body: [
                            [
                                { text: ' ', border: [false, false, true, false] },
                                { text: currentPage.toString(), border: [true, false, false, false] },
                                { text: `© ${new Date().getFullYear()} American Bureau of Shipping. All rights reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                                { text: ' ', border: [false, false, false, false] }
                            ]
                        ]
                    },
                    layout: {
                        vLineColor: function (i, node) { return '#b40c1e'; },
                    }
                }
            }

            return footerObj;
        },
        info: {
            title: 'View Finding',
        },
        content: [
            {
                table: {
                    widths: ['25%', '25%', '25%', '25%'],
                    headerRows: 0,
                    body: [
                        [
                            { text: 'Date Raised', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            findingObject.finding_added_date && findingObject.finding_added_date !== "" ? formatDateToLocaleDateString(findingObject.finding_added_date) : "N/A",
                            { text: 'Date Closed', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            findingObject.finding_close_date && findingObject.finding_close_date !== "" ? formatDateToLocaleDateString(findingObject.finding_close_date) : "N/A"
                        ],

                        [
                            { text: 'Finding Reference', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            findingObject.finding_ref !== "" ? findingObject.finding_ref : "N/A",
                            { text: 'Finding Current Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            findingObject.finding_status && findingObject.finding_status !== "" ? findingObject.finding_status : "N/A"
                        ],

                        [
                            { text: 'Duty Holder', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            dutyHolderObject.dh_name && dutyHolderObject.dh_name !== "" ? dutyHolderObject.dh_name : "N/A",
                            { text: 'Asset Group', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            assetGroupObject.ag_title && assetGroupObject.ag_title !== "" ? assetGroupObject.ag_title : "N/A"
                        ],

                        [
                            { text: 'Installation', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            installationObject.installation_title && installationObject.installation_title !== "" ? installationObject.installation_title : "N/A",
                            { text: 'Barrier', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            barrierObject.bar_title && barrierObject.bar_title !== "" ? barrierObject.bar_title : "N/A"
                        ],
                        ...createSeceVaTableRows({ findingType, scheduledVaList, seceList, verActivityObject, vaPerfStand }),

                        [
                            { text: 'Finding Level', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            findingObject.finding_level,
                            { text: 'Risk Ranking', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            "N/A"
                        ],

                        [
                            { text: 'Author', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            authorFullName && authorFullName !== "" ? authorFullName : "N/A",
                            { text: 'Nature', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            `${createNatureValueFrom({ findingType, findingObject, verActivityList, verActivityObject })}`
                        ],

                        [
                            { text: 'Equipment No.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            findingObject.finding_equipment_no,
                            { text: 'Equipment Type', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            findingObject.finding_equipment_type
                        ],

                        [
                            { text: 'Integrity', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            findingObject.finding_integrity,
                            { text: 'Legislative', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            findingObject.finding_legislative
                        ],

                        [
                            { text: 'Paperwork Process', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'] },
                            findingObject.finding_paperwork_process,
                            { text: 'ROGI', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'] },
                            findingObject.finding_rogi
                        ],

                    ]
                },
                style: 'standardTable',
            },
            { text: "Previous Installation Findings", style: 'title' },
            {
                style: 'tableSM',
                table: {
                    widths: ['auto', 'auto', 'auto', '*', '*', '*', '*', 'auto'],
                    headerRows: 1,
                    body: [
                        //header
                        [
                            { text: 'Finding Ref.', style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#000'] },
                            { text: 'Finding Level', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                            { text: 'Date Raised', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                            { text: 'Synopsis', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                            { text: 'Action Party', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                            { text: 'Author', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                            { text: 'Closure Date', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                            { text: 'Current Status', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'] },
                        ],
                        ...createPreviousFindingsTableRows(previousInstallFindingList),
                    ]
                }
            },

            { text: "Previous Duty Holder Findings", style: 'title' },

            {
                style: 'tableSM',
                table: {
                    widths: ['auto', 'auto', 'auto', '*', '*', '*', '*', 'auto'],
                    headerRows: 1,
                    body: [
                        //header
                        [
                            { text: 'Finding Ref.', style: 'StandardTableHeader', borderColor: ['#000', '#000', '#fff', '#000'] },
                            { text: 'Finding Level', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                            { text: 'Date Raised', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                            { text: 'Synopsis', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                            { text: 'Action Party', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                            { text: 'Author', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                            { text: 'Closure Date', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#fff', '#000'] },
                            { text: 'Current Status', style: 'StandardTableHeader', borderColor: ['#fff', '#000', '#000', '#000'] },
                        ],
                        ...createPreviousFindingsTableRows(previousDhFindingList),
                    ]
                }
            },

            {
                table: {
                    widths: ['*'],
                    headerRows: 0,
                    body: [
                        [{ text: 'Synopsis', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
                        [findingObject.finding_synopsis],

                        //...createActivityPSTableRows({ findingType, scheduledVaList, verActivityObject, vaPerfStand }),                     

                        [{ text: 'Reference', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
                        [findingObject.finding_ps_reference && findingObject.finding_ps_reference !== "" ? htmlToPdfmake(findingObject.finding_ps_reference) : "N/A"],

                        [{ text: 'Reference Attachments', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
                        [
                            {
                                table: {
                                    widths: ['50%', '50%'],
                                    headerRows: 1,
                                    body: [
                                        [
                                            { text: 'File Name', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                                            { text: 'Description', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }
                                        ],
                                        ...createAttachmentsTableRows(findingObject.finding_ps_reference_attachments),
                                    ]
                                }
                            }
                        ],

                        [{ text: 'Finding', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
                        [findingObject.finding_summary && findingObject.finding_summary !== "" ? htmlToPdfmake(findingObject.finding_summary) : "N/A"],

                        [{ text: 'Action', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
                        [findingObject.finding_action && findingObject.finding_action !== "" ? htmlToPdfmake(findingObject.finding_action) : "N/A"],

                        [{ text: 'Work Order Raised', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
                        [findingObject.finding_work_order_raised],

                        [{ text: 'Work Order Description', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
                        [findingObject.finding_work_order_raised_desc && findingObject.finding_work_order_raised_desc !== "" ? findingObject.finding_work_order_raised_desc : "N/A"],

                        [{ text: 'Contingency Actions Addressing Finding', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
                        [findingObject.finding_contingency_actions],

                        [{ text: 'Attachments', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }],
                        [
                            {
                                table: {
                                    widths: ['50%', '50%'],
                                    headerRows: 1,
                                    body: [
                                        [
                                            { text: 'File Name', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                                            { text: 'Description', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }
                                        ],
                                        ...createAttachmentsTableRows(findingObject.finding_attachments),
                                    ]
                                }
                            }
                        ],
                    ]
                },
                style: 'standardTable',
            },

            {
                table: {
                    widths: ['25%', '25%', '25%', '25%'],
                    headerRows: 0,
                    body: [
                        [
                            { text: 'Response Date', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            formatDate(findingObject.finding_response_date),
                            { text: 'Completion Date', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            formatDate(findingObject.finding_completion_date)
                        ],

                        [
                            { text: 'Action Party', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            findingObject.finding_action_party,
                            { text: 'Duty Holder Responsible', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            findingObject.finding_dh_responsible
                        ],

                        [
                            { text: 'ABS Responsible', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'] },
                            findingObject.finding_abs_responsible,
                            { text: 'Document Update Required', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'] },
                            findingObject.finding_doc_update_required
                        ],

                    ]
                },
                style: 'standardTable',
            },

            {
                table: {
                    widths: ['20%', '20%', '10%', '20%', '20%', '10%'],
                    headerRows: 0,
                    body: [
                        [{ text: 'Linked Installation Findings', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'], colSpan: 6 }, {}, {}, {}, {}, {}],
                        ...createLinkedFindingsTableRows(linkedInstallFindingList),
                    ]
                },
                style: 'standardTable',
            },

            {
                table: {
                    widths: ['20%', '20%', '10%', '20%', '20%', '10%'],
                    headerRows: 0,
                    body: [
                        [{ text: 'Linked Duty Holder Findings', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'], colSpan: 6 }, {}, {}, {}, {}, {}],
                        ...createLinkedFindingsTableRows(linkedDhFindingList),
                    ]
                },
                style: 'standardTable',
            },

            {
                table: {
                    widths: ['20%', '20%', '10%', '20%', '20%', '10%'],
                    headerRows: 0,
                    body: [
                        [{ text: 'Finding Responses ', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'], colSpan: 6 }, {}, {}, {}, {}, {}],
                        ...createFindingResponseTableRows(findingResponseList),
                    ]
                },
                style: 'standardTable',
            },
        ],
        defaultStyle: {
            font: 'Tinos',
            fontSize: 10,
            color: '#231f20',
            lineHeight: 1.4
        },
        styles: {
            title: {
                marginTop: 10,
                font: 'Montserrat',
                fontSize: 10,
                bold: true,
                color: '#002a4e'
            },
            standardTable: {
                margin: [0, 5, 0, 20],
                lineHeight: 1.2
            },
            tableSM: {
                fontSize: 8
            },
            StandardTableHeader: {
                fillColor: '#002a4e',
                color: '#fff',
                lineHeight: 1
            },
            footerTitle: {
                font: 'Montserrat',
                color: '#002a4e',
            },
            footerText: {
                font: 'Montserrat',
                fontSize: 10
            }
        }
    }

    //open in new tab
    const pdfTab = () => {
        pdfMake.createPdf(docDefinition).open();
    }

    if (shouldSpin) {
        return (
            <PageSpinner />
        )
    }

    if (sysError || fndError) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">
            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{CreateTitle(findingType)}</h1>
            </div>

            <Formik
                enableReinitialize
                initialValues={{}}
                validationSchema={{}}
                onSubmit={(values, actions) => {
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="my-4 flex">
                            {/* navOrigin !== "" && (navOrigin !== "activity-report" && navOrigin !== "view-finding") ?
                                <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>
                                : <></>
                            */}
                            {
                               isFromNotificationPage || 
                               findingObject.workflow_status === NOTIFICATION_STATUS_PENDING ||
                                    (findingObject.workflow_status === NOTIFICATION_STATUS_APPROVED && findingObject.finding_status === "Closed")
                                    ?
                                    <></> :
                                    <Link to={createLocationFrom({ finding: findingObject })} className="button-red mr-2">Edit</Link>
                            }

                            <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>
                        </div>

                        <div className="block overflow-x-auto">
                            <table style={{ minWidth: '600px' }} className="table-report w-full mb-4 border">
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Date Raised</th>
                                        <td>
                                            {findingObject.finding_added_date && findingObject.finding_added_date !== "" ? formatDateToLocaleDateString(findingObject.finding_added_date) : "N/A"}
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Date Closed</th>
                                        <td>
                                            {findingObject.finding_close_date && findingObject.finding_close_date !== "" ? formatDateToLocaleDateString(findingObject.finding_close_date) : "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white w-1/4">Finding Reference</th>
                                        <td className="bg-gray-100 w-1/4">{findingObject.finding_ref !== "" ? findingObject.finding_ref : "N/A"}</td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white w-1/4">Finding Current Status</th>
                                        <td className="bg-gray-100 w-1/4">
                                            {findingObject.finding_status && findingObject.finding_status !== "" ? findingObject.finding_status : "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Duty Holder</th>
                                        <td className="">
                                            {dutyHolderObject.dh_name && dutyHolderObject.dh_name !== "" ? dutyHolderObject.dh_name : "N/A"}
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Asset Group</th>
                                        <td className="">
                                            {assetGroupObject.ag_title && assetGroupObject.ag_title !== "" ? assetGroupObject.ag_title : "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Installation</th>
                                        <td className="bg-gray-100">
                                            {installationObject.installation_title && installationObject.installation_title !== "" ? installationObject.installation_title : "N/A"}
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Barrier</th>
                                        <td className="bg-gray-100">
                                            {barrierObject.bar_title && barrierObject.bar_title !== "" ? barrierObject.bar_title : "N/A"}
                                        </td>
                                    </tr>

                                    <React.Fragment>
                                        {findingType === MULTI_VA_FINDING_TYPE ?
                                            <React.Fragment>
                                                {scheduledVaList.length > 0 ?
                                                    scheduledVaList.map((sch, index) =>
                                                        <React.Fragment key={index}>
                                                            <tr style={{ borderTop: "solid 1px gray" }}>
                                                                <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6"><label>Activity {index + 1}</label></th>
                                                            </tr>
                                                            <tr style={{ borderTop: "solid 1px #404040" }}>
                                                                <th className="bg-blue-800 font-sans font-semibold text-white">SECE Ref.</th>
                                                                <td className="bg-gray-100">{sch.sece_id && sch.sece_id.sece_client_ref && sch.sece_id.sece_client_ref !== "" ? sch.sece_id.sece_client_ref : "N/A"}</td>
                                                                <th className="bg-blue-800 font-sans font-semibold text-white">SECE Name</th>
                                                                <td className="bg-gray-100">
                                                                    {sch.sece_id && sch.sece_id._id ?
                                                                        <Link to={{ pathname: `/seces/view/${sch.sece_id._id}` }} className="hover:underline">{sch.sece_id && sch.sece_id.sece_title ? sch.sece_id.sece_title : "N/A"}</Link>
                                                                        :
                                                                        <span>{sch.sece_id && sch.sece_id.sece_title ? sch.sece_id.sece_title : "N/A"}</span>
                                                                    }
                                                                </td>
                                                            </tr>
                                                            <tr style={{ borderBottom: "solid 1px gray" }}>
                                                                <th className="bg-blue-900 font-sans font-semibold text-white">Verification Activity Ref.</th>
                                                                <td>
                                                                    {sch && sch._id && sch.va_client_ref ? (
                                                                        <Link
                                                                            to={{ pathname: `/verification-activities/view/${sch._id}` }}
                                                                            className="hover:underline"
                                                                            target="blank"
                                                                        >
                                                                            {sch.va_client_ref}
                                                                        </Link>
                                                                    ) : (
                                                                        <span>{"N/A"}</span>
                                                                    )}
                                                                </td>
                                                                <th className="bg-blue-900 font-sans font-semibold text-white">PS Ref.</th>
                                                                <td>
                                                                    {sch && sch.ps_id && sch.ps_id.ps_ref !== "" ?
                                                                        (<Link to={{ pathname: `/performance-standards/view/${sch.ps_id._id}` }}
                                                                            className="hover:underline">
                                                                            {sch.ps_id.ps_ref}
                                                                        </Link>) :
                                                                        (<span>{"N/A"}</span>)
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                {seceList.length > 0 ? seceList.map((se, index) =>
                                                    <tr key={index}>
                                                        <th className="bg-blue-900 font-sans font-semibold text-white">SECE Ref.</th>
                                                        <td className="">{se.sece_client_ref && se.sece_client_ref !== "" ? se.sece_client_ref : "N/A"}</td>
                                                        <th className="bg-blue-900 font-sans font-semibold text-white">SECE Name</th>
                                                        <td className="">
                                                            {se && se._id ?
                                                                <Link to={{ pathname: `/seces/view/${se._id}` }} className="hover:underline">{se && se.sece_title ? se.sece_title : "N/A"}</Link>
                                                                :
                                                                <span>{se && se.sece_title ? se.sece_title : "N/A"}</span>
                                                            }
                                                        </td>
                                                    </tr>
                                                ) :
                                                    <tr>
                                                        <th className="bg-blue-800 font-sans font-semibold text-white">SECE Ref.</th>
                                                        <td className="bg-gray-100">{"N/A"}</td>
                                                        <th className="bg-blue-800 font-sans font-semibold text-white">SECE Name</th>
                                                        <td className="bg-gray-100">
                                                            <Link to={"#"} className="hover:underline">{"N/A"}</Link>
                                                        </td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">Verification Activity Ref.</th>
                                                    <td className="bg-gray-100">{verActivityObject.va_client_ref && verActivityObject.va_client_ref !== "" ? verActivityObject.va_client_ref : "N/A"}</td>
                                                    
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">PS Ref.</th>
                                                    <td>                
                                                        {vaPerfStand && vaPerfStand.ps_ref ?
                                                            (<Link to={{ pathname: `/performance-standards/view/${vaPerfStand._id}` }}
                                                                className="hover:underline">
                                                               {vaPerfStand.ps_ref}
                                                            </Link>) :
                                                            (<span>{"N/A"}</span>)
                                                        }
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Finding Level</th>
                                        <td>
                                            {findingObject.finding_level}
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Risk Ranking</th>
                                        <td>{"N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Author</th>
                                        <td className="bg-gray-100">
                                            {authorFullName && authorFullName !== "" ? authorFullName : "N/A"}
                                        </td>

                                        <th className="bg-blue-800 font-sans font-semibold text-white">Nature</th>
                                        <td className="bg-gray-100">
                                            {(findingType === INST_FINDING_TYPE || findingType === DH_FINDING_TYPE) && findingObject.finding_nature}
                                            {verActivityList.length > 0 ? formatVaNature(verActivityList)
                                                : verActivityObject.va_type
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Equipment No.</th>
                                        <td>
                                            {findingObject.finding_equipment_no}
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Equipment Type</th>
                                        <td>
                                            {findingObject.finding_equipment_type}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Integrity</th>
                                        <td className="bg-gray-100">
                                            {findingObject.finding_integrity}
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Legislative</th>
                                        <td className="bg-gray-100">
                                            {findingObject.finding_legislative}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Paperwork Process</th>
                                        <td>
                                            {findingObject.finding_paperwork_process}
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">ROGI</th>
                                        <td>
                                            {findingObject.finding_rogi}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Days Open</th>
                                        <td className="bg-gray-100">
                                            {findingObject.finding_days_open}
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Days Since Last Response</th>
                                        <td className="bg-gray-100">
                                            {findingObject.finding_days_since_last_response}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Previous Installation Findings
                                            <span className="float-right">
                                                <FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} />
                                            </span>
                                        </th>
                                    </tr>

                                    <tr>
                                        <td>
                                            {fndByInstLoading ? <>Loading...</> : <></>}
                                            {fndByInstError ? <>Something went wrong while fetching data</> : <></>}
                                            {previousInstallFindingList.length > 0 ?
                                                <Table
                                                    columns={prevFndColumn}
                                                    data={previousInstallFindingList} />
                                                : (!fndByInstLoading ? "No Data To Display" : "")
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Previous Duty Holder Findings
                                            <span className="float-right">
                                                <FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} />
                                            </span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {fndByDhLoading ? <>Loading...</> : <></>}
                                            {fndByDhError ? <>Something went wrong while fetching data</> : <></>}
                                            {previousDhFindingList.length > 0 ?
                                                <Table
                                                    columns={prevFndColumn}
                                                    data={previousDhFindingList} />
                                                : (!fndByDhLoading ? "No Data To Display" : "")
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Synopsis</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                {findingObject.finding_synopsis ? findingObject.finding_synopsis : "N/A"}
                                            </div>
                                        </td>
                                    </tr>
                                  
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Reference
                                            <span className="float-right">
                                                {/*
                                                 <Link to={{ pathname: `/reporting/findings/${formMode}`, state: { formMode:formMode} }}  className="font-normal text-white underline">View Reference(s)</Link>
                                              */}
                                            </span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="mt-2">
                                                {findingObject.finding_ps_reference && findingObject.finding_ps_reference !== "" ?
                                                    <Sanitiser html={findingObject.finding_ps_reference} /> :
                                                    "N/A"
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Reference Attachments</th>
                                    </tr>
                                    <tr >
                                        <td>
                                            <div className="mb-3">
                                                {findingObject.finding_ps_reference_attachments && findingObject.finding_ps_reference_attachments.length ?
                                                    <Table columns={columns} data={createAttachList(findingObject.finding_ps_reference_attachments, findingObject.fnd_ps_ref_attmnt_dest_path)} pagination={false} filter={false} /> :
                                                    "No data to Display"
                                                }
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Finding</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="mt-2">

                                                {findingObject.finding_summary && findingObject.finding_summary !== "" ?
                                                    <Sanitiser html={findingObject.finding_summary} /> :
                                                    "N/A"
                                                }

                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Action</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="mt-2">

                                                {findingObject.finding_action && findingObject.finding_action !== "" ?
                                                    <Sanitiser html={findingObject.finding_action} /> :
                                                    "N/A"
                                                }

                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Work Order Raised</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {findingObject.finding_work_order_raised}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Work Order Description</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                {findingObject.finding_work_order_raised_desc && findingObject.finding_work_order_raised_desc !== "" ? findingObject.finding_work_order_raised_desc : "N/A"}
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Contingency Actions Addressing Finding</th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {findingObject.finding_contingency_actions}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Attachments <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="mb-3">
                                                {findingObject.finding_attachments && findingObject.finding_attachments.length ?
                                                    <Table columns={columns} data={createAttachList(findingObject.finding_attachments, findingObject.fnd_attmnt_dest_path)} pagination={false} filter={false} /> :
                                                    "No Data to Display"
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Response Date</th>
                                        <td>

                                            <div className="mt-5">
                                                {formatDate(findingObject.finding_response_date)}
                                            </div>

                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Completion Date</th>
                                        <td>

                                            <div className="mt-5">
                                                {formatDate(findingObject.finding_completion_date)}
                                            </div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Action Party</th>
                                        <td className="bg-gray-100">
                                            {findingObject.finding_action_party}
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Duty Holder Responsible</th>
                                        <td className="bg-gray-100">
                                            {findingObject.finding_dh_responsible}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">ABS Responsible</th>
                                        <td className="bg-gray-100">
                                            {findingObject.finding_abs_responsible}
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Document Update Required</th>
                                        <td>
                                            {findingObject.finding_doc_update_required}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="7">Linked Installation Findings </th>
                                    </tr>
                                    {linkedInstallFindingList.length > 0 ? linkedInstallFindingList.map((fnd, index) =>
                                        <React.Fragment key={index}>
                                            <tr>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Finding Ref.</th>
                                                <td>{fnd.fnd_ref}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Level</th>
                                                <td>{fnd.fnd_level}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Current Status</th>
                                                <td>{createStatusFrom(statusList, fnd.status_id)}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">
                                                </th>
                                            </tr>
                                            <tr>
                                                <th className="bg-blue-900 font-sans font-semibold text-white">Synopsis</th>
                                                <td className="bg-gray-100" colSpan="6">{fnd.fnd_synopsis}</td>
                                            </tr>
                                        </React.Fragment>
                                    ) :
                                        <tr>
                                            <td className="bg-gray-100" colSpan="6">{"No Data To Display"}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="7">Linked Duty Holder Findings </th>
                                    </tr>
                                    {linkedDhFindingList.length > 0 ? linkedDhFindingList.map((fnd, index) =>
                                        <React.Fragment key={index}>
                                            <tr>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Finding Ref.</th>
                                                <td>{fnd.fnd_ref}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Level</th>
                                                <td>{fnd.fnd_level}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Current Status</th>
                                                <td>{createStatusFrom(statusList, fnd.status_id)}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">
                                                </th>
                                            </tr>
                                            <tr>
                                                <th className="bg-blue-900 font-sans font-semibold text-white">Synopsis</th>
                                                <td className="bg-gray-100" colSpan="6">{fnd.fnd_synopsis}</td>
                                            </tr>
                                        </React.Fragment>
                                    ) :
                                        <tr>
                                            <td className="bg-gray-100" colSpan="6">{"No Data To Display"}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="7">Finding Responses </th>
                                    </tr>
                                    {findingResponseList.length > 0 ? findingResponseList.map((resp, index) =>
                                        <React.Fragment key={index}>
                                            <tr>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Updated By {resp.response_user_type ? resp.response_user_type : "N/A"}</th>
                                                <td>{resp.response_update_by ? resp.response_update_by : "N/A"}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Date</th>
                                                <td>{formatDate(resp.response_update_date)}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Current Status</th>
                                                <td>{resp.response_status ? resp.response_status : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <td className="bg-gray-100" colSpan="6">
                                                    {
                                                        resp.response_note ?
                                                            <Sanitiser html={resp.response_note} /> : "N/A"
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Response Attachments<span className="float-right"></span></th>
                                            </tr>
                                            <tr>
                                                <td colSpan="6">
                                                    <div className="mb-3">
                                                        {reformatResponseAttachment(resp.response_attachments) && reformatResponseAttachment(resp.response_attachments).length > 0 ?
                                                            <Table columns={columns} data={createAttachList((reformatResponseAttachment(resp.response_attachments)), findingObject.fnd_resp_attmnt_dest_path)} pagination={false} filter={false} /> :
                                                            "No Data to Display"
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ) :
                                        <tr>
                                            <td className="bg-gray-100" colSpan="6">{"No Data To Display"}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="my-4 flex">
                            {/* navOrigin !== "" && (navOrigin !== "activity-report" && navOrigin !== "view-finding") ?
                                <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>
                                : <></>
                            */ }
                            {
                                 isFromNotificationPage || findingObject.workflow_status === NOTIFICATION_STATUS_PENDING ||
                                    (findingObject.workflow_status === NOTIFICATION_STATUS_APPROVED &&
                                        findingObject.finding_status === "Closed")
                                    ?
                                    <></> :
                                    <Link to={createLocationFrom({ finding: findingObject })} className="button-red mr-2">Edit</Link>
                            }
                            <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>
                        </div>
                    </form>
                )}
            </Formik>

            {/* HELP TIP */}
            <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

                    <h3 className="mb-2 text-xl text-blue-900 font-bold">Help Tip</h3>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultricies nunc id vehicula pulvinar. Sed vitae luctus purus. Integer feugiat nisi porta lacus cursus, sed sollicitudin orci mattis. Praesent euismod ornare tortor eget aliquam. Vivamus fermentum convallis erat, at ultrices odio. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>

                </div>
            </div>
            {/* HELP TIP END */}

        </div>
    )
}

function reformatResponseAttachment(param) {
    return parseJSONSafely(param) ? parseJSONSafely(param) : [];
}

function formatVaNature(vaList) {
    let newVaNatureList = "N/A";
    let natureList = vaList.map(v => v.va_type);
    if (natureList && natureList.length) {
        let numberOfVaRefs = natureList.length;
        if (numberOfVaRefs > 1) {
            let tempVaNatures = "";
            natureList.forEach((nature, index) => {
                tempVaNatures += nature + ((index + 1) < numberOfVaRefs ? ", " : "");
            });
            newVaNatureList = tempVaNatures;
        } else {
            let nature = natureList[0]
            newVaNatureList = nature;
        }
    }
    return newVaNatureList;
}

function formatSysVarDataAndUpdateStates(list, setStatusList, statusTypeId) {
    let statusList = [];
    statusList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === statusTypeId && (sysVar.sysvar_title === "Open" || sysVar.sysvar_title === "Closed"));
    setStatusList(statusList);
}

function formatDate(date) {
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    let formatedDate = ""
    if (date && date !== "") {
        let newDate = new Date(date);
        formatedDate = newDate.toLocaleDateString('en-GB', options);
    }
    return formatedDate;
}

function createAttachList(attachments, fileDestPath) {
    let linkList = [];
    let hasDestPath = fileDestPath && fileDestPath !== "";
    if (attachments && attachments.length) {
        let fullPath = `${REACT_APP_FILE_MANAGER_API}/download-file?path=${hasDestPath ? ("/" + fileDestPath + "/") : ""}`;

        attachments.forEach((att, index) => {
            let link = <a href={hasDestPath ? (fullPath + att.name) : (fullPath + att.path + att.name)} className="hover:underline" key={index} target={"_blank"} rel="noopener noreferrer" download={true}>
                {att.name}
            </a>
            let newListItem = {
                name: link,
                description: att.description && att.description !== "" ? att.description : "N/A"
            }
            linkList.push(newListItem);
        })
    }
    return linkList;
}

export const createLocationFrom = ({ finding, search }) => {
    let location = {};
    if (finding) {
        const { finding_id = "", report_id = "", finding_type = "", finding_vs_id = "" } = finding;

        if (finding_id === "" && report_id === "") {
            return location;
        }

        if (report_id === "") {
            location = {
                pathname: `/reporting/finding/edit/${finding_id}`,
                state: { formMode: 'edit' },
                ...(search && search !== "" && { search: search })
            }
        } else {
            location = {
                pathname: `/reporting/${report_id}/finding/edit/${finding_id}`,
                state: { formMode: 'edit', rptId: report_id },
                ...(search && search !== "" && { search: search })
            }
        }

        if (finding_type === INST_FINDING_TYPE) {
            location = {
                pathname: `/reporting/finding/edit/${finding_id}`,
                state: {
                    formMode: 'edit',
                    type: INST_FINDING_TYPE,
                    vs_id: finding_vs_id,
                    title: "Edit Installation Finding"
                },
                ...(search && search !== "" && { search: search })
            }
        }

        if (finding_type === DH_FINDING_TYPE) {
            location = {
                pathname: `/reporting/finding/edit/${finding_id}`,
                state: {
                    formMode: 'edit',
                    type: DH_FINDING_TYPE,
                    vs_id: finding_vs_id,
                    title: "Edit Duty Holder Finding"
                },
                ...(search && search !== "" && { search: search })
            }
        }
    }
    return location;
}

function CreateTitle(findingType) {
    let newTitle = "View Finding";

    switch (findingType) {
        case INST_FINDING_TYPE:
            newTitle = "View Installation Finding"
            break;
        case DH_FINDING_TYPE:
            newTitle = "View Duty Holder Finding"
            break;
        default:
            break;
    }

    return newTitle;
}

export const createPreviousFindingsTableRows = (findingList) => {
    let rowList = [];

    if (!findingList || findingList.length === 0) {
        let emptyRow = ["No Data to Display", "", "", "", "", "", "", ""];
        rowList.push(emptyRow);
        return rowList;
    }

    findingList.forEach((finding, index) => {
        const {
            fnd_ref,
            fnd_level,
            fnd_date_raised,
            fnd_synopsis,
            fnd_action_party,
            fnd_author,
            fnd_closure_date,
            fnd_current_status
        } = finding ? finding : {};

        const isOddNumber = ((index) % 2) === 0;

        let row = [
            { text: fnd_ref, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
            { text: fnd_level, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
            { text: fnd_date_raised, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
            { text: fnd_synopsis, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
            { text: fnd_action_party, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
            { text: fnd_author, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
            { text: fnd_closure_date, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" },
            { text: fnd_current_status, fillColor: isOddNumber ? "#e5e6eb" : "#ffffff" }
        ]
        rowList.push(row);
    })

    return rowList;
}

export const createLinkedFindingsTableRows = (findingList) => {
    let rowList = [];

    if (!findingList || findingList.length === 0) {
        let emptyRow = ["No Data to Display", "", "", "", "", ""];
        rowList.push(emptyRow);
        return rowList;
    }

    findingList.forEach((finding, index) => {
        const {
            fnd_ref,
            fnd_level,
            fnd_current_status,
            fnd_synopsis,

        } = finding ? finding : {};

        let part1 = [
            { text: 'Finding Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
            `${fnd_ref}`,
            { text: 'Level', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
            `${fnd_level}`,
            { text: 'Current Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
            `${fnd_current_status}`
        ];

        let part2 = [{ text: 'Synopsis', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 6 }, {}, {}, {}, {}, {}];

        let part3 = [{ text: `${fnd_synopsis}`, alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'], colSpan: 6 }, {}, {}, {}, {}, {}];

        rowList.push(part1);
        rowList.push(part2);
        rowList.push(part3);
    })

    return rowList;
}

export const createFindingResponseTableRows = (findingResponseList) => {
    let rowList = [];

    if (!findingResponseList || findingResponseList.length === 0) {
        let emptyRow = ["No Data to Display", "", "", "", "", ""];
        rowList.push(emptyRow);
        return rowList;
    }

    findingResponseList.forEach((finding) => {
        const {
            response_user_type,
            response_update_by,
            response_update_date,
            response_status,
            response_note = "",
            response_attachments
        } = finding ? finding : {};

        let formatedUpdateDate = response_update_date && response_update_date !== "" ? formatDateToLocaleDateString(response_update_date) : "N/A";

        let detailAlt = htmlToPdfmake(response_note);
        let modifiedDetailAlt = getFirstElementOrObject(detailAlt) ?? {text: "N/A"};

        let part1 = [
            { text: `Updated By ${response_user_type}`, style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'] },
            `${response_update_by}`,
            { text: 'Date', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'] },
            `${formatedUpdateDate}`,
            { text: 'Current Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'] },
            `${response_status}`
        ];

        //let part2 = [{ text: `${formatedResponseNote}`, alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'], colSpan: 6 }, {}, {}, {}, {}, {}];
        let part2 = [{...modifiedDetailAlt, alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'], colSpan: 6}, {}, {}, {}, {}, {}];

        let part3 = [{ text: 'Response Attachments', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 6 }, {}, {}, {}, {}, {}];
        let part4 = [
            {
                table: {
                    widths: ['50%', '50%'],
                    headerRows: 1,
                    body: [
                        [
                            { text: 'File Name', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                            { text: 'Description', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }
                        ],
                        ...createAttachmentsTableRows(response_attachments),
                    ]
                }
                , colSpan: 6
            }, {}, {}, {}, {}, {}
        ];

        rowList.push(part1);
        rowList.push(part2);
        rowList.push(part3);
        rowList.push(part4);
    })

    return rowList;
}

export const createAttachmentsTableRows = (attachements) => {
    let rowList = [];

    attachements = attachements && attachements !== "" ? parseJSONSafely(attachements) : [];

    if (!attachements || attachements.length === 0) {
        let emptyRow = ["No Data to Display", ""];
        rowList.push(emptyRow);
        return rowList;
    }

    attachements.forEach((attachement) => {
        const {
            name,
            description
        } = attachement ? attachement : {};

        let row = [
            `${name}`,
            `${description}`,
        ];
        rowList.push(row);
    })

    return rowList;
}

export const createNatureValueFrom = ({ findingType, findingObject, verActivityList, verActivityObject }) => {
    let nature = "N/A";

    if (!findingType && !findingObject && !verActivityList && !verActivityObject) {
        return nature;
    } else if (findingType === INST_FINDING_TYPE || findingType === DH_FINDING_TYPE) {
        nature = findingObject.finding_nature;
    } else if (verActivityList.length > 0) {
        nature = formatVaNature(verActivityList)
    } else {
        nature = verActivityObject.va_type
    }

    return nature;
}

export const createSeceVaTableRows = ({ findingType, scheduledVaList, seceList, verActivityObject, vaPerfStand }) => {
    let rowList = [];

    if (!findingType && !scheduledVaList && !seceList && !verActivityObject) {
        let part1 = [
            { text: 'SECE Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
            "N/A",
            { text: 'SECE Name', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
            "N/A",
        ];

        let part2 = [
            { text: 'Verification Activity Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
            "N/A",
            { text: 'PS Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
            "N/A",
        ];
        rowList.push(part1);
        rowList.push(part2);
        return rowList;
    }

    if (findingType === MULTI_VA_FINDING_TYPE) {
        scheduledVaList.forEach((sch, index) => {
            const {
                sece_id,
                ps_id,
                va_client_ref,
            } = sch ? sch : {};

            const {
                sece_client_ref,
                sece_title,
            } = sece_id ? sece_id : {};

            const {
                ps_ref,
            } = ps_id ? ps_id : {};

            let part1 = [
                { text: `Activity ${index + 1}`, style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                "",
                { text: '', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                ""
            ];


            let part2 = [
                { text: 'SECE Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                `${sece_client_ref}`,
                { text: 'SECE Name', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                `${sece_title}`
            ];

            let part3 = [
                { text: 'Verification Activity Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                `${va_client_ref}`,
                { text: 'PS Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                `${ps_ref}`,
            ];

            rowList.push(part1);
            rowList.push(part2);
            rowList.push(part3);
        })
    } else {
        seceList.forEach((sece, index) => {
            const {
                sece_client_ref,
                sece_title,
            } = sece ? sece : {};

            let part1 = [
                { text: 'SECE Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                `${sece_client_ref}`,
                { text: 'SECE Name', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                `${sece_title}`
            ];

            rowList.push(part1);
        })

        const {
            va_client_ref,
        } = verActivityObject ? verActivityObject : {};

        const {
            ps_ref,
        } = vaPerfStand ? vaPerfStand : {};

        let part2 = [
            { text: 'Verification Activity Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
            `${va_client_ref}`,
            { text: 'PS Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
            `${ps_ref}`,
        ];

        rowList.push(part2);
    }

    return rowList;
}

// export const createActivityPSTableRows = ({ findingType, scheduledVaList, verActivityObject, vaPerfStand }) => {
//     let rowList = [];

//     if (!findingType && !scheduledVaList && !verActivityObject && !vaPerfStand) {
//         let part1 = [{ text: 'Verification Activity', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }];
//         let part2 = [{ text: 'No Data to Display', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }];
        
//         rowList.push(part1);
//         rowList.push(part2);
//         return rowList;
//     }

//     if (findingType === MULTI_VA_FINDING_TYPE) {
//         scheduledVaList.forEach((sch, index) => {
//             const {ps_id,  va_long_desc = "N/A"} = sch ? sch : {};

//             const {ps_criteria} = ps_id ? ps_id : {};

//             let part1 = [{ text: `Activity ${index + 1}`, style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }];

//             let part2 = [{ text: 'Verification Activity Description', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }];
//             let part3 = [va_long_desc];

//             let part4 = [{ text: 'Performance Standard Criteria', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }];
//             let part5 = [ps_criteria && ps_criteria !== "" ? htmlToPdfmake(ps_criteria) : "N/A"];

//             rowList.push(part1);
//             rowList.push(part2);
//             rowList.push(part3);
//             rowList.push(part4);
//             rowList.push(part5);
//         })
//     } else {

//        let part1 = [{ text: 'Verification Activity', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }];
//        let part2 = [verActivityObject.va_long_desc ? verActivityObject.va_long_desc : "N/A"];

//        let part3 = [{ text: 'Performance Standard Criteria', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }];
//        let part4 = [vaPerfStand.ps_criteria && vaPerfStand.ps_criteria !== "" ? htmlToPdfmake(vaPerfStand.ps_criteria) : "N/A"];
        
//        rowList.push(part1);
//        rowList.push(part2);
//        rowList.push(part3);
//        rowList.push(part4);
//     }

//     return rowList;
// }

function modifyFindingStatus(status) {
    let newStatus = "";
    if (status && status !== "") {
        status = lowerCase(status);
        switch (status) {
            case "open":
                newStatus = "unapproved";
                break;
            case "closed":
                newStatus = "approved";
                break;
            default:
                newStatus = status;
                break;
        }
    }
    return newStatus;
}

export default ViewFinding;