/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useContext} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import Table from '../global/Table';
import TableDropDown from '../global/TableDropDown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import PageSpinner from "../global/pageSpinner";
import {AuthContext} from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import {capitaliseFirstCase, createUserTypeAndRoleObject} from "../../utils/index";
import BreadCrumb from "../global/Breadcrumb";
import {NOTIFICATION_STATUS_APPROVED, NOTIFICATION_STATUS_REJECT, NOTIFICATION_STATUS_REVISION} from "../../constants";
import Comment from '../global/Comment';
import {sysObjects} from "../../constants/sysObjectList";

const dropdownListItems = ({dutyHolderId, assetGroupId, installationId, barrierId, seceId, mahId, raId, psId}) => (
  assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined ?
    [
      {
        id: 1,
        name: 'Verification Activities',
        link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activities`
      },
      {
        id: 2,
        name: 'Reference Activities',
        link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/reference-activities`
      }
    ]
    : assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined ?
    [
      {
        id: 1,
        name: 'Verification Activities',
        link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activities`
      },
      {
        id: 2,
        name: 'Reference Activities',
        link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/reference-activities`
      }
    ]
    : null
);

const PS_BY_SECE_ID_QUERY = gql`
    query PerformanceStandardsBySeceId ($seceId: ID!){
        performanceStandardsBySeceId (seceId: $seceId){
            _id
            ps_ref
            ps_title
            ps_ref
            ps_criticality
            ps_archived
            ps_archived_id
            ps_archived_date
            installation_id{
                installation_title
            }
            bar_id{
                bar_title
            }
            ps_status
        }
    }`;

const UPDATE_PERFORMANCE_STANDARD = gql`
    mutation UpdatePerformanceStandard($psId: String!, $userId: String, $ps: UpdatePerformanceStandardInput!){
        updatePerformanceStandard(_id: $psId, userId: $userId, ps: $ps){
            _id
        }
    }`;

function PerformanceStandards(props) {

  const [showHelp, setShowHelp] = useState(false);
  const [psList, setPsList] = useState([]);
  const [showArchivedPs, setShowArchivedPs] = useState(false);
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

  const {id, assetGroupId, installationId, barrierId, seceId, mahId, raId} = useParams();

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const history = useHistory();

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const {location: {state = ""}, match: {url = ""}} = props ? props : {};

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (isInitialPageLoad) {
      setIsInitialPageLoad(false);
      return;
    }
    history.push('/');
  }, [navContext]);

  const [performanceStandardsBySeceId, {loading, error, data: psData, refetch}] = useLazyQuery(PS_BY_SECE_ID_QUERY);
  const [updatePerformanceStandard, {data: updatePsData}] = useMutation(UPDATE_PERFORMANCE_STANDARD);

  useEffect(() => {
    if (state === "archived") {
      setShowArchivedPs(true);
    }
  }, []);

  useEffect(() => {
    if (seceId) {
      performanceStandardsBySeceId({variables: {seceId: seceId}, errorPolicy: 'all'});
    }
  }, [showArchivedPs]);

  useEffect(() => {
    if (psData && psData.performanceStandardsBySeceId) {
      const {_id: userId = ""} = authContext.user ? authContext.user : {};
      let tempPsList = formatPsData(psData.performanceStandardsBySeceId, showArchivedPs, userId, userTypeAndRoleObject);
      setPsList(tempPsList);
    }
  }, [psData]);

  useEffect(() => {
    if (updatePsData && updatePsData.updatePerformanceStandard) {
      refetch();
    }
  }, [updatePsData]);

  const archiveManager = (psId, isArchived, archivedId, archivedDate, userId) => {
    if (psId !== "") {
      const payload = {
        ps_archived: isArchived ? false : true,
        ps_archived_id: isArchived ? archivedId : userId,
        ps_archived_date: isArchived ? archivedDate : new Date()
      }
      updatePerformanceStandard({variables: {psId: psId, userId: userId, ps: payload}});
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Ref.',
        accessor: 'ps_ref',
      },
      {
        Header: 'Performance Standard',
        accessor: 'ps_title',
      },
      {
        Header: 'Criticality',
        accessor: 'ps_criticality',
      },
      {
        Header: 'Installation',
        accessor: 'installation_id.installation_title',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({value: {_id, ps_archived, ps_archived_id, ps_archived_date, user_id, ps_status, userTypeAndRoleObject}}) => {
          return (
            <div className="text-right">
              <Comment sysObjName={sysObjects.performanceStandard}
                sysObjRecordId={_id}
                buttonName={"Comments"}
                buttonClassName={"button-red mb-1 mr-1 text-xs"} />

              {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/view/${_id}`,
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {ps_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (ps_status === NOTIFICATION_STATUS_APPROVED ||
                      ps_status === NOTIFICATION_STATUS_REJECT ||
                      ps_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/edit/${_id}`,
                        state: { formMode: 'edit' }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }
              {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/view/${_id}`,
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {ps_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (ps_status === NOTIFICATION_STATUS_APPROVED ||
                      ps_status === NOTIFICATION_STATUS_REJECT ||
                      ps_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/edit/${_id}`,
                        state: { formMode: 'edit' }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }

              {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                (ps_status === NOTIFICATION_STATUS_APPROVED ||
                  ps_status === NOTIFICATION_STATUS_REJECT ||
                  ps_status === NOTIFICATION_STATUS_REVISION) &&
                <Link
                  to="#"
                  className="button-red mb-1 mr-1 text-xs"
                  onClick={() => {
                    archiveManager(_id, ps_archived, ps_archived_id, ps_archived_date, user_id)
                  }}
                >
                  {ps_archived ? 'Unarchive' : 'Archive'}
                </Link>
              }

              {ps_status !== NOTIFICATION_STATUS_REJECT &&
                <TableDropDown title="More" items={dropdownListItems({
                  dutyHolderId: id,
                  assetGroupId: assetGroupId,
                  installationId: installationId,
                  barrierId: barrierId,
                  seceId: seceId,
                  mahId: mahId,
                  raId: raId,
                  psId: _id
                })} />}

              {ps_status === NOTIFICATION_STATUS_REJECT && <Link
                to="#"
                className="button-red mr-1 text-xs"
                style={{ textDecorationLine: 'line-through' }}
              >
                More
              </Link>
              }

            </div>
          )
        }
      },
    ],
    []
  )

  if (loading) {
    return (
      <PageSpinner/>
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Performance Standards <FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)}/></h1>

        {showHelp &&
        <div>
          {showArchivedPs === false ?
          <div>
            <p className="mt-3">A table of active Performance Standards, each entry can be viewed, edited and archived.  Click 'More' for additional options.</p>
            <p>Add a new Performance Standard by clicking 'Add Performance Standards'.</p>
          </div>
          :
          <div>
            <p className="mt-3">A table of archived Performance Standards, each entry can be viewed and unarchived.  Click 'More' for additional options.</p>
          </div>
          }
        </div>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <BreadCrumb payload={JSON.stringify({
        dh_id: id,
        ag_id: assetGroupId,
        installation_id: installationId,
        bar_id: barrierId,
        sece_id: seceId,
        last_crumb: 'Performance Standards'
      })}/>

      {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined &&
      <div className="inline">
        <Link
          to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/seces`}
          className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
                                                                      className="text-white"/> Back</Link>
        
        {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" &&
        <Link
          to={{
            pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/add`,
            state: {formMode: 'add'}
          }}
          className="button-red mr-2 mb-2 py-3 px-5"
        >
          Add Performance Standards
        </Link>
        }
      </div>
      }

      {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined &&
      <div className="inline">
        <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/seces`}
              className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
                                                                          className="text-white"/> Back</Link>

        {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" &&
        <Link
          to={{
            pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/add`,
            state: {formMode: 'add'}
          }}
          className="button-red mr-2 mb-2 py-3 px-5"
        >
          Add Performance Standards
        </Link>
        }
      </div>
      }


      <Link to={{
        pathname: showArchivedPs ? url : (url + "/archived"),
        state: showArchivedPs ? "" : "archived"
      }}
            className="button-red mr-2 mb-2 py-3 px-5"
            onClick={() => {
              setShowArchivedPs(!showArchivedPs)
            }}
            style={{backgroundColor: showArchivedPs ? '#a70000' : ''}}
      >
        {showArchivedPs ? "Active Performance Standards" : "Archived Performance Standards"}
      </Link>

      {psList.length > 0 &&
        <Link to={`${url}/histories`} className="button-red mb-2 mr-2 py-3 px-5">History</Link>
      }

      {psList.length !== 0 ?
        <Table columns={columns} data={psList}/>
        : <h4 className="mt-4">There are currently no {showArchivedPs ? "archived" : ""} Performance Standards</h4>}

    </div>
  )
}

function formatPsData(psList, showArchivedPs, userId, userTypeAndRoleObject) {
  let newPsList = [];

  if (psList && psList.length && userId !== "") {
    let tempPsList = psList.filter(ps => showArchivedPs ? ps.ps_archived === true : ps.ps_archived !== true);
    tempPsList.forEach(perfStand => {
      let formatedStatus = capitaliseFirstCase(perfStand.ps_status);
      let newPs = { ...perfStand, status: formatedStatus, user_id: userId, userTypeAndRoleObject: userTypeAndRoleObject };
      newPsList.push(newPs);
    })
  };

  return newPsList;
}

export default PerformanceStandards;