/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useContext} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import Table from '../global/Table';
import TableDropDown from '../global/TableDropDown';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import PageSpinner from "../global/pageSpinner";
import {AuthContext} from "../../context/authContext";
import {capitaliseFirstCase, createUserTypeAndRoleObject} from "../../utils/index";
import BreadCrumb from "../global/Breadcrumb";
import {NOTIFICATION_STATUS_REJECT, NOTIFICATION_STATUS_REVISION, NOTIFICATION_STATUS_APPROVED} from "../../constants";
import Comment from '../global/Comment';
import {sysObjects} from "../../constants/sysObjectList";
import { NavContext } from '../../context/navContext';

const dropdownListItems = ({dutyHolderId, assetGroupId, installationId}) => (
    [
      {
        id: 1,
        name: 'Barriers',
        link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/barriers`
      },
      {
        id: 2,
        name: 'Major Accident Hazards',
        link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/mahs`
      },
      {
        id: 3,
        name: 'SECEs',
        link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/seces`
      },
      {
        id: 4,
        name: 'Reference Activities',
        link: `/dutyholder/${dutyHolderId}/asset-group/${assetGroupId}/installation/${installationId}/reference-activities`
      }
    ]
);

const DUTYHOLDER_QUERY = gql`
query DutyHolder($dHId: ID!){
        dutyHolder(_id: $dHId) {
            _id
            dh_name
        }
}`;

const ASSETGROUP_QUERY = gql`
query assetGroup ($asstGrpId: ID!){
        assetGroup (_id: $asstGrpId){
            _id
            ag_title
        }
}`;

const INSTALLATIONS_SEARCH_QUERY = gql`
query installationSearch($searchParam: InstallationSearchInput!){
    installationSearch(searchParam: $searchParam) {
      _id
      installation_ref
      installation_code
      installation_title
      installation_archived
      installation_archived_id
      installation_archived_date
      ag_id {
        ag_title
      }
      installation_status
  }
}`;

const UPDATE_INSTALLATION = gql`
mutation UpdateInstallation ($installId: String!, $userId: String, $inst: UpdateInstallationInput!){
        updateInstallation (_id: $installId, userId: $userId, inst: $inst){
            _id
        }
}`;

function Installations(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [installationList, setInstallationList] = useState([]);
  const [dutyHolderName, setDutyHolderName] = useState("");
  const [assetGroupName, setAssetGroupName] = useState("");
  const [showArchivedInstalls, setShowArchivedInstalls] = useState(false);

  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

  const [drDnDutyholder, setDrDnDutyholder] = useState({});
  const [drDnInstallation, setDrDnInstallation] = useState({});
  const [drDnSece, setDrDnSece] = useState({});

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const history = useHistory();

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const {id, assetGroupId} = useParams();
  const {location: {state = ""}, match: {url = ""}} = props ? props : {};

  const [dutyHolder, {data: dutyHolderData}] = useLazyQuery(DUTYHOLDER_QUERY);
  const [assetGroup, {data: assetGroupData}] = useLazyQuery(ASSETGROUP_QUERY);

  const [installationSearch, {
    loading: instSearchLoading,
    error: instSearchError,
    data: instSearchData,
    refetch
  }] = useLazyQuery(INSTALLATIONS_SEARCH_QUERY);

  const [updateInstallation, {data: updateInstallData}] = useMutation(UPDATE_INSTALLATION);

  useEffect(() => {
    if (assetGroupId !== undefined) {
      assetGroup({variables: {asstGrpId: assetGroupId}, errorPolicy: 'all'})
    }
    if (id !== undefined) {
      dutyHolder({variables: {dHId: id}, errorPolicy: 'all'})
    };
    if (state === "archived") {
      setShowArchivedInstalls(true);
    };
  }, []);

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    const { dutyHolder, installation, sece } = navContext;
    const { id: dutyHolderId } = dutyHolder ?? {};

    if (isInitialPageLoad) {
      setIsInitialPageLoad(false);
      return;
    }

    if (dutyHolderId && dutyHolderId !== "" && dutyHolderId !== id) {
      history.push(`/dutyholder/${dutyHolderId}/asset-groups`);
    }

    //history.push('/');

    setDrDnDutyholder(dutyHolder);
    setDrDnInstallation(installation);
    setDrDnSece(sece);
  }, [navContext]);

  useEffect(() => {
    if (dutyHolderData && dutyHolderData.dutyHolder) {
      setDutyHolderName(dutyHolderData.dutyHolder.dh_name);
    }
  }, [dutyHolderData]);

  useEffect(() => {
    if (assetGroupData && assetGroupData.assetGroup) {
      setAssetGroupName(assetGroupData.assetGroup.ag_title);
    }
  }, [assetGroupData]);

  useEffect(() => {
    setInstallationList([]);
    let dutyHolder = "";

    if (id !== "") {
      dutyHolder = id;
    }

    const payload = {
      dh_id: drDnDutyholder.id ? drDnDutyholder.id : dutyHolder,
      ag_id: assetGroupId,
      installation_id: drDnInstallation.id ? drDnInstallation.id : "",
      archived: showArchivedInstalls
    };

    installationSearch({ variables: { searchParam: payload }, errorPolicy: 'all' });
  }, [drDnDutyholder, drDnInstallation, drDnSece, showArchivedInstalls]);

  useEffect(() => {
    if (instSearchData && instSearchData.installationSearch) {
      const {_id: userId = ""} = authContext.user ? authContext.user : {};
      let instList = instSearchData.installationSearch;
      let tempInstallList = formatInstallationsData(instList, showArchivedInstalls, userId, state, userTypeAndRoleObject);
      setInstallationList(tempInstallList);
    }
  }, [instSearchData]);

  useEffect(() => {
    if (updateInstallData && updateInstallData.updateInstallation) {
      refetch();
    }
  }, [updateInstallData]);

  const archiveManager = (installId, isArchived, archivedId, archivedDate, userId) => {
    if (userId !== "") {
      const payload = {
        installation_archived: isArchived ? false : true,
        installation_archived_id: isArchived ? archivedId : userId,
        installation_archived_date: isArchived ? archivedDate : new Date()
      }
      updateInstallation({variables: {installId: installId, userId: userId, inst: payload}});
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Installation Code',
        accessor: 'installation_code',
      },
      {
        Header: 'Installation',
        accessor: 'installation_title',
      },
      {
        Header: assetGroupId !== undefined ? 'Asset Group' : 'Duty Holder',
        accessor: assetGroupId !== undefined ? 'ag_id.ag_title' : 'dh_id.dh_name',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { _id, installation_archived, installation_archived_id, installation_archived_date, user_id, state, installation_status, userTypeAndRoleObject } }) => {
          return (
            <div className="text-right">

              <Comment sysObjName={sysObjects.installation}
                sysObjRecordId={_id}
                buttonName={"Comments"}
                buttonClassName={"button-red mb-1 mr-1 text-xs"} />

              <Link
                to={{ pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${_id}/histories` }}
                className="button-red mb-1 mr-1 text-xs"
              >
                History
              </Link>

              <Link
                to={{
                  pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${_id}/view`,
                  state: { archivedStatus: state }
                }}
                className="button-red mb-1 mr-1 text-xs"
              >
                View
              </Link>

              {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" && 
              (installation_status === NOTIFICATION_STATUS_APPROVED || 
               installation_status === NOTIFICATION_STATUS_REJECT ||
               installation_status === NOTIFICATION_STATUS_REVISION
               ) &&
                <div className="inline">
                  {installation_archived ? "" : <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/edit/${_id}`,
                      state: { formMode: 'edit', archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    Edit
                  </Link>}
                  <Link
                    to="#"
                    className="button-red mb-1 mr-1 text-xs"
                    onClick={() => {
                      archiveManager(_id, installation_archived, installation_archived_id, installation_archived_date, user_id)
                    }}
                  >
                    {installation_archived ? 'Unarchive' : 'Archive'}
                  </Link>
                </div>
              }
              {installation_status !== NOTIFICATION_STATUS_REJECT &&
                <TableDropDown title="More" items={dropdownListItems({
                  dutyHolderId: id,
                  assetGroupId: assetGroupId,
                  installationId: _id
                })} />}

              {installation_status === NOTIFICATION_STATUS_REJECT && <Link
                to="#"
                className="button-red mr-1 text-xs"
                style={{ textDecorationLine: 'line-through' }}
              >
                More
              </Link>
              }

            </div>
          )
        }
      },
    ],
    []
  )

  if (instSearchLoading) {
    return (
      <PageSpinner/>
    )
  }

  if (instSearchError) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Installations <FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)}/></h1>

        {showHelp &&
        <div>
          {showArchivedInstalls === false ?
          <div>
            <p className="mt-3">A table of active Installations, each entry can be viewed, edited and archived.  Click 'More' for additional options.</p>
            <p>Add a new Installation by clicking 'Add Installation'.</p>
          </div>
          :
          <div>
            <p className="mt-3">A table of archived Installations, each entry can be viewed and unarchived.</p>  
            <p>Click 'More' for additional options.</p>
          </div>
          }
        </div>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <BreadCrumb payload={JSON.stringify({
        dh_id: id,
        ag_id: assetGroupId,
        last_crumb: 'Installations'
      })}/>

        <Link
          to={{
            pathname: `/dutyholder/${id}/asset-groups`,
          }}
          className="button-red mr-2 mb-2 py-3 px-5"
        >
          <FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white"/> Back
        </Link>

        {userTypeAndRoleObject && userTypeAndRoleObject.userType ==="ABS" &&
          <Link
            to={{
              pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/add`,
              state: {formMode: 'add', archivedStatus: state}
            }}
            className="button-red mr-2 mb-2 py-3 px-5"
          >
            Add Installation
          </Link>
        }


      <Link to={{
        pathname: showArchivedInstalls ? url : (url + "/archived"),
        state: showArchivedInstalls ? "" : "archived"
      }}
            className="button-red mr-2 mb-2 py-3 px-5"
            onClick={() => {
              setShowArchivedInstalls(!showArchivedInstalls)
            }}
      >
        {showArchivedInstalls ? "Active Installations" : "Archived Installations"}
      </Link>

      {installationList.length > 0 &&
        <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/histories`} className="button-red mb-2 mr-2 py-3 px-5">History</Link>
      }

      {installationList.length ?
        <Table columns={columns} data={installationList}/>
        : <h4 className="mt-4">There are currently no Installations</h4>}

    </div>
  )
}

function formatInstallationsData(installationList, showArchivedInstalls, userId, state, userTypeAndRoleObject) {
  let newInstallationList = [];

  if (installationList && installationList.length && userId !== "") {
    let tempInstallList = installationList.filter(install => showArchivedInstalls ? install.installation_archived === true : install.installation_archived !== true);
    tempInstallList.forEach(install => {
      let formatedStatus = capitaliseFirstCase(install.installation_status);
      let newInstallation = { ...install, status: formatedStatus, user_id: userId, state: state, userTypeAndRoleObject: userTypeAndRoleObject };
      newInstallationList.push(newInstallation);
    })
  };

  return newInstallationList;
}

export default Installations;