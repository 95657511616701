/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Link, useParams, useHistory} from 'react-router-dom';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import PageSpinner from '../global/pageSpinner';
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';
import {countries} from "../../constants/countryList";
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils";

const LOCATION_QUERY = gql`
query Location($locationId: ID!){
    location(_id: $locationId) {
        _id
        location_name
        location_address_line_1
        location_address_line_2
        location_address_city
        location_address_country
        location_address_post_zip_code
        location_notes
        location_status
    }
}`;

const DUTYHOLDER_QUERY = gql`
query DutyHolder($dutyHolderId: ID!){
     dutyHolder(_id: $dutyHolderId){
        _id
        dh_name
        dh_location
        dh_head_office{
            _id
            location_name
        }
    }
}`;

const UPDATE_DUTYHOLDER = gql`
mutation UpdateDutyHolder($dutyHolderId: String!, $dutyHolder: DutyHolderInput!, $userId: String!){
    updateDutyHolder(_id: $dutyHolderId, dutyHolder:$dutyHolder, userId: $userId) {
        _id
        dh_location
        dh_head_office{
          _id
          location_name
        }
    }
}`;

function ViewLocation(props) {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [locationObject, setLocationObject] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [isLocationHeadOffice, setIsLocationHeadOffice] = useState(false);
  const [dutyholderName, setDutyHolderName] = useState(false);

  let { featureName = "", featureId = "", locationId = "" } = useParams();
  const { location: { state = "" } } = props ? props : {};

  const [location, {loading, error, data: locationData}] = useLazyQuery(LOCATION_QUERY);
  const [dutyHolder, {data: dutyHolderData}] = useLazyQuery(DUTYHOLDER_QUERY);
  const [updateDutyHolder, {data: updateDutyHolderData}] = useMutation(UPDATE_DUTYHOLDER);

  useEffect(() => {
  if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
  }
  }, [authContext]);

  useEffect(() => {
  if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
  }
  }, [userTypeAndRoleObject]);
  
  useEffect(() => {
    if (featureId!==""&&locationId!=="") {
      dutyHolder({ variables: { dutyHolderId: featureId }, errorPolicy: 'all' });
      location({ variables: { locationId: locationId }, errorPolicy: 'all' });
    };
    setCountryList(countries);
  }, []);

  useEffect(() => {
      if (dutyHolderData && dutyHolderData.dutyHolder) {
        let dutyHolder = dutyHolderData.dutyHolder;
        setIsLocationHeadOffice(dutyHolder.dh_head_office&&dutyHolder.dh_head_office._id===locationId);
        setDutyHolderName(dutyHolder.dh_name);
      }       
  }, [dutyHolderData]);

  useEffect(() => {
    if (locationData && locationData.location) {
      setLocationObject(locationData.location);
    }
  }, [locationData]);

  useEffect(() => {
    if (updateDutyHolderData && updateDutyHolderData.updateDutyHolder) {
      let dutyHolder = updateDutyHolderData.updateDutyHolder;
      setIsLocationHeadOffice(dutyHolder.dh_head_office && dutyHolder.dh_head_office._id === locationId);
      history.push(getBackLink());
    }
  }, [updateDutyHolderData]);

  
  const getBackLink = (state) => {
    if (state && state.origin && state.origin === "notifications") {
      return { pathname: `/notifications` }
    };
    if (featureName !== "" && featureId !== "") {
      let backLink = `/${featureName}/${featureId}/locations`
      return backLink;
    }
  }
  

  const getCountryName = (countryId) => {
    let countryName = 'Not Available'
    if (countryId !== "") {
        let country = countryList.find(c => c.id === countryId);
        countryName = country&&country.name;
    }
    return countryName;
  }

  const setHeadAsOffice = (locId, authContext) => {
    const { _id: userId = "" } = authContext.user ? authContext.user : {};
    if (featureName !== "" && featureId !== "" && locId && locId !== "" && userId !== "") {
      let dutyHolderInput = {
        dh_head_office: locId
      }
      updateDutyHolder({ variables: { dutyHolderId: featureId, dutyHolder: dutyHolderInput, userId: userId }, errorPolicy: 'all' })
    };
  }

  const setHeadOfficeSnippet = (isHeadOffice) => {  
    let headOffice = ""
    if (isHeadOffice) {
       headOffice = " (Head Office)";
    };
    return headOffice;
  }

  //PDF
  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 100, 40, 24],
    header: function (currentPage, pageCount) {

      var headerObj;
      if (currentPage % 2) {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['75%', '25%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: locationObject.location_name + setHeadOfficeSnippet(isLocationHeadOffice), font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: 'LOCATION DETAILS', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      } else {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['25%', '75%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: locationObject.location_name + setHeadOfficeSnippet(isLocationHeadOffice), font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: 'LOCATION DETAILS', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      }

      return headerObj;
    },
    footer: function (currentPage, pageCount) {

      var footerObj;

      if (currentPage % 2) {
        footerObj = {
          table: {
            widths: [30, '*', 100, 28],
            body: [
              [
                { text: ' ', border: [false, false, false, false] },
                { text: 'LOCATION DETAILS', style: 'footerText', border: [false, false, false, false] },
                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                { text: ' ', border: [true, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      } else {
        footerObj = {
          table: {
            widths: [30, 100, '*', 28],
            body: [
              [
                { text: ' ', border: [false, false, true, false] },
                { text: currentPage.toString(), border: [true, false, false, false] },
                { text: `© ${new Date().getFullYear()} American Bureau of Shipping. All rights reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                { text: ' ', border: [false, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      }

      //return currentPage.toString() -1; 
      return footerObj;

    },
    //metadata
    info: {
      title: locationObject.location_name + setHeadOfficeSnippet(isLocationHeadOffice) + ' - Details',
      //author: 'Joe Bloggs',
    },
    content: [

      //CONTENT
      {
        table: {
          widths: ['100%'], //col widths
          headerRows: 0,
          body: [
            [{ text: 'Duty Holder', style: 'StandardTableHeader' }],
            [{ text: dutyholderName }],
            [{ text: 'Location Name', style: 'StandardTableHeader' }],
            [{ text: locationObject.location_name + setHeadOfficeSnippet(isLocationHeadOffice) }],
            [{ text: 'Address', style: 'StandardTableHeader' }],
            [{
              text: [
                {text: locationObject.location_address_line_1 + '\n'},
                {text: locationObject.location_address_line_1 + '\n'},
                {text: locationObject.location_address_city + '\n'},
                {text: locationObject.location_address_post_zip_code + '\n'},
                {text: getCountryName(parseInt(locationObject.location_address_country))}
              ]
            }],
            [{ text: 'Status', style: 'StandardTableHeader' }],
            [{ text: locationObject.location_status }],
          ]
        },
        style: 'standardTable',
      },
      //CONTENT END
    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1.2
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }
    //open in new tab
    const pdfTab = () => {
      pdfMake.createPdf(docDefinition).open();
    }
  //PDF END

  if (loading) {
    return (
      <PageSpinner/>
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">
          Location Details
        </h1>
      </div>

      {/* <Link to={getBackLink(state)} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link> */}

      <button onClick={() => pdfTab()} className="mr-2 button-red">PDF</button>
      {(isLocationHeadOffice || (state && state.origin === "notifications")) ? "" :
        <Link to="#"
          className="button-red mb-1 mr-2"
          onClick={() => { setHeadAsOffice(locationObject._id, authContext) }}
        >
          Set as Head Office
        </Link>
      }

      <div className="w-full lg:w-1/2">

        <div className="my-6 pb-6 border-b-2 border-gray-200">
          <h2 className="font-bold text-2xl text-blue-900">{dutyholderName} <br/> {locationObject.location_name + setHeadOfficeSnippet(isLocationHeadOffice)}</h2>

          {locationObject.location_notes !=="" && userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
            <p className="mt-3">{locationObject.location_notes}</p>
          }

          <h3 className="mt-4 mb-1 font-bold text-red-900">Address</h3>

          <p><strong>{dutyholderName}</strong></p>
          <p>{locationObject.location_address_line_1}</p>
          <p>{locationObject.location_address_line_2}</p>
          <p>{locationObject.location_address_city}</p>
          <p>{locationObject.location_address_post_zip_code}</p>
          <p>{locationObject.location_address_country}</p>

          <p className="mt-5"><strong>Status:</strong> {locationObject.location_status}</p>

        </div>

        {/* <Link to={getBackLink(state)} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link> */}
        <button onClick={() => pdfTab()} className="mr-2 button-red">PDF</button>
        {(isLocationHeadOffice || (state && state.origin === "notifications")) ? "" : 
        <Link to="#"
          className="button-red mb-1 mr-2"
          onClick={() => { setHeadAsOffice(locationObject._id, authContext) }}
        >
          Set as Head Office
        </Link>}

      </div>

    </div>
  );
}

export default ViewLocation;







