/* eslint-disable */
import * as React from 'react';
import { DetailsView, FileManagerComponent, Inject, NavigationPane, Toolbar } from '@syncfusion/ej2-react-filemanager';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { authFlow } from '../../index';
import { uniqueId } from 'lodash';
import classNames from 'classnames';
export default class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.contextmenuItems = ['Open', '|', 'Cut', 'Copy', 'Delete', 'Rename', '|', 'Details'];
    this.state = {
      showDialog: false,
      attachmentList: this.props.attachmentList
    };
  }
  static defaultProps = {
    homeDir: '/abs/users',
    toolbarText: 'Attach Documents',
    attachmentList: [],
    isFileManager: false
  }
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (JSON.stringify(this.props.attachmentList) !== JSON.stringify(nextProps.attachmentList)) {
      this.setState({
        attachmentList: nextProps.attachmentList || []
      })
    }
    return true
  }

  onFileOpen(args) {
    let file = args.fileDetails;
    if (file.isFile) {
      args.cancel = true;
      if (file.size <= 0) {
        file.size = 10000;
      }
      if (this.fileUploadObj && this.fileUploadObj.files) {
        this.fileUploadObj.files = [{ name: file.name, size: file.size, type: file.type }];
      }
      this.dialogObj.hide();
    }
  }
  toggleModal() {
    this.setState({
      showDialog: !this.state.showDialog
    })
  }
  handleRemove(event, file) {
    event.preventDefault();
    const newAttachmentList = this.state.attachmentList.filter(attachment => attachment.name !== file)
    this.setState({
      attachmentList: newAttachmentList
    }, () => {
      if (this.props.onAttach) {
        this.props.onAttach(newAttachmentList)
      }
    })
  }
  toolbarClick(args) {
    if (args.item.text === this.props.toolbarText) {
      if (this.props.onAttach) {
        const attachmentList = args.fileDetails.map(attachment => ({
          name: attachment.name,
          path: attachment.filterPath
        }));
        this.setState({
          showDialog: false
        }, () => {
          this.props.onAttach([...this.state.attachmentList, ...attachmentList]);
          this.setState({
            attachmentList
          })
        })
      }
    }
  }
  enableAttach(event) {
    this.filemanagerObj.disableToolbarItems([this.props.toolbarText]);
    if (event.fileDetails.isFile && event.action === 'select') {
      this.filemanagerObj.enableToolbarItems([this.props.toolbarText]);
    }
  }
  render() {
    return (<div>
      {!this.props.isFileManager && (<div className="form-control block w-full md:w-1/2">
        <button
          disabled={this.props.disabled}
          type="button"
          className={"button-red min-w-full block py-3 px-5 modal-button" + `${this.props.disabled ? " cursor-not-allowed" : ""}`}
          onClick={() => this.toggleModal()}>{this.props.buttonText}</button>
        {this.state.attachmentList.length > 0 && (
          <ul className="block pt-3 list-none pl-0 ml-0">
            {this.state.attachmentList.map((file, index) => (
              <React.Fragment key={uniqueId()}>
                <li key={uniqueId()} className="pb-3">{file.name} - <a href="#" onClick={(event) => this.handleRemove(event, file.name)}
                  aria-label={`Remove ${file.name}`}><FontAwesomeIcon
                    icon={['fas', 'times-circle']} size="sm" className="mr-3 text-red-900" /></a></li>
                {this.props.showDescription &&
                  <div className="mb-2 border-gray-400 border-b-2">
                    <textarea placeholder={"Enter Description"} id={uniqueId()} name={file.name} defaultValue={file.description || (this.props.tempFileList.length && this.props.tempFileList[index] ? this.props.tempFileList[index].description : "")}
                      onChange={({ target: { value } }) => { this.props.handleFileDescription({ ...file, description: value }) }} className="mb-2 form-control block w-full">
                    </textarea>
                  </div>
                }
              </React.Fragment>
            ))}
          </ul>
        )
        }
      </div>)}
      {(this.state.showDialog || this.props.isFileManager) && (
        <div className={classNames(!this.props.isFileManager && 'modal fixed w-full h-full top-0 left-0 flex flex-center')} style={{ zIndex: '999' }}>
          {!this.props.isFileManager && (
            <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-30"
              onClick={() => this.toggleModal()}></div>)}
          <div
            className={classNames(!this.props.isFileManager && 'absolute w-full md:w-1/2 lg:w-1/2 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-40')}
            style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
            <FileManagerComponent
              id="filemanager"
              ref={(scope) => {
                this.filemanagerObj = scope;
              }}
              fileSelect={(event) => {
                this.enableAttach(event);
              }}
              ajaxSettings={{
                url: this.props.apiURL,
                getImageUrl: `${this.props.apiURL}/GetImage`,
                uploadUrl: `${this.props.apiURL}/Upload`,
                downloadUrl: `${this.props.apiURL}/Download`
              }}
              {... !this.props.isFileManager && ({ toolbarSettings: { items: ['NewFolder', 'Refresh', 'View', 'Details', 'Upload', this.props.toolbarText] } })}
              toolbarClick={this.toolbarClick.bind(this)}
              contextMenuSettings={{ file: this.contextmenuItems, folder: this.contextmenuItems }}
              fileOpen={this.onFileOpen.bind(this)}
              beforeSend={(args) => {
                const data = JSON.parse(args.ajaxSettings.data);
                args.ajaxSettings.beforeSend = (args) => {
                  args.httpRequest.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('authToken')}`)
                }
                if (args.action === 'Upload') {
                  return args.ajaxSettings.data = JSON.stringify(data);
                }
                args.ajaxSettings.data = JSON.stringify(data);
              }}
              failure={() => {
                new Promise((success, fail) => authFlow(success, fail)).then(() => {
                  this.filemanagerObj.path = this.props.homeDir
                  this.filemanagerObj.refresh();
                });
              }}
              path={this.props.homeDir}
            >
              <Inject services={[NavigationPane, DetailsView, Toolbar]} />
            </FileManagerComponent>
          </div>
        </div>
      )}
    </div>);
  }
}