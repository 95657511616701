/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext } from 'react';
import DatePicker from 'react-datepicker';
import Table from '../global/Table';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormError from "../global/FormError";
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';
import PageSpinner from '../global/pageSpinner';
import { capitaliseFirstCase, createUserTypeAndRoleObject } from "../../utils/index";
import { AuthContext } from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import {
  NOTIFICATION_STATUS_PENDING,
  NOTIFICATION_STATUS_ONGOING,
  NOTIFICATION_STATUS_COMPLETE,
  SCHEDULE_FORM_ORIGIN_VS_REGISTER,
  SCHEDULE_SYSTEM_VARIABLE_TYPE_ID, 
  PERIODICITY_SYSTEM_VARIABLE_TYPE_ID,
  NOTIFICATION_STATUS_CLOSED
} from '../../constants/';

const VERIFICATION_SCHEDULES_SEARCH = gql`
query VerificationScheduleSearch($param: VerificationScheduleSearch!){
  verificationScheduleSearch(param: $param){
        _id
        vs_title
        vs_periodicity{
          _id
          sysvar_title
        }
        schedule_type_id {
          _id
          sysvar_title
        }
        dh_id{
          _id
          dh_name
          dh_code
        }
        installation_ids{
          _id
          installation_code
          installation_title
        }
        sece_ids{
          _id
          sece_title
        }
        va_ids{
          _id
          va_short_desc
          va_type
        }
        ra_ids{
          _id
          ra_title
        }
        verifier_ids{
          _id
          user_fname
          user_lname
        }
        vs_current_status{
          _id
          sysvar_title
        }
        vs_start_date_time
        vs_end_date_time
        vs_added_date
        vs_added_id{
          _id
        }
        vs_archived
        vs_status
    }
}`;

const DUTY_HOLDERS_QUERY = gql`
    query {
        dutyHolders {
            _id
            dh_name
            dh_status
            dh_added_date
            dh_archived
        }
}`;

const INSTALLATIONS_BY_DH_ID_QUERY = gql`
query installationsByDutyHolderId($dhId: ID){
    installationsByDutyHolderId(dhId: $dhId) {
      _id
      installation_title
      installation_archived
      installation_status
  }
}`;

const SECES_BY_DH_ID_AND_ISTALL_IDS_QUERY = gql`
query SecesByDHIdAndInstallIds($dhId: ID, $installIds: [ID]){
  secesByDHIdAndInstallIds(dhId: $dhId, installIds: $installIds) {
       _id
       sece_title
       sece_order_no
       sece_archived
       sece_status
       sece_client_ref
    }
}`;

const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
            _id
            sysvar_title
            sysvar_archived
            sysvartype_id{
                _id
                sysvartype_title
            }
        }
}`;

const USERS_QUERY = gql`
query {
    users {
        _id
        user_fname
        user_lname
        user_archived
        user_type {
            _id
            user_type_display_name
        }     
        user_role{
            _id
            user_role_code
        }   
    }
}`;

const activityCode = [
  { name: "Onshore Verification", code: "ONV" },
  { name: "Offshore Verification", code: "OFV" },
  { name: "AdHoc Visit", code: "AVT" },
  { name: "In Depth Audit", code: "IDA" },
  { name: "Meeting", code: "MTG" }
];

const filterFormDefaultValues = {
  schedule_title: "",
  dh_id: "",
  installation_id: "",
  sece_id: "",
  schedule_type: "",
  periodicity: "",
  verifier: "",
  schedule_start_date: "",
  schedule_end_date: "",
  status_id: "",
}

const vaStatusList = [
  { id: "1", value: "ongoing", label: "Ongoing" },
  { id: "2", value: "pending", label: "Pending" },
  { id: "3", value: "completed", label: "Completed" },
]

function VerificationRegister(props) {
  const [drDnDutyholder, setDrDnDutyholder] = useState({});
  const [drDnInstallation, setDrDnInstallation] = useState({});
  const [drDnSece, setDrDnSece] = useState({});

  const [showHelp, setShowHelp] = useState(false);
  const [activityCodeList, setActivityCodeList] = useState([]);
  const [scheduleList, setScheduleList] = useState([]);

  const [refreshPage, setRefreshPage] = useState(false);
  const [verSchStatus, setVerSchStatus] = useState(NOTIFICATION_STATUS_ONGOING);

  //Filter Section Start
  const [dhList, setDhList] = useState([]);
  const [installationList, setInstallationList] = useState([]);
  const [seceList, setSeceList] = useState([]);
  const [scheduleTypeList, setScheduleTypeList] = useState([]);
  const [periodicityList, setPeriodicityList] = useState([]);
  const [verifierList, setVerifierList] = useState([]);
  const [statusList, setStatusList] = useState([]);

  const [selectedDutyHolderId, setSelectedDutyHolderId] = useState("");
  const [selectedInstallationId, setSelectedInstallationId] = useState("");
  const [selectedSeceId, setSelectedSeceId] = useState("");

  const [hasFilterParam, setHasFilterParam] = useState(false);
  //Filter Section End

  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const navContext = useContext(NavContext);

  const [formInitValues, setFormInitValues] = useState({ va_ids: [], });
  const [filterFormInitValue, setFilterFormInitValue] = useState({});
  const { location: { state: { vs_status = "" } = {} } } = props ? props : {};

  const [users, {data: usersData }] = useLazyQuery(USERS_QUERY);
  const [systemVariablesBySysVarTypeIds, {data: sysVarsData}] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);
  const [verificationScheduleSearch, { loading, error, data: vsData }] = useLazyQuery(VERIFICATION_SCHEDULES_SEARCH);

  const [dutyHolders, { data: dutyHoldersData }] = useLazyQuery(DUTY_HOLDERS_QUERY);
  const [installationsByDutyHolderId, { data: installsData }] = useLazyQuery(INSTALLATIONS_BY_DH_ID_QUERY);
  const [secesByDHIdAndInstallIds, { data: secesData }] = useLazyQuery(SECES_BY_DH_ID_AND_ISTALL_IDS_QUERY);

  const sysVarTypeIds = [SCHEDULE_SYSTEM_VARIABLE_TYPE_ID, PERIODICITY_SYSTEM_VARIABLE_TYPE_ID];

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    console.log(selectedSeceId);
    setStatusList(vaStatusList);
    users({ variables: {}, errorPolicy: 'all' });
    dutyHolders({ variables: {}, errorPolicy: 'all' });
    setFilterFormInitValue(filterFormDefaultValues);
    systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });
  }, []);

  useEffect(() => {
    const { dutyHolder, installation, sece } = navContext;
    setFilterFormInitValue(filterFormDefaultValues);
    setDrDnDutyholder(dutyHolder);
    setDrDnInstallation(installation);
    setDrDnSece(sece);
  }, [navContext]);

  useEffect(() => {
    if (usersData && usersData.users) {
      let tempList = formatUserData(usersData.users);
      setVerifierList(tempList);
    }
  }, [usersData]);

  useEffect(() => {
    if (vs_status && vs_status !== "") {
      setVerSchStatus(vs_status);
    }
  }, [vs_status]);

  useEffect(() => {
    if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
      formatSysVarDataAndUpdateStates(sysVarsData.systemVariablesBySysVarTypeIds,
        setScheduleTypeList,
        setPeriodicityList,
        SCHEDULE_SYSTEM_VARIABLE_TYPE_ID,
        PERIODICITY_SYSTEM_VARIABLE_TYPE_ID);
    }
  }, [sysVarsData]);

  useEffect(() => {
    fetchMultiSelectData(selectedDutyHolderId, selectedInstallationId)
  }, [selectedDutyHolderId, selectedInstallationId]);

  useEffect(() => {
    if (dutyHoldersData && dutyHoldersData.dutyHolders) {
        let list = dutyHoldersData.dutyHolders;
        let newDutyHolderList = list.filter(dh => dh.dh_status === "approved" && dh.dh_archived !== true);
        setDhList(newDutyHolderList);
    }
  }, [dutyHoldersData]);

  useEffect(() => {
    if (installsData && installsData.installationsByDutyHolderId) {
      let tempList = installsData.installationsByDutyHolderId ? installsData.installationsByDutyHolderId : [];
      setInstallationList(tempList);
    }
  }, [installsData]);

  useEffect(() => {
    if (secesData && secesData.secesByDHIdAndInstallIds) {
      let tempList = secesData.secesByDHIdAndInstallIds ? secesData.secesByDHIdAndInstallIds : [];
      setSeceList(tempList)
    }
  }, [secesData]);

  useEffect(() => {
    setActivityCodeList(activityCode);

    let tempParam = {
      vs_status: verSchStatus,
      dutyholderIds: drDnDutyholder.id ? [drDnDutyholder.id] : [],
      installationIds: drDnInstallation.id ? [drDnInstallation.id] : [],
      seceIds: drDnSece.id ? [drDnSece.id] : [],
    }

    //If The Filter has Values, Use This
    if (hasFilterParam) {
      let verifierIds = filterFormInitValue.verifier && filterFormInitValue.verifier !== "" ? [filterFormInitValue.verifier] : [];
      let scheduleTypeIds = filterFormInitValue.schedule_type && filterFormInitValue.schedule_type !== "" ? [filterFormInitValue.schedule_type] : [];
      let dutyholderIds = filterFormInitValue.dh_id && filterFormInitValue.dh_id !== "" ? [filterFormInitValue.dh_id] : [];
      let installationIds = filterFormInitValue.installation_id && filterFormInitValue.installation_id !== "" ? [filterFormInitValue.installation_id] : [];
      let seceIds = filterFormInitValue.sece_id && filterFormInitValue.sece_id !== "" ? [filterFormInitValue.sece_id] : [];
      let periodicity = filterFormInitValue.periodicity && filterFormInitValue.periodicity !== "" ? [filterFormInitValue.periodicity] : [];
      let filter = {
        verifierIds: verifierIds,
        scheduleTypeIds: scheduleTypeIds,
        periodicity: periodicity,
        dutyholderIds: dutyholderIds,
        installationIds: installationIds,
        seceIds: seceIds,
        scheduleTitle: filterFormInitValue.schedule_title,
        vs_start_date_time: filterFormInitValue.schedule_start_date,
        vs_end_date_time: filterFormInitValue.schedule_end_date,
        vs_status: verSchStatus,
      }
      tempParam = filter
    }

    verificationScheduleSearch({ variables: { param: tempParam }, errorPolicy: 'all' });
    setFormInitValues({...formInitValues, status_id: verSchStatus})
  }, [verSchStatus, drDnDutyholder, drDnInstallation, drDnSece]);

  useEffect(() => {
    if (refreshPage) {
      const tempParam = {
        vs_status: verSchStatus,
        dutyholderIds: drDnDutyholder.id ? [drDnDutyholder.id] : [],
        installationIds: drDnInstallation.id ? [drDnInstallation.id] : [],
        seceIds: drDnSece.id ? [drDnSece.id] : [],
      }     
      verificationScheduleSearch({ variables: { param: tempParam }, errorPolicy: 'all' });
      setRefreshPage(false);
    }
  }, [refreshPage, drDnDutyholder, drDnInstallation, drDnSece]);

  useEffect(() => {
    if (vsData && vsData.verificationScheduleSearch) {
      let filteredVs = vsData.verificationScheduleSearch.filter(vs => vs.schedule_type_id && vs.schedule_type_id.sysvar_title !== "Meeting");

      let formatedList = formatScheduleData(filteredVs, activityCodeList);
      setScheduleList(formatedList);
    }
  }, [vsData]);

  function toggleFilterModal() {
    const modal = document.querySelector('.filtermodal')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
  }

  const clearFilter = () => {
    setFilterFormInitValue({ ...filterFormDefaultValues });
    setSelectedDutyHolderId("");
    setSelectedInstallationId("");
    setSelectedSeceId("");
    setInstallationList([]);
    setSeceList([]);
    setHasFilterParam(false);

    const tempParam = {
      vs_status: verSchStatus,
      dutyholderIds: drDnDutyholder.id ? [drDnDutyholder.id] : [],
      installationIds: drDnInstallation.id ? [drDnInstallation.id] : [],
      seceIds: drDnSece.id ? [drDnSece.id] : [],
    }
    verificationScheduleSearch({ variables: { param: tempParam }, errorPolicy: 'all' });
    toggleFilterModal();
    setVerSchStatus(NOTIFICATION_STATUS_ONGOING);
  }

  const fetchMultiSelectData = (dutyHolderId, installationId) => {
    if (dutyHolderId && dutyHolderId !== "") {
      installationsByDutyHolderId({ variables: { dhId: dutyHolderId }, errorPolicy: 'all' });
      setSeceList([]); // This clears out any existing seces
    }
    if (dutyHolderId && dutyHolderId !== "" && installationId && installationId !== "") {
      secesByDHIdAndInstallIds({ variables: { dhId: dutyHolderId, installIds: installationId }, errorPolicy: 'all' });
    }
  }

  const handleDhChange = (event) => {
    let value = event.target.value;
    setSelectedDutyHolderId(value);
  };

  const handleInstallationChange = (event) => {
    let value = event.target.value;
    setSelectedInstallationId(value);
  };

  const handleSeceChange = (event) => {
    let value = event.target.value;
    setSelectedSeceId(value);
  };

  var pdfList = [];

  pdfList.push([{ text: 'Duty Holder', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, { text: 'Installation', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#fff', '#000', '#000', '#fff'] }, { text: 'Schedule Title', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#fff', '#000', '#000', '#fff'] }, { text: 'Dates', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#fff', '#000', '#000', '#fff'] }, { text: 'Schedule Type', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#fff', '#000', '#000', '#fff'] }, { text: 'Personnel', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#fff', '#000', '#000', '#fff'] }, { text: 'Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#fff', '#000', '#000', '#000'] }]);

  scheduleList.length && scheduleList.map((vl) => {
    pdfList.push([
      { text: vl.dh_name },
      { text: vl.installation_title },
      { text: vl.schedule_title },
      { text: vl.ver_dates },
      { text: vl.schedule_type },
      { text: vl.ver_personnel },
      { text: vl.ver_status }
    ])
  });

  //PDF
  var docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [30, 100, 40, 24],
    header: function (currentPage, pageCount) {

      var headerObj;
      if (currentPage % 2) {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['75%', '25%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: 'ABS', font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: 'VERIFICATION REGISTER', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      } else {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['25%', '75%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: 'ABS', font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: 'VERIFICATION REGISTER', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      }

      return headerObj;
    },
    footer: function (currentPage, pageCount) {

      var footerObj;

      if (currentPage % 2) {
        footerObj = {
          table: {
            widths: [30, '*', 100, 28],
            body: [
              [
                { text: ' ', border: [false, false, false, false] },
                { text: 'VERIFICATION REGISTER', style: 'footerText', border: [false, false, false, false] },
                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                { text: ' ', border: [true, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      } else {
        footerObj = {
          table: {
            widths: [30, 100, '*', 28],
            body: [
              [
                { text: ' ', border: [false, false, true, false] },
                { text: currentPage.toString(), border: [true, false, false, false] },
                { text: `© ${new Date().getFullYear()} American Bureau of Shipping. All rights reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                { text: ' ', border: [false, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      }

      return footerObj;

    },
    info: {
      title: 'ABS - Verification Register',
    },
    content: [

      //CONTENT
      {
        table: {
          widths: ['20%', '20%', '10%', '20%', '10%', '10%', '10%'], //col widths
          headerRows: 0,
          body: pdfList
        },
        style: 'standardTable',
      },
      //CONTENT END
    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1.2
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }

  const pdfTab = () => {
    pdfMake.createPdf(docDefinition).open();
  }  

  const columns = React.useMemo(
    () => [
      {
        Header: 'Duty Holder',
        accessor: 'dh_name',
      },
      {
        Header: 'Installation',
        accessor: 'installation_title',
      },
      {
        Header: 'Schedule Title',
        accessor: 'schedule_title',
      },
      {
        Header: 'Dates',
        accessor: 'ver_dates',
        sortType: (a, b) => {
          const { values: { ver_dates: aDate = "" } } = a ? a : {};
          const { values: { ver_dates: bDate = "" } } = b ? b : {};
          
          if (aDate === "N/A" && bDate === "N/A") {
            return 0; // Both are "N/A," consider them equal
          } else if (aDate === "N/A") {
            return 1; // a is "N/A," place it after b
          } else if (bDate === "N/A") {
            return -1; // b is "N/A," place it after a
          }

          let aDateTemp = aDate.split(" -")[0];
          let bDateTemp = bDate.split(" -")[0];
          var a1 = new Date(aDateTemp);
          var b1 = new Date(bDateTemp);
          if (a1 < b1)
            return 1;
          else if (a1 > b1)
            return -1;
          else
            return 0;
        }
      },
      {
        Header: 'Schedule Type',
        accessor: 'schedule_type',
      },
      {
        Header: 'Personnel',
        accessor: 'ver_personnel',
        style: { whiteSpace: "pre-wrap" }
      },
      {
        Header: 'Status',
        accessor: 'ver_status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { id = "", plain_status } }) => {
          return (
            <div className="text-right">
              
              {plain_status !== NOTIFICATION_STATUS_CLOSED &&
                <Link
                  to={{
                    pathname: `/verification/${id}/activity-register`,
                    state: { vs_status: verSchStatus }
                  }} className="button-red mb-1 mr-1 text-xs">
                  {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" ? plain_status === NOTIFICATION_STATUS_PENDING ? "Start" : "Continue" : "View Activities"}
                </Link>
              }

              <div className="inline">
                {userTypeAndRoleObject && 
                userTypeAndRoleObject.userType === "ABS" && 
                (plain_status === NOTIFICATION_STATUS_PENDING || 
                 plain_status === NOTIFICATION_STATUS_ONGOING || 
                 plain_status === NOTIFICATION_STATUS_CLOSED) ?
                  <Link to={{
                    pathname: `/verification/edit/step1/${id}`,
                    state: { vs_status: verSchStatus, origin: SCHEDULE_FORM_ORIGIN_VS_REGISTER }
                  }}
                    className="button-red mb-1 mr-1 text-xs">
                    Edit
                  </Link>
                  : <></>
                }
              </div>
            </div>
          )
        }
      },
    ],
    [verSchStatus, userTypeAndRoleObject]
  )

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Verification Register <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <div>
            <p className="mt-3">Verification schedules relating to the Duty Holder and / or Installation selected on the drop-down menu.</p>
            <p>Click on the buttons provided to select ongoing, pending and completed schedules.</p>
          </div>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "ABS" && drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Verification Register- Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "Duty Holder" && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Verification Register- Installation: {drDnInstallation.name}</h6>}
      {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Verification Register- Duty Holder / Installation : All</h6>}

      <Link to="#" className="button-red mr-2 mb-2 py-3 px-5"
        onClick={() => { setVerSchStatus(NOTIFICATION_STATUS_ONGOING) }}
        style={{ ...(verSchStatus === NOTIFICATION_STATUS_ONGOING && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}
      >{"Ongoing"}</Link>
      <Link to="#" className="button-red mr-2 mb-2 py-3 px-5"
        onClick={() => { setVerSchStatus(NOTIFICATION_STATUS_PENDING) }}
        style={{ ...(verSchStatus === NOTIFICATION_STATUS_PENDING && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}
      >{"Pending"}</Link>
      <Link to="#" className="button-red mr-2 mb-2 py-3 px-5"
        onClick={() => { setVerSchStatus(NOTIFICATION_STATUS_COMPLETE) }}
        style={{ ...(verSchStatus === NOTIFICATION_STATUS_COMPLETE && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}
      >{"Completed"}</Link>
      <Link to="#" className="button-red mr-2 mb-2 py-3 px-5"
        onClick={() => { setVerSchStatus(NOTIFICATION_STATUS_CLOSED) }}
        style={{ ...(verSchStatus === NOTIFICATION_STATUS_CLOSED && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}
      >{"Closed"}</Link>

      <span className="button-red mr-2 mb-2 py-3 px-5 cursor-pointer" onClick={() => toggleFilterModal()} style={{ ...(hasFilterParam && {backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}>Filter</span>
      <span className="button-red mr-2 mb-2 py-3 px-5 cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      <Table
        columns={columns}
        data={scheduleList}
      />

      {/* SEARCH FILTER POP UP */}
      <div className="filtermodal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
        <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleFilterModal()}></div>
        <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

          <Formik
            initialValues={filterFormInitValue}
            onSubmit={(values, action) => {

              let verifierIds = values.verifier && values.verifier !== "" ? [values.verifier] : [];
              let scheduleTypeIds = values.schedule_type && values.schedule_type !== "" ? [values.schedule_type] : [];
              let dutyholderIds = values.dh_id && values.dh_id !== "" ? [values.dh_id] : [];
              let installationIds = values.installation_id && values.installation_id !== "" ? [values.installation_id] : [];
              let seceIds = values.sece_id && values.sece_id !== "" ? [values.sece_id] : [];
              let periodicity = values.periodicity && values.periodicity !== "" ? [values.periodicity] : [];

              let filter = {
                verifierIds: verifierIds,
                scheduleTypeIds: scheduleTypeIds,
                periodicity: periodicity,
                dutyholderIds: dutyholderIds,
                installationIds: installationIds,
                seceIds: seceIds,
                scheduleTitle: values.schedule_title,
                vs_start_date_time: values.schedule_start_date,
                vs_end_date_time: values.schedule_end_date,
                vs_status: values.status_id && values.status_id !== "" ? values.status_id : verSchStatus,
              }

              if (values.status_id && values.status_id !== "") {
                setVerSchStatus(values.status_id);
              }

              verificationScheduleSearch({ variables: { param: filter }, errorPolicy: 'all' });
              setHasFilterParam(true);
              setFilterFormInitValue({ ...values });
              toggleFilterModal();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              setFieldValue,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="mb-4 pb-2 border-b-2 border-gray-200">
                  <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">Filter Verification Schedule</h2>
                </div>

                <div>
                  <label htmlFor="schedule_title" className="block mb-1 text-blue-900 font-semibold">Schedule Title</label>
                  <input
                    type="text"
                    className="form-control block w-full"
                    name="schedule_title"
                    id="schedule_title"
                    placeholder="Title"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.schedule_title}
                  />
                  <FormError touched={touched.schedule_title} message={errors.schedule_title} />
                </div>

                <div className="mb-1">
                  <label htmlFor="dh_id" className="block mb-1 text-blue-900 font-semibold">Duty Holder</label>
                  <select
                    className="form-control block w-full"
                    name="dh_id"
                    id="dh_id"
                    onChange={(event) => {
                      handleChange(event);
                      handleDhChange(event);
                      setFieldValue("installation_id", "");
                      setFieldValue("sece_id", "");
                    }}
                    onBlur={handleBlur}
                    value={values.dh_id}
                  >
                    <option value={""}>Select Duty Holder</option>
                    {dhList.map(dh =>
                      <option key={dh._id} value={dh._id}>{dh.dh_name}</option>
                    )}
                  </select>
                  <FormError touched={touched.dh_id} message={errors.dh_id} />
                </div>

                <div className="mb-1">
                  <label htmlFor="installation_id" className="block mb-1 text-blue-900 font-semibold">Installation</label>
                  <select
                    className="form-control block w-full"
                    name="installation_id"
                    id="installation_id"
                    onChange={(event) => {
                      handleChange(event);
                      handleInstallationChange(event);
                      setFieldValue("sece_id", "");
                    }}
                    onBlur={handleBlur}
                    value={values.installation_id}
                  >
                    <option value={""}>Select Installation</option>
                    {installationList.map(inst =>
                      <option key={inst._id} value={inst._id}>{inst.installation_title}</option>
                    )}
                  </select>
                  <FormError touched={touched.installation_id} message={errors.installation_id} />
                </div>

                <div className="mb-1">
                  <label htmlFor="sece_id" className="block mb-1 text-blue-900 font-semibold">SECE</label>
                  <select
                    className="form-control block w-full"
                    name="sece_id"
                    id="sece_id"
                    onChange={(event) => {
                      handleChange(event);
                      handleSeceChange(event);
                    }}
                    onBlur={handleBlur}
                    value={values.sece_id}
                  >
                    <option value={""}>Select SECE</option>
                    {seceList.map(sece =>
                      <option key={sece._id} value={sece._id}>{`${sece.sece_client_ref ? sece.sece_client_ref : "N/A"} : ${sece.sece_title}`}</option>
                    )}
                  </select>
                  <FormError touched={touched.sece_id} message={errors.sece_id} />
                </div>

                <div className="mb-4">
                  <label htmlFor="schedule_type" className="block mb-1 text-blue-900 font-semibold">Schedule Type</label>
                  <select
                    className="form-control block w-full"
                    name="schedule_type"
                    id="schedule_type"
                    onChange={(event) => { handleChange(event); }}
                    onBlur={handleBlur}
                    value={values.schedule_type}
                  >
                    <option value={""}>Select Schedule Type</option>
                    {scheduleTypeList.map(sch =>
                      <option key={sch._id} value={sch._id}>{sch.sysvar_title}</option>
                    )}
                  </select>
                </div>

                <div className="mb-4">
                  <label htmlFor="periodicity" className="block mb-1 text-blue-900 font-semibold">Periodicity</label>
                  <select
                    className="form-control block w-full"
                    name="periodicity"
                    id="periodicity"
                    onChange={(event) => { handleChange(event); }}
                    onBlur={handleBlur}
                    value={values.periodicity}
                  >
                    <option value={""}>Select Periodicity</option>
                    {periodicityList.map(item =>
                      <option key={item.id} value={item.id}>{item.period}</option>
                    )}
                  </select>
                </div>

                <div className="mb-4">
                  <label htmlFor="verifier" className="block mb-1 text-blue-900 font-semibold">Verifier</label>
                  <select
                    className="form-control block w-full"
                    name="verifier"
                    id="verifier"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.verifier}
                  >
                    <option value={""}>Select Verifier</option>
                    {verifierList.map(verifier =>
                      <option key={verifier.id} value={verifier.id}>{verifier.full_name}</option>
                    )}
                  </select>
                </div>

                <div>
                  <label htmlFor="schedule_start_date" className="block mb-1 text-blue-900 font-semibold">Start Date/Time</label>
                  <DatePicker
                    id="schedule_start_date"
                    selected={values.schedule_start_date}
                    dateFormat="dd MMMM Y - h:mm aa"
                    name="schedule_start_date"
                    onChange={date => setFieldValue('schedule_start_date', date)}
                    onBlur={handleBlur}
                    value={values.schedule_start_date}
                    className="form-control block w-full"
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    autoComplete="off"
                  />
                  <FormError touched={touched.schedule_start_date} message={errors.schedule_start_date} />
                </div>

                <div>
                  <label htmlFor="schedule_end_date" className="block mb-1 text-blue-900 font-semibold">End Date/Time</label>
                  <DatePicker
                    id="schedule_end_date"
                    selected={values.schedule_end_date}
                    dateFormat="dd MMMM Y - h:mm aa"
                    name="schedule_end_date"
                    onChange={date => setFieldValue('schedule_end_date', date)}
                    onBlur={handleBlur}
                    value={values.schedule_end_date}
                    className="form-control block w-full"
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    autoComplete="off"
                  />
                  <FormError touched={touched.schedule_end_date} message={errors.schedule_end_date} />
                </div>

                <div className="mb-4">
                  <label htmlFor="status_id" className="block mb-1 text-blue-900 font-semibold">Completion Status</label>
                  <select
                    className="form-control block w-full"
                    name="status_id"
                    id="status_id"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.status_id}
                  >
                    <option value={""}>Completion Status</option>
                    {statusList.map(status =>
                      <option key={status.id} value={status.value}>{status.label}</option>
                    )}
                  </select>
                </div>

                <button type="submit" className="mt-3 mb-4 button-red capitalize mr-4">Apply Filter</button>
                <button type="button" onClick={() => { clearFilter() }} className="mt-3 mb-4 button-red capitalize">Clear Filter</button>
              </form>
            )}
          </Formik>

        </div>
      </div>
      {/* SEARCH FILTER POP UP END */}

    </div>
  )
  //TITLE / INFO & MAIN BUTTON SECTION - END
}

function formatScheduleData(list, activityCodeList) {
  let scheduleList = [];
  let dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };

  if (list && list.length && activityCodeList && activityCodeList.length) {
    list.forEach((item, index) => {
      let installationList = item.installation_ids;
      let installation = item.installation_ids[0];
      let installationIds = item.installation_ids.map(inst => inst._id);
      let dhId = item.dh_id && item.dh_id._id ? item.dh_id._id : "";
      let dhCode = item.dh_id ? item.dh_id.dh_code : "";
      let dhName = item.dh_id ? item.dh_id.dh_name : "";
      let installCode = installation ? installation.installation_code : "N/A";

      let status = item.vs_status ? item.vs_status : "unknown";
      let formatedStatus = capitaliseFirstCase(status);

      let startDate = new Date(item.vs_start_date_time);
      let startDateString = startDate.toLocaleDateString('en-GB', dateOptions);

      let endDate = new Date(item.vs_end_date_time);
      let endDateString = endDate.toLocaleDateString('en-GB', dateOptions);

      let verifierList = item.verifier_ids;

      let scheduleType = item.schedule_type_id ? item.schedule_type_id.sysvar_title : "";
      let activityCode = activityCodeList.find(act => act.name === scheduleType);
      let code = activityCode ? activityCode.code : "NAV";

      let formatedRefNum = formatRef(startDate, dhCode, installCode, code, index);
      let formatedVerifiers = formatVerifiers(verifierList);

      let newSchedule = {
        id: item._id,
        dhId: dhId,
        schedule_title: item.vs_title,
        ver_ref: formatedRefNum,
        dh_name: dhName,
        installationIds: installationIds,
        installation_title: formatTitleList(installationList),
        ver_dates: startDateString + " - " + endDateString,
        ver_status: formatedStatus,
        plain_status: status,
        schedule_type: scheduleType,
        ver_personnel: formatedVerifiers,
        va_ids: item.va_ids,
      }

      scheduleList.push(newSchedule);
    });
  }

  return scheduleList
}

function formatTitleList(installationList) {
  let formatedTitle = "";

  if (installationList && installationList.length) {
    let listSize = installationList.length;
    installationList.map((t, index) => {
      formatedTitle = formatedTitle + t.installation_title + (listSize > (index + 1) ? "," : "") + "\n";
    })
  }

  return formatedTitle;
}

function formatRef(startDate, dhCode, installCode, activityCode, index) {
  let verRef = "";
  let refNumFormat = "0000000"
  let num = index + 1;
  let newDate = new Date(startDate);
  let year = newDate.getFullYear();
  let lastTwoYrDigits = year.toString().substring(2);
  let refNum = refNumFormat.concat(num.toString());
  let formatedRefNum = refNum.substring(refNum.length - 7);

  verRef = `${lastTwoYrDigits}-${dhCode}-${installCode}-${activityCode}-${formatedRefNum}`;

  return verRef;
}

function formatVerifiers(verifierList) {
  let newVerifierList = "";

  if (verifierList && verifierList.length) {

    let numberOfVerifiers = verifierList.length;

    if (numberOfVerifiers > 1) {
      let tempVerifiers = "";
      verifierList.forEach((ver, index) => {
        tempVerifiers += ver.user_fname + " " + ver.user_lname + "\n";
      });
      newVerifierList = tempVerifiers;
    } else {
      let verifier = verifierList[0]
      let firstName = verifier ? verifier.user_fname : "";
      let lastName = verifier ? verifier.user_lname : "";
      let fullName = firstName + " " + lastName;
      newVerifierList = fullName;
    }

  }
  return newVerifierList;
}

function formatSysVarDataAndUpdateStates(list, setScheduleTypeList, setPeriodicityList, scheduleTypeId, periodicityTypeId) {
  let periodicities = [];
  let scheduleTypes = []
  scheduleTypes = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === scheduleTypeId);
  let tempPeriodicities = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === periodicityTypeId);
  tempPeriodicities.forEach(period => {
      let newPeriodicity = {
          id: period._id,
          period: period.sysvar_title
      }
      periodicities.push(newPeriodicity);
  })
  setScheduleTypeList(scheduleTypes);
  setPeriodicityList(periodicities);
}

function formatUserData(userList) {
  let newUserList = [];
  if (userList && userList.length) {
      let tempList = userList.filter(user => user.user_type && user.user_type.user_type_display_name === "ABS" && user.user_archived !== true)
      tempList.forEach(user => {

          let newUser = {
              id: user._id,
              full_name: user.user_fname + " " + user.user_lname,
              user_type: user.user_type ? user.user_type.user_type_display_name : "",
          }
          newUserList.push(newUser);
      })
  }
  return newUserList;
}

export default VerificationRegister;