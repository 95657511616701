/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { gql, useQuery, useLazyQuery } from '@apollo/client';
import Menu, { Item as MenuItem, SubMenu } from 'rc-menu';
import 'rc-menu/assets/index.css';
import {AuthContext} from "../../context/authContext";
import {NavContext} from "../../context/navContext";
import {DashboardContext} from "../../context/dashboardContext";
import {createUserTypeAndRoleObject, parseJSONSafely} from "../../utils/index";
import {ACTION_STATUS_UNAPPROVED } from '../../constants/';

const USER_NAV_DROPDOWN_QUERY = gql`
query UserNavDropdown($user_id: ID){
  user_nav_dropdown(user_id: $user_id) {
       title
       user_type
       duty_holders{
            _id
            dh_name
            installations{
              _id
              installation_title
            }
       }
    }
}`;

const ACTIONS_SEARCH_QUERY = gql`
    query ActionSearch($param: ActionSearchInput!){
      actionSearch(param: $param) {
        _id
      }
}`;

function Nav(props) {
  const [mainNavActive, setMainNavActive] = useState(false);
  const [userType, setUserType] = useState("");
  const [navDrpDwnData, setNavDrpDwnData] = useState(null);
  const [actionItemCount, setActionItemCount] = useState(0);
  const [authUserId, setAuthUserId] = useState("");

  const [selectedDrpDwnTitle, setSelectedDrpDwnTitle] = useState("");
  const [selectedDrpDwnInstId, setSelectedDrpDwnInstId] = useState("");

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const dashboardContext = useContext(DashboardContext);

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const location = useLocation();

  const [user_nav_dropdown, { data: navDropdownData }] = useLazyQuery(USER_NAV_DROPDOWN_QUERY);

  const { data: actionData } = useQuery(ACTIONS_SEARCH_QUERY,
    {variables: {
      param:{
        status: ACTION_STATUS_UNAPPROVED, 
        ...(authUserId && authUserId !== "" && {user_id: authUserId}),
        ...(selectedDrpDwnInstId && selectedDrpDwnInstId !== "" && {installation_id: selectedDrpDwnInstId}),
      }},      
     errorPolicy: 'all',
     pollInterval: 1000 });

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
       setUserType(result.userType);
    }
  }, [authContext]);

  useEffect(() => {
    user_nav_dropdown({ variables: { user_id: "" }, errorPolicy: 'all' });
    let dropDownSelectionString = localStorage.getItem('dropDownSelection');

    let { dutyholder = {}, installation = {}, sece = {} } = parseJSONSafely(dropDownSelectionString) ? parseJSONSafely(dropDownSelectionString) : {};
    navContext.updateDutyHolder(dutyholder);
    navContext.updateInstallation(installation);
    navContext.updateSece(sece);

    let title = "";
    if (dutyholder.name && installation.name) {
      title = `${dutyholder.name} / ${installation.name}`;
    }

    if (!dutyholder.name && installation.name) {
      title = installation.name;
    }
    setSelectedDrpDwnTitle(title);
    setSelectedDrpDwnInstId(installation.id);

    window.addEventListener("resize", resize.bind(this));
    resize();
  }, [])

  useEffect(() => {
    if (authContext && authContext.user) {
      let user = authContext.user;
      const { _id: userId = "" } = user ? user : {};
      if (userId !== "") {
        setAuthUserId(userId);
      };
    }
  }, [authContext])

  useEffect(() => {
    if (navDropdownData && navDropdownData.user_nav_dropdown) {
      let userNavDropdown = navDropdownData.user_nav_dropdown;
      setNavDrpDwnData(userNavDropdown);
    }
  }, [navDropdownData])

  useEffect(() => {
    if (actionData && actionData.actionSearch) {
      let list = actionData.actionSearch;
      let listCount = list && list.length ? list.length : 0;
      setActionItemCount(listCount);
    }
  }, [actionData]);

  const resize = () => {
    let currentHideNav = (window.innerWidth < 1199);
    if (currentHideNav) {
      setMainNavActive(true);
    } else {
      setMainNavActive(false);
    }
  }

  const updateDhInsllNavContext = (dutyholder, installation) => {
    let tempDholder = { id: dutyholder._id, name: dutyholder.dh_name }
    let tempInstall = { id: installation._id, name: installation.installation_title }
    navContext.updateDutyHolder(tempDholder);
    navContext.updateInstallation(tempInstall);
    let dropDownSelection = { dutyholder: tempDholder, installation: tempInstall, sece: {} }
    localStorage.setItem('dropDownSelection', JSON.stringify(dropDownSelection));
    let title = `${tempDholder.name} / ${installation.installation_title}`;
    setSelectedDrpDwnTitle(title);
    setSelectedDrpDwnInstId(installation._id);
    return true;
  }

  const updateInsllSeceNavContext = (installation, sece) => {
    let tempInstall = { id: installation._id, name: installation.installation_title}
    navContext.updateInstallation(tempInstall);
    let dropDownSelection = { dutyholder: {}, installation: tempInstall, sece: sece }
    localStorage.setItem('dropDownSelection', JSON.stringify(dropDownSelection));
    setSelectedDrpDwnTitle(installation.installation_title);
    setSelectedDrpDwnInstId(installation._id);
    return true;
  }

  const clearDropDownSelection = () => {
    navContext.clearContext();
    localStorage.removeItem('dropDownSelection');
    setSelectedDrpDwnTitle("");
    setSelectedDrpDwnInstId("");
  }

  return (
    <nav className="lg:min-h-screen flex" style={{ marginTop: -133, paddingTop: 133 }}>

      <div className="flex-row w-full bg-gray-100 py-4 px-8 mb-4 lg:mb-0">

        <div className="lg:text-right">
          <FontAwesomeIcon icon={['fas', 'bars']} size="lg" className="text-blue-900 cursor-pointer" onClick={() => setMainNavActive(!mainNavActive)} />
        </div>

        <div className={mainNavActive ? "hidden" : "block"}>

           {dropDownManager(navDrpDwnData, selectedDrpDwnTitle, updateDhInsllNavContext, updateInsllSeceNavContext, location)}

          {(navContext.dutyHolder.id || navContext.installation.id || navContext.sece.id) && <div className="relative z-10">
            <Link to={{...location}} className="mb-3 text-xs text-blue-900 hover:underline" onClick={() => clearDropDownSelection()}>Clear Selection</Link>
          </div>}

          <ul className="relative z-10 lg:w-250 mt-5 ml-0 pl-0 text-blue-900 lg:text-sm font-sans font-semibold leading-loose list-none">

            <li><Link to="/" className="hover:underline">Dashboard</Link>
              <ul className="mb-0 mt-0 ml-5 font-sans list-none" style={{ opacity: 0.60 }}>
                <li><Link to="/seces-status" className="hover:underline">SECE Status</Link></li>
              </ul>
            </li>

            {userTypeAndRoleObject && userTypeAndRoleObject.userType === "Duty Holder" &&
              <li><Link to={`/dutyholder/${userTypeAndRoleObject.userDhId}/asset-groups`} className="hover:underline">Duty Holder Hierarchy</Link></li>
            }

            <li><Link to="/verification" className="hover:underline">Verification</Link>
              <ul className="mb-0 mt-0 ml-5 font-sans list-none" style={{ opacity: 0.60 }}>
                <li><Link to="/verification/verification-register" className="hover:underline">Verification Register</Link></li>
                {userType === "ABS" &&
                  <li>
                    <Link
                      to={{
                        pathname: `/verification/add/step1`,
                        state: { formMode: 'add' }
                      }}
                      className="hover:underline"
                    >
                      Create Schedule
                    </Link>
                  </li>
                }
              </ul>
            </li>
            {/* <li><Link to="/documentation" className="hover:underline">Documentation</Link></li> */}
            <li><Link to="/reporting/report-search" className="hover:underline">Reporting</Link>
              <ul className="mb-0 mt-0 ml-5 font-sans list-none" style={{ opacity: 0.60 }}>
                {userType === "ABS" &&
                  <li>
                    <Link
                      to={{
                        pathname: `/reporting/vs-report/report-index/add`,
                        state: { formMode: 'add' }
                      }}
                      className="hover:underline"
                    >
                      Create Report
                    </Link>
                  </li>
                }
                <li>
                  <Link to="/reporting/report-search" className="hover:underline">
                    Report Search
                  </Link>
                </li>
              </ul>
            </li>

            <li><Link to="/reporting/findings"
                className="hover:underline"
                onClick={() => dashboardContext.updateFindingSearchParam({})}
                >Findings</Link>
              <ul className="mb-0 mt-0 ml-5 font-sans list-none" style={{ opacity: 0.60 }}>
                {/* {userType === "ABS" &&
                  <li>
                    <Link
                      to={{pathname: '/reporting/finding/create-finding-filter' }}
                      className="hover:underline"
                    >
                      Create Finding
                    </Link>
                  </li>
                } */}
                <li><Link to="/reporting/findings"
                  className="hover:underline"
                  onClick={() => dashboardContext.updateFindingSearchParam({})}
                >Findings Search</Link></li>
              </ul>
            </li>

            <li>
              <Link to={{
                pathname: `/reporting/activity-search`,
                search: "?category=original"
              }} className="hover:underline">Activities</Link>
              <ul className="mb-0 mt-0 ml-5 font-sans list-none" style={{ opacity: 0.60 }}>
                <li>
                  <Link to={{ 
                    pathname: `/reporting/activity-search`,
                    search: "?category=original"
                     }} className="hover:underline">Activity Search</Link>
                </li>
                <li>
                  <Link to={{
                    pathname: `/reporting/activity-search`,
                    search: "?category=planned"
                  }}
                    className="hover:underline">Scheduled Activities
                  </Link>
                </li>
                <li>
                  <Link to={{ pathname: `/reporting/activity-search`, search: "?category=overdue-or-missed" }}
                    className="hover:underline">Overdue or Missed Activities
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link
                to={{
                  pathname: `/reporting/action-list`,
                  search: `?userId=${authUserId}`
                }}
                className="hover:underline">
                Action List
              </Link>
              {authUserId && authUserId !== "" &&
                <span className="font-xs bg-red-900 py-1 px-2 text-white" style={{ borderRadius: "50%" }}>{actionItemCount}</span>
              }
            </li>
            <li><Link to="/statistics" className="hover:underline">Statistics</Link></li>
            {/*<li><Link to="/comparison-engine" className="hover:underline">Comparison Engine</Link></li>*/}
            <li><Link to="/bowtie-dashboard" className="hover:underline">Bowtie Dashboard</Link></li>
            <li><Link to="/info-matrixes" className="hover:underline">Info Matrix</Link></li>
          </ul>
        </div>

      </div>

    </nav>
  );
}

function dropDownManager(navDropdownData, selectedDrpDwnTitle, updateDhInsllNavContext, updateInsllSeceNavContext, location) {
  if (navDropdownData && navDropdownData.user_type && navDropdownData.user_type === 'ABS') {
    return (
      displayABSDropdown(navDropdownData, selectedDrpDwnTitle, updateDhInsllNavContext, location)
    )
  }

  if (navDropdownData && navDropdownData.user_type && navDropdownData.user_type === 'Duty Holder') {
    return (
      displayClientDropdown(navDropdownData, selectedDrpDwnTitle, updateInsllSeceNavContext, location)
    )
  }
}

function displayClientDropdown(navDropdownData, selectedDrpDwnTitle, updateInsllSeceNavContext, location) {
  return (
    <div className="mt-3 mb-0">
      <Menu id="main-nav-dd" className="font-sans font-semibold text-sm" triggerSubMenuAction="click" mode="horizontal">
        <SubMenu className="w-full" title={<span className="text-blue-900 flex justify-between items-center">{selectedDrpDwnTitle !== "" ? selectedDrpDwnTitle: navDropdownData.title} <FontAwesomeIcon className="text-right" icon={['fas', 'caret-down']} /></span>} key="4">
          {navDropdownData.duty_holders[0] && navDropdownData.duty_holders[0].installations.map((installation) =>
            <MenuItem key={installation._id}>
              <Link to={{ ...location }} onClick={() => { updateInsllSeceNavContext(installation, {}) }} className="flex font-sans font-semibold text-sm text-blue-900">{installation.installation_title}</Link>
            </MenuItem>
          )}
        </SubMenu>
      </Menu>
    </div>
  )
}

function displayABSDropdown(navDropdownData, selectedDrpDwnTitle, updateDhInsllNavContext, location) {
  return (
    <div className="mt-3 mb-0">
      <Menu id="main-nav-dd" className="font-sans font-semibold text-sm z-20" triggerSubMenuAction="click" mode="horizontal">
        <SubMenu className="w-full z-20" title={<span className="text-blue-900 flex justify-between items-center">{selectedDrpDwnTitle !== "" ? selectedDrpDwnTitle : navDropdownData.title } <FontAwesomeIcon icon={['fas', 'caret-down']} /></span>} key="4">
          {navDropdownData.duty_holders.map((dutyHolder) =>
            <SubMenu key={dutyHolder._id} title={<span className="font-sans font-semibold text-sm text-blue-900">{dutyHolder.dh_name}</span>} mode="vertical-right">
              {dutyHolder.installations.map((installation) =>
                <MenuItem key={installation._id}><Link to={{ ...location }} onClick={() => { updateDhInsllNavContext(dutyHolder, installation) }} className="flex font-sans font-semibold text-sm text-blue-900">{installation.installation_title}</Link></MenuItem>
              )}
            </SubMenu>
          )}
        </SubMenu>
      </Menu>
    </div>
  )
}

export default Nav;




