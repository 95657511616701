/* eslint-disable react-hooks/exhaustive-deps */
import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import * as Yup from 'yup';
import Table from '../global/Table';
import FormError from "../global/FormError";
import Sanitiser from "../global/sanitiser";
import { Link, useHistory, useParams } from 'react-router-dom';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext } from "../../context/authContext";
import TextEditor from '../../components/global/TextEditor';
import PageSpinner from '../global/pageSpinner';
import {NavContext} from '../../context/navContext';
import {
    CURRENT_STATUS_FINDINGS_SYSTEM_VARIABLE_TYPE_ID,
    NATURE_SYSTEM_VARIABLE_TYPE_ID,
    VA_FREQUENCY_SYSTEM_VARIABLE_TYPE_ID,
    ANSWERS_SYSTEM_VARIABLE_TYPE_ID,
    FINDING_LEVEL_SYSTEM_VARIABLE_TYPE_ID,
    SYS_VAR_ANSWER_YES_ID,
    USER_TYPE_ABS_ID,
    NOTIFICATION_STATUS_APPROVED,
    USER_TYPE_DUTY_HOLDER_ID,
    FINDING_ACTION_SAVE,
    FINDING_ACTION_REVIEW,
    FINDING_ORIGIN_ACTIVITY_REGISTER,
    FINDING_ORIGIN_REPORT,
    FINDING_ORIGIN_STANDALONE,
    DH_FINDING_TYPE,
    INST_FINDING_TYPE,
    MULTI_VA_FINDING_TYPE,
    SINGLE_VA_FINDING_TYPE
} from '../../constants/';

import FileUploadAlt from '../global/FileUploadAlt';

import {
    parseJSONSafely,
    createUserTypeAndRoleObject,
    formatDateToLocaleDateString,
    truncateString,
    valueFromInfoMatrixLevel
} from "../../utils";
import { faTrashAlt, faPenAlt, faFilter } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

const { REACT_APP_FILE_MANAGER_API } = process.env;

/* Add Va */
const AddVaFilterValidation = Yup.object().shape({});
const addVaFilterFormDefaultValues = {
    sece_id: "",
    ps_id: "",
    nature_id: "",
    onshore_offshore: "",
    periodicity: "",
}

const onOffshoreList = [
    {id: "1", value: "Onshore" },
    {id: "2", value: "Offshore" }
]
/* Add Va */

const validation = (formMode, rptId, findingReportId, vsId, findingType, isDutyHolder) => {
    let validation = {};

    let verifyValObject = {
        // finding_current_status: Yup.string().label("finding_current_status").required("Current Status is required"),
        finding_level: Yup.string().label("finding_level").required("Finding Level is required"),
        //finding_nature: Yup.string().label("finding_nature").required("Nature is required"),
        finding_integrity: Yup.string().label("finding_integrity").required("Integrity is required"),
        finding_legislative: Yup.string().label("finding_legislative").required("Legislative is required"),
        finding_paperwork_process: Yup.string().label("finding_paperwork_process").required("Paperwork Process is required"),
        finding_rogi: Yup.string().label("finding_rogi").required("ROGI is required"),
        finding_synopsis: Yup.string().label("finding_synopsis").required("Synopsis is required"),
        finding_ps_reference: Yup.string().label("finding_ps_reference").required("Reference is required"),
        finding_summary: Yup.string().label("finding_summary").required("Finding Summary is required"),
        finding_action: Yup.string().label("finding_action").required("Action is required"),
        finding_contingency_actions: Yup.string().label("finding_contingency_actions").required("Contingency Actions Addressing Finding is required"),
        finding_response_date: Yup.string().label("finding_response_date").required("Response Date is required"),
        finding_completion_date: Yup.string().label("finding_completion_date").required("Completion Date is required").test("finding_completion_date", "Completion date cannot be before response date", function (value) {
            const { path, createError, parent } = this;
            let responseDate = parent.finding_response_date;
            return moment(value).isBefore(responseDate) ? createError({ path, message: "Completion date cannot be before response date" }) : true;
        }),
        finding_action_party: Yup.string().label("finding_action_party").required("Action Party is required"),
        //finding_dh_responsible: Yup.string().label("finding_dh_responsible").required("Duty Holder Responsible is required"),
        finding_doc_update_required: Yup.string().label("finding_doc_update_required").required("Document Update Required is required"),
    }

    let fullValidationObject = {
        ...(isDutyHolder ? {} : { ...verifyValObject })
        // ...verifyValObject
        //finding_dh: Yup.string().label("finding_dh").required("Duty Holder is required"),
        //finding_ag: Yup.string().label("finding_ag").required("Asset Group is required"),
        //finding_installation: Yup.string().label("finding_installation").required("Installation is required"),
        //finding_barrier: Yup.string().label("finding_barrier").required("Barrier is required"),
        //finding_seces: Yup.string().label("finding_seces").required("SECEs is required"),
        //finding_va: Yup.string().label("finding_va").required("Verification Activity is required"),
    }

    let instLevelFndVal = {
        ...(findingType === INST_FINDING_TYPE && { finding_installation: Yup.string().label("finding_installation").required("Installation is required") }),
    }

    if (rptId !== "" || findingReportId !== "" || vsId !== "") {
        validation = Yup.object().shape({ ...verifyValObject, ...instLevelFndVal });
    } else {
        validation = Yup.object().shape({ ...fullValidationObject, ...instLevelFndVal });
    }

    return validation;
}

const findingResponseValidation = (userType) => {
    let validation = {};

    let verifyValObject = {
        response_note: Yup.string().label("response_note").required("Response Note is required"),
    }

    let fullValidationObject = {
        ...verifyValObject,
        response_status: Yup.string().label("response_status").required("Response Status is required"),
    }

    if (userType === "ABS") {
        validation = Yup.object().shape(fullValidationObject);
    }
    else {
        validation = Yup.object().shape(verifyValObject);
    }

    return validation;
}

const VERIFICATION_SCHEDULE_ID_QUERY = gql`
query VerificationSchedule($vsId: ID!){
    verificationSchedule(_id: $vsId){
        _id
        vs_title
        vs_periodicity{
          _id
          sysvar_title 
        }
        schedule_type_id{
          _id
          sysvar_title
        }
        dh_id{
          _id
          dh_name
        }
        installation_ids{
          _id
          installation_title
          ag_id{
              _id
          }
        }
        sece_ids{
          _id
          sece_title
        }
        va_ids{
          _id
          va_short_desc
        }
        ra_ids{
          _id
          ra_title
        }
        verifier_ids{
          _id
          user_fname
          user_lname
        }
        vs_start_date_time
        vs_end_date_time
        vs_added_date
        vs_added_id{
            _id
        }
        vs_archived
    }
}`;

const ASSETGROUP_BY_INST_ID_QUERY = gql`
query AssetGroupsByInstId($instId: ID!){
    assetGroupsByInstId(instId: $instId) {
        _id
        ag_ref
        ag_title
        ag_notes
        ag_archived
        ag_status
    }
}`;

const FINDINGS_BY_FILTER_PARAMS_QUERY = gql`
query FindingSearch($param: FindingSearchInput!){
    findingSearch(filterParam: $param){
      _id
      finding_ref
      finding_synopsis
      finding_added_date
      finding_closure_date

      dh_id {
          _id
          dh_name
      }
      installation_id {
        _id
        installation_title
      }
      finding_current_status {
        _id
        sysvar_title
      }

      finding_infomatrix_level
      finding_level{
        sysvar_title
      }

      finding_action_party{
        user_fname
        user_lname
      }

      finding_added_id{
          user_fname
          user_lname
          user_type {
            _id
            user_type_display_name
          }
      }
    }
}`;

const FINDINGS_BY_DH_QUERY = gql`
query findingsByDhId($dhId:ID!, $instId:String!, $seceIdList:[ID], $fndId:ID){
    findingsByDhId(dhId: $dhId, instId:$instId, seceIdList: $seceIdList, fndId: $fndId){
      _id
      finding_ref
      finding_synopsis
      finding_added_date
      finding_closure_date

      finding_current_status{
        sysvar_title
      }

      finding_infomatrix_level
      finding_level{
        sysvar_title
      }

      finding_action_party{
        user_fname
        user_lname
      }

      finding_added_id{
          user_fname
          user_lname
          user_type {
            _id
            user_type_display_name
          }
      }
    }
}`;

const FINDINGS_BY_INST_QUERY = gql`
query findingsByInstId($instId: ID!, $seceIdList: [ID], $fndId: ID){
    findingsByInstId(instId: $instId, seceIdList :$seceIdList, fndId: $fndId){
      _id
      finding_ref
      finding_synopsis
      finding_added_date
      finding_closure_date

      finding_current_status{
        sysvar_title
      }

      finding_infomatrix_level
      finding_level{
        sysvar_title
      }

      finding_action_party{
        user_fname
        user_lname
      }

      finding_added_id{
          user_fname
          user_lname
          user_type {
            _id
            user_type_display_name
          }
      }
    }
}`;

const FINDING_BY_ID_QUERY = gql`
query Finding($findingId: ID!){
    finding(_id: $findingId){
      _id
      dh_id{
          _id
          dh_name
      }
      ag_id{
          _id
          ag_title
      }
      installation_id{
           _id
           installation_title
      }
      barrier_id{
          _id
          bar_title
      }
      sece_ids{
        _id
        sece_title
        sece_ref
        sece_client_ref
        sece_order_no
        sece_archived
        sece_status
      }
      va_id{
          _id
          va_type
          va_short_desc
          va_client_ref
          va_long_desc
      }
      va_ids{
        _id
        va_type
        va_short_desc
        va_client_ref
        va_long_desc
      }
      vs_id{
        _id
      }
      report_id{
          _id
      }
      report_ids{
        _id
        sece_id{
            _id
            sece_title
            sece_ref
            sece_client_ref
            sece_order_no
            sece_archived
            sece_status
        }
        va_id{
            _id
            va_type
            va_short_desc
            va_client_ref
            va_long_desc
        }
      }

      finding_current_status{
          _id
          sysvar_title
      }

      finding_nature_ids{
        _id
        sysvar_title
      }
      finding_level{
          _id
      }
      finding_level_summary
      finding_infomatrix_level
      finding_integrity{
        _id
    }
    finding_legislative{
        _id
    }
    finding_rogi{
        _id
    }
    finding_pw_process{
        _id
    }

      finding_equip_number
      finding_equip_type

      finding_synopsis
      finding_summary
      finding_action

      finding_work_order_raised{
        _id
      }
      finding_work_order_raised_desc
      finding_contingency_actions{
        _id
      }

      finding_response_date
      finding_completion_date

      finding_closure_date
      finding_abs_responsible{
        _id
      }
      finding_dh_responsible{
        _id
      }
      linked_install_fnds{
        _id
        finding_ref
        finding_synopsis
        finding_added_date
        finding_closure_date
        finding_infomatrix_level
        finding_current_status{
            _id
            sysvar_title
        }
      }
      linked_dh_fnds{
        _id
        finding_ref
        finding_synopsis
        finding_added_date
        finding_closure_date
        finding_infomatrix_level
        finding_current_status{
            _id
            sysvar_title
        }
      }
      finding_doc_update_required{
        _id
      }
      finding_ps_reference
      finding_ps_reference_attachments
      finding_attachments

      fnd_resp_attmnt_dest_path

      finding_responses

      finding_action_party{
        _id
      }
      finding_auth{
        _id
        user_fname
        user_lname
      }
      finding_ref

      finding_days_open
      finding_days_since_last_response

      finding_type
      finding_date
      finding_status
      finding_added_date
  }
}`;

const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
            _id
            sysvar_title
            sysvar_archived
            sysvartype_id{
                _id
                sysvartype_title
            }
        }
}`;

const REF_ACT_SEARCH = gql`
    query ReferenceActivitySearch($param: ReferenceActivitySearchInput!){
        referenceActivitySearch(param: $param){
            _id
            ra_ref
            ra_title
            ra_desc
            ra_archived
            ra_status
        }
}`;

const VA_BY_ID_QUERY = gql`
    query VerificationActivity($vaId: ID!){
        verificationActivity(_id: $vaId){
            _id
            dh_id{
                _id
            }
            ag_id{
                _id
            }
            installation_id{
                _id
            }
            va_ref
            va_client_ref
            va_type
            va_short_desc
            va_long_desc
            sece_id{
                _id
            }
            ps_id {
                _id
                ps_ref
                ps_title
                ps_desc
                ps_criteria
            }
        }
}`;

// const VAS_BY_DHID_AND_SECEIDS_QUERY = gql`
//     query vasByDhIdAndSeceIds($dhId: ID!, $seceIds: [ID!]){
//         vasByDhIdAndSeceIds(dhId: $dhId, seceIds: $seceIds){
//             _id
//             va_ref
//             va_type
//             va_short_desc
//             va_long_desc
//             va_client_ref
//             dh_id{
//                 _id
//             }
//             ag_id{
//                 _id
//             }
//             installation_id{
//                 _id
//             }
//             sece_id{
//                 _id
//             }
//             ps_id {
//                 _id
//                 ps_criteria
//             }
//         }
// }`;

const REPORT_BY_ID_QUERY = gql`
query Report($rptId: ID!){
    report(_id: $rptId) {
      _id
      dh_id{
        _id
        dh_name
      }
      ag_id{
        _id
        ag_title
      }
      installation_id{
        _id
        installation_title
      }
      barrier_id{
        _id
        bar_title
      }
      sece_id{
        _id
        sece_title
        sece_ref
        sece_client_ref
      }
      va_id{
        _id
        va_ref
        va_type
        va_short_desc
        va_long_desc
        va_client_ref
      }
      vs_id{
        _id
      }
      report_ref
      report_comp_sample
      report_current_status{
        _id
      }
      report_phase
      report_time_taken
      report_verification_details
      report_conclusive_statement{
        _id
      }
      report_attachments
      linked_va_ids{
       _id
      }
      interacting_sece_ids{
        _id
      }
      report_added_id{
          _id
      }
      report_bg_activities
      report_archived
      report_status
  }
}`;

const REPORTS_BY_ID_LIST_QUERY = gql`
query ReportsByIdList($rptIdList: [ID!]!){
    reportsByIdList(rptIdList: $rptIdList) {
      _id
      dh_id{
        _id
        dh_name
      }
      ag_id{
        _id
        ag_title
      }
      installation_id{
        _id
        installation_title
      }
      barrier_id{
        _id
        bar_title
      }
      sece_id{
        _id
        sece_title
        sece_ref
        sece_client_ref
      }
      va_id{
        _id
        va_ref
        va_type
        va_short_desc
        va_long_desc
        va_client_ref
      }
      vs_id{
        _id
      }
      report_ref
      report_comp_sample
      report_current_status{
        _id
      }
      report_phase
      report_time_taken
      report_verification_details
      report_conclusive_statement{
        _id
      }
      report_attachments
      linked_va_ids{
       _id
      }
      interacting_sece_ids{
        _id
      }
      report_added_id{
          _id
      }
      report_bg_activities
      report_archived
      report_status
  }
}`;

// const DUTY_HOLDERS_QUERY = gql`
//     query {
//         dutyHolders {
//             _id
//             dh_name
//             dh_status
//             dh_added_date
//             dh_archived
//         }
// }`;

// const ASSETGROUPS_BY_DH_ID_QUERY = gql`
// query AssetGroupsByDutyHolderId($dhId: ID){
//     assetGroupsByDutyHolderId(dhId: $dhId) {
//       _id
//       ag_title
//       ag_archived
//       ag_status
//   }
// }`;

// const BARRIERS_BY_DH_AG_INSLL_IDS_QUERY = gql`
// query BarriersByDhIdAgIdInstId($dhId: ID, $agId: ID, $instId: ID){
//     barriersByDhIdAgIdInstId(dhId: $dhId, agId: $agId, instId: $instId){
//        _id
//        bar_title
//        bar_archived
//        bar_status
//     }
// }`;

// const INSTALLATIONS_BY_DH_ID_AND_AG_ID_QUERY = gql`
// query InstallsByDhIdAndAgId($dhId: ID!, $agId: ID!,){
//     installsByDhIdAndAgId(dhId: $dhId, agId:$agId) {
//       _id
//       installation_title
//       installation_archived
//       installation_status
//   }
// }`;

// const SECES_BY_BAR_ID_QUERY = gql`
//     query SecesByBarrierId ($barrierId: ID!){
//         secesByBarrierId (barrierId: $barrierId){
//             _id
//             sece_title
//             sece_client_ref
//             sece_order_no
//             sece_archived
//             sece_status
//             bar_id{ bar_title }
//         }
//     }
// `;

const SECES_BY_ID_QUERY = gql`
query sece($seceId: ID!){
    sece(_id: $seceId) {
       _id
       sece_title
       sece_ref
       sece_client_ref
       dh_id{
        _id
       }
       ag_id{
        _id
        ag_title
       }
       bar_id{
        _id
        bar_title
       }
       installation_id{
        _id
        installation_title
       }
    }
}`;

const CREATE_FINDING = gql`
    mutation CreateFinding($fnd: CreateFinding!) {
        createFinding(fnd: $fnd) {
            _id
            finding_ref
            finding_synopsis
            success
            status_code
            message
        }
    }
`;

const UPDATE_FINDING = gql`
    mutation UpdateFinding($fndId: String!, $userId: String, $fnd: UpdateFindingInput!) {
        updateFinding(_id: $fndId, userId: $userId, fnd: $fnd) {
            _id
            success
            status_code
            message
        }
    }
`;

const USERS_QUERY = gql`
query {
    users {
        _id
        user_fname
        user_lname
        user_archived
        user_duty_holder{
            _id
        }
        user_type {
            _id
            user_type_display_name
        }
        user_role{
            _id
            user_role_code
        }
    }
}`;

const DH_USERS_RESPONSIBLE_QUERY = gql`
query DhUsersResponsible($param: FindingPartyInput!){
    dh_users_responsible(param: $param) {
        _id
        user_fname
        user_lname
        user_type {
            _id
        }
    }
}`;

const ABS_USERS_RESPONSIBLE_QUERY = gql`
query ABSUsersResponsible($param: FindingPartyInput!){
    abs_users_responsible(param: $param) {
        _id
        user_fname
        user_lname
        user_type {
            _id
        }
    }
}`;

const ACTION_PARTIES_QUERY = gql`
query ActionParties($param: FindingPartyInput!){
    action_parties(param: $param) {
        _id
        user_fname
        user_lname
        user_type {
            _id
        }
    }
}`;

const INFOMATRIX_LEVELS_QUERY = gql`
query InfoMatrixLevelsByInstId($instId: String!){
    infoMatrixLevelsByInstId(instId: $instId)
}`;

const VAS_BY_ID_QUERY = gql`
query VasByIdList($idList: [ID]!){
    vasByIdList(idList: $idList){
        _id
        va_client_ref
        va_short_desc
        va_long_desc
        va_archived
        va_status
        va_type
        dh_id{
            _id
            dh_name
        }
        ag_id{
            _id
            ag_title
          }
        installation_id{
            _id
            installation_title
        }
        bar_id{
            _id
            bar_title
        }
        sece_id {
          _id  
          sece_client_ref
          sece_title
        }
        ps_id {
            _id  
            ps_ref
            ps_title
            ps_desc
            ps_criteria
        }
    }
}`;

const SECE_SEARCH_QUERY = gql`
query SeceSearchAlt($param: SeceSearch!){
    seceSearchAlt(param: $param){
        _id
        sece_title
        sece_order_no
        sece_group
        sece_archived
        sece_status
        sece_client_ref
    }
}`;

const PS_SEARCH_QUERY = gql`
query PerformanceStandardSearch($param: PerformanceStandardSearch!){
    performanceStandardSearch(param: $param){
        _id
        ps_title
        ps_archived
        ps_status
    }
}`;

const ADD_ACTIVITY_SEARCH_QUERY = gql`
query VerificationActivitySearchAlt2($param: VerificationActivitySearch!){
    verificationActivitySearchAlt2(param: $param){
    _id

    va_ref
    va_type
    va_client_ref
    va_short_desc
    va_long_desc

    sece_id{
     _id
     sece_title
     sece_ref
     sece_client_ref
    }

    ps_id{
        _id
        ps_ref
        ps_title
    }

    }
}`;

function FindingManagement(props) {
    const [enableValidation, setEnableValidation] = useState(false);
    const [findingType, setFindingType] = useState("");
    const [isDutyHolder, setIsDutyHolder] = useState(false);
    const [findingTitle, setFindingTitle] = useState("");
    const [findingVsId, setFindingVsId] = useState("");

    const [synopsisCharsLeft, setSynopsisCharsLeft] = useState(150);
    const [synopsisMaxChar, setSynopsisMaxChar] = useState(150);

    // const [selectedDhId, setSelectedDhId] = useState('');
    // const [selectedAgId, setSelectedAgId] = useState('');
    // const [selectedInstallId, setSelectedInstallId] = useState('');
    // const [selectedBarrierId, setSelectedBarrierId] = useState('');
    // const [selectedSeceIds, setSelectedSeceIds] = useState([]);

    const [shouldSpin, setShouldSpin] = useState(true);
    const [findingAction, setFindingAction] = useState(FINDING_ACTION_SAVE);

    const [lastResponse, setLastResponse] = useState("");
    const [daysOpen, setDaysOpen] = useState("");

    const [natureList, setNatureList] = useState([]);
    const [frequencyList, setFrequencyList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [findingLevelList, setFindingLevelList] = useState([]);

    const [answerOptionList, setAnswerOptionList] = useState([]);

    const [verActivityObject, setVerActivityObject] = useState({});
    //const [verActivityList, setVerActivityList] = useState([]);

    const [verScheduleObject, setVerScheduleObject] = useState({});
    const [vaRefActivityList, setVaRefActivityList] = useState([]);
    const [vaPerfStand, setVaPerfStand] = useState({});

    const [dutyHolderObject, setDutyHolderObject] = useState({});
    const [assetGroupObject, setAssetGroupObject] = useState({});
    const [barrierObject, setBarrierObject] = useState({});
    const [installationObject, setInstallationObject] = useState({});
    const [seceObject, setSeceObject] = useState({});
    const [authorFullName, setAuthorFullName] = useState("");

    const [absRepList, setAbsRepList] = useState([]);
    const [dhRepList, setDhRepList] = useState([]);
    const [actionPartyList, setActionPartyList] = useState([]);
    const [infoMatrixLevels, setInfoMatrixLevels] = useState([]);

    //const [dutyHolderList, setDutyHolderList] = useState([]);
    //const [assetGroupList, setAssetGroupList] = useState([]);
    //const [barrierList, setBarrierList] = useState([]);
    const [installationList, setInstallationList] = useState([]);
    //const [seceList, setSeceList] = useState([]);
    //const [vaList, setVaList] = useState([]);
    //const [scheduledVaList, setScheduledVaList] = useState([]);

    const [reportObject, setReportObject] = useState({});
    //const [reportList, setReportList] = useState([]);
    const [rptSeceIdList, setRptSeceIdList] = useState([]);

    const [installFindingList, setInstallFindingList] = useState([]);
    const [linkedInstallFindingList, setLinkedInstallFindingList] = useState([]);

    const [dhFindingList, setDhFindingList] = useState([]);
    const [linkedDhFindingList, setLinkedDhFindingList] = useState([]);

    const [previousInstallFindingList, setPreviousInstallFindingList] = useState([]);
    const [previousDhFindingList, setPreviousDhFindingList] = useState([]);

    const [findingCurrentStatus, setFindingCurrentStatus] = useState({});
    const [findingResponseList, setFindingResponseList] = useState([]);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [findingReportId, setFindingReportId] = useState("");
    const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

    //const [navOrigin, setNavOrigin] = useState("");

    /* Add Va */
    const [addVaFilterFormInitValue, setAddVaFilterFormInitValue] = useState({});
    const [onshoreOffshoreList, setOnshoreOffshoreList] = useState([]);
    const [selectedSeceId, setSelectedSeceId] = useState("");
    const [hasFilterParam, setHasFilterParam] = useState(false);
    const [addActivityList, setAddActivityList] = useState([]);
    const [selectedAddActivityList, setSelectedAddActivityList] = useState([]);
    const [seceList, setSeceList] = useState([]);
    const [psList, setPsList] = useState([]);
    //const [originalActivityList, setOriginalActivityList] = useState([]);
    const [disableAddVaButton, setDisableAddVaButton] = useState(false);
    /* Add Va */

    const [formInitValues, setFormInitValues] = useState({
        finding_ref: "",
        finding_current_status: "",
        finding_level: "",
        finding_dh: "",
        finding_ag: "",
        finding_installation: "",
        finding_barrier: "",
        finding_sece: "",
        finding_seces: [],
        finding_va: "",
        finding_vas: [],
        finding_nature: "",
        finding_equipment_no: "",
        finding_equipment_type: "",
        finding_integrity: "",
        finding_legislative: "",
        finding_paperwork_process: "",
        finding_rogi: "",
        finding_close_date: "",
        finding_synopsis: "",
        finding_summary: "",
        finding_action: "",
        finding_work_order_raised: "",
        finding_work_order_raised_desc: "",
        finding_contingency_actions: "",
        finding_response_date: "",
        finding_completion_date: "",
        finding_action_party: "",
        finding_dh_responsible: "",
        finding_abs_responsible: "",
        finding_doc_update_required: "",
        //finding_response_notes: "",
        finding_ps_reference: "",
        finding_ps_reference_attachments: "[]",
        finding_attachments: "[]",
        //finding_response_attachments:"[]",
        finding_added_date: "",
        finding_status: "",
        finding_type: "",
        finding_days_open: "",
        finding_days_since_last_response: "",
        fnd_resp_attmnt_dest_path: ""
    });

    const [fndResponseFormInitValues, setFndResponseFormInitValues] = useState({
        id: "",
        response_status: "",
        response_note: "",
        response_attachments: "[]"
    });

    const [fndResponseFormAction, setFndResponseFormAction] = useState("");

    const {
        formMode = "",
        findingId = "",
        rptId = "",
        vsId = ""
    } = useParams(); //form add or edit state passed from link

    const { location: { state: {
        actId = "",
        editRptId = "",
        status = "",
        origin = "",
        type: fndType = "",
        vaIds = [],
        rptIds = [],
        vs_id: fndVsId = "",
        title: fndTitle = "",
        inst_id: fnd_inst_id = "",
        sece_id: fnd_sece_id = "",
    } = {} } = {} } = props ? props : {};

    const history = useHistory();
    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);

    const [users, { data: usersData }] = useLazyQuery(USERS_QUERY);
    const [dh_users_responsible, { data: dhUsersResponsibleData }] = useLazyQuery(DH_USERS_RESPONSIBLE_QUERY);
    const [abs_users_responsible, { data: absUsersResponsibleData }] = useLazyQuery(ABS_USERS_RESPONSIBLE_QUERY);
    const [action_parties, { data: actionPartiesData }] = useLazyQuery(ACTION_PARTIES_QUERY);
    const [infoMatrixLevelsByInstId, { error: imError, data: infoMatLevelsData }] = useLazyQuery(INFOMATRIX_LEVELS_QUERY);

    const [systemVariablesBySysVarTypeIds, { error: sysError, data: sysVarsData }] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);

    /* Add Va */
    const [seceSearchAlt, { data: seceSearchData }] = useLazyQuery(SECE_SEARCH_QUERY);
    const [performanceStandardSearch, { data: psSearchData }] = useLazyQuery(PS_SEARCH_QUERY);
    const [verificationActivitySearchAlt2, { data: vaSearchData }] = useLazyQuery(ADD_ACTIVITY_SEARCH_QUERY);
    /* Add Va */

    //const [dutyHolders, { error: dhError, data: dutyHoldersData }] = useLazyQuery(DUTY_HOLDERS_QUERY);
    //const [assetGroupsByDutyHolderId, { error: agError, data: assetGroupsData }] = useLazyQuery(ASSETGROUPS_BY_DH_ID_QUERY);
    //const [barriersByDhIdAgIdInstId, { error: barError, data: barriersByDhIdAgIdInstIdData }] = useLazyQuery(BARRIERS_BY_DH_AG_INSLL_IDS_QUERY);
    //const [installsByDhIdAndAgId, { error: instError, data: installsByDhIdAndAgIdData }] = useLazyQuery(INSTALLATIONS_BY_DH_ID_AND_AG_ID_QUERY);
    //const [secesByBarrierId, { error: secesByBarError, data: secesByBarData }] = useLazyQuery(SECES_BY_BAR_ID_QUERY);
    const [sece, { error: secError, data: seceData }] = useLazyQuery(SECES_BY_ID_QUERY);
    const [verificationSchedule, { data: vsData }] = useLazyQuery(VERIFICATION_SCHEDULE_ID_QUERY);

    const [report, { error: rptError, data: reportData }] = useLazyQuery(REPORT_BY_ID_QUERY);
    const [reportsByIdList, { error: rptListError, data: reportListData }] = useLazyQuery(REPORTS_BY_ID_LIST_QUERY);
    const [referenceActivitySearch, { data: refActData }] = useLazyQuery(REF_ACT_SEARCH);
    const [verificationActivity, { data: vaQueryData }] = useLazyQuery(VA_BY_ID_QUERY);
    //const [vasByDhIdAndSeceIds, { data: vasByDhIdAndSeceIdsData }] = useLazyQuery(VAS_BY_DHID_AND_SECEIDS_QUERY);
    const [vasByIdList, { data: scheduledVAData }] = useLazyQuery(VAS_BY_ID_QUERY);

    const [assetGroupsByInstId, { data: agData }] = useLazyQuery(ASSETGROUP_BY_INST_ID_QUERY);

    const [finding, { error: fndError, data: findingData }] = useLazyQuery(FINDING_BY_ID_QUERY);
    const [findingSearch, { data: findingSearchData }] = useLazyQuery(FINDINGS_BY_FILTER_PARAMS_QUERY);

    const [findingsByDhId, { loading: fndByDhLoading, error: fndByDhError, data: findingsByDhIdData }] = useLazyQuery(FINDINGS_BY_DH_QUERY);
    const [findingsByInstId, { loading: fndByInstLoading, error: fndByInstError, data: findingsByInstIdData }] = useLazyQuery(FINDINGS_BY_INST_QUERY);

    const [createFinding, { loading: createFindingLoading, error: createFindingError, data: createFindingData }] = useMutation(CREATE_FINDING);
    const [updateFinding, { loading: updateFindingLoading, error: updateFindingError, data: updateFindingData }] = useMutation(UPDATE_FINDING);

    const sysVarTypeIds = [
        CURRENT_STATUS_FINDINGS_SYSTEM_VARIABLE_TYPE_ID,
        NATURE_SYSTEM_VARIABLE_TYPE_ID,
        VA_FREQUENCY_SYSTEM_VARIABLE_TYPE_ID,
        ANSWERS_SYSTEM_VARIABLE_TYPE_ID,
        FINDING_LEVEL_SYSTEM_VARIABLE_TYPE_ID
    ];

    const prevFndColumn = React.useMemo(
        () => [
            {
                Header: 'Finding Ref.',
                accessor: 'fnd_ref',
            },
            {
                Header: 'Finding Level',
                accessor: 'fnd_level',
            },
            {
                Header: 'Date Raised',
                accessor: 'fnd_date_raised',
            },
            {
                Header: 'Synopsis',
                accessor: 'fnd_synopsis',
            },
            {
                Header: 'Action Party',
                accessor: 'fnd_action_party',
            },
            {
                Header: 'Author',
                accessor: 'fnd_author',
            },
            {
                Header: 'Closure Date',
                accessor: 'fnd_closure_date',
            },
            {
                Header: 'Current Status',
                accessor: 'fnd_current_status',
            },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: ({ value }) => (
                    <div className="text-right">
                        <Link
                            to={`/reporting/finding/view/${value.id}`}
                            className="button-red mb-1 mr-1 text-xs"
                            target={"_blank"}
                        >
                            View
                        </Link>
                    </div>
                ),
            },
        ],
        []
    )

    const fndResponseAttachColumns = React.useMemo(
        () => [
            {
                Header: 'File Name',
                accessor: 'name', // accessor is the "key" in the data
            },
            {
                Header: 'Description',
                accessor: 'description', // accessor is the "key" in the data
            },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: () => (<div></div>)
            },
        ],
        []
    )

    useEffect(() => {
        if (authContext && authContext.user) {
            let result = createUserTypeAndRoleObject(authContext);
            const { userType } = result ? result : {};
            if (userType === "Duty Holder") {
                setIsDutyHolder(true);
            }
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        if (isInitialPageLoad) {
          setIsInitialPageLoad(false);
          return;
        }
        //history.push('/');
      }, [navContext]);

    useEffect(() => {
        if (userTypeAndRoleObject &&
            userTypeAndRoleObject.userType !== "ABS" &&
            formMode !== "edit") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);

    useEffect(() => {
        try {
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        } catch (error) { window.scrollTo(0, 0); };

        //let searchQueryParams = new URLSearchParams(search);
        //let origin = searchQueryParams.get("origin");
        //setNavOrigin(origin);

        /* Add Va */
        setAddVaFilterFormInitValue(addVaFilterFormDefaultValues);
        setOnshoreOffshoreList(onOffshoreList);
        /* Add Va */

        setSynopsisMaxChar(150);
        systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });

        setDaysOpen(""); //Temp Measure
        setLastResponse(""); //Temp Measure

        setFindingType(fndType);
        setFindingTitle(fndTitle);

        setFindingVsId(fndVsId);

        //To Be Removed Start
        let temp = `${vaRefActivityList.length + lastResponse.length + daysOpen.length + natureList.length}`
        temp = "";
        console.log(temp);
        //To Be Removed Start vsId

        if (fnd_inst_id !== "" && fnd_sece_id !== "") {
            findingsByInstId({ variables: { instId: fnd_inst_id, seceIdList: [fnd_sece_id], fndId: null }, errorPolicy: 'all' });
        };

        if (rptId !== "" || vsId !== "") {
            seceSearchAlt({ variables: { param: { report_id: rptId, vs_id: vsId } }, errorPolicy: 'all' });
            verificationActivitySearchAlt2({ variables: { param: { report_id: rptId, vs_id: vsId } }, errorPolicy: 'all' });
        }

        if (rptId !== "") {
            findingSearch({ variables: { param: { report_id: rptId, exclude_finding_id: findingId } }, errorPolicy: 'all' });
        };

        if (((formMode === "verify") || (formMode === "verify-edit")) && rptId !== "") {            
            report({ variables: { rptId: rptId }, errorPolicy: 'all' });
        };

        if (((formMode === "verify") || (formMode === "verify-edit")) && rptIds.length > 0) {
            reportsByIdList({ variables: { rptIdList: rptIds }, errorPolicy: 'all' });
        };

        if (((formMode === "verify") || (formMode === "verify-edit")) && vaIds.length > 0) {
            vasByIdList({ variables: { idList: vaIds }, errorPolicy: 'all' });
        };

        if (((formMode === "verify") || (formMode === "verify-edit")) && vsId !== "" && actId !== "") {
            verificationActivity({ variables: { vaId: actId }, errorPolicy: 'all' })
        };

        if (((formMode === "verify") || (formMode === "verify-edit")) && vsId !== "") {
            verificationSchedule({ variables: { vsId: (vsId ? vsId : fndVsId) }, errorPolicy: 'all' });
        };

        if (((formMode === "add" || formMode === "edit")) && (fndVsId !== "")) {
            verificationSchedule({ variables: { vsId: fndVsId }, errorPolicy: 'all' });
        };

        // if (formMode === "add" || "edit" || "verify" || "verify-edit") {
        //     dutyHolders({ variables: {}, errorPolicy: 'all' });
        // };

        if ((formMode === "edit") && (findingId !== "")) {
            finding({ variables: { findingId: findingId }, errorPolicy: 'all' });
        };

        users({ variables: {}, errorPolicy: 'all' });
    }, []);

    // useEffect(() => {
    //     if (authContext && authContext.user) {
    //         let result = createUserTypeAndRoleObject(authContext);
    //         setUserTypeAndRoleObject(result);
    //     }
    // }, [authContext]);

    useEffect(() => {
        if (findingSearchData && findingSearchData.findingSearch) {
            let findings = findingSearchData.findingSearch;
            let asbFindings = findings.filter(fnd => fnd.finding_added_id.user_type._id === USER_TYPE_ABS_ID);
            let dhFindings = findings.filter(fnd => fnd.finding_added_id.user_type._id === USER_TYPE_DUTY_HOLDER_ID);

            let formatedAbsFndList = formatPreviousFindings(asbFindings, 150);
            let formatedDhFndList = formatPreviousFindings(dhFindings, 150);

            setInstallFindingList(formatedAbsFndList);
            setDhFindingList(formatedDhFndList);
        }
    }, [findingSearchData]);

    useEffect(() => {
        if (usersData && usersData.users) {
            let list = usersData.users;
            if ((formMode === "verify") || (formMode === "verify-edit") || (formMode === "add")) {
                const { _id: userId = "" } = authContext.user ? authContext.user : {};
                let authur = list.find(user => user && user._id === userId);
                const { user_fname: firstName = "", user_lname: lastName = "" } = authur ? authur : {};
                setAuthorFullName(`${firstName} ${lastName}`);
            }
        }
    }, [usersData]);

    useEffect(() => {
        if (actionPartiesData && actionPartiesData.action_parties) {
            setActionPartyList(actionPartiesData.action_parties);
        }
    }, [actionPartiesData]);

    useEffect(() => {
        if (dhUsersResponsibleData && dhUsersResponsibleData.dh_users_responsible) {
            setDhRepList(dhUsersResponsibleData.dh_users_responsible);
        }
    }, [dhUsersResponsibleData]);

    useEffect(() => {
        if (absUsersResponsibleData && absUsersResponsibleData.abs_users_responsible) {
            setAbsRepList(absUsersResponsibleData.abs_users_responsible);
        }
    }, [absUsersResponsibleData]);

    useEffect(() => {
        if (infoMatLevelsData && infoMatLevelsData.infoMatrixLevelsByInstId) {
            setInfoMatrixLevels(infoMatLevelsData.infoMatrixLevelsByInstId);
        }
    }, [infoMatLevelsData]);

    useEffect(() => {
        if (reportData && reportData.report) {
            let report = reportData.report;
            let dutyHolder = report.dh_id ? report.dh_id : {};
            const { _id: dhId = "" } = dutyHolder ? dutyHolder : {};
            let assetGroup = report.ag_id ? report.ag_id : {};
            let installation = report.installation_id ? report.installation_id : {};
            const { _id: instId = "" } = installation ? installation : {};
            let barrier = report.barrier_id ? report.barrier_id : {};
            let sece = report.sece_id ? report.sece_id : {};
            const { _id: seceId = "" } = sece ? sece : {};
            let verAct = report.va_id ? report.va_id : {};
            let verSch = report.vs_id ? report.vs_id : {};

            // Add Va Stuff
            let preSelectedActivity = addActivityList.find(a => verAct._id && (a._id === verAct._id));           
            let preSelectedActivityList = preSelectedActivity ? [preSelectedActivity] : [];
            //setOriginalActivityList(preSelectedActivityList);
            setSelectedAddActivityList(preSelectedActivityList);
            // Add Va Stuff

            setDutyHolderObject(dutyHolder);
            setAssetGroupObject(assetGroup);
            setInstallationObject(installation);
            setBarrierObject(barrier);
            setSeceObject(sece);
   
            setVerActivityObject(verAct);

            setVerScheduleObject(verSch);
            setReportObject(report);

            setShouldSpin(false);
            //This gets the Action parties

            dh_users_responsible({ variables: { param: { sece_ids: [seceId] } }, errorPolicy: 'all' });

            action_parties({ variables: { param: { dh_id: dhId, installation_id: instId, sece_ids: [seceId] } }, errorPolicy: 'all' });
            infoMatrixLevelsByInstId({ variables: { instId: instId }, errorPolicy: 'all' });
        }
    }, [reportData]);

    useEffect(() => {
        if (reportListData && reportListData.reportsByIdList) {
            let rptList = reportListData.reportsByIdList;

            //setReportList(rptList);

            let dh = rptList[0] ? rptList[0].dh_id : {};
            let ag = rptList[0] ? rptList[0].ag_id : {};
            let bar = rptList[0] ? rptList[0].barrier_id : {};
            let inst = rptList[0] ? rptList[0].installation_id : {};

            //let vaList = rptList.map(r => r.va_id);

            let seceList = rptList.map(r => r.sece_id);

            let dhId = dh ? dh._id : "";
            let instId = inst ? inst._id : "";
            let seceIdList = seceList.map(s => s._id);

            setRptSeceIdList(seceIdList);
            setAssetGroupObject(ag);
            setBarrierObject(bar);
            setInstallationObject(inst);

            //setVerActivityList(vaList);

            findingsByDhId({ variables: { dhId: dhId, instId: instId, seceIdList: seceIdList, fndId: null }, errorPolicy: 'all' });
            findingsByInstId({ variables: { instId: instId, seceIdList: seceIdList, fndId: null }, errorPolicy: 'all' });

            dh_users_responsible({ variables: { param: { sece_ids: seceIdList } }, errorPolicy: 'all' });
            action_parties({ variables: { param: { dh_id: dhId, installation_id: instId, sece_ids: seceIdList } }, errorPolicy: 'all' });
            abs_users_responsible({ variables: { param: { installation_id: instId } }, errorPolicy: 'all' });
        }
    }, [reportListData]);

    useEffect(() => {
        if (vsData && vsData.verificationSchedule) {
            let vs = vsData.verificationSchedule;
            let dutyHolder = vs.dh_id ? vs.dh_id : {};

            let insts = vs.installation_ids ? vs.installation_ids : [];

            let tempInst = insts[0];

            setDutyHolderObject(dutyHolder);
            setInstallationList([...insts])

            if (tempInst) {
                setInstallationObject(tempInst);
                if (findingType === INST_FINDING_TYPE) {
                    assetGroupsByInstId({ variables: { instId: tempInst._id }, errorPolicy: 'all' });
                }
            }

            setVerScheduleObject(vs);

            if (findingType === DH_FINDING_TYPE || findingType === INST_FINDING_TYPE) {
                abs_users_responsible({ variables: { param: { finding_type: findingType, dh_id: dutyHolder._id } }, errorPolicy: 'all' });

                dh_users_responsible({
                    variables: {
                        param: {
                            finding_type: findingType,
                            dh_id: dutyHolder._id,
                            ...(tempInst && { installation_id: tempInst._id })
                        }
                    }, errorPolicy: 'all'
                });

                action_parties({
                    variables: {
                        param: {
                            finding_type: findingType,
                            dh_id: dutyHolder._id,
                            ...(tempInst && { installation_id: tempInst._id })
                        }
                    }, errorPolicy: 'all'
                });

            }

            setShouldSpin(false);
        }
    }, [vsData]);

    useEffect(() => {
        if (agData && agData.assetGroupsByInstId) {
            setAssetGroupObject(agData.assetGroupsByInstId);
        }
    }, [agData]);

    useEffect(() => {
        if (scheduledVAData && scheduledVAData.vasByIdList) {
            let scheduledVaList = scheduledVAData.vasByIdList;

            let dh = scheduledVaList[0] ? scheduledVaList[0].dh_id : {};
            let ag = scheduledVaList[0] ? scheduledVaList[0].ag_id : {};
            let bar = scheduledVaList[0] ? scheduledVaList[0].bar_id : {};
            let inst = scheduledVaList[0] ? scheduledVaList[0].installation_id : {};

            let seceList = scheduledVaList.map(s => s.sece_id);

            let dhId = dh ? dh._id : "";
            let instId = inst ? inst._id : "";
            let seceIdList = seceList.map(s => s._id);

            setRptSeceIdList(seceIdList);

            setAssetGroupObject(ag);
            setBarrierObject(bar);
            setInstallationObject(inst);

            //setScheduledVaList(scheduledVaList);
            //setOriginalActivityList(scheduledVaList);
            setSelectedAddActivityList(scheduledVaList);

            findingsByDhId({ variables: { dhId: dhId, instId: instId, seceIdList: seceIdList, fndId: null }, errorPolicy: 'all' });
            findingsByInstId({ variables: { instId: instId, seceIdList: seceIdList, fndId: null }, errorPolicy: 'all' });

            dh_users_responsible({ variables: { param: { sece_ids: seceIdList } }, errorPolicy: 'all' });
            action_parties({ variables: { param: { dh_id: dhId, installation_id: instId, sece_ids: seceIdList } }, errorPolicy: 'all' });
        }
    }, [scheduledVAData]);

    useEffect(() => {
        if (verActivityObject && verActivityObject._id && verActivityObject._id !== "") {
            if (rptId !== "") {
                referenceActivitySearch({ variables: { param: { va_id: verActivityObject._id } }, errorPolicy: 'all' });
                verificationActivity({ variables: { vaId: verActivityObject._id }, errorPolicy: 'all' });
            }
        }
    }, [verActivityObject]);

    useEffect(() => {
        if (refActData && refActData.referenceActivitySearch) {
            let list = refActData.referenceActivitySearch;
            let filteredList = list.filter(r => r.ra_archived !== true && r.ra_status === NOTIFICATION_STATUS_APPROVED);
            setVaRefActivityList(filteredList);
        }
    }, [refActData]);

    useEffect(() => {
        if (vaQueryData && vaQueryData.verificationActivity) {
            let va = vaQueryData.verificationActivity;
            const { sece_id: { _id: seceId = "" }, ps_id = {} } = va ? va : {};
            setVaPerfStand(ps_id);

            if (((formMode === "verify") || (formMode === "verify-edit")) && vsId !== "" && actId !== "" && seceId !== "") {                         
                setVerActivityObject(va);
                sece({ variables: { seceId: seceId }, errorPolicy: 'all' })
            };
        }
    }, [vaQueryData]);

    useEffect(() => {
        if (seceData && seceData.sece && ((formMode === "verify") || (formMode === "verify-edit")) && vsId !== "" && actId !== "") {
            let sece = seceData.sece;
            let dutyHolder = sece.dh_id ? sece.dh_id : {};
            let assetGroup = sece.ag_id ? sece.ag_id : {};
            let installation = sece.installation_id ? sece.installation_id : {};
            let barrier = sece.bar_id ? sece.bar_id : {};

            const { _id: dhId = "" } = dutyHolder;
            const { _id: seceId = "" } = sece;
            const { _id: instId = "" } = installation;

            setAssetGroupObject(assetGroup);
            setInstallationObject(installation);
            setBarrierObject(barrier);
            setSeceObject(sece);
            dh_users_responsible({ variables: { param: { sece_ids: [seceId] } }, errorPolicy: 'all' });
            action_parties({ variables: { param: { dh_id: dhId, installation_id: instId, sece_ids: [seceId] } }, errorPolicy: 'all' });
        }
    }, [seceData]);

    useEffect(() => {
        if (seceSearchData && seceSearchData.seceSearchAlt) {
            setSeceList(seceSearchData.seceSearchAlt);
        }
    }, [seceSearchData]);

    useEffect(() => {
        if (psSearchData && psSearchData.performanceStandardSearch) {
            let tempList = psSearchData.performanceStandardSearch;
            let filteredList = tempList.filter(ps => ps.ps_archived !== true);
            setPsList(filteredList);
        }
    }, [psSearchData]);

    useEffect(() => {
        if (vaSearchData && vaSearchData.verificationActivitySearchAlt2) {
            let tempList = vaSearchData.verificationActivitySearchAlt2;
            let filteredList = tempList.filter(va => va.va_archived !== true);

            // Add Va Stuff
            if (formMode !== "edit") {
                let preSelectedActivity = filteredList.find(a => actId && (a._id === actId));
                let preSelectedActivityList = preSelectedActivity ? [preSelectedActivity] : [];
                //setOriginalActivityList(preSelectedActivityList);
                setSelectedAddActivityList(preSelectedActivityList);
            }

            if (formMode !== "edit" && reportObject.va_id && selectedAddActivityList.length === 0) {
                const { va_id: rptVaObj } = reportObject ?? {};
                const { _id } = rptVaObj ?? {};
                let preSelectedActivity = filteredList.find(a => _id && (a._id === _id));
                let preSelectedActivityList = preSelectedActivity ? [preSelectedActivity] : [];
                //setOriginalActivityList(preSelectedActivityList);
                setSelectedAddActivityList(preSelectedActivityList);
            }

            if (formMode === "edit" && verActivityObject &&
                verActivityObject._id && 
                selectedAddActivityList.length === 0 && 
                findingType === SINGLE_VA_FINDING_TYPE) {
                const { _id: vaObjId } = verActivityObject ?? {};

                let preSelectedActivity = filteredList.find(a => vaObjId && (a._id === vaObjId));
                let preSelectedActivityList = preSelectedActivity ? [preSelectedActivity] : [];
                //setOriginalActivityList(preSelectedActivityList);
                setSelectedAddActivityList(preSelectedActivityList);
            }
            // Add Va Stuff

            setAddActivityList(filteredList);
        }
    }, [vaSearchData]);

    useEffect(() => {
        if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
            formatSysVarDataAndUpdateStates(sysVarsData.systemVariablesBySysVarTypeIds,
                setNatureList,
                setFrequencyList,
                setStatusList,
                setAnswerOptionList,
                setFindingLevelList,
                NATURE_SYSTEM_VARIABLE_TYPE_ID,
                VA_FREQUENCY_SYSTEM_VARIABLE_TYPE_ID,
                CURRENT_STATUS_FINDINGS_SYSTEM_VARIABLE_TYPE_ID,
                ANSWERS_SYSTEM_VARIABLE_TYPE_ID,
                FINDING_LEVEL_SYSTEM_VARIABLE_TYPE_ID
            );
        }
    }, [sysVarsData]);

    useEffect(() => {
        if (findingResponseList.length === 0) {
            let openStatus = statusList.find(s => s.sysvar_title === "Open");
            if (openStatus) {
                setFindingCurrentStatus({ id: openStatus._id, name: openStatus.sysvar_title });
            }
        } else {
            let fndResLength = findingResponseList.length;
            let lastFndResp = findingResponseList[fndResLength - 1];
            let openStatus = statusList.find(s => s.sysvar_title === lastFndResp.response_status);
            if (openStatus) {
                setFindingCurrentStatus({ id: openStatus._id, name: openStatus.sysvar_title });
            }
        }
    }, [statusList, findingResponseList]);

    useEffect(() => {
        if (findingData && findingData.finding) {
            let finding = findingData.finding;

            let finding_type = finding.finding_type;

            if (finding_type === INST_FINDING_TYPE) {
                setFindingTitle("Edit Installation Finding");
            }

            if (finding_type === DH_FINDING_TYPE) {
                setFindingTitle("Edit Duty Holder Finding");
            }

            if (finding_type === SINGLE_VA_FINDING_TYPE) {
                let va = finding.va_id;
                // Add Va Stuff
                let preSelectedActivity = addActivityList.find(a => va._id && (a._id === va._id));
                let preSelectedActivityList = preSelectedActivity ? [preSelectedActivity] : [];
                //setOriginalActivityList(preSelectedActivityList);
                setSelectedAddActivityList(preSelectedActivityList);
                // Add Va Stuff
            }

            let dutyHolder = finding.dh_id ? finding.dh_id : {};
            let assetGroup = finding.ag_id ? finding.ag_id : {};
            let installation = finding.installation_id ? finding.installation_id : {};
            let barrier = finding.barrier_id ? finding.barrier_id : {};
            //let sece = finding.sece_id ? finding.sece_id : {}; //Some Findings in the dev database will still have single sece
            let seces = finding.sece_ids ? finding.sece_ids : [];
            let verAct = finding.va_id ? finding.va_id : {};
            let verActs = finding.va_ids ? finding.va_ids : [];
            verAct = verActs[0];

            const { _id: tempVsId = "" } = finding.vs_id ? finding.vs_id : {};

            let fndNatureList = finding.finding_nature_ids ? finding.finding_nature_ids : [];
            let fndNature = fndNatureList[0];
            let findingNature = fndNature ? fndNature._id : "";

            let linkedInstFndList = finding.linked_install_fnds ? formatPreviousFindings(finding.linked_install_fnds, 150) : [];
            let linkedDhFndList = finding.linked_dh_fnds ? formatPreviousFindings(finding.linked_dh_fnds, 150) : [];

            let fndId = finding._id ? finding._id : "";
            let dutyHolderId = dutyHolder._id ? dutyHolder._id : "";
            let assetGroupId = assetGroup._id ? assetGroup._id : "";
            let installationId = installation._id ? installation._id : "";
            let barrierId = barrier._id ? barrier._id : "";
            let seceIds = formatSeceData(seces);
            let vaId = verAct && verAct._id ? verAct._id : "";
            let vaIds = verActs.map(va => va._id);

            const { user_fname: authFName, user_lname: authLName } = finding.finding_auth ? finding.finding_auth : {};
            let findingAuth = `${authFName} ${authLName}`;

            let findingAttachments = finding.finding_ps_reference_attachments ? finding.finding_ps_reference_attachments : "[]";
            let attachments = finding.finding_attachments ? finding.finding_attachments : "[]";
            //let responseAttachments = finding.finding_response_attachments?finding.finding_response_attachments:"[]";

            //This removes  any un handled whitespace
            let formated_fnd_responses = "[]";
            if(finding.finding_responses !== null){               
                formated_fnd_responses =finding.finding_responses && finding.finding_responses.replace(/\s+/g, ' ');
            }

            let findingResponses = parseJSONSafely(formated_fnd_responses) !== null ? parseJSONSafely(formated_fnd_responses) : [];

            const { _id: reportId = "" } = finding.report_id ? finding.report_id : {};
            //let reportList = finding.report_ids ? finding.report_ids : [];

            //setReportList(reportList);
            //setScheduledVaList(verActs);

            const { sysvar_title: fndCurrentStatus } = finding.finding_current_status ?? {};
            const fndWorkFlowStatus = finding.finding_status;

            let initValue = {
                finding_ref: finding.finding_ref ? finding.finding_ref : "N/A",
                finding_current_status: finding.finding_current_status && finding.finding_current_status._id ? finding.finding_current_status._id : "",
                //finding_level: finding.finding_level && finding.finding_level._id ? finding.finding_level._id : "",
                //finding_level: finding.finding_level_summary && finding.finding_level_summary ? finding.finding_level_summary : "",
                finding_level: finding.finding_infomatrix_level && finding.finding_infomatrix_level ? finding.finding_infomatrix_level : "",

                finding_dh: dutyHolderId,
                finding_ag: assetGroupId,
                finding_installation: installationId,
                finding_barrier: barrierId,
                finding_seces: seceIds,
                finding_va: vaId,
                finding_vas: vaIds,

                finding_nature: findingNature,

                finding_equipment_no: finding.finding_equip_number ? finding.finding_equip_number : "",
                finding_equipment_type: finding.finding_equip_type ? finding.finding_equip_type : "",

                finding_integrity: finding.finding_integrity && finding.finding_integrity._id ? finding.finding_integrity._id : "",
                finding_legislative: finding.finding_legislative && finding.finding_legislative._id ? finding.finding_legislative._id : "",
                finding_paperwork_process: finding.finding_pw_process && finding.finding_pw_process._id ? finding.finding_pw_process._id : "",
                finding_rogi: finding.finding_rogi && finding.finding_rogi._id ? finding.finding_rogi._id : "",

                finding_close_date: finding.finding_closure_date ? new Date(finding.finding_closure_date) : "",
                finding_synopsis: finding.finding_synopsis ? finding.finding_synopsis : "",
                finding_summary: finding.finding_summary ? finding.finding_summary : "",
                finding_action: finding.finding_action ? finding.finding_action : "",
                finding_work_order_raised: finding.finding_work_order_raised && finding.finding_work_order_raised._id ? finding.finding_work_order_raised._id : "",
                finding_work_order_raised_desc: finding.finding_work_order_raised_desc ? finding.finding_work_order_raised_desc : "",
                finding_contingency_actions: finding.finding_contingency_actions && finding.finding_contingency_actions._id ? finding.finding_contingency_actions._id : "",
                finding_response_date: finding.finding_response_date ? new Date(finding.finding_response_date) : new Date(),
                finding_completion_date: finding.finding_completion_date ? new Date(finding.finding_completion_date) : new Date(),

                finding_action_party: finding.finding_action_party && finding.finding_action_party._id ? finding.finding_action_party._id : "",
                finding_dh_responsible: finding.finding_dh_responsible && finding.finding_dh_responsible._id ? finding.finding_dh_responsible._id : "",
                finding_abs_responsible: finding.finding_abs_responsible && finding.finding_abs_responsible._id ? finding.finding_abs_responsible._id : "",
                finding_doc_update_required: finding.finding_doc_update_required && finding.finding_doc_update_required._id ? finding.finding_doc_update_required._id : "",
                //finding_response_notes: finding.finding_response_notes ? finding.finding_response_notes : "",

                finding_ps_reference: finding.finding_ps_reference ? finding.finding_ps_reference : "",
                finding_ps_reference_attachments: findingAttachments,
                finding_attachments: attachments,
                //finding_response_attachments: responseAttachments,
                finding_added_date: finding.finding_added_date ? new Date(finding.finding_added_date) : new Date(),
                finding_status: finding.finding_status,

                finding_days_open: finding.finding_days_open,
                finding_days_since_last_response: finding.finding_days_since_last_response,
                fnd_resp_attmnt_dest_path: finding.fnd_resp_attmnt_dest_path
            } 
            
            let canDisable = fndCurrentStatus === "Open" && fndWorkFlowStatus === "approved" ? true : false;
            setDisableAddVaButton(canDisable);

            setFindingReportId(reportId);
            setAuthorFullName(findingAuth);
            setFormInitValues({ ...formInitValues, ...initValue })

            setDutyHolderObject(dutyHolder);
            //setSelectedDhId(dutyHolderId);
            setAssetGroupObject(assetGroup);
            //setSelectedAgId(assetGroupId);
            setInstallationObject(installation);
            //setSelectedInstallId(installationId);
            setBarrierObject(barrier);

            setSeceObject(seces[0]); //TempMeasure, should display the list.

            //setSelectedSeceIds(seceIds);
            setVerActivityObject(verAct);
            //setVerActivityList(verActs)

            setFindingResponseList(findingResponses);
            setShouldSpin(false);

            setLinkedInstallFindingList(linkedInstFndList);
            setLinkedDhFindingList(linkedDhFndList);

            let seceIdList = seces.map(s => s._id);
            setRptSeceIdList(seceIdList)

            setFindingType(finding_type);
            setFindingVsId(tempVsId);

            //This gets the Action parties
            dh_users_responsible({ variables: { param: { sece_ids: seceIdList } }, errorPolicy: 'all' });
            action_parties({ variables: { param: { dh_id: dutyHolderId, installation_id: installationId, sece_ids: seceIdList } }, errorPolicy: 'all' });

            if (installationId && installationId !== "") {
                infoMatrixLevelsByInstId({ variables: { instId: installationId }, errorPolicy: 'all' });
            }

            findingsByDhId({ variables: { dhId: dutyHolderId, instId: installationId, seceIdList: seceIdList, fndId: finding._id }, errorPolicy: 'all' });
            findingsByInstId({ variables: { instId: installationId, seceIdList: seceIdList, fndId: fndId }, errorPolicy: 'all' });

            verificationSchedule({ variables: { vsId: tempVsId }, errorPolicy: 'all' });

            if (finding_type === MULTI_VA_FINDING_TYPE) {
                vasByIdList({ variables: { idList: vaIds }, errorPolicy: 'all' });
            }

            if (reportId !== "" || tempVsId !== "") {
                seceSearchAlt({ variables: { param: { report_id: reportId, vs_id: tempVsId } }, errorPolicy: 'all' });
                verificationActivitySearchAlt2({ variables: { param: { report_id: reportId, vs_id: tempVsId } }, errorPolicy: 'all' });
            }
        }
    }, [findingData]);

    // useEffect(() => {
    //     if (selectedDhId !== '') {
    //         setAssetGroupList([]);
    //         assetGroupsByDutyHolderId({ variables: { dhId: selectedDhId }, errorPolicy: 'all' });
    //         setPreviousDhFindingList([]);
    //         setPreviousInstallFindingList([]);
    //     }
    // }, [selectedDhId]);

    // useEffect(() => {
    //     if (selectedDhId !== '' && selectedAgId !== '') {
    //         setInstallationList([]);
    //         installsByDhIdAndAgId({ variables: { dhId: selectedDhId, agId: selectedAgId }, errorPolicy: 'all' });
    //         setPreviousInstallFindingList([]);
    //     }
    // }, [selectedAgId]);

    // useEffect(() => {
    //     if (selectedDhId !== '' && selectedAgId !== '' && selectedInstallId !== '') {
    //         setBarrierList([]);
    //         setSeceList([]);
    //         barriersByDhIdAgIdInstId({ variables: { dhId: selectedDhId, agId: selectedAgId, instId: selectedInstallId }, errorPolicy: 'all' });
    //         //ABS Users Responsible for the Installation
    //         abs_users_responsible({ variables: {param: {installation_id: selectedInstallId} }, errorPolicy: 'all' });
    //         infoMatrixLevelsByInstId({ variables: { instId: selectedInstallId }, errorPolicy: 'all' });
    //     }
    // }, [selectedInstallId]);

    // useEffect(() => {
    //     if (selectedDhId !== '' && selectedAgId !== '' && selectedInstallId !== '' && selectedBarrierId !== '') {
    //         setSeceList([]);
    //         secesByBarrierId({ variables: { barrierId: selectedBarrierId }, errorPolicy: 'all' });
    //     }
    // }, [selectedBarrierId]);

    // useEffect(() => {
    //     if (selectedDhId !== '' && selectedSeceIds && selectedSeceIds.length) {
    //         const seceIds = selectedSeceIds.map(s => s.value);
    //         vasByDhIdAndSeceIds({ variables: { dhId: selectedDhId, seceIds: seceIds }, errorPolicy: 'all' })
    //         dh_users_responsible({ variables: {param: {sece_ids: seceIds} }, errorPolicy: 'all' });
    //         action_parties({ variables: {param: {dh_id: selectedDhId, installation_id: selectedInstallId, sece_ids: seceIds} }, errorPolicy: 'all' });
    //     }
    //     setVaList([]);

    //     let seceIds = selectedSeceIds && selectedSeceIds.length ? selectedSeceIds.map(sece => sece && sece.value) : [];
    //     if (selectedDhId !== "" && selectedInstallId !== "" && seceIds.length > 0 && formMode === "add") {
    //         findingsByDhId({ variables: { dhId: selectedDhId, instId: selectedInstallId, seceIdList: seceIds, fndId: null }, errorPolicy: 'all' });
    //         findingsByInstId({ variables: { instId: selectedInstallId, seceIdList: seceIds, fndId: null }, errorPolicy: 'all' });
    //     }
    // }, [selectedSeceIds]);

    useEffect(() => {
        if (installationObject && installationObject._id && installationObject._id !== '') {
            let instId = installationObject._id;
            //ABS Users Responsible for the Installation

            if (findingType !== DH_FINDING_TYPE) {
                abs_users_responsible({ variables: { param: { finding_type: findingType, installation_id: instId } }, errorPolicy: 'all' });
                infoMatrixLevelsByInstId({ variables: { instId: instId }, errorPolicy: 'all' });
            }
        }
    }, [installationObject]);

    useEffect(() => {
        if (seceObject && seceObject._id && seceObject._id !== '' && formMode === "verify") {
            let dhId = dutyHolderObject._id;
            let instId = installationObject._id;
            let seceId = seceObject._id;
            findingsByDhId({ variables: { dhId: dhId, instId: instId, seceIdList: [seceId], fndId: null }, errorPolicy: 'all' });
            findingsByInstId({ variables: { instId: instId, seceIdList: [seceId], fndId: null }, errorPolicy: 'all' });
        }
    }, [seceObject]);

    useEffect(() => {
        if (findingsByDhIdData && findingsByDhIdData.findingsByDhId) {
            let formatedPrevFndList = formatPreviousFindings(findingsByDhIdData.findingsByDhId, 20);
            setPreviousDhFindingList(formatedPrevFndList);
        }
    }, [findingsByDhIdData]);

    useEffect(() => {
        if (findingsByInstIdData && findingsByInstIdData.findingsByInstId) {
            let formatedPrevInstFndList = formatPreviousFindings(findingsByInstIdData.findingsByInstId, 20);
            setPreviousInstallFindingList(formatedPrevInstFndList);
        }
    }, [findingsByInstIdData]);

    useEffect(() => {
        fetchMultiSelectData(selectedSeceId)
    }, [selectedSeceId]);

    // useEffect(() => {
    //     if (dutyHoldersData && dutyHoldersData.dutyHolders) {
    //         //let list = dutyHoldersData.dutyHolders;
    //         //let newDutyHolderList = list.filter(dh => dh.dh_status === "approved" && dh.dh_archived !== true);
    //         //setDutyHolderList(newDutyHolderList)
    //     }
    // }, [dutyHoldersData]);

    // useEffect(() => {
    //     if (assetGroupsData && assetGroupsData.assetGroupsByDutyHolderId) {
    //         let list = assetGroupsData.assetGroupsByDutyHolderId;
    //         let newAssetGroupList = list.filter(ag => ag.ag_status === "approved" && ag.ag_archived !== true);
    //         setAssetGroupList(newAssetGroupList);
    //     }
    // }, [assetGroupsData]);

    // useEffect(() => {
    //     if (barriersByDhIdAgIdInstIdData && barriersByDhIdAgIdInstIdData.barriersByDhIdAgIdInstId) {
    //         //let list = barriersByDhIdAgIdInstIdData.barriersByDhIdAgIdInstId;
    //         //let newBarrierList = list.filter(bar => bar.bar_archived !== true);
    //         //setBarrierList(newBarrierList);
    //     }
    // }, [barriersByDhIdAgIdInstIdData]);

    // useEffect(() => {
    //     if (installsByDhIdAndAgIdData && installsByDhIdAndAgIdData.installsByDhIdAndAgId) {
    //         //let list = installsByDhIdAndAgIdData.installsByDhIdAndAgId;
    //         //let newInstallList = list.filter(install => install.installation_archived !== true);
    //         //setInstallationList(newInstallList);
    //     }
    // }, [installsByDhIdAndAgIdData]);

    //useEffect(() => {
    //    if (secesByDhAgInstllIdData && secesByDhAgInstllIdData.secesByDhAgInstllId) {
    //        let list = secesByDhAgInstllIdData.secesByDhAgInstllId;
    //        let newSeceList = list.filter(sece => sece.sece_archived !== true);
    //        setSeceList(newSeceList);
    //    }
    //}, [secesByDhAgInstllIdData]);

    // useEffect(() => {
    //     if (secesByBarData && secesByBarData.secesByBarrierId) {
    //         //let list = secesByBarData.secesByBarrierId;
    //         //let newSeceList = list.filter(sece => sece.sece_archived !== true);
    //         //setSeceList(newSeceList);
    //     }
    // }, [secesByBarData]);

    // useEffect(() => {
    //     if (vasByDhIdAndSeceIdsData && vasByDhIdAndSeceIdsData.vasByDhIdAndSeceIds) {
    //         //let list = vasByDhIdAndSeceIdsData.vasByDhIdAndSeceIds;
    //         //let newVaList = list.filter(va => va.va_archived !== true);
    //         //setVaList(newVaList);
    //     }
    // }, [vasByDhIdAndSeceIdsData]);

    useEffect(() => {
        if (createFindingData && createFindingData.createFinding) {
            const { success, message } = createFindingData.createFinding;
            if (!success) {
                setShouldSpin(false);
                alert(message);
            }
            if (success) {

                if (rptId !== "") {
                    history.push(`/reporting/${rptId}/findings`, { reportId: rptId });
                }
                if (rptId === "") {
                    history.push('/reporting/findings');
                }

                if (editRptId !== "") {
                    history.push(`/reporting/${formMode}/${editRptId}`, {
                        formMode: formMode,
                        verId: vsId,
                        actId: actId,
                        status: status,
                        origin: origin
                    });
                }
            }
        }
    }, [createFindingData]);

    useEffect(() => {
        if (updateFindingData && updateFindingData.updateFinding) {
            const { success, message } = updateFindingData.updateFinding;
            if (!success) {
                setShouldSpin(false);
                alert(message);
            }
            if (success) {
                if (rptId !== "") {
                    history.push(`/reporting/${rptId}/findings`, { reportId: rptId });
                }
                if (rptId === "") {
                    history.push('/reporting/findings');
                }
            }
        }
    }, [updateFindingData]);

    const toggleModal = () => {
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    //word count for synopsis
    const handleWordCount = (event, synopsisMaxChar) => {
        const charCount = event.target.value.length;
        const maxChar = synopsisMaxChar;
        const charLength = maxChar - charCount;
        setSynopsisCharsLeft(charLength);
    }

    const toggleLinkedInstallFndModal = () => {
        const modal = document.querySelector('.linkInstallFndmodal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    const toggleLinkedDhFndModal = () => {
        const modal = document.querySelector('.linkDhFndmodal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    const toggleFindingResponseModal = () => {
        const modal = document.querySelector('.fndResponsemodal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    const toggleAddActivitiesModal = () => {
        const modal = document.querySelector('.addactmodal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    const toggleAddActivitiesFilterModal = () => {
        const modal = document.querySelector('.addactfiltermodal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    const clearAddActivitiesFilter = () => {
        setAddVaFilterFormInitValue(addVaFilterFormDefaultValues);
        setSelectedSeceId("");
        setPsList([]);
        setHasFilterParam(false);

        if (rptId !== "" || vsId !== "") {
            verificationActivitySearchAlt2({ variables: { param: { report_id: rptId, vs_id: vsId } }, errorPolicy: 'all' });
        };

        toggleAddActivitiesFilterModal();
    }

    const fetchMultiSelectData = (seceId) => {  
        if (seceId && seceId !== "") {
            let seceIdList = [seceId];
            let param = { sece_ids: seceIdList }
            performanceStandardSearch({ variables: { param: param }, errorPolicy: 'all' });
        }
    }

    const handleAddActivity = (vaid) => {
        let value = vaid;
        if (value && value !== "") {
            let selectedActList = selectedAddActivityList;
            let isSelected = selectedActList.some(act => act._id === value);
            if (!isSelected) {
                let selectedAct = addActivityList.find(act => act._id === value);
                setSelectedAddActivityList([...selectedActList, selectedAct]);

                findingType !== MULTI_VA_FINDING_TYPE && setFindingType(MULTI_VA_FINDING_TYPE);
            } else {

                let fileteredSelections = selectedActList.filter(act => act._id !== value);
                fileteredSelections.length === 0 && setFindingType("");
                setSelectedAddActivityList([...fileteredSelections])
            }
        }
    }

    const handleRemoveActivity = (act) => {
        let actId = act._id;
        let activities = selectedAddActivityList.filter(acty => acty._id !== actId);
        setSelectedAddActivityList([...activities])
    }

    const handleSeceChange = (event) => {
        let value = event.target.value;
        setSelectedSeceId(value);
        setPsList([]);
    };

    const handleAddLinkedInstallFnd = (fndId) => {
        if (fndId && fndId !== "") {
            let linkedFndList = linkedInstallFindingList;
            let isSelected = linkedFndList.some(fnd => fnd.id === fndId);
            if (!isSelected) {
                let selectedFnd = installFindingList.find(fnd => fnd.id === fndId);
                setLinkedInstallFindingList([...linkedFndList, selectedFnd])
            } else {
                let fileteredSelections = linkedFndList.filter(fnd => fnd.id !== fndId);
                setLinkedInstallFindingList([...fileteredSelections])
            }
        }
    }

    const handleRemoveLinkedInstallFnd = (fnd) => {
        let fndId = fnd.id;
        let fnds = linkedInstallFindingList.filter(fnd => fnd.id !== fndId);
        setLinkedInstallFindingList([...fnds])
    }

    const handleAddLinkedDhFnd = (fndId) => {
        if (fndId && fndId !== "") {
            let linkedFndList = linkedDhFindingList;
            let isSelected = linkedFndList.some(fnd => fnd.id === fndId);
            if (!isSelected) {
                let selectedFnd = dhFindingList.find(fnd => fnd.id === fndId);
                setLinkedDhFindingList([...linkedFndList, selectedFnd])
            } else {
                let fileteredSelections = linkedFndList.filter(fnd => fnd.id !== fndId);
                setLinkedDhFindingList([...fileteredSelections])
            }
        }
    }

    const handleRemoveLinkedDhFnd = (fnd) => {
        let fndId = fnd.id;
        let fnds = linkedDhFindingList.filter(fndg => fndg.id !== fndId);
        setLinkedDhFindingList([...fnds])
    }

    const handleRemoveFindingResponse = (id) => {
        if (id && id !== "") {
            let fnds = findingResponseList.filter(fndResp => fndResp.id !== id);
            setFindingResponseList([...fnds]);
        }
    }

    const handleEditFindingResponse = (id) => {
        if (id && id !== "") {
            let fnd = findingResponseList.find(fndResp => fndResp.id === id);
            setFndResponseFormInitValues(fnd);
            setFndResponseFormAction("edit");
            toggleFindingResponseModal();
        }
    }

    // const handleVaChange = (event, vaList) => {
    //     let value = event.target.value;
    //     if (value && value !== "" && vaList && vaList.length) {
    //         let selectedVaObj = vaList.find(va => va._id === value)
    //         const { ps_id = {} } = selectedVaObj ? selectedVaObj : {};
    //         setVaPerfStand(ps_id);
    //         setVerActivityObject(selectedVaObj);
    //     }
    // }

    const handleSave = (values, { setSubmitting, finding_action }) => {
        submitFindingLogic(values, setSubmitting, finding_action)
    };

    const submitFindingLogic = (values, setSubmitting, finding_action) => {
        //setShouldSpin(true);

        const { _id: userId = "" } = authContext.user;

        let insatll_fnd_ids = createIdList(linkedInstallFindingList);
        let dh_fnd_ids = createIdList(linkedDhFindingList);

        let finding_level_summary = createFindingLevelSummaryFromInfoMatrix(values.finding_level);
        let finding_infomatrix_level = values.finding_level;

        if (findingType === DH_FINDING_TYPE) {
            finding_level_summary = values.finding_level;
        }

        let findingCloseDate = createClosedateFrom(findingResponseList);
        let findingResponses = JSON.stringify(findingResponseList);

        let fndCurentStatus = findingCurrentStatus.id ? { finding_current_status: findingCurrentStatus.id } : {}

        //let selectedVaIdToSave = selectedAddActivityList.length === 1 ? {va_id: selectedAddActivityList[0]._id} : {};
        let selectedVaIdListToSave = selectedAddActivityList.length > 0 ? selectedAddActivityList.map(a => a._id) : [];
       
        if (formMode === "add"
            && userId !== ""
            && findingType !== INST_FINDING_TYPE
            && findingType !== DH_FINDING_TYPE
        ) {
            let finding = {
                //finding_current_status: values.finding_current_status,
                ...fndCurentStatus,
                dh_id: values.finding_dh,
                ag_id: values.finding_ag,
                installation_id: values.finding_installation,
                barrier_id: values.finding_barrier,
                sece_ids: formatBackToIdList(values.finding_seces),
                ...(values.va_id && values.va_id !== "" && {va_id: values.va_id}),
                ...(values.finding_va && values.finding_va !== "" ? { va_ids: [values.finding_va] }: []),
                //va_id: verActivityObject._id ? verActivityObject._id : "",
                //vs_id: verScheduleObject._id ? verScheduleObject._id : "",
                //report_id: reportObject._id ? reportObject._id : "",

                //finding_nature: values.finding_nature,
                ...(values.finding_nature && values.finding_nature !== "" && { finding_nature_ids: [values.finding_nature] }),
                //finding_level: values.finding_level,

                finding_level_summary: finding_level_summary,
                finding_infomatrix_level: finding_infomatrix_level,
                ...(values.finding_integrity && values.finding_integrity !== "" && {finding_integrity: values.finding_integrity}),
                ...(values.finding_legislative && values.finding_legislative !== "" && {finding_legislative: values.finding_legislative}),
                ...(values.finding_rogi && values.finding_rogi !== "" && {finding_rogi: values.finding_rogi}),
                ...(values.finding_paperwork_process && values.finding_paperwork_process !== "" && {finding_pw_process: values.finding_paperwork_process}),

                finding_equip_number: values.finding_equipment_no,
                finding_equip_type: values.finding_equipment_type,

                finding_synopsis: values.finding_synopsis,
                finding_summary: values.finding_summary,
                finding_action: values.finding_action,

                ...(values.finding_work_order_raised && values.finding_work_order_raised !== "" && {finding_work_order_raised: values.finding_work_order_raised}),
                finding_work_order_raised_desc: values.finding_work_order_raised_desc,
                ...(values.finding_contingency_actions && values.finding_contingency_actions !== "" && {finding_contingency_actions: values.finding_contingency_actions}),

                ...(values.finding_action_party && values.finding_action_party !== "" && {finding_action_party: values.finding_action_party}),
                finding_auth: userId,
                finding_ref: values.finding_ref ? values.finding_ref : "",

                finding_date: values.finding_date ? values.finding_date : "",
                finding_response_date: values.finding_response_date,
                finding_completion_date: values.finding_completion_date,

                finding_closure_date: findingCloseDate,

                ...(values.finding_dh_responsible && values.finding_dh_responsible !== "" && {finding_dh_responsible: values.finding_dh_responsible}),
                ...(values.finding_abs_responsible && values.finding_abs_responsible !== "" && {finding_abs_responsible: values.finding_abs_responsible}),

                linked_install_fnds: insatll_fnd_ids,
                linked_dh_fnds: dh_fnd_ids,

                ...(values.finding_doc_update_required && values.finding_doc_update_required !== "" && {finding_doc_update_required: values.finding_doc_update_required}),
                //finding_response_notes: values.finding_response_notes,
                finding_ps_reference: values.finding_ps_reference,
                finding_ps_reference_attachments: values.finding_ps_reference_attachments,
                finding_attachments: values.finding_attachments,
                //finding_response_attachments: findingResponseAttachments,

                finding_responses: findingResponses,
                finding_action_type: finding_action ? finding_action : findingAction,
                finding_origin: FINDING_ORIGIN_STANDALONE,

                finding_added_date: new Date(),
                finding_added_id: userId,
                finding_modifier_id: userId,
                finding_archived: false,
                finding_type: findingType
            };

            createFinding({ variables: { fnd: finding } });
        }

        if (formMode === "add"
            && userId !== ""
            && findingType === INST_FINDING_TYPE) {

            let finding = {
                //finding_current_status: values.finding_current_status,
                ...fndCurentStatus,

                ...(dutyHolderObject && dutyHolderObject._id && dutyHolderObject._id !== "" && { dh_id: dutyHolderObject._id }),
                ...(assetGroupObject && assetGroupObject._id && assetGroupObject._id !== "" && { ag_id: assetGroupObject._id }),
                ...(values.finding_installation && values.finding_installation !== "" && { installation_id: values.finding_installation }),

                //barrier_id: barrierObject._id ? barrierObject._id : "",

                //sece_id: seceObject._id ? seceObject._id : "",
                //sece_ids: rptSeceIdList,

                va_id: null,
                va_ids: [],

                ...(findingVsId !== "" && { vs_id: findingVsId }),

                // report_id: rptIds[0],
                // report_ids: rptIds,

                //finding_nature: values.finding_nature,
                ...(values.finding_nature && values.finding_nature !== "" && { finding_nature_ids: [values.finding_nature] }),
                //finding_level: values.finding_level,
                finding_level_summary: finding_level_summary,
                finding_infomatrix_level: finding_infomatrix_level,

                ...(values.finding_integrity && values.finding_integrity !== "" && { finding_integrity: values.finding_integrity }),
                ...(values.finding_legislative && values.finding_legislative !== "" && { finding_legislative: values.finding_legislative }),
                ...(values.finding_rogi && values.finding_rogi !== "" && { finding_rogi: values.finding_rogi }),
                ...(values.finding_paperwork_process && values.finding_paperwork_process !== "" && { finding_pw_process: values.finding_paperwork_process }),

                finding_equip_number: values.finding_equipment_no,
                finding_equip_type: values.finding_equipment_type,

                finding_synopsis: values.finding_synopsis,
                finding_summary: values.finding_summary,
                finding_action: values.finding_action,

                ...(values.finding_work_order_raised && values.finding_work_order_raised !== "" && { finding_work_order_raised: values.finding_work_order_raised }),
                finding_work_order_raised_desc: values.finding_work_order_raised_desc,
                ...(values.finding_contingency_actions && values.finding_contingency_actions !== "" && { finding_contingency_actions: values.finding_contingency_actions }),

                ...(values.finding_action_party && values.finding_action_party !== "" && {finding_action_party: values.finding_action_party}),
                finding_auth: userId,
                finding_ref: values.finding_ref ? values.finding_ref : "",

                finding_date: values.finding_date ? values.finding_date : "",
                finding_response_date: values.finding_response_date,
                finding_completion_date: values.finding_completion_date,

                finding_closure_date: findingCloseDate,

                ...(values.finding_dh_responsible && values.finding_dh_responsible !== "" && { finding_dh_responsible: values.finding_dh_responsible }),
                ...(values.finding_abs_responsible && values.finding_abs_responsible !== "" && { finding_abs_responsible: values.finding_abs_responsible }),

                linked_install_fnds: insatll_fnd_ids,
                linked_dh_fnds: dh_fnd_ids,

                ...(values.finding_doc_update_required && values.finding_doc_update_required !== "" && {finding_doc_update_required: values.finding_doc_update_required}),
                
                //finding_response_notes: values.finding_response_notes,

                finding_ps_reference: values.finding_ps_reference,
                finding_ps_reference_attachments: values.finding_ps_reference_attachments,
                finding_attachments: values.finding_attachments,
                //finding_response_attachments: findingResponseAttachments,

                finding_responses: findingResponses,

                finding_action_type: finding_action ? finding_action : findingAction,
                finding_origin: FINDING_ORIGIN_ACTIVITY_REGISTER,
                finding_type: findingType,

                finding_added_date: new Date(),
                finding_added_id: userId,
                finding_modifier_id: userId,
                finding_archived: false
            };

            createFinding({ variables: { fnd: finding } });
        }

        if (formMode === "add"
            && userId !== ""
            && findingType === DH_FINDING_TYPE) {

            let finding = {
                //finding_current_status: values.finding_current_status,
                ...fndCurentStatus,

                ...(dutyHolderObject._id && dutyHolderObject._id !== "" && { dh_id: dutyHolderObject._id }),

                //ag_id: assetGroupObject._id ? assetGroupObject._id : "",
                installation_id: null,
                installation_ids: [],
                //barrier_id: barrierObject._id ? barrierObject._id : "",

                //sece_id: seceObject._id ? seceObject._id : "",
                //sece_ids: rptSeceIdList,

                va_id: null,
                va_ids: [],

                vs_id: findingVsId,

                //report_id: rptIds[0],
                //report_ids: rptIds,

                //finding_nature: values.finding_nature,
                ...(values.finding_nature && values.finding_nature !== "" && { finding_nature_ids: [values.finding_nature] }),
                //finding_level: values.finding_level,
                finding_level_summary: values.finding_level,
                finding_infomatrix_level: values.finding_level,

                ...(values.finding_integrity && values.finding_integrity !== "" && { finding_integrity: values.finding_integrity }),
                ...(values.finding_legislative && values.finding_legislative !== "" && { finding_legislative: values.finding_legislative }),
                ...(values.finding_rogi && values.finding_rogi !== "" && { finding_rogi: values.finding_rogi }),
                ...(values.finding_paperwork_process && values.finding_paperwork_process !== "" && { finding_pw_process: values.finding_paperwork_process }),

                finding_equip_number: values.finding_equipment_no,
                finding_equip_type: values.finding_equipment_type,

                finding_synopsis: values.finding_synopsis,
                finding_summary: values.finding_summary,
                finding_action: values.finding_action,

                ...(values.finding_work_order_raised && values.finding_work_order_raised !== "" && { finding_work_order_raised: values.finding_work_order_raised }),
                finding_work_order_raised_desc: values.finding_work_order_raised_desc,
                ...(values.finding_contingency_actions && values.finding_contingency_actions !== "" && { finding_contingency_actions: values.finding_contingency_actions }),

                ...(values.finding_action_party && values.finding_action_party !== "" && {finding_action_party: values.finding_action_party}),
                finding_auth: userId,
                finding_ref: values.finding_ref ? values.finding_ref : "",

                finding_date: values.finding_date ? values.finding_date : "",
                finding_response_date: values.finding_response_date,
                finding_completion_date: values.finding_completion_date,

                finding_closure_date: findingCloseDate,

                ...(values.finding_dh_responsible && values.finding_dh_responsible !== "" && {finding_dh_responsible: values.finding_dh_responsible}),
                ...(values.finding_abs_responsible && values.finding_abs_responsible !== "" && {finding_abs_responsible: values.finding_abs_responsible}),

                linked_install_fnds: insatll_fnd_ids,
                linked_dh_fnds: dh_fnd_ids,

                ...(values.finding_doc_update_required && values.finding_doc_update_required !== "" && {finding_doc_update_required: values.finding_doc_update_required}),

                //finding_response_notes: values.finding_response_notes,

                finding_ps_reference: values.finding_ps_reference,
                finding_ps_reference_attachments: values.finding_ps_reference_attachments,
                finding_attachments: values.finding_attachments,
                //finding_response_attachments: findingResponseAttachments,

                finding_responses: findingResponses,

                finding_action_type: finding_action ? finding_action : findingAction,
                finding_origin: FINDING_ORIGIN_ACTIVITY_REGISTER,
                finding_type: findingType,

                finding_added_date: new Date(),
                finding_added_id: userId,
                finding_modifier_id: userId,
                finding_archived: false
            };

            createFinding({ variables: { fnd: finding } });
        }

        if (((formMode === "verify") || (formMode === "verify-edit"))
            && userId !== ""
            && rptId !== "" 
            && findingType === SINGLE_VA_FINDING_TYPE) {
           
            let finding = {
                //finding_current_status: values.finding_current_status,
                ...fndCurentStatus,

                ...(dutyHolderObject._id && dutyHolderObject._id !== "" && { dh_id: dutyHolderObject._id }),
                ...(assetGroupObject && assetGroupObject._id && assetGroupObject._id !== "" && { ag_id: assetGroupObject._id }),
                ...(installationObject._id && installationObject._id !== "" && { installation_id: installationObject._id }),
                ...(barrierObject._id && barrierObject._id !== "" && { barrier_id: barrierObject._id }),
                //sece_id: seceObject._id ? seceObject._id : "",
                sece_ids: seceObject._id ? [seceObject._id] : [],
                ...(verActivityObject._id && verActivityObject._id !== "" && { va_id: verActivityObject._id }),

                //va_ids: verActivityObject._id ? [verActivityObject._id] : "",
                va_ids: selectedVaIdListToSave,

                ...(verScheduleObject._id && verScheduleObject._id !== "" && { vs_id: verScheduleObject._id }),
                ...(reportObject._id && reportObject._id !== "" && { report_id: reportObject._id }),

                //finding_nature: values.finding_nature,
                ...(values.finding_nature && values.finding_nature !== "" && { finding_nature_ids: [values.finding_nature] }),
                //finding_level: values.finding_level,
                finding_level_summary: finding_level_summary,
                finding_infomatrix_level: finding_infomatrix_level,

                ...(values.finding_integrity && values.finding_integrity !== "" && { finding_integrity: values.finding_integrity }),
                ...(values.finding_legislative && values.finding_legislative !== "" && { finding_legislative: values.finding_legislative }),
                ...(values.finding_rogi && values.finding_rogi !== "" && { finding_rogi: values.finding_rogi }),
                ...(values.finding_paperwork_process && values.finding_paperwork_process !== "" && { finding_pw_process: values.finding_paperwork_process }),

                finding_equip_number: values.finding_equipment_no,
                finding_equip_type: values.finding_equipment_type,

                finding_synopsis: values.finding_synopsis,
                finding_summary: values.finding_summary,
                finding_action: values.finding_action,

                ...(values.finding_work_order_raised && values.finding_work_order_raised !== "" && {finding_work_order_raised: values.finding_work_order_raised}),
                finding_work_order_raised_desc: values.finding_work_order_raised_desc,
                ...(values.finding_contingency_actions && values.finding_contingency_actions !== "" && {finding_contingency_actions: values.finding_contingency_actions}),            

                ...(values.finding_action_party && values.finding_action_party !== "" && {finding_action_party: values.finding_action_party}),
                finding_auth: userId,
                finding_ref: values.finding_ref ? values.finding_ref : "",

                finding_date: values.finding_date ? values.finding_date : "",
                finding_response_date: values.finding_response_date,
                finding_completion_date: values.finding_completion_date,

                finding_closure_date: findingCloseDate,

                ...(values.finding_dh_responsible && values.finding_dh_responsible !== "" && {finding_dh_responsible: values.finding_dh_responsible}),
                ...(values.finding_abs_responsible && values.finding_abs_responsible !== "" && {finding_abs_responsible: values.finding_abs_responsible}),
                            
                linked_install_fnds: insatll_fnd_ids,
                linked_dh_fnds: dh_fnd_ids,

                ...(values.finding_doc_update_required && values.finding_doc_update_required !== "" && {finding_doc_update_required: values.finding_doc_update_required}),
                
                //finding_response_notes: values.finding_response_notes,

                finding_ps_reference: values.finding_ps_reference,
                finding_ps_reference_attachments: values.finding_ps_reference_attachments,
                finding_attachments: values.finding_attachments,
                //finding_response_attachments: findingResponseAttachments,

                finding_responses: findingResponses,

                finding_action_type: finding_action ? finding_action : findingAction,
                finding_origin: FINDING_ORIGIN_ACTIVITY_REGISTER,

                finding_added_date: new Date(),
                finding_added_id: userId,
                finding_modifier_id: userId,
                finding_archived: false,
                finding_type: findingType
            };

            createFinding({ variables: { fnd: finding } });
        }

        if (((formMode === "verify") || (formMode === "verify-edit"))
            && userId !== ""
            && findingType === MULTI_VA_FINDING_TYPE) {

            let reportId = rptIds[0] ? rptIds[0] : rptId;
            let finding = {
                //finding_current_status: values.finding_current_status,
                ...fndCurentStatus,

                ...(dutyHolderObject._id && dutyHolderObject._id !== "" ? { dh_id: dutyHolderObject._id } : {}),
                ...(assetGroupObject && assetGroupObject._id && assetGroupObject._id !== "" ? { ag_id: assetGroupObject._id } : {}),
                ...(installationObject._id && installationObject._id !== "" ? { installation_id: installationObject._id } : {}),
                ...(barrierObject._id && barrierObject._id !== "" ? { barrier_id: barrierObject._id } : {}),

                //sece_id: seceObject._id ? seceObject._id : "",
                sece_ids: rptSeceIdList,

                //va_id: verActivityObject._id ? verActivityObject._id : "",
                //va_ids: vaIds,
                va_ids: selectedVaIdListToSave,

                vs_id: vsId ? vsId: fndVsId,

                ...(reportId && reportId !== "" ? { report_id: reportId } : {}),
                report_ids: rptIds,

                //finding_nature: values.finding_nature,
                ...(values.finding_nature && values.finding_nature !== "" ? { finding_nature_ids: [values.finding_nature] } : {}),
                //finding_level: values.finding_level,
                finding_level_summary: finding_level_summary,
                finding_infomatrix_level: finding_infomatrix_level,

                ...(values.finding_integrity && values.finding_integrity !== "" && { finding_integrity: values.finding_integrity }),
                ...(values.finding_legislative && values.finding_legislative !== "" && { finding_legislative: values.finding_legislative }),
                ...(values.finding_rogi && values.finding_rogi !== "" && { finding_rogi: values.finding_rogi }),
                ...(values.finding_paperwork_process && values.finding_paperwork_process !== "" && { finding_pw_process: values.finding_paperwork_process }),

                finding_equip_number: values.finding_equipment_no,
                finding_equip_type: values.finding_equipment_type,

                finding_synopsis: values.finding_synopsis,
                finding_summary: values.finding_summary,
                finding_action: values.finding_action,

                ...(values.finding_work_order_raised && values.finding_work_order_raised !== "" && {finding_work_order_raised: values.finding_work_order_raised}),
                finding_work_order_raised_desc: values.finding_work_order_raised_desc,
                ...(values.finding_contingency_actions && values.finding_contingency_actions !== "" && {finding_contingency_actions: values.finding_contingency_actions}),            

                ...(values.finding_action_party && values.finding_action_party !== "" && {finding_action_party: values.finding_action_party}),
                finding_auth: userId,
                finding_ref: values.finding_ref ? values.finding_ref : "",

                finding_date: values.finding_date ? values.finding_date : "",
                finding_response_date: values.finding_response_date,
                finding_completion_date: values.finding_completion_date,

                finding_closure_date: findingCloseDate,

                ...(values.finding_dh_responsible && values.finding_dh_responsible !== "" && {finding_dh_responsible: values.finding_dh_responsible}),
                ...(values.finding_abs_responsible && values.finding_abs_responsible !== "" && {finding_abs_responsible: values.finding_abs_responsible}),

                linked_install_fnds: insatll_fnd_ids,
                linked_dh_fnds: dh_fnd_ids,

                ...(values.finding_doc_update_required && values.finding_doc_update_required !== "" && {finding_doc_update_required: values.finding_doc_update_required}),

                //finding_response_notes: values.finding_response_notes,

                finding_ps_reference: values.finding_ps_reference,
                finding_ps_reference_attachments: values.finding_ps_reference_attachments,
                finding_attachments: values.finding_attachments,
                //finding_response_attachments: findingResponseAttachments,

                finding_responses: findingResponses,

                finding_action_type: finding_action ? finding_action : findingAction,
                finding_origin: FINDING_ORIGIN_ACTIVITY_REGISTER,
                finding_type: findingType,

                finding_added_date: new Date(),
                finding_added_id: userId,
                finding_modifier_id: userId,
                finding_archived: false
            };

            createFinding({ variables: { fnd: finding } });
        }

        if (((formMode === "verify") || (formMode === "verify-edit"))
            && userId !== ""
            && vsId !== ""
            && actId !== "") {

            let finding = {
                //finding_current_status: values.finding_current_status,
                ...fndCurentStatus,

                ...(dutyHolderObject._id && dutyHolderObject._id !== "" && { dh_id: dutyHolderObject._id }),
                ...(assetGroupObject._id && assetGroupObject._id !== "" && { ag_id: assetGroupObject._id }),
                ...(installationObject._id && installationObject._id !== "" && { installation_id: installationObject._id }),
                ...(barrierObject._id && barrierObject._id !== "" && { barrier_id: barrierObject._id }),
                //sece_id: seceObject._id ? seceObject._id : "",
                sece_ids: seceObject._id ? [seceObject._id] : [],
                va_id: actId,
                //...(actId && actId !== "" && { va_ids: [actId] }),
                va_ids: selectedVaIdListToSave,
                vs_id: vsId,
                ...(reportObject._id && reportObject._id !== "" && { report_id: reportObject._id }),
                
                ...(editRptId !== "" && {report_id: editRptId}),

                //report_ids: rptIds,

                //finding_nature: values.finding_nature,
                ...(values.finding_nature && values.finding_nature !== "" && { finding_nature_ids: [values.finding_nature] }),
                //finding_level: values.finding_level,
                finding_level_summary: finding_level_summary,
                finding_infomatrix_level: finding_infomatrix_level,

                ...(values.finding_integrity && values.finding_integrity !== "" && { finding_integrity: values.finding_integrity }),
                ...(values.finding_legislative && values.finding_legislative !== "" && { finding_legislative: values.finding_legislative }),
                ...(values.finding_rogi && values.finding_rogi !== "" && { finding_rogi: values.finding_rogi }),
                ...(values.finding_paperwork_process && values.finding_paperwork_process !== "" && { finding_pw_process: values.finding_paperwork_process }),

                finding_equip_number: values.finding_equipment_no,
                finding_equip_type: values.finding_equipment_type,

                finding_synopsis: values.finding_synopsis,
                finding_summary: values.finding_summary,
                finding_action: values.finding_action,

                ...(values.finding_work_order_raised && values.finding_work_order_raised !== "" && {finding_work_order_raised: values.finding_work_order_raised}),
                finding_work_order_raised_desc: values.finding_work_order_raised_desc,
                ...(values.finding_contingency_actions && values.finding_contingency_actions !== "" && {finding_contingency_actions: values.finding_contingency_actions}),
            
                ...(values.finding_action_party && values.finding_action_party !== "" && {finding_action_party: values.finding_action_party}),
                finding_auth: userId,
                //This will eventually be be overridden in the Back End
                finding_ref: `FND${Date.now()}`,

                finding_date: values.finding_date ? values.finding_date : "",
                finding_response_date: values.finding_response_date,
                finding_completion_date: values.finding_completion_date,

                finding_closure_date: findingCloseDate,

                ...(values.finding_dh_responsible && values.finding_dh_responsible !== "" && {finding_dh_responsible: values.finding_dh_responsible}),
                ...(values.finding_abs_responsible && values.finding_abs_responsible !== "" && {finding_abs_responsible: values.finding_abs_responsible}),            

                linked_install_fnds: insatll_fnd_ids,
                linked_dh_fnds: dh_fnd_ids,

                ...(values.finding_doc_update_required && values.finding_doc_update_required !== "" && {finding_doc_update_required: values.finding_doc_update_required}),

                //finding_response_notes: values.finding_response_notes,

                finding_ps_reference: values.finding_ps_reference,
                finding_ps_reference_attachments: values.finding_ps_reference_attachments,
                finding_attachments: values.finding_attachments,
                //finding_response_attachments: findingResponseAttachments,

                finding_responses: findingResponses,

                finding_action_type: finding_action ? finding_action : findingAction,
                finding_origin: FINDING_ORIGIN_REPORT,

                finding_added_date: new Date(),
                finding_added_id: userId,
                finding_modifier_id: userId,
                finding_archived: false,
                finding_type: findingType
            };

            if (editRptId !== "") {
                createFinding({ variables: { fnd: finding } });
            } else {
                // Here we are using local storage to manage temporary findings
                const stringifiedFindings = localStorage.getItem(actId);
                const tempValue = stringifiedFindings && stringifiedFindings !== "" ? stringifiedFindings : "[]";
                let findingList = parseJSONSafely(tempValue);
                findingList.push(finding)
                const payload = JSON.stringify(findingList);
                const key = `va-id-${actId}`;
                localStorage.setItem(key, payload);

                history.push(`/reporting/${formMode}/${editRptId}`, {
                    formMode: formMode,
                    verId: vsId,
                    actId: actId,
                    status: status,
                    origin: origin
                });
            }     
        }

        //Editing a finding that is not associated with a Report
        if (formMode === "edit" && userId && userId !== "" && findingId !== "") {
            //console.log(createFindingLevelSummary(findingLevelList, values.finding_level));

            let finding = {
                //finding_current_status: values.finding_current_status,
                ...fndCurentStatus,

                ...(findingReportId === "" && {
                    ...(values.finding_dh !== "" && { dh_id: values.finding_dh }),
                    ...(values.finding_ag !== "" && { ag_id: values.finding_ag }),
                    ...(values.finding_installation !== "" && { installation_id: values.finding_installation }),
                    ...(values.finding_seces.length && { barrier_id: values.finding_barrier }),
                    ...(values.finding_seces.length && { sece_ids: formatBackToIdList(values.finding_seces) }),
                    ...(values.finding_va !== "" && { va_id: values.finding_va }),
                    ...(values.finding_vas.length && { va_ids: values.finding_vas }) //To be investigated
                }),

                ...(findingType === MULTI_VA_FINDING_TYPE && {
                    ...(values.finding_dh && values.finding_dh !== "" ? { dh_id: values.finding_dh } : {}),
                    ...(values.finding_ag && values.finding_ag !== "" ? { ag_id: values.finding_ag } : {}),
                    ...(values.finding_installation && values.finding_installation !== "" ? { installation_id: values.finding_installation } : {}),
                    ...(values.finding_barrier && values.finding_barrier !== "" ? { barrier_id: values.finding_barrier } : {}),
                    sece_ids: rptSeceIdList,
                    va_id: values.finding_va,
                    //va_ids: verActivityList.map(v => v._id)
                    va_ids: selectedVaIdListToSave,
                }),

                ...(findingType === SINGLE_VA_FINDING_TYPE && {
                    va_id: values.finding_va,
                    va_ids: selectedVaIdListToSave,
                }),

                ...(findingType === INST_FINDING_TYPE && {
                    va_id: null,
                    va_ids: selectedVaIdListToSave,
                }),

                ...(findingType === DH_FINDING_TYPE && {
                    va_id: null,
                    va_ids: selectedVaIdListToSave,
                }),

                //finding_nature: values.finding_nature,
                ...(values.finding_nature && values.finding_nature !== "" && { finding_nature_ids: [values.finding_nature] }),
                //finding_level: values.finding_level,
                finding_level_summary: finding_level_summary,
                finding_infomatrix_level: finding_infomatrix_level,

                ...(values.finding_integrity && values.finding_integrity !== "" && { finding_integrity: values.finding_integrity }),
                ...(values.finding_legislative && values.finding_legislative !== "" && { finding_legislative: values.finding_legislative }),
                ...(values.finding_rogi && values.finding_rogi !== "" && { finding_rogi: values.finding_rogi }),
                ...(values.finding_paperwork_process && values.finding_paperwork_process !== "" && { finding_pw_process: values.finding_paperwork_process }),

                finding_equip_number: values.finding_equipment_no,
                finding_equip_type: values.finding_equipment_type,

                finding_synopsis: values.finding_synopsis,
                finding_summary: values.finding_summary,
                finding_action: values.finding_action,

                ...(values.finding_work_order_raised && values.finding_work_order_raised !== "" && {finding_work_order_raised: values.finding_work_order_raised}),
                finding_work_order_raised_desc: values.finding_work_order_raised_desc,
                ...(values.finding_contingency_actions && values.finding_contingency_actions !== "" && {finding_contingency_actions: values.finding_contingency_actions}),            

                ...(values.finding_action_party && values.finding_action_party !== "" && {finding_action_party: values.finding_action_party}),
 
                finding_ref: values.finding_ref ? values.finding_ref : "",

                finding_date: values.finding_date ? values.finding_date : "",
                finding_response_date: values.finding_response_date,
                finding_completion_date: values.finding_completion_date,

                finding_closure_date: findingCloseDate,

                ...(values.finding_dh_responsible && values.finding_dh_responsible !== "" && {finding_dh_responsible: values.finding_dh_responsible}),
                ...(values.finding_abs_responsible && values.finding_abs_responsible !== "" && {finding_abs_responsible: values.finding_abs_responsible}),

                linked_install_fnds: insatll_fnd_ids,
                linked_dh_fnds: dh_fnd_ids,

                ...(values.finding_doc_update_required && values.finding_doc_update_required !== "" && {finding_doc_update_required: values.finding_doc_update_required}),
 
                //finding_response_notes: values.finding_response_notes,

                finding_ps_reference: values.finding_ps_reference,
                finding_ps_reference_attachments: values.finding_ps_reference_attachments,
                finding_attachments: values.finding_attachments,
                //finding_response_attachments: findingResponseAttachments,

                finding_responses: findingResponses,
                finding_action_type: finding_action ? finding_action : findingAction,

                finding_modified_date: new Date(),
                finding_modifier_id: userId,
                finding_type: findingType
            };
            
            updateFinding({ variables: { fndId: findingId, userId: userId, fnd: finding } });
        }

        setSubmitting(false);
    }

    if (((formMode === "verify") || (formMode === "verify-edit") || (formMode === "edit")) && shouldSpin) {
        return (
            <PageSpinner />
        )
    }

    if (sysError ||
        rptError ||
        fndError ||
        secError ||
        imError ||
        rptListError) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    if (createFindingLoading || updateFindingLoading) {
        return (
            <PageSpinner displayText={findingAction === FINDING_ACTION_SAVE ? "saving form" : "submitting form"} />
        )
    }

    if (createFindingError || updateFindingError) {
        return (
            <span>Something went wrong while submitting the form</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">
            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase"> {CreateTitle(findingTitle, formMode)}</h1>
            </div>

            <Formik
                validateOnChange={enableValidation}
                validateOnBlur={enableValidation}
                enableReinitialize={true}
                initialValues={formInitValues}
                validationSchema={validation(formMode, rptId, findingReportId, vsId, findingType, isDutyHolder)}
                onSubmit={(values, { setSubmitting }) => {
                    submitFindingLogic(values, setSubmitting);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    submitForm,
                    setSubmitting,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="my-4 flex">
                            {/* navOrigin !== "" && (navOrigin !== "activity-report" && navOrigin !== "view-finding") ?
                                <button type="button" onClick={() => { window.history.back() }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>
                                : <></>
                            */}
                            {/*<Link to="/reporting/findings" className="button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>*/}
                            {/* <Link
                                to={{
                                    pathname: "/findings/manage-activities",
                                    state: {
                                        formMode: 'verify-edit',
                                    },
                                    search: ""
                                }}
                                className="button-red">
                                <FontAwesomeIcon icon={['fas', 'plus']} className="text-white" /> Add Activity</Link> */}
                            {(userTypeAndRoleObject &&
                              userTypeAndRoleObject.userType &&
                                userTypeAndRoleObject.userType !== "ABS") ||
                                findingType === DH_FINDING_TYPE ||
                                findingType === INST_FINDING_TYPE ? <></>
                                :
                                <button
                                    disabled={disableAddVaButton}
                                    style={{ cursor: (disableAddVaButton === true ? "not-allowed" : "pointer") }}
                                    type="button"
                                    onClick={() => { toggleAddActivitiesModal() }}
                                    className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'plus']} /> Add Activity</button>
                            }
                            
                        </div>

                        <div className="block overflow-x-auto">

                            <table style={{ minWidth: '600px' }} className="table-report w-full mb-4 border">
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white w-1/4">Date Raised</th>
                                        <td className="bg-gray-100 w-1/4">
                                            {values.finding_added_date !== "" ? formatDateToLocaleDateString(values.finding_added_date) : formatDateToLocaleDateString(new Date())}
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white w-1/4">Date Closed</th>
                                        <td className="bg-gray-100 w-1/4">
                                            {values.finding_close_date !== "" ? formatDateToLocaleDateString(values.finding_close_date) : "N/A"}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white w-1/4">Finding Reference</th>
                                        <td className="w-1/4">{values.finding_ref !== "" ? values.finding_ref : "Generated On First Save"}</td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white w-1/4">Finding Current Status</th>
                                        <td className="w-1/4">{findingCurrentStatus.name ? findingCurrentStatus.name : "N/A"}</td>
                                        {
                                            /* <td className="w-1/4">
                                                <div className="mt-5">
                                                    <select
                                                        className="form-control block w-full"
                                                        name="finding_current_status"
                                                        id="finding_current_status"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.finding_current_status}
                                                    >
                                                        <option value={""}>Select Status</option>
                                                        {statusList.map(status =>
                                                            <option key={status._id} value={status._id}>{status.sysvar_title}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched.finding_current_status} message={errors.finding_current_status} />
                                                </div>
                                            </td> */
                                        }
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white"><label htmlFor="finding_dh">Duty Holder</label></th>
                                        <td className="bg-gray-100">
                                            {/* {(formMode === 'add' || formMode === 'edit') && findingReportId === "" ?
                                                <div className="mt-5">
                                                    <select
                                                        className="form-control block w-full"
                                                        name="finding_dh"
                                                        id="finding_dh"
                                                        onChange={(event) => {
                                                            setSelectedDhId(event.target.value);
                                                            handleChange(event);
                                                            setFieldValue("finding_ag", "");
                                                            setFieldValue("finding_installation", "");
                                                            setFieldValue("finding_barrier", "");
                                                            setFieldValue('finding_seces', []);
                                                            setFieldValue("finding_va", "");
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.finding_dh}
                                                    >
                                                        <option value={""}>Select Duty Holder</option>
                                                        {dutyHolderList.map(dh =>
                                                            <option key={dh._id} value={dh._id}>{dh.dh_name}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched.finding_dh} message={errors.finding_dh} />
                                                </div>
                                                : dutyHolderObject.dh_name && dutyHolderObject.dh_name} */}
                                            {
                                                dutyHolderObject.dh_name &&
                                                    dutyHolderObject.dh_name !== "" ?
                                                    dutyHolderObject.dh_name : "N/A"
                                            }
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white"><label htmlFor="finding_ag">Asset Group</label></th>
                                        <td className="bg-gray-100">
                                            {/* {(formMode === 'add' || formMode === 'edit') && findingReportId === "" ?
                                                <div className="mt-5">
                                                    <select
                                                        disabled={values.finding_dh === ""}
                                                        className="form-control block w-full"
                                                        name="finding_ag"
                                                        id="finding_ag"
                                                        onChange={(event) => {
                                                            setSelectedAgId(event.target.value);
                                                            handleChange(event);
                                                            setFieldValue("finding_installation", "");
                                                            setFieldValue("finding_barrier", "");
                                                            setFieldValue('finding_seces', []);
                                                            setFieldValue("finding_va", "");
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.finding_ag}
                                                    >
                                                        <option value={""}>Select Asset Group</option>
                                                        {assetGroupList.map(ag =>
                                                            <option key={ag._id} value={ag._id}>{ag.ag_title}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched.finding_ag} message={errors.finding_ag} />
                                                </div>

                                                : assetGroupObject && assetGroupObject.ag_title && assetGroupObject.ag_title
                                            } */}
                                            {
                                                assetGroupObject &&
                                                    assetGroupObject.ag_title ?
                                                    assetGroupObject.ag_title : "N/A"
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="finding_installation">Installation</label></th>
                                        <td>
                                            {findingType === INST_FINDING_TYPE ?
                                                <div className="mt-5">
                                                    <select
                                                        //disabled={values.finding_dh === "" || values.finding_ag === ""}
                                                        className="form-control block w-full"
                                                        name="finding_installation"
                                                        id="finding_installation"
                                                        onChange={(event) => {
                                                            //setSelectedInstallId(event.target.value);
                                                            handleChange(event);
                                                            //setFieldValue("finding_barrier", "");
                                                            //setFieldValue('finding_seces', []);
                                                            //setFieldValue("finding_va", "");
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.finding_installation}
                                                    >
                                                        <option value={""}>Select Installation</option>
                                                        {installationList.map(inst =>
                                                            <option key={inst._id} value={inst._id}>{inst.installation_title}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched.finding_installation} message={errors.finding_installation} />
                                                </div>
                                                : (findingType === DH_FINDING_TYPE ? "N/A" : (installationObject && installationObject.installation_title ? installationObject.installation_title : "N/A"))
                                            }
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="finding_barrier">Barrier</label></th>
                                        <td>
                                            {/* {(formMode === 'add' || formMode === 'edit') && findingReportId === "" ?
                                                <div className="mt-5">
                                                    <select
                                                        disabled={values.finding_dh === "" || values.finding_ag === ""}
                                                        className="form-control block w-full"
                                                        name="finding_barrier"
                                                        id="finding_barrier"
                                                        onChange={(event) => {
                                                            handleChange(event);
                                                            setSelectedBarrierId(event.target.value);
                                                            setFieldValue('finding_seces', []);
                                                            setFieldValue("finding_va", "");
                                                        }}
                                                        onBlur={handleBlur}
                                                        value={values.finding_barrier}
                                                    >
                                                        <option value={""}>Select Barrier</option>
                                                        {barrierList.map(bar =>
                                                            <option key={bar._id} value={bar._id}>{bar.bar_title}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched.finding_barrier} message={errors.finding_barrier} />
                                                </div>
                                                : barrierObject && barrierObject.bar_title && barrierObject.bar_title
                                            } */}
                                            {
                                                barrierObject &&
                                                    barrierObject.bar_title ?
                                                    barrierObject.bar_title : "N/A"
                                            }
                                        </td>
                                    </tr>
                                    <React.Fragment>
                                        {findingType === MULTI_VA_FINDING_TYPE ?
                                            <React.Fragment>
                                                {selectedAddActivityList.length > 0 ?
                                                    selectedAddActivityList.map((sch, index) =>
                                                        <React.Fragment key={index}>

                                                            <tr style={{ borderTop: "solid 1px gray" }}>
                                                                <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">
                                                                    <label>Activity {index + 1}</label>
                                                                    {/* {originalActivityList.some(sva => sva._id === sch._id) ? <></> :
                                                                        <span className="float-right">
                                                                            <FontAwesomeIcon icon={faTrashAlt} className="text-white cursor-pointer" onClick={() => handleRemoveActivity(sch)} />
                                                                        </span>
                                                                    } */}
                                                                    {
                                                                        selectedAddActivityList.length === 1 ||
                                                                            disableAddVaButton === true ||
                                                                            (userTypeAndRoleObject &&
                                                                                userTypeAndRoleObject.userType &&
                                                                                userTypeAndRoleObject.userType !== "ABS") ? <></> :
                                                                            <span className="float-right">
                                                                                <FontAwesomeIcon icon={faTrashAlt} className="text-white cursor-pointer" onClick={() => handleRemoveActivity(sch)} />
                                                                            </span>
                                                                    }
                                                                </th>
                                                            </tr>

                                                            <tr style={{ borderTop: "solid 1px #404040" }}>
                                                                <th className="bg-blue-800 font-sans font-semibold text-white">SECE Ref.</th>
                                                                <td className="bg-gray-100">{sch.sece_id && sch.sece_id.sece_client_ref && sch.sece_id.sece_client_ref !== "" ? sch.sece_id.sece_client_ref : "N/A"}</td>
                                                                <th className="bg-blue-800 font-sans font-semibold text-white">SECE Name</th>
                                                                <td className="bg-gray-100">
                                                                    {/* <Link to={{ pathname: `/reporting/findings/${formMode}`, state: { formMode: 'add' } }} className="hover:underline">{sch.sece_id && sch.sece_id.sece_title ? sch.sece_id.sece_title : "N/A"}</Link> */}
                                                                    <Link to={{ pathname: `/seces/view/${sch.sece_id && sch.sece_id._id}`}} className="hover:underline">{sch.sece_id && sch.sece_id.sece_title ? sch.sece_id.sece_title : "N/A"}</Link>
                                                                </td>
                                                            </tr>
                                                            <tr style={{ borderBottom: "solid 1px gray" }}>
                                                                <th className="bg-blue-900 font-sans font-semibold text-white">Verification Activity Ref.</th>
                                                                <td>
                                                                    {sch && sch._id && sch.va_client_ref ? (
                                                                        <Link
                                                                            to={{ pathname: `/verification-activities/view/${sch._id}` }}
                                                                            className="hover:underline"
                                                                            target="blank"
                                                                        >
                                                                            {sch.va_client_ref}
                                                                        </Link>
                                                                    ) : (
                                                                        <span>{"N/A"}</span>
                                                                    )}                 
                                                                </td>
                                                                <th className="bg-blue-900 font-sans font-semibold text-white">PS Ref.</th>
                                                                <td>
                                                                    {sch && sch.ps_id && sch.ps_id.ps_ref !== "" ?
                                                                        (<Link to={{ pathname: `/performance-standards/view/${sch.ps_id._id}` }}
                                                                            className="hover:underline">
                                                                            {sch.ps_id.ps_ref}
                                                                        </Link>) :
                                                                        (<span>{"N/A"}</span>)
                                                                    }
                                                                </td>
                                                            </tr>
                                                        </React.Fragment>
                                                    )
                                                    :
                                                    <>
                                                    </>
                                                }
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <tr>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">SECE Ref.</th>
                                                    <td className="bg-gray-100">{seceObject && seceObject.sece_client_ref && seceObject.sece_client_ref !== "" ? seceObject.sece_client_ref : "N/A"}</td>
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">SECE Name</th>
                                                    <td className="bg-gray-100">
                                                        {seceObject && seceObject._id ?
                                                            <Link to={{ pathname: `/seces/view/${seceObject._id}` }} className="hover:underline">{seceObject && seceObject.sece_title ? seceObject.sece_title : "N/A"}</Link>
                                                            :
                                                            <span>{seceObject && seceObject.sece_title ? seceObject.sece_title : "N/A"}</span>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">Verification Activity Ref.</th>
                                                    <td>
                                                        {verActivityObject && verActivityObject._id && verActivityObject.va_client_ref ? (
                                                            <Link
                                                                to={{ pathname: `/verification-activities/view/${verActivityObject._id}` }}
                                                                className="hover:underline"
                                                                target="blank"
                                                            >
                                                                {verActivityObject.va_client_ref}
                                                            </Link>
                                                        ) : (
                                                            <span>{"N/A"}</span>
                                                        )}           
                                                    </td>
                                                    <th className="bg-blue-900 font-sans font-semibold text-white">PS Ref.</th>
                                                    <td>                
                                                        {vaPerfStand && vaPerfStand.ps_ref ?
                                                            (<Link to={{ pathname: `/performance-standards/view/${vaPerfStand._id}` }}
                                                                className="hover:underline">
                                                               {vaPerfStand.ps_ref}
                                                            </Link>) :
                                                            (<span>{"N/A"}</span>)
                                                        }
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="finding_level">Finding Level</label></th>
                                        <td>
                                            {findingType === DH_FINDING_TYPE ?
                                                <div className="mt-5">
                                                    <select
                                                        disabled={isDutyHolder}
                                                        className="form-control block w-full"
                                                        name="finding_level"
                                                        id="finding_level"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.finding_level}
                                                    >
                                                        <option value={""}>Select</option>
                                                        {findingLevelList.map(level =>
                                                            <option key={level._id} value={level.sysvar_title}>{level.sysvar_title}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched.finding_level} message={errors.finding_level} />
                                                </div>
                                                :
                                                <div className="mt-5">
                                                    <select
                                                        disabled={isDutyHolder}
                                                        className="form-control block w-full"
                                                        name="finding_level"
                                                        id="finding_level"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.finding_level}
                                                    >
                                                        <option value={""}>Select</option>
                                                        {infoMatrixLevels.map(level =>
                                                            <option key={level} value={level}>{level}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched.finding_level} message={errors.finding_level} />
                                                </div>
                                            }
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Risk Ranking</th>
                                        <td>{"N/A"}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white">Author</th>
                                        <td className="bg-gray-100">
                                            {authorFullName}
                                        </td>

                                        <th className="bg-blue-800 font-sans font-semibold text-white">Nature</th>
                                        <td className="bg-gray-100">
                                            <div className="mt-5">
                                                {
                                                    findingType === MULTI_VA_FINDING_TYPE?
                                                        formatVaNature(selectedAddActivityList)
                                                        :
                                                        (verActivityObject && verActivityObject.va_type && verActivityObject.va_type)
                                                }
                                                {/* {verActivityObject.va_type && verActivityObject.va_type} */}
                                                {
                                                    (findingType === INST_FINDING_TYPE ||
                                                        findingType === DH_FINDING_TYPE) ?
                                                        <>
                                                            <select
                                                                disabled={isDutyHolder}
                                                                className="form-control block w-full"
                                                                name="finding_nature"
                                                                id="finding_nature"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.finding_nature}
                                                            >
                                                                <option>Select Nature</option>
                                                                {natureList.map(nature =>
                                                                    <option key={nature._id} value={nature._id}>{nature.sysvar_title}</option>
                                                                )}
                                                            </select>
                                                            <FormError touched={touched.finding_nature} message={errors.finding_nature} />
                                                        </>
                                                        :
                                                        <></>
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="finding_equipment_no">Equipment No.</label></th>
                                        <td>
                                            {findingType === INST_FINDING_TYPE ||
                                                findingType === DH_FINDING_TYPE ?
                                                <div>
                                                    {"N/A"}
                                                </div> :
                                                <div className="mt-5">
                                                    <input
                                                        disabled={isDutyHolder}
                                                        type="text"
                                                        className="form-control block w-full"
                                                        name="finding_equipment_no"
                                                        id="finding_equipment_no"
                                                        placeholder="Enter"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.finding_equipment_no}
                                                    />
                                                    <FormError touched={touched.finding_equipment_no} message={errors.finding_equipment_no} />
                                                </div>
                                            }
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="finding_equipment_type">Equipment Type</label></th>
                                        <td>
                                            {findingType === INST_FINDING_TYPE ||
                                                findingType === DH_FINDING_TYPE ?
                                                <div>
                                                    {"N/A"}
                                                </div> :
                                                <div className="mt-5">
                                                    <input
                                                        disabled={isDutyHolder}
                                                        type="text"
                                                        className="form-control block w-full"
                                                        name="finding_equipment_type"
                                                        id="finding_equipment_type"
                                                        placeholder="Enter"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.finding_equipment_type}
                                                    />
                                                    <FormError touched={touched.finding_equipment_type} message={errors.finding_equipment_type} />
                                                </div>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white"><label htmlFor="finding_integrity">Integrity</label></th>
                                        <td className="bg-gray-100">
                                            <div className="mt-5">
                                                <select
                                                    disabled={isDutyHolder}
                                                    className="form-control block w-full"
                                                    name="finding_integrity"
                                                    id="finding_integrity"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.finding_integrity}
                                                >
                                                    <option value={""}>Select</option>
                                                    {answerOptionList.map(anwser =>
                                                        <option key={anwser._id} value={anwser._id}>{anwser.sysvar_title}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.finding_integrity} message={errors.finding_integrity} />
                                            </div>
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white"><label htmlFor="finding_legislative">Legislative</label></th>
                                        <td className="bg-gray-100">
                                            <div className="mt-5">
                                                <select
                                                    disabled={isDutyHolder}
                                                    className="form-control block w-full"
                                                    name="finding_legislative"
                                                    id="finding_legislative"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.finding_legislative}
                                                >
                                                    <option value={""}>Select</option>
                                                    {answerOptionList.map(anwser =>
                                                        <option key={anwser._id} value={anwser._id}>{anwser.sysvar_title}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.finding_legislative} message={errors.finding_legislative} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="finding_paperwork_process">Paperwork Process</label></th>
                                        <td>
                                            <div className="mt-5">
                                                <select
                                                    disabled={isDutyHolder}
                                                    className="form-control block w-full"
                                                    name="finding_paperwork_process"
                                                    id="finding_paperwork_process"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.finding_paperwork_process}
                                                >
                                                    <option value={""}>Select</option>
                                                    {answerOptionList.map(anwser =>
                                                        <option key={anwser._id} value={anwser._id}>{anwser.sysvar_title}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.finding_paperwork_process} message={errors.finding_paperwork_process} />
                                            </div>
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="finding_rogi">ROGI</label></th>
                                        <td>
                                            <div className="mt-5">
                                                <select
                                                    disabled={isDutyHolder}
                                                    className="form-control block w-full"
                                                    name="finding_rogi"
                                                    id="finding_rogi"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.finding_rogi}
                                                >
                                                    <option value={""}>Select</option>
                                                    {answerOptionList.map(anwser =>
                                                        <option key={anwser._id} value={anwser._id}>{anwser.sysvar_title}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.finding_rogi} message={errors.finding_rogi} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white"><label htmlFor="finding_integrity">Days Open</label></th>
                                        <td className="bg-gray-100">
                                            <div className="mt-5">
                                                {values.finding_days_open !== "" ? values.finding_days_open : "N/A"}
                                            </div>
                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white"><label htmlFor="finding_legislative">Days Since Last Response</label></th>
                                        <td className="bg-gray-100">
                                            <div className="mt-5">
                                                {values.finding_days_since_last_response !== "" ? values.finding_days_since_last_response : "N/A"}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Previous Installation Findings
                                            <span className="float-right">
                                                <FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} />
                                            </span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {fndByInstLoading ? <>Loading...</> : <></>}
                                            {fndByInstError ? <>Something went wrong while fetching data</> : <></>}
                                            {previousInstallFindingList.length > 0 ?
                                                <Table
                                                    columns={prevFndColumn}
                                                    data={previousInstallFindingList} />
                                                : (!fndByInstLoading ? "No Data To Display" : "")
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Previous Duty Holder Findings
                                            <span className="float-right">
                                                <FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} />
                                            </span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {fndByDhLoading ? <>Loading...</> : <></>}
                                            {fndByDhError ? <>Something went wrong while fetching data</> : <></>}
                                            {previousDhFindingList.length > 0 ?
                                                <Table
                                                    columns={prevFndColumn}
                                                    data={previousDhFindingList} />
                                                : (!fndByDhLoading ? "No Data To Display" : "")
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6"><label htmlFor="finding_synopsis" >Synopsis</label></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {synopsisCharsLeft} of 150 characters left
                                            <div className="mt-2">
                                                <textarea
                                                    disabled={isDutyHolder}
                                                    name="finding_synopsis"
                                                    id="finding_synopsis"
                                                    onChange={(event) => { handleWordCount(event, synopsisMaxChar); handleChange(event) }}
                                                    onBlur={handleBlur}
                                                    value={values.finding_synopsis}
                                                    className="form-control w-full"
                                                    maxLength="150"
                                                />
                                                <FormError touched={touched.finding_synopsis} message={errors.finding_synopsis} />
                                            </div>

                                        </td>
                                    </tr>
                                                                       
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6"><label htmlFor="finding_ps_reference">Reference</label>
                                            <span className="float-right">
                                                {/*
                                                 <Link to={{ pathname: `/reporting/findings/${formMode}`, state: { formMode:formMode} }}  className="font-normal text-white underline">View Reference(s)</Link>
                                              */}
                                            </span>
                                        </th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="mt-2">
                                                <TextEditor
                                                    disabled={isDutyHolder}
                                                    name="finding_ps_reference"
                                                    id="finding_ps_reference"
                                                    //onChange={handleChange}
                                                    onChange={details => setFieldValue('finding_ps_reference', details)}
                                                    //onBlur={handleBlur}
                                                    onBlur={touched => setFieldTouched('finding_ps_reference', touched)}
                                                    value={values.finding_ps_reference}
                                                    wrapper="w-full"
                                                />
                                                <FormError touched={touched.finding_ps_reference} message={errors.finding_ps_reference} />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6">Reference Attachments
                                            <span className="float-right">
                                                <FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} />
                                            </span>
                                        </th>
                                    </tr>
                                    <tr >
                                        <td colSpan="6">
                                            {authContext.user && authContext.user.user_home_dir &&
                                                <div className="mb-3 mt-0">
                                                    <FileUploadAlt
                                                        disabled={(userTypeAndRoleObject && userTypeAndRoleObject.userType && userTypeAndRoleObject.userType !== "ABS")}
                                                        onAttach={(files) => setFieldValue('finding_ps_reference_attachments', JSON.stringify(files))}
                                                        id='fileManager'
                                                        buttonText='Attach Document'
                                                        apiURL={REACT_APP_FILE_MANAGER_API}
                                                        toolbarText='Attach'
                                                        homeDir={`/${authContext.user.user_home_dir}/`}
                                                        showDescription={true}
                                                        {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.finding_ps_reference_attachments) ? parseJSONSafely(values.finding_ps_reference_attachments) : [] })}
                                                    />
                                                </div>
                                            }
                                        </td>
                                    </tr>
                                    
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6"><label htmlFor="finding_summary">Finding</label></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="mt-2">
                                                <TextEditor
                                                    disabled={isDutyHolder}
                                                    name="finding_summary"
                                                    id="finding_summary"
                                                    //onChange={handleChange}
                                                    onChange={details => setFieldValue('finding_summary', details)}
                                                    //onBlur={handleBlur}
                                                    onBlur={touched => setFieldTouched('finding_summary', touched)}
                                                    value={values.finding_summary}
                                                    wrapper="w-full"
                                                />
                                                <FormError touched={touched.finding_summary} message={errors.finding_summary} />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6"><label htmlFor="finding_action">Action</label></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="mt-2">
                                                <TextEditor
                                                    disabled={isDutyHolder}
                                                    name="finding_action"
                                                    id="finding_action"
                                                    //onChange={handleChange}
                                                    onChange={details => setFieldValue('finding_action', details)}
                                                    //onBlur={handleBlur}
                                                    onBlur={touched => setFieldTouched('finding_action', touched)}
                                                    value={values.finding_action}
                                                    wrapper="w-full"
                                                />
                                                <FormError touched={touched.finding_action} message={errors.finding_action} />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6"><label htmlFor="finding_work_order_raised">Duty Holder Work Order Raised?</label></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <select
                                                    disabled={isDutyHolder}
                                                    className="form-control block w-full"
                                                    name="finding_work_order_raised"
                                                    id="finding_work_order_raised"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.finding_work_order_raised}
                                                >
                                                    <option value={""}>Select</option>
                                                    {answerOptionList.map(anwser =>
                                                        <option key={anwser._id} value={anwser._id}>{anwser.sysvar_title}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.finding_work_order_raised} message={errors.finding_work_order_raised} />
                                            </div>

                                            {values.finding_work_order_raised === SYS_VAR_ANSWER_YES_ID &&
                                                <div>
                                                    <textarea
                                                        disabled={isDutyHolder}
                                                        name="finding_work_order_raised_desc"
                                                        id="finding_work_order_raised_desc"
                                                        aria-label="Work Order Raised Description"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.finding_work_order_raised_desc}
                                                        className="form-control w-full"
                                                        rows="5"
                                                    />
                                                    <FormError touched={touched.finding_work_order_raised_desc} message={errors.finding_work_order_raised_desc} />
                                                </div>
                                            }
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="6"><label htmlFor="finding_contingency_actions">Contingency Actions Addressing Finding</label></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div>
                                                <select
                                                    disabled={isDutyHolder}
                                                    className="form-control block w-full"
                                                    name="finding_contingency_actions"
                                                    id="finding_contingency_actions"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.finding_contingency_actions}
                                                >
                                                    <option value={""}>Select</option>
                                                    {answerOptionList.map(anwser =>
                                                        <option key={anwser._id} value={anwser._id}>{anwser.sysvar_title}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.finding_contingency_actions} message={errors.finding_contingency_actions} />
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white">Attachments <span className="float-right"><FontAwesomeIcon icon={['far', 'question-circle']} className="text-white cursor-pointer" onClick={() => toggleModal()} /></span></th>
                                    </tr>
                                    <tr>
                                        <td>
                                            {authContext.user && authContext.user.user_home_dir &&
                                                <div className="mb-3">
                                                    <FileUploadAlt
                                                        disabled={(userTypeAndRoleObject && userTypeAndRoleObject.userType && userTypeAndRoleObject.userType !== "ABS")}
                                                        onAttach={(files) => setFieldValue('finding_attachments', JSON.stringify(files))}
                                                        id='fileManager'
                                                        buttonText='Attach Document'
                                                        apiURL={REACT_APP_FILE_MANAGER_API}
                                                        toolbarText='Attach'
                                                        homeDir={`/${authContext.user.user_home_dir}/`}
                                                        showDescription={true}
                                                        {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.finding_attachments) ? parseJSONSafely(values.finding_attachments) : [] })}
                                                    />
                                                </div>
                                            }
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="finding_response_date">Response Date</label></th>
                                        <td>

                                            <div className="mt-5">
                                                <DatePicker
                                                    disabled={isDutyHolder}
                                                    id="finding_response_date"
                                                    selected={values.finding_response_date}
                                                    dateFormat="dd MMMM Y"
                                                    name="finding_response_date"
                                                    onChange={date => setFieldValue('finding_response_date', date)}
                                                    onBlur={handleBlur}
                                                    value={values.finding_response_date}
                                                    className="form-control block w-full"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    minDate={new Date()}
                                                    autoComplete="off"
                                                />
                                                <FormError touched={touched.finding_response_date} message={errors.finding_response_date} />
                                            </div>

                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="finding_completion_date" >Completion Date</label></th>
                                        <td>

                                            <div className="mt-5">
                                                <DatePicker
                                                    disabled={isDutyHolder}
                                                    id="finding_completion_date"
                                                    selected={values.finding_completion_date}
                                                    dateFormat="dd MMMM Y"
                                                    name="finding_completion_date"
                                                    onChange={date => setFieldValue('finding_completion_date', date)}
                                                    onBlur={handleBlur}
                                                    value={values.finding_completion_date}
                                                    className="form-control block w-full"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    minDate={new Date()}
                                                    autoComplete="off"
                                                />
                                                <FormError touched={touched.finding_completion_date} message={errors.finding_completion_date} />
                                            </div>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-800 font-sans font-semibold text-white"><label htmlFor="finding_action_party">Action Party</label></th>
                                        <td className="bg-gray-100">

                                            <div className="mt-5">
                                                <select
                                                    disabled={isDutyHolder}
                                                    className="form-control block w-full"
                                                    name="finding_action_party"
                                                    id="finding_action_party"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.finding_action_party}
                                                >
                                                    <option value={""}>Select Action Party</option>
                                                    {actionPartyList.map(party =>
                                                        <option key={party._id} value={party._id}>{party.user_fname + " " + party.user_lname}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.finding_action_party} message={errors.finding_action_party} />
                                            </div>

                                        </td>
                                        <th className="bg-blue-800 font-sans font-semibold text-white"><label htmlFor="finding_dh_responsible">Duty Holder Responsible</label></th>
                                        <td className="bg-gray-100">
                                            <div className="mt-5">
                                                <select
                                                    disabled={isDutyHolder}
                                                    className="form-control block w-full"
                                                    name="finding_dh_responsible"
                                                    id="finding_dh_responsible"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.finding_dh_responsible}
                                                >
                                                    <option value={""}>Select Duty Holder Rep</option>
                                                    {dhRepList.map(dhRep =>
                                                        <option key={dhRep._id} value={dhRep._id}>{dhRep.user_fname + " " + dhRep.user_lname}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.finding_dh_responsible} message={errors.finding_dh_responsible} />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="finding_abs_responsible">ABS Responder</label></th>
                                        <td>
                                            <div className="mt-5">
                                                <select
                                                    disabled={isDutyHolder}
                                                    className="form-control block w-full"
                                                    name="finding_abs_responsible"
                                                    id="finding_abs_responsible"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.finding_abs_responsible}
                                                >
                                                    <option value={""}>Select ABS Rep</option>
                                                    {absRepList.length > 0 && absRepList.map(absRep =>
                                                        <option key={absRep._id} value={absRep._id}>{absRep.user_fname + " " + absRep.user_lname}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.finding_abs_responsible} message={errors.finding_abs_responsible} />
                                            </div>
                                        </td>
                                        <th className="bg-blue-900 font-sans font-semibold text-white"><label htmlFor="finding_doc_update_required">Document Update Required</label></th>
                                        <td>
                                            <div className="mt-5">
                                                <select
                                                    disabled={isDutyHolder}
                                                    className="form-control block w-full"
                                                    name="finding_doc_update_required"
                                                    id="finding_doc_update_required"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.finding_doc_update_required}
                                                >
                                                    <option value={""}>Select</option>
                                                    {answerOptionList.map(anwser =>
                                                        <option key={anwser._id} value={anwser._id}>{anwser.sysvar_title}</option>
                                                    )}
                                                </select>
                                                <FormError touched={touched.finding_doc_update_required} message={errors.finding_doc_update_required} />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="7">Linked Installation Findings
                                            <span className="float-right">
                                                {!isDutyHolder && <FontAwesomeIcon icon={['fas', 'plus']} className="text-white cursor-pointer" onClick={() => toggleLinkedInstallFndModal()} />}
                                            </span>
                                        </th>
                                    </tr>
                                    {linkedInstallFindingList.map((fnd, index) =>
                                        <React.Fragment key={index}>
                                            <tr>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Finding Ref.</th>
                                                <td>{fnd.fnd_ref}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Level</th>
                                                <td>{fnd.fnd_level}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Current Status</th>
                                                <td>{createStatusFrom(statusList, fnd.status_id)}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">
                                                    <span className="float-right">
                                                        <FontAwesomeIcon icon={faTrashAlt} className="text-white cursor-pointer" onClick={() => handleRemoveLinkedInstallFnd(fnd)} />
                                                    </span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th className="bg-blue-900 font-sans font-semibold text-white">Synopsis</th>
                                                <td className="bg-gray-100" colSpan="6">{fnd.fnd_synopsis}</td>
                                            </tr>
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="7">Linked Duty Holder Findings
                                            <span className="float-right">
                                                {!isDutyHolder && <FontAwesomeIcon icon={['fas', 'plus']} className="text-white cursor-pointer" onClick={() => toggleLinkedDhFndModal()} />}
                                            </span>
                                        </th>
                                    </tr>
                                    {linkedDhFindingList.map((fnd, index) =>
                                        <React.Fragment key={index}>
                                            <tr>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Finding Ref.</th>
                                                <td>{fnd.fnd_ref}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Level</th>
                                                <td>{fnd.fnd_level}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Current Status</th>
                                                <td>{createStatusFrom(statusList, fnd.status_id)}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">
                                                    <span className="float-right">
                                                        <FontAwesomeIcon icon={faTrashAlt} className="text-white cursor-pointer" onClick={() => handleRemoveLinkedDhFnd(fnd)} />
                                                    </span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th className="bg-blue-900 font-sans font-semibold text-white">Synopsis</th>
                                                <td className="bg-gray-100" colSpan="6">{fnd.fnd_synopsis}</td>
                                            </tr>
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>

                            <table className="table-report w-full mb-4 border" style={{ minWidth: '600px' }}>
                                <tbody>
                                    <tr>
                                        <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="7">Finding Responses</th>
                                    </tr>
                                    {findingResponseList.map((resp, index) =>
                                        <React.Fragment key={index}>
                                            <tr>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Updated By {resp.response_user_type ? resp.response_user_type : "N/A"}</th>
                                                <td>{resp.response_update_by}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Date</th>
                                                <td>{formatDate(resp.response_update_date)}</td>
                                                <th className="bg-blue-800 font-sans font-semibold text-white">Current Status</th>
                                                <td>{resp.response_status ? resp.response_status : "N/A"}</td>
                                                {/* {values.finding_status !== ACTION_STATUS_APPROVED &&
                                                    <th className="bg-blue-800 font-sans font-semibold text-white">
                                                        <span className="float-right flex">              
                                                            {userTypeAndRoleObject && resp.response_user_type === userTypeAndRoleObject.userType ?
                                                                <>
                                                                    <span className="mr-5">
                                                                        <FontAwesomeIcon icon={faPenAlt} className="text-white cursor-pointer" onClick={() => handleEditFindingResponse(resp.id)} />
                                                                    </span>
                                                                    <span>
                                                                        <FontAwesomeIcon icon={faTrashAlt} className="text-white cursor-pointer" onClick={() => handleRemoveFindingResponse(resp.id)} />
                                                                    </span>
                                                                </>
                                                                :
                                                                <></>
                                                            }
                                                        </span>
                                                    </th>
                                                } */}
                                                <th className="bg-blue-800 font-sans font-semibold text-white">
                                                    <span className="float-right flex">
                                                        {userTypeAndRoleObject && 
                                                         resp.response_user_type === userTypeAndRoleObject.userType && 
                                                         userTypeAndRoleObject.userType ==="ABS"?
                                                            <>
                                                                <span className="mr-5">
                                                                    <FontAwesomeIcon icon={faPenAlt} className="text-white cursor-pointer" onClick={() => handleEditFindingResponse(resp.id)} />
                                                                </span>
                                                                <span>
                                                                    <FontAwesomeIcon icon={faTrashAlt} className="text-white cursor-pointer" onClick={() => handleRemoveFindingResponse(resp.id)} />
                                                                </span>
                                                            </>
                                                            :
                                                            <></>
                                                        }
                                                    </span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="bg-gray-100" colSpan="7">
                                                    <Sanitiser html={resp.response_note} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="bg-blue-800 font-sans font-semibold text-white" colSpan="7">Response Attachments<span className="float-right"></span></th>
                                            </tr>
                                            <tr>
                                                <td colSpan="7">
                                                    <div className="mb-3">
                                                        {reformatResponseAttachment(resp.response_attachments)  && reformatResponseAttachment(resp.response_attachments).length > 0 ?
                                                            <Table columns={fndResponseAttachColumns} data={createAttachList((reformatResponseAttachment(resp.response_attachments)), values.fnd_resp_attmnt_dest_path)} pagination={false} filter={false} /> :
                                                            "No Data to Display"
                                                        }
                                                    </div>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    )}
                                </tbody>
                            </table>
                        </div>

                        {   userTypeAndRoleObject &&
                            userTypeAndRoleObject.userType === "Duty Holder" &&
                            findingCurrentStatus.name &&
                            findingCurrentStatus.name === "Closed" ?
                            <></> :
                            <button type="button" className="mt-3 mb-2 mr-1 button-red capitalize"
                                onClick={() => {
                                    setFndResponseFormAction("add");
                                    toggleFindingResponseModal()
                                }}>
                                Add Response
                            </button>
                        }

                        <button
                            type="button"
                            disabled={isSubmitting}
                            className={`${"mt-3 mb-2 mr-1 button-red capitalize" + (isSubmitting ? " cursor-not-allowed" : "")}`}
                            name="save"
                            value="save"
                            onClick={() => {
                                setEnableValidation(false);
                                setFindingAction(FINDING_ACTION_SAVE);
                                handleSave(values, {setSubmitting, finding_action: FINDING_ACTION_SAVE});
                            }}>Save</button>
                        {/* When adding findings from an individual report page, this should not appear*/}
                        {!(((formMode === "verify") || (formMode === "verify-edit")) && vsId !== "" && actId !== "" && editRptId === "") &&
                            <button
                                type="button"
                                disabled={isSubmitting}
                                className={`${"mt-3 mb-2 mr-1 button-red capitalize" + (isSubmitting ? " cursor-not-allowed" : "")}`}
                                name="save"
                                value="save"
                                onClick={() => {
                                    setEnableValidation(true); 
                                    setFindingAction(FINDING_ACTION_REVIEW);
                                    setTimeout(() => { submitForm() }, 200)
                                }}
                            >Submit</button>
                        }

                        <button type="submit" disabled={isSubmitting} style={{ display: "none" }}>
                            Hidden Submit
                        </button>
                    </form>
                )}
            </Formik>

            {/* HELP TIP */}
            <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
                    <h3 className="mb-2 text-xl text-blue-900 font-bold">Help Tip</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ultricies nunc id vehicula pulvinar. Sed vitae luctus purus. Integer feugiat nisi porta lacus cursus, sed sollicitudin orci mattis. Praesent euismod ornare tortor eget aliquam. Vivamus fermentum convallis erat, at ultrices odio. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.</p>
                </div>
            </div>
            {/* HELP TIP END */}

            {/* Linked Installation Finding Modal */}
            <div className="linkInstallFndmodal modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleLinkedInstallFndModal()}></div>
                <div className="absolute w-full md:w-11/12 lg:w-10/12 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
                    <div className="w-full px-8 pb-8">
                        <div className="pb-3 border-b-2 border-gray-200">
                            <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Linked Installation Findings</h1>
                        </div>
                        <Formik
                            onSubmit={(values, actions) => {
                                toggleLinkedInstallFndModal();
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                                isSubmitting
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <table className="w-full mb-5 mt-5 table-auto">
                                        <tbody>
                                            <tr>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Installation</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Finding Ref.</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Finding Level</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Date Raised</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Action Party</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Closure Date</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Finding Current Status</th>
                                                <th className="p-3 text-right text-blue-900 font-semibold border">Add (N/Y)</th>
                                            </tr>
                                            {
                                                installFindingList.length ? installFindingList.map(fnd =>
                                                    <tr key={fnd.id}>
                                                        <td className="p-3 border">{fnd.installation}</td>
                                                        <td className="p-3 border">{fnd.fnd_ref}</td>
                                                        <td className="p-3 border">{fnd.fnd_level}</td>
                                                        <td className="p-3 border">{fnd.fnd_date_raised}</td>
                                                        <td className="p-3 border">{fnd.fnd_action_party}</td>
                                                        <td className="p-3 border">{fnd.fnd_closure_date}</td>
                                                        <td className="p-3 border">{fnd.fnd_current_status}</td>
                                                        <td className="p-3 text-right border">
                                                            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                                <input type="checkbox"
                                                                    name={"fnd-" + fnd.id} id={"fnd-" + fnd.id}
                                                                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                                                                    checked={linkedInstallFindingList.some(lf => lf.id === fnd.id)}
                                                                    onChange={() => handleAddLinkedInstallFnd(fnd.id)}
                                                                />
                                                                <label htmlFor={"fnd-" + fnd.id} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) :
                                                    <tr>
                                                        <td colSpan={8} className="p-3 border text-center">{"Data is not Available."}</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <button
                                        type="button"
                                        className="mt-3 button-red capitalize"
                                        onClick={() => toggleLinkedInstallFndModal()}>Apply Selection</button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            {/* Linked Installation Finding Modal */}

            {/* Linked Duty Holder Finding Modal */}
            <div className="linkDhFndmodal modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleLinkedDhFndModal()}></div>
                <div className="absolute w-full md:w-11/12 lg:w-10/12 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
                    <div className="w-full px-8 pb-8">
                        <div className="pb-3 border-b-2 border-gray-200">
                            <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Linked Duty Holder Findings</h1>
                        </div>
                        <Formik
                            onSubmit={(values, actions) => {
                                toggleLinkedInstallFndModal();
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                                isSubmitting
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <table className="w-full mb-5 mt-5 table-auto">
                                        <tbody>
                                            <tr>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Installation</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Finding Ref.</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Finding Level</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Date Raised</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Action Party</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Closure Date</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Finding Current Status</th>
                                                <th className="p-3 text-right text-blue-900 font-semibold border">Add (N/Y)</th>
                                            </tr>
                                            {
                                                dhFindingList.length ? dhFindingList.map(fnd =>
                                                    <tr key={fnd.id}>
                                                        <td className="p-3 border">{fnd.installation}</td>
                                                        <td className="p-3 border">{fnd.fnd_ref}</td>
                                                        <td className="p-3 border">{fnd.fnd_level}</td>
                                                        <td className="p-3 border">{fnd.fnd_date_raised}</td>
                                                        <td className="p-3 border">{fnd.fnd_action_party}</td>
                                                        <td className="p-3 border">{fnd.fnd_closure_date}</td>
                                                        <td className="p-3 border">{fnd.fnd_current_status}</td>
                                                        <td className="p-3 text-right border">
                                                            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                                <input type="checkbox"
                                                                    name={"fnd-dh-" + fnd.id} id={"fnd-dh-" + fnd.id}
                                                                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                                                                    checked={linkedDhFindingList.some(lf => lf.id === fnd.id)}
                                                                    onChange={() => handleAddLinkedDhFnd(fnd.id)}
                                                                />
                                                                <label htmlFor={"fnd-dh-" + fnd.id} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) :
                                                    <tr>
                                                        <td colSpan={8} className="p-3 border text-center">{"Data is not Available."}</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <button
                                        type="button"
                                        className="mt-3 button-red capitalize"
                                        onClick={() => toggleLinkedDhFndModal()}>Apply Selection</button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            {/* Linked Duty Holder Finding Modal */}

            {/* Linked Finding Responses Modal */}
            <div className="fndResponsemodal modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-10">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleFindingResponseModal()}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
                    <table className="table-report w-full mb-4 border">
                        <tbody>
                            <tr>
                                <th className="bg-blue-900 font-sans font-semibold text-white" colSpan="1">Add Response</th>
                            </tr>
                            <tr>
                                <td>
                                    <Formik
                                        enableReinitialize
                                        initialValues={fndResponseFormInitValues}
                                        validationSchema={findingResponseValidation((userTypeAndRoleObject && userTypeAndRoleObject.userType ? userTypeAndRoleObject.userType : "ABS"))}
                                        onSubmit={(values, { resetForm }) => {
                                            resetForm({});
                                            let respId = uuidv4();
                                            let response_update_date = new Date();
                                            
                                            let userName = userTypeAndRoleObject && userTypeAndRoleObject.userName;
                                            let userType = userTypeAndRoleObject && userTypeAndRoleObject.userType;

                                            let dhResponseStatus = {};
                                            if (userType && userType === "Duty Holder") {
                                                dhResponseStatus = { response_status: "Open" };
                                            }

                                            if (fndResponseFormAction === "add") {
                                                let tempValues = { ...values, ...dhResponseStatus, id: respId, response_attachments: values.response_attachments, response_update_date: response_update_date, response_update_by: userName, response_user_type: userType };
                                                setFindingResponseList([...findingResponseList, tempValues]);
                                                setFndResponseFormAction("");
                                            }

                                            if (fndResponseFormAction === "edit") {
                                                let tempValues = { ...values, ...dhResponseStatus, response_attachments: values.response_attachments, response_update_date: response_update_date, response_update_by: userName, response_user_type: userType };
                                                const { id = "" } = values;
                                                let tempList = findingResponseList;
                                                let fndIndex = tempList.findIndex(fnd => fnd.id === id);
                                                tempList[fndIndex] = tempValues;
                                                setFindingResponseList([...tempList]);
                                                setFndResponseFormAction("");
                                            }

                                            toggleFindingResponseModal();
                                        }}
                                    >
                                        {({
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            setFieldValue,
                                            handleSubmit,
                                            setFieldTouched,
                                            isSubmitting
                                        }) => (
                                            <form onSubmit={handleSubmit}>
                                                {userTypeAndRoleObject && userTypeAndRoleObject.userType && userTypeAndRoleObject.userType === "ABS" && <div>
                                                    <label htmlFor="response_status" className="block mb-1 text-blue-900 font-semibold">Current Status</label>
                                                    <select
                                                        className="form-control block w-full"
                                                        name="response_status"
                                                        id="response_status"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.response_status}
                                                    >
                                                        <option value={""}>Select Current Status</option>
                                                        {statusList.map(status =>
                                                            <option key={status._id} value={status.sysvar_title}>{status.sysvar_title}</option>
                                                        )}
                                                    </select>
                                                    <FormError touched={touched.response_status} message={errors.response_status} />
                                                </div>}

                                                <div>
                                                    <label htmlFor="response_note" className="block mb-1 text-blue-900 font-semibold">Response Note</label>
                                                    <TextEditor
                                                        name="response_note"
                                                        id="response_note"
                                                        //onChange={handleChange}
                                                        onChange={note => setFieldValue('response_note', note)}
                                                        //onBlur={handleBlur}
                                                        onBlur={note => setFieldTouched('response_note', note)}
                                                        value={values.response_note}
                                                        wrapper="w-full"
                                                    />

                                                    {/* <textarea
                                                        type="text"
                                                        className="form-control block w-full"
                                                        name="response_note"
                                                        id="response_note"
                                                        placeholder="Note"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.response_note}
                                                        rows="7"
                                                    /> */}
                                                    <FormError touched={touched.response_note} message={errors.response_note} />
                                                </div>

                                                <div className="mb-4">
                                                    <label htmlFor="response_attachments" className="block mb-1 text-blue-900 font-semibold">Response Attachments</label>
                                                    {authContext.user && authContext.user.user_home_dir &&
                                                        <FileUploadAlt
                                                            onAttach={(files) => setFieldValue('response_attachments', JSON.stringify(files))}
                                                            id='fileManager'
                                                            buttonText='Attach Files'
                                                            apiURL={REACT_APP_FILE_MANAGER_API}
                                                            toolbarText='Attach'
                                                            homeDir={`/${authContext.user.user_home_dir}/`}
                                                            showDescription={true}
                                                            {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.response_attachments) ? parseJSONSafely(values.response_attachments) : [] })}
                                                        />
                                                    }
                                                    <FormError touched={touched.response_attachments} message={errors.response_attachments} />
                                                </div>

                                                <button type="submit" className="mt-3 button-red capitalize">Add Response</button>
                                            </form>
                                        )}
                                    </Formik>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* Linked Finding Responses Modal */}

            {/* Add Activities */}
            <div className="addactmodal modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleAddActivitiesModal()}></div>
                <div className="absolute w-full md:w-11/12 lg:w-11/12 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>
                    <div className="w-full px-8 pb-8">
                        <div className="pb-3 border-b-2 border-gray-200">
                            <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Add Activities To Finding</h1>
                        </div>
                        <div className="pb-3 border-b-2 border-gray-200">
                            <button                               
                                type="button"
                                onClick={() => { toggleAddActivitiesFilterModal() }}
                                style={{ ...(hasFilterParam && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}
                                className="mt-2 mr-2 button-red"><FontAwesomeIcon icon={faFilter} /> Filter Activities</button>
                        </div>
                        <Formik
                            onSubmit={(values, actions) => {

                                toggleAddActivitiesModal();
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                handleSubmit,
                                isSubmitting
                            }) => (
                                <form onSubmit={handleSubmit}>
                                    <table className="w-full mb-5 mt-5 table-auto">
                                        <tbody>
                                            <tr>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">SECE Ref.</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">SECE Title</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">PS Client Ref.</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">PS Name</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">VA Ref.</th>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">VA Short Description</th>
                                                <th className="p-3 text-right text-blue-900 font-semibold border">Add (N/Y)</th>
                                            </tr>
                                            {addActivityList.length ? addActivityList.map(va =>
                                                <tr key={va._id}>
                                                    <td className="p-3 border">{va.sece_id && va.sece_id.sece_client_ref ? va.sece_id.sece_client_ref : "N/A"}</td>
                                                    <td className="p-3 border">{va.sece_id && va.sece_id.sece_title ? va.sece_id.sece_title : "N/A"}</td>
                                                    <td className="p-3 border">{va.ps_id && va.ps_id.ps_ref ? va.ps_id.ps_ref : "N/A"}</td>
                                                    <td className="p-3 border">{va.ps_id && va.ps_id.ps_title ? va.ps_id.ps_title : "N/A"}</td>
                                                    <td className="p-3 border">{va.va_client_ref}</td>
                                                    <td className="p-3 border">{va.va_short_desc}</td>
                                                    <td className="p-3 text-right border">
                                                        <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                            <input
                                                                //disabled = {originalActivityList.some(sva => sva._id === va._id)} 
                                                                disabled = {selectedAddActivityList.length === 1 && selectedAddActivityList.some(sva => sva._id === va._id)} 
                                                                type="checkbox"
                                                                name={"va-" + va._id} id={"va-" + va._id}
                                                                className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                                                                checked={selectedAddActivityList.some(sva => sva._id === va._id)}
                                                                onChange={() => { handleAddActivity(va._id) }}
                                                            />
                                                            <label htmlFor={"va-" + va._id} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) :
                                                <tr>
                                                    <td className="p-3 border">{"Data is not Available."}</td>
                                                    <td className="p-3 text-right border"></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                    <button
                                        type="button"
                                        className="mt-3 button-red capitalize"
                                        onClick={() => { toggleAddActivitiesModal(); }}>Apply Selection</button>
                                </form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
            {/* Add Activities */}

            {/* Add Activity Filter Pop Up*/}
            <div className="addactfiltermodal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-80" onClick={() => { toggleAddActivitiesFilterModal(); }}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

                    <Formik
                        initialValues={addVaFilterFormInitValue}
                        validationSchema={AddVaFilterValidation}
                        enableReinitialize={true}
                        onSubmit={(values, action) => {

                            let seceIds = values.sece_id && values.sece_id !== "" ? [values.sece_id] : [];
                            let psIds = values.ps_id && values.ps_id !== "" ? [values.ps_id] : [];

                            let filter = {
                                sece_ids: seceIds,
                                ps_ids: psIds,
                                va_type: values.nature_id,
                                va_onshore_offshore: values.onshore_offshore,
                                va_freq: values.periodicity,
                                report_id: rptId,
                                vs_id: vsId
                            }

                            setHasFilterParam(true);

                            verificationActivitySearchAlt2({ variables: { param: { ...filter } }, errorPolicy: 'all' });

                            setAddVaFilterFormInitValue({ ...values });
                            toggleAddActivitiesFilterModal();
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            handleSubmit,
                            submitForm,
                            isSubmitting
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div className="mb-4 pb-2 border-b-2 border-gray-200">
                                    <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">Filter Verification Activities</h2>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="sece_id" className="block mb-1 text-blue-900 font-semibold">SECE</label>
                                    <select
                                        className="form-control block w-full"
                                        name="sece_id"
                                        id="sece_id"
                                        onChange={(event) => {
                                            handleChange(event);
                                            handleSeceChange(event);
                                            setFieldValue("ps_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.sece_id}
                                    >
                                        <option value={""}>Select SECE</option>
                                        {seceList.map(sece =>
                                            <option key={sece._id} value={sece._id}>{`${sece.sece_client_ref ? sece.sece_client_ref : "N/A"} : ${sece.sece_title}`}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="ps_id" className="block mb-1 text-blue-900 font-semibold">Performance Standard</label>
                                    <select
                                        className="form-control block w-full"
                                        disabled={values.sece_id === ""}
                                        name="ps_id"
                                        id="ps_id"
                                        onChange={(event) => { handleChange(event); }}
                                        onBlur={handleBlur}
                                        value={values.ps_id}
                                    >
                                        <option value={""}>Select Performance Standard</option>
                                        {psList.map(ps =>
                                            <option key={ps._id} value={ps._id}>{ps.ps_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="nature_id" className="block mb-1 text-blue-900 font-semibold">Nature</label>
                                    <select
                                        className="form-control block w-full"
                                        name="nature_id"
                                        id="nature_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.nature_id}
                                    >
                                        <option value={""}>Select Nature</option>
                                        {natureList.map(nature =>
                                            <option key={nature._id} value={nature.sysvar_title}>{nature.sysvar_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="onshore_offshore" className="block mb-1 text-blue-900 font-semibold">Onshore / Offshore</label>
                                    <select
                                        className="form-control block w-full"
                                        name="onshore_offshore"
                                        id="onshore_offshore"
                                        onChange={(event) => { handleChange(event); }}
                                        onBlur={handleBlur}
                                        value={values.onshore_offshore}
                                    >
                                        <option value={""}>Select Onshore / Offshore</option>
                                        {onshoreOffshoreList.map(onoff =>
                                            <option key={onoff.id} value={onoff.value}>{onoff.value}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="periodicity" className="block mb-1 text-blue-900 font-semibold">Frequency</label>
                                    <select
                                        className="form-control block w-full"
                                        name="periodicity"
                                        id="periodicity"
                                        onChange={(event) => { handleChange(event); }}
                                        onBlur={handleBlur}
                                        value={values.periodicity}
                                    >
                                        <option value={""}>Select Periodicity</option>
                                        {frequencyList.map(freq =>
                                            <option key={freq.id} value={freq.title}>{freq.title}</option>
                                        )}
                                    </select>
                                </div>

                                <button type="button" onClick={() => { submitForm() }} className="mt-3 mb-4 button-red capitalize mr-4">Apply Filter</button>
                                <button type="button"
                                    onClick={() => {
                                        setFieldValue("sece_id", "");
                                        setFieldValue("ps_id", "");
                                        setFieldValue("nature_id", "");
                                        setFieldValue("onshore_offshore", "");
                                        setFieldValue("periodicity", "");
                                        clearAddActivitiesFilter()
                                    }} className="mt-3 mb-4 button-red capitalize">Clear Filter</button>
                            </form>
                        )}
                    </Formik>
                </div>
            </div>
            {/* Add Activity Filter Pop Up*/}
        </div>
    )
}


function reformatResponseAttachment(param) {
    return parseJSONSafely(param) ? parseJSONSafely(param) : [];
}

function createAttachList(attachments, fileDestPath) {
    let linkList = [];
    if (attachments && attachments.length) {
        attachments.forEach((att, index) => {
            let newListItem = {
                name: att.name,
                description: att.description && att.description !== "" ? att.description : "N/A"
            }
            linkList.push(newListItem);
        })
    }
    return linkList;
}

function formatVaNature(vaList) {
    let newVaNatureList = "N/A";
    let natureList = vaList.map(v => v.va_type);
    if (natureList && natureList.length) {
        let numberOfVaRefs = natureList.length;
        if (numberOfVaRefs > 1) {
            let tempVaNatures = "";
            natureList.forEach((nature, index) => {
                tempVaNatures += nature + ((index + 1) < numberOfVaRefs ? ", " : "");
            });
            newVaNatureList = tempVaNatures;
        } else {
            let nature = natureList[0]
            newVaNatureList = nature;
        }
    }
    return newVaNatureList;
}

export const createStatusFrom = (statusList, statusId) => {
    let status = "N/A";
    if (statusList && statusList.length > 0 && statusId && statusId !== "") {
        let statusObj = statusList.find(s => s._id === statusId);
        if (statusObj) {
            status = statusObj.sysvar_title;
        }
    }
    return status;
}

export const formatPreviousFindings = (prevFnds, synopsisLength) => {
    let preFndList = [];
    if (prevFnds && prevFnds.length) {
        prevFnds.forEach(fnd => {
            const {
                _id,
                finding_ref,
                finding_synopsis = "N/A",
                finding_added_date = "",
                finding_closure_date = "",
            } = fnd ? fnd : {};

            const { _id: status_id = "", sysvar_title: status = "N/A" } = fnd.finding_current_status ? fnd.finding_current_status : {};
            //const { sysvar_title: level = "N/A" } = fnd.finding_level ? fnd.finding_level : {};
            const { installation_title: inst = "N/A" } = fnd.installation_id ? fnd.installation_id : {};
            const fnd_level = valueFromInfoMatrixLevel(fnd.finding_infomatrix_level);
            const { user_fname: apFName, user_lname: apLName } = fnd.finding_action_party ? fnd.finding_action_party : {};
            const { user_fname: authorFName, user_lname: authorLName } = fnd.finding_added_id ? fnd.finding_added_id : {};

            let prevFndItem = {
                id: _id,
                installation: inst,
                fnd_ref: finding_ref ? finding_ref : "N/A",
                fnd_level: fnd_level !== "" ? fnd_level : "N/A",
                fnd_date_raised: finding_added_date && finding_added_date !== "" ? formatDateToLocaleDateString(finding_added_date) : "N/A",
                fnd_synopsis: truncateString(finding_synopsis, synopsisLength),
                fnd_action_party: apFName && apLName ? `${apFName} ${apLName}` : "N/A",
                fnd_author: authorFName && authorLName ? `${authorFName} ${authorLName}` : "N/A",
                fnd_closure_date: finding_closure_date && finding_closure_date !== "" ? formatDateToLocaleDateString(finding_closure_date) : "N/A",
                fnd_current_status: status,
                status_id: status_id
            }
            preFndList.push(prevFndItem);
        })
    }
    return preFndList;
}

function formatSysVarDataAndUpdateStates(list, setNatureList, setFrequencyList, setStatusList, setAnswerOptionList, setFindingLevelList, natureTypeId, vaFreqTypeId, statusTypeId, answerTypeId, findingLevelTypeId) {
    let natureList = [];
    let vaFreqList = [];
    let statusList = [];
    let answerList = [];
    let findingLevelList = [];

    natureList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === natureTypeId);
    let tempVaFreqList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === vaFreqTypeId);
    statusList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === statusTypeId && (sysVar.sysvar_title === "Open" || sysVar.sysvar_title === "Closed"));
    answerList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === answerTypeId);
    findingLevelList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === findingLevelTypeId);

    tempVaFreqList.forEach(freq => {
        let newVaFreq = {
            id: freq._id,
            title: freq.sysvar_title
        }
        vaFreqList.push(newVaFreq);
    })

    setNatureList(natureList);
    setFrequencyList(vaFreqList);
    setStatusList(statusList);  
    setAnswerOptionList(answerList);
    setFindingLevelList(findingLevelList);
}

// function createFindingLevelSummary(levelList, levelId) {
//     let summaryLevel = "";

//     if (levelList && levelList.length && levelId !== "") {
//         let level = levelList.find(l => l._id === levelId);
//         if (level) {
//             switch (level.sysvar_title) {
//                 case "1":
//                     summaryLevel = "High";
//                     break;
//                 case "2":
//                     summaryLevel = "Medium";
//                     break;
//                 case "3":
//                     summaryLevel = "Low";
//                     break;
//                 case "A":
//                     summaryLevel = "High";
//                     break;
//                 case "B":
//                     summaryLevel = "Medium";
//                     break;
//                 case "C":
//                     summaryLevel = "Low";
//                     break;
//                 case "High":
//                     summaryLevel = "High";
//                     break;
//                 case "Medium":
//                     summaryLevel = "Medium";
//                     break;
//                 case "Low":
//                     summaryLevel = "Low";
//                     break;
//                 default:
//                     break;
//             }
//         }
//     }
//     return summaryLevel;
// }

function createFindingLevelSummaryFromInfoMatrix(infomatrix) {
    let summaryLevel = "";
    if (infomatrix && infomatrix !== "") {
        let valueArrays = infomatrix.split(" : ");
        let level = valueArrays[0];
        level.trim();
        if (level) {
            switch (level) {
                case "SCiS Level 3":
                case "SCiS LoR":
                case "SCiS LoC":
                    summaryLevel = "High";
                    break;
                case "SCiS Level 2":
                    summaryLevel = "Medium";
                    break;
                case "SCiS Level 1":
                    summaryLevel = "Low";
                    break;
                default:
                    break;
            }
        }
    }
    return summaryLevel;
}

function createIdList(list) {
    let newIdList = []
    if (list && list.length) {
        list.forEach(item => {
            newIdList.push(item.id)
        })
    }
    return newIdList
}

function formatDate(date) {
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    let formatedDate = ""
    if (date && date !== "") {
        let newDate = new Date(date);
        formatedDate = newDate.toLocaleDateString('en-GB', options);
    }
    return formatedDate;
}

function formatSeceData(seces) {
    let formatedList = [];
    if (seces && seces.length) {
        let newSeceList = seces.filter(sece => sece.sece_archived !== true && sece.sece_status === NOTIFICATION_STATUS_APPROVED);
        newSeceList.forEach(sece => {
            let newSece = {
                value: sece._id,
                label: `${sece.sece_client_ref} - ${sece.sece_title}`
            }
            formatedList.push(newSece);
        });
    }

    return formatedList;
}

function formatBackToIdList(items) {
    let formatedList = [];
    if (items && items.length) {
        items.forEach(item => {
            let id = item.value;
            formatedList.push(id);
        });
    }
    return formatedList;
}

function createClosedateFrom(responseList) {
    let closeDate = "";
    if (responseList && responseList.length) {
        let respLength = responseList.length;
        let lastFndResp = responseList[respLength - 1];
        const { response_status = "", response_update_date = "" } = lastFndResp ? lastFndResp : {};
        if (response_status === "Closed") {
            closeDate = response_update_date
        }
    }
    return closeDate;
}

function CreateTitle(title, formMode) {
    let newTitle = "Add Finding";

    if (formMode === "edit" && title === "") {
        return "Edit Finding";
    }

    newTitle = title;
    return newTitle;
}

export default FindingManagement;