/* eslint-disable react-hooks/exhaustive-deps */

import React, {useEffect, useRef, useState, useContext} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import {useHistory, useParams} from 'react-router-dom';
//import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {gql, useMutation, useLazyQuery} from '@apollo/client';

import Select from '../global/Select';
import {omit} from 'lodash';
import {AuthContext} from "../../context/authContext";
import {createUserTypeAndRoleObject} from "../../utils";

import {USER_TYPE_DUTY_HOLDER_ID, NOTIFICATION_STATUS_APPROVED} from '../../constants/';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const ValidationSchema = Yup.object().shape({
  user_type: Yup.string().label("user_type").required("User Type is required"),
  user_role: Yup
    .string()
    .when("user_type", {
      is: val => val !== "",
      then: Yup.string().label("user_role").required("User role is required")
    })
    .nullable(),
  user_fname: Yup.string().label("user_fname").required("First Name is required"),
  user_duty_holder: Yup
    .string()
    .when("user_type", {
      is: val => val === USER_TYPE_DUTY_HOLDER_ID,
      then: Yup.string().label("user_duty_holder").required("Duty holder is required")
    })
    .nullable(),
  user_duty_holder_location: Yup
    .string()
    .when("user_type", {
      is: val => val === USER_TYPE_DUTY_HOLDER_ID,
      then: Yup.string().label("user_duty_holder_location").required("Duty holder location is required")
    })
    .nullable(),
  user_lname: Yup.string().label("user_lname").required("Last Name is required"),
  user_email: Yup.string().label("user_email").email("Email must be a valid email address").required("Email Address is required"),
  user_job_title: Yup.string().label("user_job_title").required("Job title is required"),
  user_location: Yup
    .string()
    .when("user_type", {
      is: val => val !== USER_TYPE_DUTY_HOLDER_ID,
      then: Yup.string().label("user_location").required("Location is required")
    }),
  user_office_tel: Yup.string().label("user_office_tel").matches(phoneRegExp, 'Phone number is not valid').required("Office Tel. is required"),
  user_mobile: Yup.string().label("user_mobile").matches(phoneRegExp, 'Mobile number is not valid').required("Mobile is required"),
})

const USER_QUERY = gql`
query User($userId: ID!){
    user(_id: $userId) {
        user_location
        user_ms_oid
        user_email
        user_fname
        user_job_title
        user_lname
        user_mobile
        user_notes
        user_office_tel
        user_profile_image
        user_type {
            _id
            user_type_display_name
        }
        user_role {
            _id
        }
        user_duty_holder {
            _id
            dh_name
        }
        user_duty_holder_location {
            _id
        },
    }
}
`;

const DUTY_HOLDER_LOCATIONS_QUERY = gql`
query DutyHolderLocations($dutyHolderId: ID!){
    dutyHolder(_id: $dutyHolderId) {
        dh_location
    }
}
`;

const CREATE_USER = gql`
    mutation AddUser($user: CreateUserInput!) {
        createUser(user: $user) {
            _id
            user_email
            user_fname
            user_lname
            user_job_title
            user_location
            user_office_tel
            user_mobile
            user_notes
            user_profile_image
            success
            status_code
            message
        }
    }
`;

const UPDATE_USER = gql`
    mutation UpdateUser($id: String!, $user: UpdateUserInput! $userId: String!) {
        updateUser(_id: $id, user: $user, userId: $userId) {
            user_email
            user_fname
            user_lname
            user_job_title
            user_location
            user_office_tel
            user_mobile
            user_notes
            user_profile_image
            success
            status_code
            message
        }
    }
`;

const AddEditUser = () => {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [dutyHolderId, setDutyHolderId] = useState('');
  const [dutyHolderLocationIds, setDutyHolderLocationIds] = useState([]);

  const [formInitValues, setFormInitValues] = useState({
    user_type: '',
    user_role: '',
    user_duty_holder: '',
    user_fname: '',
    user_lname: '',
    user_email: '',
    user_job_title: '',
    user_location: '',
    user_office_tel: '',
    user_mobile: '',
    user_profile_image: '',
    user_notes: '',

  })

  const { formMode, userId = "", dhId = "" } = useParams();

  const fileUpload = useRef();

  const [user, { data: userData}] = useLazyQuery(USER_QUERY);
  const [dutyHolder, { data: dutyHolderData}] = useLazyQuery(DUTY_HOLDER_LOCATIONS_QUERY);

  const [createUser, { data: createUserData }] = useMutation(CREATE_USER);
  const [updateUser, { data: updateUserData }] = useMutation(UPDATE_USER);

  useEffect(() => {
  if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
  }
  }, [authContext]);

  useEffect(() => {
  if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
  }
  }, [userTypeAndRoleObject]);


  useEffect(() => {
    if (userId && userId !== "") {
      user({ variables: { userId: userId }, errorPolicy: 'all' });
    }

    if (dhId && dhId !== "") {
      setFormInitValues({ ...formInitValues, user_type: USER_TYPE_DUTY_HOLDER_ID, user_duty_holder: dhId });
      setDutyHolderId(dhId);
    }
  }, [])

  useEffect(() => {
    if (dutyHolderId && dutyHolderId !== "") {
      dutyHolder({ variables: { dutyHolderId: dutyHolderId }, errorPolicy: 'all' });
    }
  }, [dutyHolderId])

  useEffect(() => {
    if (userData && userData.user) {
      const userType = userData.user.user_type._id;

      userData.user.user_type = userType;

      if (userData.user.user_role) {
        userData.user.user_role = userData.user.user_role._id
      }

      if (userData.user.user_duty_holder) {
        setDutyHolderId(userData.user.user_duty_holder._id);

        userData.user.user_duty_holder = userData.user.user_duty_holder._id;
        userData.user.user_duty_holder_location = userData.user.user_duty_holder_location._id;

        setFormInitValues(userData.user);
      } else {
        setFormInitValues(userData.user);
      }
    }
  }, [userData])

  useEffect(() => {
    if (dutyHolderData && dutyHolderData.dutyHolder) {
      setDutyHolderLocationIds(dutyHolderData.dutyHolder.dh_location);
    }
  }, [dutyHolderData])

  useEffect(() => {
    if (createUserData) {
      const { success, message } = createUserData.createUser;
      if (!success) {
        alert(message);
      }
      if (success) {
        let returnPath = dhId && dhId !== "" ? `/dutyHolder/${dhId}/users` : "/users"
        history.push(returnPath);
      }
    }
  }, [createUserData]);

  useEffect(() => {
    authContext.updateUserDetails();
    if (updateUserData) {
      const { success, message } = updateUserData.updateUser;
      if (!success) {
        alert(message);
      }
      if (success) {
        let returnPath = dhId && dhId !== "" ? `/dutyHolder/${dhId}/users` : "/users"
        history.push(returnPath);
      }
    }
  }, [updateUserData]);

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} User</h1>
      </div>

      <Formik
        enableReinitialize
        initialValues={omit(formInitValues, '__typename')}
        validationSchema={ValidationSchema}
        onSubmit={(values, actions) => {
          const tempValues = { ...values };

          tempValues.user_added_id = authContext.user._id;

          if (formMode === 'add') {
            createUser({ variables: { user: tempValues } })
          }

          if (formMode === 'edit') {
            updateUser({ variables: { id: userId, user: values, userId: authContext.user._id} })
          }

        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            {/*
            <div className="my-4">
              <Link to={dhId && dhId !== "" ? `/dutyHolder/${dhId}/users` : "/users"} className="button-red">
                <FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back
              </Link>
            </div> */}

            <div className="mt-6">
              <label htmlFor="user_type" className="block mb-1 text-blue-900 font-semibold">User Type*</label>

              <Select
                disabled={dhId && dhId !== ""}
                title="Select User Type"
                name="user_type"
                dataKey="user_type"
                accessor="user_type_display_name"
                customClassName="form-control block w-full md:w-1/2"
                onChange={(event) => {
                  setFieldValue('user_duty_holder', '')
                  setFieldValue('user_duty_holder_location', '')
                  handleChange(event)
                }}
                onBlur={handleBlur}
                value={values.user_type}
                query={gql`query {
                      user_type {
                        _id
                        user_type_display_name
                      }
                  }
                `}
              />

              <FormError touched={touched.user_type} message={errors.user_type} />
            </div>

            {values.user_type !== "" &&

              <div>
                <label htmlFor="user_role" className="block mb-1 text-blue-900 font-semibold">User Role*</label>

                <Select
                  title="Select User Role"
                  name="user_role"
                  dataKey="user_role_by_type"
                  accessor="user_role_display"
                  customClassName="form-control block w-full md:w-1/2"
                  onChange={(event) => {
                    setFieldValue('user_role', event.target.value);
                  }}
                  onBlur={handleBlur}
                  value={values.user_role}
                  query={gql`query UserRoleByType($userTypeId: ID!){
                    user_role_by_type(userTypeId: $userTypeId) {
                      _id
                      user_type
                      user_role_display
                      user_role_code
                    }
                  }
                `}
                  queryOptions={{
                    variables: { userTypeId: values.user_type },
                    errorPolicy: 'all'
                  }}
                />

                <FormError touched={touched.user_role} message={errors.user_role} />
              </div>
            }

            {values.user_type === USER_TYPE_DUTY_HOLDER_ID &&
              <>

                <div>
                  <label htmlFor="user_duty_holder" className="block mb-1 text-blue-900 font-semibold">Duty Holder*</label>

                  <Select
                    disabled={dhId && dhId !== ""}
                    title="Select Duty Holder"
                    name="user_duty_holder"
                    dataKey="dutyHolders"
                    accessor="dh_name"
                    customClassName="form-control block w-full md:w-1/2"
                    onChange={(event) => {
                      setDutyHolderId(event.target.value)
                      handleChange(event)
                    }}
                    onBlur={handleBlur}
                    value={values.user_duty_holder}
                    queryOptions={{
                      variables: { status: NOTIFICATION_STATUS_APPROVED },
                      errorPolicy: 'all'
                    }}
                    query={gql`query DutyHolders($status: String) {
                    dutyHolders(status: $status) {
                      _id
                      dh_name
                    }
                  }
                `}
                  />

                  <FormError touched={touched.user_duty_holder} message={errors.user_duty_holder} />
                </div>
                <div>
                  <label htmlFor="user_duty_holder_location" className="block mb-1 text-blue-900 font-semibold">Duty
                    Holder
                    Location*</label>

                  <Select
                    title="Select Duty Holder Location"
                    name="user_duty_holder_location"
                    dataKey="locations_by_id"
                    accessor="location_name"
                    customClassName="form-control block w-full md:w-1/2"
                    onChange={(event) => {
                      handleChange(event)
                    }}
                    onBlur={handleBlur}
                    value={values.user_duty_holder_location}
                    queryOptions={{
                      variables: { dutyHolderLocationIds, status: NOTIFICATION_STATUS_APPROVED },
                      errorPolicy: 'all'
                    }}
                    query={gql`
                  query DutyHolderLocations($dutyHolderLocationIds: [ID!], $status: String ){
                      locations_by_id(_ids: $dutyHolderLocationIds, status: $status ) {
                          _id
                          location_name
                      }
                  }
                `}
                  />

                  <FormError touched={touched.user_duty_holder_location} message={errors.user_duty_holder_location} />
                </div>
              </>
            }
            <div>
              <label htmlFor="user_fname" className="block mb-1 text-blue-900 font-semibold">First
                Name*</label>
              <input
                type="text"
                className="form-control block w-full md:w-1/2"
                name="user_fname"
                id="user_fname"
                placeholder="First Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user_fname}
              />
              <FormError touched={touched.user_fname} message={errors.user_fname} />
            </div>

            <div>
              <label htmlFor="user_lname" className="block mb-1 text-blue-900 font-semibold">Last Name*</label>
              <input
                type="text"
                className="form-control block w-full md:w-1/2"
                name="user_lname"
                id="user_lname"
                placeholder="Last Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user_lname}
              />
              <FormError touched={touched.user_lname} message={errors.user_lname} />
            </div>

            <div>
              <label htmlFor="user_email" className="block mb-1 text-blue-900 font-semibold">Email Address*</label>
              <input
                type="email"
                className="form-control block w-full md:w-1/2"
                name="user_email" id="user_email"
                placeholder="Email Address"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user_email}
                disabled={formMode === 'edit' ? true : false}
              />
              <FormError touched={touched.user_email} message={errors.user_email} />
            </div>

            <div>
              <label htmlFor="user_job_title" className="block mb-1 text-blue-900 font-semibold">Job Title*</label>
              <input
                type="text"
                className="form-control block w-full md:w-1/2"
                name="user_job_title"
                id="user_job_title"
                placeholder="Job Title"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user_job_title}
              />
              <FormError touched={touched.user_job_title} message={errors.user_job_title} />
            </div>

            {values.user_type !== USER_TYPE_DUTY_HOLDER_ID &&

              <div className="md:w-1/2">
                <label htmlFor="user_location" className="block mb-1 text-blue-900 font-semibold">Location*</label>
                <Select
                  title="Select Location"
                  name="user_location"
                  dataKey="locations"
                  accessor="location_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.user_location}
                  query={gql`    
                              query {
                                  locations {
                                      _id
                                      location_name
                                      location_address_city
                                  }
                              }
                            `}
                />
                <FormError touched={touched.user_location} message={errors.user_location} />
              </div>
            }

            <div>
              <label htmlFor="user_office_tel" className="block mb-1 text-blue-900 font-semibold">Office Tel.*</label>
              <input
                type="tel"
                className="form-control block w-full md:w-1/2"
                name="user_office_tel"
                id="user_office_tel"
                placeholder="Office Tel."
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user_office_tel}
              />
              <FormError touched={touched.user_office_tel} message={errors.user_office_tel} />
            </div>

            <div>
              <label htmlFor="user_mobile"
                className="block mb-1 text-blue-900 font-semibold">Mobile*</label>
              <input
                type="tel"
                className="form-control block w-full md:w-1/2"
                name="user_mobile"
                id="user_mobile"
                placeholder="Mobile"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.user_mobile}
              />
              <FormError touched={touched.user_mobile} message={errors.user_mobile} />
            </div>

            <div className="mb-4">
              <label htmlFor="user_profile_image" className="block mb-1 text-blue-900 font-semibold">Profile Image</label>

              {formMode === 'edit' && (
                <img src={values.user_profile_image} className="mb-2" alt="" />
              )}

              <input
                type="file"
                ref={fileUpload}
                className="form-control block w-full md:w-1/2"
                name="user_profile_image"
                id="user_profile_image"
                placeholder="Profile Image"
                onChange={() => {
                  const width = 100;
                  const height = 100;

                  const file = fileUpload.current.files[0];
                  const reader = new FileReader();

                  reader.readAsDataURL(file);

                  reader.onload = (event) => {
                    const img = new Image();
                    img.src = event.target.result;

                    img.onload = () => {
                      const elem = document.createElement('canvas');
                      elem.width = width;
                      elem.height = height;
                      const ctx = elem.getContext('2d');
                      ctx.drawImage(img, 0, 0, width, height);

                      const dataURL = ctx.canvas.toDataURL();

                      setFieldValue('user_profile_image', dataURL);
                    }
                  }

                  reader.onerror = error => alert(error);

                }}
                onBlur={handleBlur}
              />
            </div>

            <div className="mb-4">
              <label htmlFor="user_notes" className="block mb-1 text-blue-900 font-semibold">Notes</label>
              <textarea
                type="text"
                className="form-control block w-full md:w-1/2"
                name="user_notes"
                id="user_notes"
                placeholder="Notes"
                onChange={handleChange}
                defaultValue={values.user_notes}
              />
            </div>

            <button type="submit" className="mt-3 button-red capitalize">{formMode} User</button>

          </form>
        )}
      </Formik>

    </div>
  )

}

export default AddEditUser;
