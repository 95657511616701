/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useContext} from 'react';
import { Link, useHistory, useParams} from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { NOTICE_STATUS_ACCEPTED} from '../../constants/index';
import {AuthContext} from "../../context/authContext";
import Sanitiser from "../global/sanitiser";

const PENDING_USER_NOTICES_QUERY = gql`
query PendingUserNotices($userId: ID!){
    pendingUserNotices(userId: $userId) {
        _id
        not_type
        user_type
        dh_id
        installation_ids{
            _id
            installation_title
        }
        bar_ids{
            _id
            bar_title
        }
        sece_ids{
            _id
            sece_title
        }
        not_title
        not_description
        not_file
    }
}`;

const CREATE_USER_NOTICE = gql`
mutation CreateUserNotice($userNot: CreateUserNotice) {
    createUserNotice(userNot: $userNot) {
            _id
            user_not_status
    }
}`;

function ViewNotice(props) {
    const [noticeObject, setNoticeObject] = useState({});
    const [pendingNotices, setPendingNotices] = useState([]);

    const { userId = "" } = useParams();

    const authContext = useContext(AuthContext);
    const history = useHistory();

    const [pendingUserNotices, { data: pendingNoticesData }] = useLazyQuery(PENDING_USER_NOTICES_QUERY);
    const [createUserNotice, { data: userNoticeData }] = useMutation(CREATE_USER_NOTICE);

    useEffect(() => {
        console.log("pendingNotices>>>>", pendingNotices);
        pendingUserNotices({ variables: { userId: userId }, errorPolicy: 'all' });
    }, []);

    useEffect(() => {
        if (pendingNoticesData && pendingNoticesData.pendingUserNotices) {
            let pendingNotList = pendingNoticesData.pendingUserNotices;
            if (pendingNotList.length === 0) {
                history.push('/');
            }
            let popedPendingNot = pendingNotList.pop();
            setNoticeObject(popedPendingNot);
            setPendingNotices(pendingNotList);     
        }
    }, [pendingNoticesData]);

    useEffect(() => {
        if (noticeObject.not_title) {
            toggleModal();
        }
    }, [noticeObject]);

    useEffect(() => {
        if (userNoticeData && userNoticeData.createUserNotice) {
            pendingUserNotices({ variables: { userId: userId }, errorPolicy: 'all' });
            toggleModal();
        }
    }, [userNoticeData]);

    const toggleModal = () => {
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    const handleAcceptClick = () => {
        const { _id: authUserId = "" } = authContext.user;
        
        if (noticeObject && noticeObject._id && authUserId !== "") {
            const payload = {
                user_id: userId,
                notice_id: noticeObject._id,
                user_not_status: NOTICE_STATUS_ACCEPTED,
                user_not_added_id: authUserId,
                user_not_added_date: new Date(),
                user_not_archived: false,
            }
            createUserNotice({ variables: { userNot: payload } })
        }
    };

    return (
        <div className="w-full px-8 pb-8">
            <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-10">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40"></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

                    <div className="mb-4 pb-2 border-b-2 border-gray-200">
                        <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">{noticeObject.not_title && noticeObject.not_title}</h2>
                    </div>

                    <Sanitiser html={noticeObject.not_description && noticeObject.not_description} />

                    <Link to={"#"} target="_blank" className="block text-blue-900 hover:underline">View File</Link>

                    <button className="mt-3 mb-4 button-red capitalize" onClick={() => handleAcceptClick()}>Accept</button>
                </div>
            </div>
        </div>
    );
}

export default ViewNotice;
