/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect, useContext} from 'react';
import ReactSelect from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { useParams, useHistory } from 'react-router-dom';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import TextEditor from '../../components/global/TextEditor';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {AuthContext} from "../../context/authContext";
import {omit} from 'lodash';

import makeAnimated from 'react-select/animated';
import FileUpload from '../global/FileUpload';
import { parseJSONSafely, updateAttachmentData, createUserTypeAndRoleObject } from "../../utils";
import {NOTICE_TYPE_SYS_VAR_TYPE_ID} from '../../constants/';

const { REACT_APP_FILE_MANAGER_API } = process.env;

const Validation = (targeted) => {
    let validation = Yup.object().shape({
        notice_type: Yup.string().label("notice_type").required("Notice Type is required"),
        //notice_periodicity: Yup.string().label("notice_periodicity").required("Notice Periodicity is required"),
        user_type: Yup
            .string()
            .when('is_user_type', {
                is: () => targeted === true, // anything that returns true will mean this now gets validated
                then: Yup.string().label("user_type").required("User Type is required")
            }),
        notice_title: Yup.string().label("notice_title").required("Title is required"),
        notice_desc: Yup.string().label("notice_desc").required("Description is required"),
    });
    return validation;
}

const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
            _id
            sysvar_title
            sysvar_archived
            sysvartype_id{
                _id
                sysvartype_title
            }
        }
}`;

const USER_TYPE_QUERY = gql`
query {
    user_type{
      _id
      user_type_display_name
  }
}`;

const DUTY_HOLDERS_QUERY = gql`
    query {
        dutyHolders {
            _id
            dh_name
            dh_status
            dh_added_date
            dh_archived
        }
}`;

const INSTALLATIONS_BY_DH_ID_QUERY = gql`
query installationsByDutyHolderId($dhId: ID){
    installationsByDutyHolderId(dhId: $dhId) {
      _id
      installation_title
      installation_archived
  }
}`;

const BARRIER_SEARCH_QUERY = gql`
    query BarrierSearch1($param: BarrierSearch!){
        barrierSearch(param: $param) {
            _id
            bar_title 
            bar_archived
        }
}`;

const SECES_SEARCH_QUERY = gql`
query SeceSearch($param: SeceSearch!){
    seceSearch(param: $param) {
       _id
       sece_title
       sece_archived
    }
}`;

const CREATE_NOTICE = gql`
mutation CreateNotice($notice: CreateNotice) {
        createNotice(not: $notice) {
            _id
            success
            status_code
            message
        }
}`;

const UPDATE_NOTICE = gql`
mutation UpdateNotice($noticeId: String!, $userId:String!, $notice: UpdateNoticeInput!) {
        updateNotice(_id: $noticeId, userId: $userId not: $notice) {
            _id
            success
            status_code
            message
        }
}`;

const NOTICE_BY_ID_QUERY = gql`
query Notice($noticeId: ID!){
    notice(_id: $noticeId) {
        _id
        not_type
        user_type
        dh_id
        installation_ids{
            _id
            installation_title
        }
        bar_ids{
            _id
            bar_title
        }
        sece_ids{
            _id
            sece_title
        }
        not_title
        not_description
        not_file
    }
}`;

const AddEditNotice = (props) => {
    //show targetted notice fields
    const [targeted, setTargeted] = useState(false);
    const [noticeTypeList, setNoticeTypeList] = useState([]);
    const [userTypeList, setUserTypeList] = useState([]);

    const [selectedDutyHolderId, setSelectedDutyHolderId] = useState("");
    const [selectedInstIds, setSelectedInstIds] = useState([]);
    const [selectedBarIds, setSelectedBarIds] = useState([]);

    const [dutyHolderList, setDutyHolderList] = useState([]);
    const [installationList, setInstallationList] = useState([]);
    const [barrierList, setBarrierList] = useState([]);
    const [seceList, setSeceList] = useState([]);

    //form add or edit state passed from link
    const { formMode = "", noticeId = "ttt" } = useParams();

    const authContext = useContext(AuthContext);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
    const history = useHistory();

    const [formInitValues, setFormInitValues] = useState({
        notice_type: "",
        //notice_periodicity:"",
        user_type: "",
        dh_id: "",
        installation_ids: [],
        barrier_ids: [],
        sece_ids: [],
        notice_title: "",
        notice_desc: "",
        notice_file: "[]"
    });

    const sysVarTypeIds = [NOTICE_TYPE_SYS_VAR_TYPE_ID];

    const [systemVariablesBySysVarTypeIds, {data: sysVarsData}] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);
    const [user_type, {data: userTypeData}] = useLazyQuery(USER_TYPE_QUERY);

    const [notice, {data: noticeData}] = useLazyQuery(NOTICE_BY_ID_QUERY);
    const [dutyHolders, {data: dutyHoldersData}] = useLazyQuery(DUTY_HOLDERS_QUERY);
    const [installationsByDutyHolderId, {data: installsData}] = useLazyQuery(INSTALLATIONS_BY_DH_ID_QUERY);
    const [barrierSearch, {data: barriersData}] = useLazyQuery(BARRIER_SEARCH_QUERY);
    const [seceSearch, {data: secesData}] = useLazyQuery(SECES_SEARCH_QUERY);
    const [createNotice, {data: createNoticeData}] = useMutation(CREATE_NOTICE);
    const [updateNotice, {data: updateNoticeData}] = useMutation(UPDATE_NOTICE);
    
    useEffect(() => {
    if (authContext && authContext.user){
        let result = createUserTypeAndRoleObject(authContext);
        setUserTypeAndRoleObject(result);
    }
    }, [authContext]);

    useEffect(() => {
    if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
        history.push('/access-permissions');
    }
    }, [userTypeAndRoleObject]);
    

    useEffect(() => {
        systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });
        user_type({ variables: {}, errorPolicy: 'all' });
        dutyHolders({ variables: {}, errorPolicy: 'all' });
        if (formMode === "edit" && noticeId !== "") {
            notice({ variables: { noticeId: noticeId }, errorPolicy: 'all' });
        }
    }, []);

    useEffect(() => {
        if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
            formatSysVarDataAndUpdateStates(sysVarsData.systemVariablesBySysVarTypeIds,
                setNoticeTypeList,
                NOTICE_TYPE_SYS_VAR_TYPE_ID
            );
        }
    }, [sysVarsData]);

    useEffect(() => { 
        if (userTypeData && userTypeData.user_type) {         
            setUserTypeList(userTypeData.user_type);
        }   
    }, [userTypeData]);

    useEffect(() => {
        fetchMultiSelectData(selectedDutyHolderId, selectedInstIds, selectedBarIds)
    }, [selectedDutyHolderId, selectedInstIds, selectedBarIds]);

    useEffect(() => {
        if (noticeData && noticeData.notice) {
            let notice = noticeData.notice;

            let installList = formatInstallationData(notice.installation_ids);
            let barrierList = formatBarrierData(notice.bar_ids);
            let seceList = formatSeceData(notice.sece_ids);

            if (notice.not_type === "Targeted") {
                if (notice.dh_id !== "") {
                    setSelectedDutyHolderId(notice.dh_id)
                }
                setTargeted(true);
            }
            
            setFormInitValues({
                notice_type: notice.not_type,
                //notice_periodicity: notice.not_periodicity,
                user_type: notice.user_type,
                dh_id: notice.dh_id,
                installation_ids: installList,
                barrier_ids: barrierList,
                sece_ids: seceList,
                notice_title: notice.not_title,
                notice_desc: notice.not_description,
                notice_file: notice.not_file
            })
        }
    }, [noticeData]);

    useEffect(() => {
        if (createNoticeData && createNoticeData.createNotice) {
            const {success, message} = createNoticeData.createNotice;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push('/notices');
            }         
        }
    }, [createNoticeData]);

    useEffect(() => {
        if (updateNoticeData && updateNoticeData.updateNotice) {
            const {success, message} = updateNoticeData.updateNotice;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push('/notices');
            }     
        }
    }, [updateNoticeData]);

    useEffect(() => {
        if (dutyHoldersData && dutyHoldersData.dutyHolders) {
            let list = dutyHoldersData.dutyHolders;
            let newDutyHolderList = list.filter(dh => dh.dh_status === "approved" && dh.dh_archived !== true);
            setDutyHolderList(newDutyHolderList)
        }
    }, [dutyHoldersData]);

    useEffect(() => {
        if (installsData && installsData.installationsByDutyHolderId) {
            let list = formatInstallationData(installsData.installationsByDutyHolderId);
            setInstallationList(list);

            let editInstallList = formatInstallEditData(formMode, list, formInitValues.installation_ids)

            setFormInitValues({
                ...formInitValues,
                installation_ids: editInstallList
            });
        }
    }, [installsData]);

    useEffect(() => {
        if (barriersData && barriersData.barrierSearch) {
            let list = formatBarrierData(barriersData.barrierSearch);
            setBarrierList(list)

            let editBarrierList = formatBarrierEditData(formMode, list, formInitValues.barrier_ids)

            setFormInitValues({
                ...formInitValues,
                barrier_ids: editBarrierList
            });
        }
    }, [barriersData]);

    useEffect(() => {
        if (secesData && secesData.seceSearch) {
            let list = formatSeceData(secesData.seceSearch);
            setSeceList(list)
            let editSeceList = formatSeceEditData(formMode, list, formInitValues.sece_ids)
            setFormInitValues({
                ...formInitValues,
                sece_ids: editSeceList
            });
        }
    }, [secesData]);
    
    const handleTypeClick = React.useCallback(e => {
        if (e.target.value === 'Targeted') {
            setTargeted(true);
        } else {
            setTargeted(false);
            setFormInitValues({ //Reset the initial form values in case the user selects a different notice type after selecting Target
                ...formInitValues,
                dh_id: "",
                installation_ids: [],
                barrier_ids: [],
                sece_ids: [],
            })
        }
    });

    const fetchMultiSelectData = (dutyHolderId, instIds, barIds) => {
        const barQueryParam = {
            ...(dutyHolderId && dutyHolderId !== "" && { dh_id: dutyHolderId }),
            ...(instIds && instIds.length && { installation_ids: instIds }),
        }

        const seceQueryParam = {
            ...(dutyHolderId && dutyHolderId !== "" && { dh_id: dutyHolderId }),
            ...(instIds && instIds.length && { installation_ids: instIds }),
            ...(barIds && barIds.length && { bar_ids: barIds }),
        }

        if (dutyHolderId && dutyHolderId !== "") {
            installationsByDutyHolderId({ variables: { dhId: dutyHolderId }, errorPolicy: 'all' });
            barrierSearch({ variables: { param: { ...barQueryParam } }, errorPolicy: 'all' });
            seceSearch({ variables: { param: { ...seceQueryParam } }, errorPolicy: 'all' });
        }
    }

    const handleDutyHolderChange = (event) => {
        let value = event.target.value;

        setInstallationList([]);
        setBarrierList([]);
        setSeceList([]);

        setSelectedDutyHolderId(value);
        setSelectedInstIds([]);
        setSelectedBarIds([]);
    };

    const handleInstallationChange = (instList) => {
        let idList = instList && instList.length ? instList.map(i => i.value) : [];

        setBarrierList([]);
        setSeceList([]);

        setSelectedInstIds(idList);
        setSelectedBarIds([]);
    };

    const handleBarrierChange = (barList) => {
        let idList = barList && barList.length ? barList.map(i => i.value) : [];
        
        setSeceList([]);

        setSelectedBarIds(idList);
    };

     //These Adds the ability to update the attachments with there description
     let tempNotAttachFileList = [];
     const handleNotAttachFilesDescription = (item) => {
         let index = tempNotAttachFileList.findIndex(f => f.name === item.name);
         if (index >= 0) {
            tempNotAttachFileList[index] = item;
         } else {
            tempNotAttachFileList.push(item);
         }
     };

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Notice</h1>
            </div>

            <Formik
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={Validation(targeted)}
                onSubmit={(values, actions) => {
                    const { _id: userId = "" } = authContext.user;
                    let updatedNotFile = updateAttachmentData(values.notice_file, tempNotAttachFileList);
                    if (formMode === 'add') {

                        let newNotice = {
                            not_type: values.notice_type,
                            //not_periodicity: values.notice_periodicity,
                            user_type: values.user_type,
                            dh_id: values.dh_id,
                            installation_ids: formatBackToIdList(values.installation_ids),
                            bar_ids: formatBackToIdList(values.barrier_ids),
                            sece_ids: formatBackToIdList(values.sece_ids),
                            not_title: values.notice_title,
                            not_description: values.notice_desc,
                            not_file: updatedNotFile,
                            not_added_date: new Date(),
                            not_added_id: userId
                        };
                        createNotice({ variables: { notice: newNotice } })
                    }

                    if (formMode === 'edit' && noticeId !== "") {
                        let newNotice = {
                            not_type: values.notice_type,
                            //not_periodicity: values.notice_periodicity,
                            user_type: values.user_type,
                            dh_id: values.dh_id,
                            installation_ids: formatBackToIdList(values.installation_ids),
                            bar_ids: formatBackToIdList(values.barrier_ids),
                            sece_ids: formatBackToIdList(values.sece_ids),
                            not_title: values.notice_title,
                            not_description: values.notice_desc,
                            not_file: updatedNotFile
                        };
                        updateNotice({ variables: { noticeId: noticeId, userId: userId, notice: newNotice } })
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    setFieldTouched,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>
                        {/*
                        <div className="my-4">
                            <Link to="/notices" className="button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>
                        </div>
                        */}

                        <div className="mt-6">
                            <label htmlFor="notice_title" className="block mb-1 text-blue-900 font-semibold">Notice Title*</label>
                            <input
                                type="text"
                                className="form-control block w-full md:w-1/2"
                                name="notice_title"
                                id="notice_title"
                                placeholder="Title"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.notice_title}
                            />
                            <FormError touched={touched.notice_title} message={errors.notice_title} />
                        </div>

                        {/*<div>
                        <label htmlFor="notice_periodicity" className="block mb-1 text-blue-900 font-semibold">Notice Periodicity*</label>
                        <select 
                            className="form-control block w-full md:w-1/2" 
                            name="notice_periodicity" 
                            id="notice_periodicity"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.notice_periodicity} 
                        >
                            <option>Select Notice Periodicity</option>
                            {periodicityList.map(item =>
                                <option key = {item._id} value={item.sysvar_title}>{item.sysvar_title}</option>
                            )}
                        </select>
                        <FormError touched={touched.notice_periodicity} message={errors.notice_periodicity} />
                            </div> */}

                        <div>
                            <label htmlFor="notice_type" className="block mb-1 text-blue-900 font-semibold">Notice Type*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="notice_type"
                                id="notice_type"
                                onClick={e => handleTypeClick(e)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.notice_type}
                            >
                                <option>Select Notice Type</option>
                                {noticeTypeList.map(noticeType =>
                                    <option key={noticeType._id} value={noticeType.sysvar_title}>{noticeType.sysvar_title}</option>
                                )}
                            </select>
                            <FormError touched={touched.notice_type} message={errors.notice_type} />
                        </div>

                        {targeted === true &&
                            <div>
                                <div>
                                    <label htmlFor="user_type" className="block mb-1 text-blue-900 font-semibold">User Type*</label>
                                    <select
                                        className="form-control block w-full md:w-1/2"
                                        name="user_type"
                                        id="user_type"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.user_type}
                                    >
                                        <option value ={""}>Select User Type</option>
                                        {userTypeList.map(userType =>
                                            <option key={userType._id} value={userType.user_type_display_name}>{userType.user_type_display_name}</option>
                                        )}
                                    </select>
                                    <FormError touched={touched.user_type} message={errors.user_type} />
                                </div>

                                <div>
                                    <label htmlFor="dh_id" className="block mb-1 text-blue-900 font-semibold">DutyHolder</label>
                                    <select
                                        className="form-control block w-full md:w-1/2"
                                        name="dh_id"
                                        id="dh_id"
                                        onChange={(event) => { 
                                            handleDutyHolderChange(event); 
                                            handleChange(event);
                                            setFieldValue('installation_ids', []);
                                            setFieldValue('barrier_ids', []);
                                            setFieldValue('sece_ids', []);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.dh_id}
                                    >
                                        <option value ={""}>Select DutyHolder</option>
                                        {dutyHolderList.map(dh =>
                                            <option key={dh._id} value={dh._id}>{dh.dh_name}</option>
                                        )}
                                    </select>
                                    <FormError touched={touched.dh_id} message={errors.dh_id} />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="installation_ids" className="block mb-1 text-blue-900 font-semibold">Installation(s)</label>
                                    <ReactSelect
                                        id="installation_ids"
                                        name="installation_ids"
                                        className="w-full md:w-1/2"
                                        classNamePrefix="react-select"
                                        options={installationList}
                                        isMulti
                                        isSearchable
                                        components={makeAnimated()}
                                        onChange={InstallationSelected => {
                                            handleInstallationChange(InstallationSelected); 
                                            setFieldValue('installation_ids', InstallationSelected);
                                            setFieldValue('barrier_ids', []);
                                            setFieldValue('sece_ids', []);
                                        }}
                                        onBlur={InstallationTouched => setFieldTouched('installation_ids', InstallationTouched)}
                                        closeMenuOnSelect={false}
                                        placeholder="Select Installations"
                                        value={values.installation_ids}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="barrier_ids" className="block mb-1 text-blue-900 font-semibold">Barrier(s)</label>
                                    <ReactSelect
                                        id="barrier_ids"
                                        name="barrier_ids"
                                        className="w-full md:w-1/2"
                                        classNamePrefix="react-select"
                                        options={barrierList}
                                        isMulti
                                        isSearchable
                                        components={makeAnimated()}
                                        onChange={BarrierSelected => {
                                            handleBarrierChange(BarrierSelected); 
                                            setFieldValue('barrier_ids', BarrierSelected);
                                            setFieldValue('sece_ids', []);
                                        }}
                                        onBlur={BarrierTouched => setFieldTouched('barrier_ids', BarrierTouched)}
                                        closeMenuOnSelect={false}
                                        placeholder="Select Barriers"
                                        value={values.barrier_ids}
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="sece_ids" className="block mb-1 text-blue-900 font-semibold">SECE(s)</label>
                                    <ReactSelect
                                        id="sece_ids"
                                        name="sece_ids"
                                        className="w-full md:w-1/2"
                                        classNamePrefix="react-select"
                                        options={seceList}
                                        isMulti
                                        isSearchable
                                        components={makeAnimated()}
                                        onChange={SeceSelected => setFieldValue('sece_ids', SeceSelected)}
                                        onBlur={SeceTouched => setFieldTouched('sece_ids', SeceTouched)}
                                        closeMenuOnSelect={false}
                                        placeholder="Select SECE"
                                        value={values.sece_ids}
                                    />
                                </div>
                            </div>
                        }

                        <div>
                            <label htmlFor="notice_desc" className="block mb-1 text-blue-900 font-semibold">Notice Description*</label>
                            <TextEditor
                                name="notice_desc"
                                id="notice_desc"
                                onChange={desc => setFieldValue('notice_desc', desc)}
                                onBlur={touched => setFieldTouched('notice_desc', touched)}
                                value={values.notice_desc}
                                wrapper="w-full md:w-1/2"
                            />
                            <FormError touched={touched.notice_desc} message={errors.notice_desc} />
                        </div>

                        <div>
                            <label htmlFor="notice_file" className="block mb-1 text-blue-900 font-semibold">Notice File</label>

                            {authContext.user && authContext.user.user_home_dir &&
                                <FileUpload
                                    onAttach={(files) => { setFieldValue('notice_file', JSON.stringify(files)) }}
                                    id='fileManager'
                                    buttonText='Attach Files'
                                    apiURL={REACT_APP_FILE_MANAGER_API}
                                    toolbarText='Attach'
                                    homeDir={`/${authContext.user.user_home_dir}/`}
                                    showDescription={true}
                                    handleFileDescription={handleNotAttachFilesDescription}
                                    tempFileList={tempNotAttachFileList}
                                    {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.notice_file) ? parseJSONSafely(values.notice_file) : [] })}
                                />
                            }
                            <FormError touched={touched.notice_file} message={errors.notice_file} />
                        </div>
                        <button type="submit" className="mt-3 button-red capitalize">{formMode} Notice</button>
                    </form>
                )}
            </Formik>
        </div>
    )
}

function formatInstallationData(installations) {
    let formatedList = [];
    if (installations && installations.length) {
        let newInstallationList = installations.filter(inst => inst.installation_archived !== true);
        newInstallationList.forEach(install => {
            let newInstall = {
                value : install._id,
                label: install.installation_title
            }
            formatedList.push(newInstall);
        });
    }
    return formatedList;
}

function formatBarrierData(barriers) {
    let formatedList = [];
    if (barriers && barriers.length) {
        let newBarrierList = barriers.filter(bar => bar.bar_archived !== true);
        newBarrierList.forEach(bar => {
            let newBarrier = {
                value : bar._id,
                label: bar.bar_title
            }
            formatedList.push(newBarrier);
        });
    }
    return formatedList;
}

function formatSeceData(seces) {
    let formatedList = [];
    if (seces && seces.length) {
        let newSeceList = seces.filter(sece => sece.sece_archived !== true);
        newSeceList.forEach(sece => {
            let newSece = {
                value: sece._id,
                label: sece.sece_title
            }
            formatedList.push(newSece);
        });
    }
    return formatedList;
}

function formatInstallEditData(formMode, installationOptions, installInitValues) {
    let newList = [];
    if (formMode === "edit" && installationOptions && installationOptions.length && installInitValues && installInitValues.length) {
        installInitValues.forEach(initInstall => {
            let install = installationOptions.find(install => install.value === initInstall.value);
            if (install) {
                newList.push(install);
            }
        });
    }
    return newList;
}

function formatBarrierEditData(formMode, barrierOptions, barrierInitValues) {
    let newList = [];
    if (formMode === "edit" && barrierOptions && barrierOptions.length && barrierInitValues && barrierInitValues.length) {
        barrierInitValues.forEach(initBar => {
            let barrier = barrierOptions.find(bar => bar.value === initBar.value);
            if (barrier) {
                newList.push(barrier);
            }
        });
    }
    return newList;
}

function formatSeceEditData(formMode, seceOptions, seceInitValues) {
    let newList = [];
    if (formMode === "edit" && seceOptions && seceOptions.length && seceInitValues && seceInitValues.length) {
        seceInitValues.forEach(initSece => {
            let sece = seceOptions.find(sece => sece.value === initSece.value);
            if (sece) {
                newList.push(sece);
            }
        });
    }
    return newList;
}


function formatBackToIdList(items) {
    let formatedList = [];
    if (items && items.length) {
        items.forEach(item => {
            let id = item.value;
            formatedList.push(id);
        });
    }
    return formatedList;
}

function formatSysVarDataAndUpdateStates(list, setNoticeTypeList, noticeTypeId) {
    let noticeTypeList = [];
    noticeTypeList = list.filter(sysVar => sysVar.sysvartype_id&&sysVar.sysvartype_id._id === noticeTypeId);
    setNoticeTypeList(noticeTypeList);
}

export default AddEditNotice;
