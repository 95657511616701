/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Line, Pie } from 'react-chartjs-2';
import { AuthContext } from '../context/authContext';
import { NavContext } from '../context/navContext';
import { DashboardContext } from "../context/dashboardContext";
import PageSpinner from "../components/global/pageSpinner";
import ErrorBoundary from './global/ErrorBoundary';
import { createUserTypeAndRoleObject, getBreadCrumbHeader } from "../utils/index";

const PENDING_USER_NOTICES_QUERY = gql`
query PendingUserNotices($userId: ID!){
  pendingUserNotices(userId: $userId) {
    _id
    not_type
    }
}`;

const DASHBOARD_SEARCH_QUERY = gql` 
query DashboardSearch($param: DashboardSearchInput!){ 
  dashboardSearch(param: $param){ 
    _id 
    verification_schedule{ 
      labels 
      datasets { 
        label 
        borderColor 
        backgroundColor 
        fill 
        data 
      } 
    }  
    findings{ 
      labels 
      datasets{ 
        label 
        data 
        backgroundColor 
      } 
    } 
    overall_schedule{ 
      status 
      color 
      backgroundColor 
      count 
    } 
    my_schedule{ 
      duration 
      count 
    } 
    my_findings{ 
      status 
      count 
    } 
    my_actions{ 
      priority 
      count 
    } 
    created_date 
  } 
}`;

function DashboardAlt(props) {
    const [HelpHidden, setHelpHidden] = useState(true);
    const [VerificationData, setVerificationData] = useState({});
    const [ReportData, setReportData] = useState({});
    const [ActivitySearchData, setActivitySearchData] = useState([]);
    const [myScheduleResult, setMyScheduleResult] = useState([]);
    const [FindingsData, setFindingsData] = useState({});
    const [myFindingsResult, setMyFindingsResult] = useState([]);
    const [myActionsResult, setMyActionsResult] = useState([]);
    const [showNotices, setShowNotices] = useState(undefined);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [drDnDutyholder, setDrDnDutyholder] = useState({});
    const [drDnInstallation, setDrDnInstallation] = useState({});
    const [drDnSece, setDrDnSece] = useState({});
    const [authUserId, setAuthUserId] = useState("");

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);
    const dashboardContext = useContext(DashboardContext);

    const history = useHistory();

    const [pendingUserNotices, { loading, error, data: pendingNoticesData }] = useLazyQuery(PENDING_USER_NOTICES_QUERY);
    const [dashboardSearch, {error: dashboardError, data: dashboardData }] = useLazyQuery(DASHBOARD_SEARCH_QUERY);

    useEffect(() => {
        console.log("drDnDutyholder>>>>", drDnDutyholder); //Temp Measure
        console.log("drDnSece>>>>", drDnSece);  //Temp Measure
    }, []);

    useEffect(() => {
        const { dutyHolder, installation, sece } = navContext;
        setDrDnDutyholder(dutyHolder);
        setDrDnInstallation(installation);
        setDrDnSece(sece);
    }, [navContext]);

    useEffect(() => {
        if (drDnInstallation) {
            //verficationScheduleStatsByMonths({ variables: { ...(drDnInstallation.id && { installId: drDnInstallation.id }), displayMonths: VS_GRAPH_DISPLAY_MONTHS }, errorPolicy: 'all' });
            //findingStatsGroupByLevel({ variables: { ...(drDnInstallation.id && { instId: drDnInstallation.id }) }, errorPolicy: 'all' });
            //reportStatsGroupByStatus({ variables: { ...(drDnInstallation.id && { instId: drDnInstallation.id }) }, errorPolicy: 'all' });
            //overallSchedule({ variables: { ...(drDnInstallation.id && { instId: drDnInstallation.id }) }, errorPolicy: 'all' });
        }
    }, [drDnInstallation]);

    useEffect(() => {
        if (authContext && authContext.user) {
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);

            const { _id: userId = "" } = authContext.user;
            const { userDhId = "", userType = "" } = result ?? {};
            if (userId !== "") {
                setAuthUserId(userId);
                pendingUserNotices({ variables: { userId: userId }, errorPolicy: 'all' });

                let dh_id = "";
                let dashboard_type = "";

                if (userType === "ABS") {
                    dh_id = drDnDutyholder.id || "";
                    dashboard_type = drDnInstallation.id ? "filter" : "default";
                } else if (userType === "Duty Holder") {
                    dh_id = userDhId;
                    dashboard_type = "filter";
                }


                let payload = {
                    user_id: userId,
                    dashboard_type: dashboard_type,
                    ...(dh_id && { dh_id: dh_id }),
                    ...(drDnInstallation.id && { installation_id: drDnInstallation.id })
                };
                dashboardSearch({ variables: { param: {...payload} }, errorPolicy: 'all' });
            }
        }

    }, [authContext, drDnDutyholder, drDnInstallation, drDnSece]);

    useEffect(() => {
        if (pendingNoticesData && pendingNoticesData.pendingUserNotices) {
            let checkNotices = checkForNotices(pendingNoticesData.pendingUserNotices);
            setShowNotices(checkNotices);
        }
    }, [pendingNoticesData]);

    useEffect(() => {
        if (dashboardData && dashboardData.dashboardSearch) {
            let dashboardList = dashboardData.dashboardSearch;
            const {
                verification_schedule = {},
                findings = {},
                overall_schedule = [],
                my_schedule = [],
                my_findings = [],
                my_actions = [] } = dashboardList[0] ? dashboardList[0] : {};

            const activityPieChartData = generateActivityPieChartData(overall_schedule);
            setVerificationData(verification_schedule);
            setFindingsData(findings);
            setReportData(activityPieChartData);
            setActivitySearchData(overall_schedule);
            setMyScheduleResult(my_schedule);
            setMyFindingsResult(my_findings);
            setMyActionsResult(my_actions);
        }
    }, [dashboardData]);

    useEffect(() => {
        if (showNotices) {
            const { _id: userId = "" } = authContext.user;
            if (showNotices && userId !== "") {
                history.push(`/notices/view/${userId}`);
            }
        }
    }, [showNotices]);

    const navigateTo = (location, state) => {
        history.push(location, state);
    }

    if (showNotices === undefined || showNotices || loading) {
        return (
            <PageSpinner />
        )
    }

    if (error || dashboardError) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="px-8 pb-8">

            <div className="pb-6 border-b-2 border-gray-200">

                <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Verification Manager Dashboard <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setHelpHidden(!HelpHidden)} /></h1>

                <p className={HelpHidden ? "hidden" : "mt-3"}>An overview of verification schedules, Findings and actions required.</p>

                <p className={HelpHidden && "hidden"}>Select a Duty Holder and / or Installation to filter the information on this page and linked pages.</p>

            </div>

            <h2 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h2>

            {getBreadCrumbHeader({
                userTypeAndRoleObject,
                drDnDutyholder,
                drDnInstallation,
                drDnSece,
                section: "",
                title: ""
            })
            }

            {/* {(userTypeAndRoleObject &&
                userTypeAndRoleObject.userType === "ABS" &&
                drDnDutyholder.id && drDnInstallation.id) && <h3 className="mb-6 font-bold text-sm text-red-900">Duty Holder:  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h3>}
            {(userTypeAndRoleObject &&
                userTypeAndRoleObject.userType === "Duty Holder" &&
                drDnInstallation.id) && <h3 className="mb-6 font-bold text-sm text-red-900"> Installation: {drDnInstallation.name}</h3>}
            {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h3 className="mb-6 font-bold text-sm text-red-900"> Duty Holder / Installation: All</h3>} */}



            {/* 6 COL GRID */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">

                {/* COL 1 - VERIFICATION SCHEDULE */}
                <div className="bg-gray-100">

                    <div className="flex flex-col h-full">

                        <div className="p-4">
                            <h3 className="mb-3 font-semibold text-center text-blue-900 uppercase">Verification Schedule</h3>

                            <div className="mb-3" style={{ minHeight: '250px' }}>
                                <ErrorBoundary>
                                    <Line
                                        data={VerificationData}
                                        options={{
                                            maintainAspectRatio: false,
                                            scales: {
                                                xAxes: [{
                                                    stacked: false,
                                                    ticks: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    },
                                                }],
                                                yAxes: [{
                                                    stacked: false,
                                                    ticks: {
                                                        fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                    },
                                                }]
                                            },
                                            legend: {
                                                labels: {
                                                    fontFamily: "Montserrat, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica Neue, Arial, Noto Sans, sans-serif"
                                                }
                                            },
                                        }}
                                    />
                                </ErrorBoundary>
                            </div>

                            <Link to="/statistics/verification-schedule-dash" className="text-blue-900 text-sm hover:underline">Filter by More Dates <FontAwesomeIcon icon={['fas', 'arrow-right']} className="mt-1 ml-2" /></Link>

                        </div>

                        <Link to="/verification/verification-register" className="button-red mt-auto justify-center">Schedule <FontAwesomeIcon icon={['fas', 'arrow-right']} className="mt-1 ml-2 text-white" /></Link>

                    </div>

                </div>
                {/* COL 1 - VERIFICATION SCHEDULE - END */}

                {/* COL 2 - REPORTS & FINDINGS */}
                <div className="bg-gray-100">

                    <div className="flex flex-col h-full">

                        <div className="p-4">
                            <h3 className="font-semibold text-center text-blue-900 uppercase">Activities &amp; Findings</h3>

                            <div className="mt-2 grid grid-cols-1 md:grid-cols-2 gap-2 xl:gap-12">
                                <div>
                                    <ErrorBoundary>
                                        <Pie
                                            data={ReportData}
                                            width={150}
                                            height={150}
                                            options={{
                                                title: { display: true, text: 'Activities', fontColor: '#002a4e' },
                                                legend: { display: false },
                                                'onClick': function (evt, item) {
                                                    let filterKey = item[0]._chart.data.labels[item[0]._index];
                                                    let location = {
                                                        pathname: `/reporting/activity-search`,
                                                        search: `category=current&completion-status=${mapToNewLabel(filterKey)}`
                                                    }
                                                    navigateTo(location);
                                                }
                                            }}
                                        />
                                    </ErrorBoundary>
                                </div>

                                <div>
                                    <ErrorBoundary>
                                        <Pie
                                            data={FindingsData}
                                            width={150}
                                            height={150}
                                            options={{
                                                title: { display: true, text: 'Findings', fontColor: '#002a4e' },
                                                legend: { display: false },
                                                'onClick': function (evt, item) {
                                                    let fndKey = mapToNewLabel(item[0]._chart.data.labels[item[0]._index]);
                                                    let location = {
                                                        pathname: `/reporting/findings`,
                                                        search: `level=${fndKey}&findingStatus=open&displayName=Findings By Level: ${fndKey}&isFromDashOrStatsPage=true`
                                                    }
                                                    navigateTo(location, {});
                                                }
                                            }}
                                        />
                                    </ErrorBoundary>
                                </div>
                            </div>

                            {/*
              <ul className="my-5 text-sm font-sans font-semibold text-blue-900 leading-2 uppercase">
                <li className="flex flex-row align-center mb-2">
                  <Link to="#" className="hover:underline">Report Search</Link>
                </li>
                <li className="flex flex-row align-center mb-2">
                  <Link to="#" className="hover:underline">Findings Search</Link>
                </li>
                <li className="flex flex-row align-center">
                  <Link to="#" className="hover:underline">Action List</Link>
                </li>
              </ul>
              */}

                        </div>

                        <div className="flex mt-auto">
                            <Link to={{ pathname: `/reporting/activity-search`,  search: "?category=original" }}
                                className="button-red w-1/2 mt-auto justify-center">Activity Search <FontAwesomeIcon icon={['fas', 'arrow-right']} className="mt-1 ml-2 text-white" />
                            </Link>
                            <Link to={{ pathname: `/reporting/findings`, state: {} }}
                                className="button-red w-1/2 mt-auto justify-center"
                                onClick={() => dashboardContext.updateFindingSearchParam({
                                    displayName: "Findings Search",
                                    isFromDashOrStatsPage: true
                                })}
                            >Finding Search <FontAwesomeIcon icon={['fas', 'arrow-right']} className="mt-1 ml-2 text-white" />
                            </Link>
                        </div>

                    </div>

                </div>
                {/* COL 2 - REPORTS & FINDINGS - END */}

                {/* COL 3 - OVERALL SCHEDULE */}
                <div className="bg-gray-100">
                    <div className="flex flex-col h-full">
                        <div className="p-4">
                            <h3 className="font-semibold text-center text-blue-900 uppercase">Overall Schedule</h3>
                            <ul className="my-5 text-sm font-sans font-semibold text-blue-900 leading-10 uppercase">
                                {ActivitySearchData.map((result, index) =>
                                    <li className="flex flex-row align-center mb-2" key={index}>
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`text-4xl ${result.color} mr-2`} />
                                        <Link to={{
                                            pathname: `/reporting/activity-search`,
                                            search: `category=current&completion-status=${mapToNewLabel(result.status)}`
                                        }}
                                            className="hover:underline">{`${result.count} ${result.status}`}
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                        <Link to={{ pathname: `/reporting/activity-search`, search:  "?category=original" }}
                            className="button-red mt-auto justify-center">Overall Activity Search
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} className="mt-1 ml-2 text-white" />
                        </Link>
                    </div>
                </div>
                {/* COL 3 - OVERALL SCHEDULE - END */}

                {/* COL 4 - MY SCHEDULE */}
                <div className="bg-gray-100">
                    <div className="flex flex-col h-full">
                        <div className="p-4">
                            <h3 className="font-semibold text-center text-blue-900 uppercase">My Schedule</h3>
                            <ul className="my-5 text-sm font-sans font-semibold text-blue-900 leading-10 uppercase">
                                {myScheduleResult.map((result, index) =>
                                    <li className="flex flex-row align-center mb-2" key={index}>
                                        <FontAwesomeIcon icon={['fas', 'circle']} className={`text-4xl ${["text-red-900", "text-yellow-900", "text-green-900"][index]} mr-2`} />
                                        <Link to={{
                                            pathname: `/reporting/activity-search`,
                                            search:`?category=my-schedule&completion-status=${mapToNewLabelAlt(result.duration)}`
                                        }}
                                            className="hover:underline">{`${result.count} ${result.duration}`}</Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                        
                        <Link to={{
                            pathname: `/reporting/activity-search`,
                            search: `?category=my-schedule`
                        }}
                            className="button-red mt-auto justify-center">Schedule <FontAwesomeIcon icon={['fas', 'arrow-right']} className="mt-1 ml-2 text-white" />
                        </Link>

                        {/* <Link to={{
                            pathname: `/verification`,
                            state: { activityFilter: "na", type: "user" }
                        }}
                            className="button-red mt-auto justify-center">Schedule <FontAwesomeIcon icon={['fas', 'arrow-right']} className="mt-1 ml-2 text-white" />
                        </Link> */}

                    </div>

                </div>
                {/* COL 4 - MY SCHEDULE - END */}

                {/* COL 5 - MY FINDINGS */}
                <div className="bg-gray-100">

                    <div className="flex flex-col h-full">

                        <div className="p-4">
                            <h3 className="font-semibold text-center text-blue-900 uppercase">My Findings</h3>

                            <ul className="my-5 text-sm font-sans font-semibold text-blue-900 leading-10 uppercase">
                                {
                                    myFindingsResult.length > 0 ? myFindingsResult.map((result, index) =>
                                        <li className="flex flex-row align-center mb-2" key={index}>
                                            <FontAwesomeIcon icon={['fas', 'circle']} className={`text-4xl ${["text-red-900", "text-yellow-900", "text-green-900"][index]} mr-2`} />
                                            <Link to={{
                                                pathname: `/reporting/findings`,
                                                search: `?completionStatus=${mapToNewLabel(result.status)}&findingStatus=open&displayName=My Findings : ${result.status}&userId=${authUserId}&isFromDashOrStatsPage=true&absDhResponsible=true`
                                            }}
                                                className="hover:underline">{`${result.count} ${result.status}`}
                                            </Link>
                                        </li>
                                    ) : "No Data to Display"
                                }
                            </ul>

                        </div>
                        <Link
                            to={{
                                pathname: `/reporting/findings`,
                                search: `?findingStatus=open&displayName=My Findings&userId=${authUserId}&isFromDashOrStatsPage=true&absDhResponsible=true`
                            }}
                            className="button-red mt-auto justify-center">My Findings <FontAwesomeIcon icon={['fas', 'arrow-right']} className="mt-1 ml-2 text-white" />
                        </Link>
                    </div>
                </div>
                {/* COL 5 - MY FINDINGS- END */}

                {/* COL 6 - My Actions */}
                <div className="bg-gray-100">
                    <div className="flex flex-col h-full">
                        <div className="p-4">
                            <h3 className="font-semibold text-center text-blue-900 uppercase">My Actions</h3>
                            <ul className="my-5 text-sm font-sans font-semibold text-blue-900 leading-10 uppercase">
                                {
                                    myActionsResult.length > 0 ? myActionsResult.map((result, index) =>
                                        <li className="flex flex-row align-center mb-2" key={index}>
                                            <FontAwesomeIcon icon={['fas', 'circle']} className={`text-4xl ${["text-red-900", "text-yellow-900", "text-green-900"][index]} mr-2`} />
                                            <Link
                                                to={{
                                                    pathname: `/reporting/my-action-list`,
                                                    state: {},
                                                    search: `?userId=${authUserId}&priority=${result.priority}`
                                                }}
                                                className="hover:underline">{`${result.count} ${result.priority} Priority`}</Link>
                                        </li>
                                    )
                                        :
                                        "No Data to Display"
                                }
                            </ul>
                        </div>
                        <Link to={{
                            pathname: `/reporting/action-list`,
                            state: {},
                            search: `?userId=${authUserId}`
                        }}
                            className="button-red mt-auto justify-center"
                        >Action List <FontAwesomeIcon icon={['fas', 'arrow-right']} className="mt-1 ml-2 text-white" />
                        </Link>
                    </div>
                </div>
                {/* COL 6 - MY PLAN - END */}

            </div>
            {/* 6 COL GRID END */}

            <div className="sm:flex mt-8">

                <div className="w-20 mb-3 sm:mb-0">
                    <FontAwesomeIcon icon={['fas', 'balance-scale']} className="mt-1 text-5xl text-gray-500" />
                </div>

                <div className="w-full">
                    <p className="text-sm">The information contained within this application can assist Duty Holders with meeting the requirements of The Offshore Installations (Offshore Safety Directive) (Safety Case etc) Regulations 2015, Directive 2013/30/EU of the European Parliament and of the Council on safety of offshore oil and gas operations and amending Directive 2004/35/EC and / or similar legislative regimes.</p>
                </div>

            </div>

            <div className="sm:flex mt-8">

                <div className="w-20 mb-3 sm:mb-0">
                    <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="mt-1 text-5xl text-gray-500" />
                </div>

                <div className="w-full">
                    <p className="text-sm">Notifications relating to items which require review are automatically sent by email to the relevant user or users.  In addition, notifications can be viewed on the notifications page by clicking on the warning triangle symbol, top right-hand side of any page of the application.</p>
                </div>

            </div>

        </div>
    )
}

function generateActivityPieChartData(list) {
    let dataPack = { labels: [], datasets: [{ label: 0, data: [], backgroundColor: [] }] };
    if (list) {
        let labels = [];
        let data = [];
        let backgroundColor = [];
        for (let index = 0; index < list.length; index++) {
            const element = list[index];
            labels.push(element.status);
            data.push(element.count);
            backgroundColor.push(element.backgroundColor);
        }
        dataPack = {
            labels: labels,
            datasets: [
                {
                    label: 0,
                    data: data,
                    backgroundColor: backgroundColor,
                }
            ]
        };
    }
    return dataPack;
}

function checkForNotices(pendingNotices) {
    let checkNotices = false;
    if (pendingNotices) {
        checkNotices = (pendingNotices.length > 0);
    }
    return checkNotices;
}

function mapToNewLabel(label) {
    let newLabel = "";
    if (label && label !== "") {
        switch (label) {
            case "Immediate":
                newLabel = "rejected"
                break;
            case "Action Tasks":
                newLabel = "open"
                break;
            case "Satisfactory":
                newLabel = "completed"
                break;
            case "Overdue":
                newLabel = "overdue"
                break;
            case "Within 30 Days":
            case "Due Within 30 Days":
                newLabel = "within30Days"
                break;
            case "Due Within More Than 30 Days":
                newLabel = "withinMoreThan30Days"
                break;
            case "Within 90 Days":
                newLabel = "within90Days"
                break;
            case "Overdue / Missed":
                newLabel = "overdue-or-missed"
                break;
            case "Scheduled":
                newLabel = "scheduled"
                break;
            case "Completed":
                newLabel = "completed"
                break;
            case "Not Commenced":
                newLabel = "not-commenced"
                break;
            case "Commenced":
                newLabel = "commenced"
                break;
            case "High":
                newLabel = "high"
                break;
            case "Medium":
                newLabel = "medium"
                break;
            case "Low":
                newLabel = "low"
                break;
            default:
                newLabel = "na"
                break;
        }
    }
    return newLabel;
}

function mapToNewLabelAlt(label) {
    let newLabel = "";
    if (label && label !== "") {
      switch (label) {
        case "Immediate":
          newLabel = "rejected"
          break;
        case "Action Tasks":
          newLabel = "open"
          break;
        case "Satisfactory":
          newLabel = "completed"
          break;
        case "Overdue":
          newLabel = "overdue"
          break;
        case "Within 30 Days":
        case "Due Within 30 Days":
          newLabel = "within-30-days"
          break;
        case "Due Within More Than 30 Days":
          newLabel = "within-more-than-30-days"
          break;
        case "Within 90 Days":
          newLabel = "within-90-days"
          break;
        case "Overdue / Missed":
          newLabel = "overdue-or-missed"
          break;
        case "Scheduled":
          newLabel = "scheduled"
          break;
        case "Completed":
          newLabel = "completed"
          break;
        case "Not Commenced":
          newLabel = "not-commenced"
          break;
        case "Commenced":
          newLabel = "commenced"
          break;
        case "High":
          newLabel = "high"
          break;
        case "Medium":
          newLabel = "medium"
          break;
        case "Low":
          newLabel = "low"
          break;
        default:
          newLabel = "na"
          break;
      }
    }
    return newLabel;
  }

export default DashboardAlt;