/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';
import {AuthContext} from "../../context/authContext";
import {parseJSONSafely, capitaliseFirstCase, createUserTypeAndRoleObject} from "../../utils";
import {NavContext} from '../../context/navContext';

const {REACT_APP_FILE_MANAGER_API} = process.env;

const MAH_QUERY = gql`
query MajorAccidentHazard($mahId: ID!){
  majorAccidentHazard(_id: $mahId) {
    dh_id{
      _id
      dh_name
    }
    ag_id
    installation_id
    mah_client_ref
    mah_title
    mah_notes
    mah_attachment
    mah_attmnt_dest_path
    mah_status
  }
}`;

const INSTALLATION_QUERY = gql`
    query Installation($installationId: ID!){
        installation(_id: $installationId) {
            installation_code
            installation_title 
        }
    }`;

const ViewMAH = (props) => {
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const [majorAccidentHazardObject, setMajorAccidentHazardObject] = useState([]);
  const [installationObject, setInstallationObject] = useState([]);
  const [mahAtachments, setMahAtachments] = useState([]);
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

  const { id, assetGroupId, installationId, seceId, mahId } = useParams();
  const { location: { state = "" } } = props ? props : {};

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const history = useHistory();

  const [majorAccidentHazard, { loading, error, data }] = useLazyQuery(MAH_QUERY);
  const [installation, { loading: instLoading, error: instError, data: instData }] = useLazyQuery(INSTALLATION_QUERY);

  useEffect(() => {
    if (mahId) {
      majorAccidentHazard({ variables: { mahId: mahId }, errorPolicy: 'all' });
      installation({ variables: { installationId: installationId }, errorPolicy: 'all' });
    }
  }, []);
  
  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (isInitialPageLoad) {
      setIsInitialPageLoad(false);
      return;
    }
    history.push('/');
  }, [navContext]);

  useEffect(() => {
    if (data && data.majorAccidentHazard) {
      let mahData = data.majorAccidentHazard;
      let attachment = mahData.mah_attachment && mahData.mah_attachment !== "" ? mahData.mah_attachment : "[]";
      let attachmentList = parseJSONSafely(attachment);
      setMajorAccidentHazardObject(mahData);
      setMahAtachments(attachmentList);
    }
  }, [data]);

  useEffect(() => {
    if (instData && instData.installation) {
      setInstallationObject(instData.installation);
    }
  }, [instData]);

  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 100, 40, 24],
    header: function (currentPage, pageCount) {

      var headerObj;
      if (currentPage % 2) {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['75%', '25%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: majorAccidentHazardObject.dh_id && majorAccidentHazardObject.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: "MAJOR ACCIDENT HAZARD DETAILS", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      } else {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['25%', '75%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: majorAccidentHazardObject.dh_id && majorAccidentHazardObject.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: "MAJOR ACCIDENT HAZARD DETAILS", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      }

      return headerObj;
    },
    footer: function (currentPage, pageCount) {

      var footerObj;

      if (currentPage % 2) {
        footerObj = {
          table: {
            widths: [30, '*', 100, 28],
            body: [
              [
                { text: ' ', border: [false, false, false, false] },
                { text: 'MAJOR ACCIDENT HAZARD DETAILS', style: 'footerText', border: [false, false, false, false] },
                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                { text: ' ', border: [true, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      } else {
        footerObj = {
          table: {
            widths: [30, 100, '*', 28],
            body: [
              [
                { text: ' ', border: [false, false, true, false] },
                { text: currentPage.toString(), border: [true, false, false, false] },
                { text: `© ${new Date().getFullYear()} American Bureau of Shipping. All rights reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                { text: ' ', border: [false, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      }

      return footerObj;

    },
    info: {
      title: majorAccidentHazardObject.dh_id && majorAccidentHazardObject.dh_id.dh_name + ' - ' + majorAccidentHazardObject.mah_title,
    },
    content: [

      { text:majorAccidentHazardObject.mah_client_ref + " - " + majorAccidentHazardObject.mah_title, style: 'title' },
      {
        table: {
          widths: ['30%', '70%'],
          headerRows: 0,
          body: [
            [{ text: 'Installation', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{ text: installationObject.installation_title, alignment: 'left', colSpan: 2 }, {}],
            [{ text: 'Attachments', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{
              text: [
                {
                  text: mahAtachments.length ? mahAtachments.map(att => {
                    return (
                      "File Name: \n" +
                      att.name + '\n' +
                      "File Description: \n" +
                      att.description + '\n \n'
                    )
                  }) : "No Attachments to Display"
                },
              ], colSpan: 2
            }, {}],
            [{ text: 'Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{ text: majorAccidentHazardObject.mah_status, alignment: 'left', colSpan: 2 }, {}],
          ]
        },
        style: 'standardTable',
      },

    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      title: {
        marginTop: 10,
        font: 'Montserrat',
        fontSize: 14,
        bold: true,
        color: '#002a4e'
      },
      redTitle: {
        marginBottom: 10,
        font: 'Montserrat',
        color: '#b40c1e'
      },
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1.2
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }

  const pdfTab = () => {
    pdfMake.createPdf(docDefinition).open();
  }

  if (loading || instLoading) {
    return (
      <PageSpinner />
    )
  }

  if (error || instError) {
    return (
      <span>Something went wrong retrieving the Major Accident Hazard data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">MAH Details</h1>
      </div>

      {displayBackLink(state, id, assetGroupId, installationId, seceId)}

      <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      <div className="w-full lg:w-1/2">

        <div className="my-6">
          <h2 className="font-bold text-2xl text-blue-900">{majorAccidentHazardObject.mah_client_ref} - {majorAccidentHazardObject.mah_title}</h2>
        </div>

        <table className="w-full mb-5 table-auto">
          <tbody>
            <tr>
              <th className="p-3 text-left bg-white border">Installation</th>
            </tr>
            <tr>
              <td className="p-3 border">{installationObject.installation_title}</td>
            </tr>
            {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" && 
              <tr>
                <th className="p-3 text-left bg-white border">Notes</th>
              </tr>
            }
            {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" && 
              <tr>
                <td className="p-3 border">{majorAccidentHazardObject.mah_notes !=="" ? majorAccidentHazardObject.mah_notes : "N/A"}</td>
              </tr>
            }
            <tr>
              <th className="p-3 text-left bg-white border">Attachment</th>
            </tr>
            <tr>
              <td className="p-3 border">
                {createFileLinks(mahAtachments, majorAccidentHazardObject)}
              </td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Status</th>
            </tr>
            <tr>
              <td className="p-3 border">{capitaliseFirstCase(majorAccidentHazardObject.mah_status)}</td>
            </tr>
          </tbody>
        </table>

        {displayBackLink(state, id, assetGroupId, installationId, seceId)}

        <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      </div>

    </div>
  )

}

function displayBackLink(state, id, assetGroupId, installationId, seceId) {
  if (state && state.origin && state.origin === "notifications") {
    return (
      <Link to={{
        pathname: `/notifications`
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
  if(assetGroupId !== undefined && installationId !== undefined){
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mahs` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  };
}

function createFileLinks(attachments, majorAccidentHazardObject) {
  let linkList = "";
  let fileDestPath = majorAccidentHazardObject.mah_attmnt_dest_path ? majorAccidentHazardObject.mah_attmnt_dest_path : "";
  if (attachments && attachments.length && fileDestPath !== "") {
    let fullPath = `${REACT_APP_FILE_MANAGER_API}/download-file?path=/${fileDestPath}/`;
    let numberOfAttachments = attachments.length;

    let newAttachments = <React.Fragment>{attachments.map((att, index) =>
      <React.Fragment key={index}>
        <div className="text-blue-900 font-sans text-sm font-bold">File Name:</div>
        <a href={fullPath + att.name} className="mb-1 hover:underline" key={index} target={"_blank"} rel="noopener noreferrer" download={true}>
          {att.name}{((index + 1) < numberOfAttachments) ? <br /> : ""}
        </a>
        <div className="text-blue-900 font-sans text-sm font-bold">File Description:</div>
        <div>
          {att.description}
        </div>
        {((index + 1) < numberOfAttachments) ? <br /> : ""}
      </React.Fragment>
    )}
    </React.Fragment>
    linkList = newAttachments;
  } else {
    linkList = <Link to="#" className="hover:underline">No File Attachment</Link>
  }

  return linkList;
}

export default ViewMAH;
