/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import { useHistory } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { omit } from 'lodash';
import PageSpinner from '../global/pageSpinner';
import { AuthContext } from "../../context/authContext";
import { parseJSONSafely, createUserTypeAndRoleObject } from '../../utils';

const Validation = Yup.object().shape({
    action_notifications: Yup.string().label("action_notifications").required("Action Notifications is required"),
    time_notifications: Yup.string().label("time_notifications").required("Time Notifications is required"),
});

const USER_BY_ID_QUERY = gql`
query User($userId:ID!){
    user(_id:$userId){
        _id
        user_type {
            _id
            user_type_display_name
        }
        user_duty_holder{
            _id
        }
        user_fname
        user_lname
    }
}`;

const INSTALLATIONS_QUERY = gql`
query {
  installations {
      _id
      dh_id{
        _id
        dh_code
        dh_name
        dh_status
        dh_archived
      }
      installation_code
      installation_title
      installation_archived
      installation_status
  }
}`;

const SECES_BY_DUTYHOLDER_ID_QUERY = gql`
query SecesByDutyHolderId($dhId: ID){
  secesByDutyHolderId(dhId: $dhId) {
       _id
       sece_title
       sece_archived
       sece_status
       installation_id{
          _id
          installation_code
          installation_title
          installation_archived
          installation_status
       }
    }
}`;

const CREATE_ACCESS_CONTROL = gql`
mutation CreateAccessControl($ac: CreateAccessControl!){
    createAccessControl(ac: $ac) {
       _id
       success
       status_code
       message
    }
}`;

const UPDATE_ACCESS_CONTROL = gql`
mutation UpdateAccessControl($accId: String!, $ac: UpdateAccessControlInput!){
    updateAccessControl(_id: $accId, ac: $ac) {
       _id
       success
       status_code
       message
    }
}`;

const ACCESS_CONTROL_BY_USER_ID_QUERY = gql`
query AccessControlByUserId($userId: String!){
    accessControlByUserId(userId: $userId) {
       _id
       ac_user_id{
           _id
       }
       ac_user_type
       ac_action_notification
       ac_time_notification
       ac_duty_holders
       ac_installations
       ac_restricted_dh_ids{
        _id
       }
       ac_restricted_instl_ids{
        _id
       }
       ac_restricted_sece_ids{
        _id
       }
    }
}`;

const selectData = [
    { id: 1, name: "Yes" },
    { id: 2, name: "No" }
]

function UserAccessControl(props) {
    const authContext = useContext(AuthContext);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
    const history = useHistory();

    const [selectList, setSelectData] = useState([]);
    const [userObject, setUserObject] = useState({});
    const [savedAccessObject, setSavedAccessObject] = useState(null);
    const [formInitValues, setFormInitValues] = useState({});

    const { formMode = "edit" } = props.location.state ? props.location.state : {};
    const { userId = "" } = props.match.params ? props.match.params : {};

    const [user, { loading: userLoading, error: userError, data: userData }] = useLazyQuery(USER_BY_ID_QUERY);
    const [installations, { loading: instllLoading, error: instllError, data: instsData }] = useLazyQuery(INSTALLATIONS_QUERY);
    const [secesByDutyHolderId, { loading: seceLoading, error: seceError, data: secesData }] = useLazyQuery(SECES_BY_DUTYHOLDER_ID_QUERY);
    const [accessControlByUserId, { loading: accessLoading, error: accessError, data: accControlData }] = useLazyQuery(ACCESS_CONTROL_BY_USER_ID_QUERY);
    const [createAccessControl, { data: createAccData }] = useMutation(CREATE_ACCESS_CONTROL);
    const [updateAccessControl, { data: updateAccData }] = useMutation(UPDATE_ACCESS_CONTROL);

    useEffect(() => {
        if (authContext && authContext.user) {
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
            history.push('/access-permissions');
        }
    }, [userTypeAndRoleObject]);

    useEffect(() => {
        if (userId !== "") {
            user({ variables: { userId: userId }, errorPolicy: 'all' });
        }
        setSelectData(selectData);
    }, [])

    useEffect(() => {
        if (userData && userData.user) {
            setUserObject(userData.user);
            if (userId !== "") {
                accessControlByUserId({ variables: { userId: userId }, errorPolicy: 'all' });
            }
        }
    }, [userData])

    useEffect(() => {
        if (accControlData && accControlData.accessControlByUserId) {       

            if (accControlData.accessControlByUserId.length > 0) {
                let savedAccess = accControlData.accessControlByUserId[0];

                let restrictedDhIds = savedAccess.ac_restricted_dh_ids ? savedAccess.ac_restricted_dh_ids.map(dh => dh._id) : [];
                let restrictedInstlIds = savedAccess.ac_restricted_instl_ids ? savedAccess.ac_restricted_instl_ids.map(ins => ins._id) : [];
                let restrictedSeceIds = savedAccess.ac_restricted_sece_ids ? savedAccess.ac_restricted_sece_ids.map(sece => sece._id) : [];

                let formatedAccess = {
                    id: savedAccess._id,
                    user_id: savedAccess.ac_user_id._id,
                    user_type: savedAccess.ac_user_type,
                    action_notifications: savedAccess.ac_action_notification,
                    time_notifications: savedAccess.ac_time_notification,
                    restricted_dh_ids: restrictedDhIds,
                    restricted_instl_ids: restrictedInstlIds,
                    restricted_sece_ids: restrictedSeceIds,
                }

                if (savedAccess.ac_user_type === "ABS") {
                    let acDutyHolders = savedAccess.ac_duty_holders && savedAccess.ac_duty_holders !== "" ? savedAccess.ac_duty_holders : "[]";
                    formatedAccess.duty_holders = parseJSONSafely(acDutyHolders);
                }
                if (savedAccess.ac_user_type === "Duty Holder") {
                    let acInstallations = savedAccess.ac_installations && savedAccess.ac_installations !== "" ? savedAccess.ac_installations : "[]";
                    formatedAccess.installations = parseJSONSafely(acInstallations);
                }

                setSavedAccessObject(formatedAccess);
                setFormInitValues(formatedAccess);
            } else {
                let formatedAccess = {
                    id: "",
                    user_id: "",
                    user_type: "",
                    action_notifications: "",
                    time_notifications: "",
                    restricted_dh_ids: [],
                    restricted_instl_ids: [],
                    restricted_sece_ids: [],
                }
                setSavedAccessObject(formatedAccess);
                setFormInitValues(formatedAccess);
            }
        }
    }, [accControlData])

    useEffect(() => {
        if (savedAccessObject) {
            let user = userObject;
            const { user_type_display_name = "" } = user.user_type ? user.user_type : {};
            if (user_type_display_name === "ABS") {
                installations({ variables: {}, errorPolicy: 'all' });
            }
            if (user_type_display_name === "Duty Holder") {
                const { _id = "" } = user.user_duty_holder ? user.user_duty_holder : {};
                if (_id !== "") {
                    secesByDutyHolderId({ variables: { dhId: _id }, errorPolicy: 'all' });
                }
            }
        }
    }, [savedAccessObject])

    useEffect(() => {
        if (instsData && instsData.installations) {
            let accessData = createAbsAccessData(instsData.installations, userObject, savedAccessObject);
            setFormInitValues(accessData);
        }
    }, [instsData])

    useEffect(() => {
        if (secesData && secesData.secesByDutyHolderId) {
            let accessData = createDhAccessData(secesData.secesByDutyHolderId, userObject, savedAccessObject);
            setFormInitValues(accessData);
        }
    }, [secesData])

    useEffect(() => {
        if (createAccData && createAccData.createAccessControl) {
            const {success, message} = createAccData.createAccessControl;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push('/users');
            }
        }
    }, [createAccData])

    useEffect(() => {
        if (updateAccData && updateAccData.updateAccessControl) {
            const {success, message} = updateAccData.updateAccessControl;
            if (!success) {
                alert(message);
            }
            if (success) {
                history.push('/users');
            }
        }
    }, [updateAccData])

    const handleDutyHolderChange = (event, dutyHolder) => {
        let checked = event.target.checked;
        let dhId = dutyHolder.id;
        let access = formInitValues;
        let dutyHolderList = access.duty_holders;
        let tempDutyHolder = dutyHolderList.find(dt => dt.id === dhId);
        let tempInstallations = tempDutyHolder.installations;
        tempDutyHolder.access = checked;
        tempInstallations.map(inst => inst.access = checked);
        setFormInitValues({ ...access });
    }

    const handleInstallationChange = (event, dhId, instId) => {
        let checked = event.target.checked;
        let access = formInitValues;
        let dutyHolderList = access.duty_holders;
        let tempDutyHolder = dutyHolderList.find(dt => dt.id === dhId);
        let tempInstallList = tempDutyHolder.installations;
        let tempInstall = tempInstallList.find(inst => inst.id === instId);
        tempInstall.access = checked;
        setFormInitValues({ ...access });
    }

    const handleSeceInstChange = (event, install) => {
        let checked = event.target.checked;
        let instId = install.id;
        let access = formInitValues;
        let instList = access.installations;
        let tempInstll = instList.find(inst => inst.id === instId);
        let tempSeceList = tempInstll.seces;
        tempInstll.access = checked;
        tempSeceList.map(sece => sece.access = checked);
        setFormInitValues({ ...access });
    }

    const handleSeceChange = (event, instId, seceId) => {
        let checked = event.target.checked;
        let access = formInitValues;
        let instList = access.installations;
        let tempInstll = instList.find(inst => inst.id === instId);
        let tempseceList = tempInstll.seces;
        let tempSece = tempseceList.find(sece => sece.id === seceId);
        tempSece.access = checked;
        setFormInitValues({ ...access });
    }

    if (userLoading || instllLoading || seceLoading || accessLoading) {
        return (
            <PageSpinner />
        )
    }

    if (userError || instllError || seceError || accessError) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} Access Control for {userObject.user_fname + " " + userObject.user_lname}</h1>
            </div>

            <Formik
                enableReinitialize
                initialValues={omit(formInitValues, '__typename')}
                validationSchema={Validation}
                onSubmit={(values, actions) => {
                    const { _id: authUserId = "" } = authContext.user;

                    if (savedAccessObject && formMode === 'edit') {

                        let newAccessControl = {
                            ac_user_id: values.user_id,
                            ac_user_type: values.user_type,
                            ac_action_notification: parseInt(values.action_notifications),
                            ac_time_notification: parseInt(values.time_notifications),
                            ac_added_date: new Date(),
                            ac_added_id: authUserId
                        }

                        if (values.user_type === "ABS") {
                            let restrictionData = formatABSRestrictedData(values.duty_holders);
                            newAccessControl.ac_restricted_dh_ids = restrictionData.dhIdList;
                            newAccessControl.ac_restricted_instl_ids = restrictionData.instlIdList;
                            newAccessControl.ac_duty_holders = "";
                        }

                        if (values.user_type === "Duty Holder") {
                            let restrictedData = formatClientRestrictedData(values.installations);
                            newAccessControl.ac_restricted_instl_ids = restrictedData.instlIdList;
                            newAccessControl.ac_restricted_sece_ids = restrictedData.seceIdList;
                            newAccessControl.ac_installations = JSON.stringify(values.installations);
                        }

                        if (savedAccessObject.user_id !== "" && values.id !== "") {
                            updateAccessControl({ variables: { accId: values.id, ac: newAccessControl } });
                        } else {
                            createAccessControl({ variables: { ac: newAccessControl } });
                        }

                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    handleSubmit,
                    setFieldTouched,
                    isSubmitting
                }) => (
                    <form onSubmit={handleSubmit}>

                        {/*
                        <div className="my-4">
                            <Link to={dhId && dhId !== "" ? `/dutyHolder/${dhId}/users` : "/users"} className="button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link>
                        </div> */}

                        <div className='mt-6'>
                            <label htmlFor="action_notifications" className="block mb-1 text-blue-900 font-semibold">Action Notifications*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="action_notifications"
                                id="action_notifications"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.action_notifications}
                            >
                                <option>Select</option>
                                {selectList.map(s =>
                                    <option value={s.id} key={s.id}>{s.name}</option>
                                )}
                            </select>
                            <FormError touched={touched.action_notifications} message={errors.action_notifications} />
                        </div>

                        <div>
                            <label htmlFor="time_notifications" className="block mb-1 text-blue-900 font-semibold">Time Notifications*</label>
                            <select
                                className="form-control block w-full md:w-1/2"
                                name="time_notifications"
                                id="time_notifications"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.time_notifications}
                            >
                                <option>Select</option>
                                {selectList.map(s =>
                                    <option value={s.id} key={s.id}>{s.name}</option>
                                )}
                            </select>
                            <FormError touched={touched.time_notifications} message={errors.time_notifications} />
                        </div>

                        {values.user_type === "ABS" ?
                            (
                                <>
                                    <h3 className="block mb-1 text-blue-900 font-semibold">Dutyholder &amp; Installation Access</h3>

                                    <table className="w-full md:w-1/2 mb-5 table-auto">
                                        <tbody>
                                            <tr>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Dutyholder / Installation</th>
                                                <th className="p-3 text-right text-blue-900 font-semibold border">Access (N/Y)</th>
                                            </tr>
                                            {values.duty_holders && values.duty_holders.length ? values.duty_holders.map(dh =>
                                                <React.Fragment key={dh.id}>
                                                    <tr className="bg-blue-900" >
                                                        <td className="p-3 text-white">{dh.name}</td>
                                                        <td className="p-3 text-right">
                                                            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                                <input type="checkbox" name="dutyholder1" id={"dh-" + dh.id}
                                                                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-900 appearance-none cursor-pointer"
                                                                    checked={dh.access}
                                                                    onChange={(event) => { handleDutyHolderChange(event, dh) }}
                                                                />
                                                                <label htmlFor={"dh-" + dh.id} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-900 cursor-pointer"></label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {dh.installations.map(inst =>
                                                        <tr className="bg-gray-100 border-r border-l" key={inst.id}>
                                                            <td className="p-3 text-left text-blue-900">{inst.name}</td>
                                                            <td className="p-3 text-right text-blue-900">
                                                                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                                    <input type="checkbox" name="dh1inst1" id={"inst-" + inst.id}
                                                                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-900 appearance-none cursor-pointer"
                                                                        checked={inst.access}
                                                                        onChange={(event) => { handleInstallationChange(event, dh.id, inst.id) }}
                                                                    />
                                                                    <label htmlFor={"inst-" + inst.id} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-900 cursor-pointer"></label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            ) : noDataNotice()}
                                        </tbody>
                                    </table>
                                </>
                            ) :
                            (
                                <>
                                    <h3 className="block mb-1 text-blue-900 font-semibold">Installation &amp; SECE Access</h3>

                                    <table className="w-full md:w-1/2 mb-5 table-auto">
                                        <tbody>
                                            <tr>
                                                <th className="p-3 text-left text-blue-900 font-semibold border">Installation / SECE</th>
                                                <th className="p-3 text-right text-blue-900 font-semibold border">Access (Y/N)</th>
                                            </tr>
                                            {values.installations && values.installations.length ? values.installations.map(inst =>
                                                <React.Fragment key={inst.id}>
                                                    <tr className="bg-blue-900" >
                                                        <td className="p-3 text-white">{inst.name}</td>
                                                        <td className="p-3 text-right">
                                                            <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                                <input type="checkbox" name="dutyholder1" id={"inst-" + inst.id}
                                                                    className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-900 appearance-none cursor-pointer"
                                                                    checked={inst.access}
                                                                    onChange={(event) => { handleSeceInstChange(event, inst) }}
                                                                />
                                                                <label htmlFor={"inst-" + inst.id} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-900 cursor-pointer"></label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    {inst.seces.map(sece =>
                                                        <tr className="bg-gray-100 border-r border-l" key={sece.id}>
                                                            <td className="p-3 text-left text-blue-900">{sece.name}</td>
                                                            <td className="p-3 text-right text-blue-900">
                                                                <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                                                    <input type="checkbox" name="dh1inst1" id={"sec-" + sece.id}
                                                                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-900 appearance-none cursor-pointer"
                                                                        checked={sece.access}
                                                                        onChange={(event) => { handleSeceChange(event, inst.id, sece.id) }}

                                                                    />
                                                                    <label htmlFor={"sec-" + sece.id} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-900 cursor-pointer"></label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            ) : noDataNotice()}
                                        </tbody>
                                    </table>
                                </>
                            )
                        }

                        <button type="submit" className="mt-3 button-red capitalize">{formMode} Access Control</button>

                    </form>
                )}
            </Formik>

        </div>
    );
}

function noDataNotice() {
    return (
        <>
            <tr className="bg-blue-900" >
                <td className="p-3 text-white">{""}</td>
                <td className="p-3 text-right">
                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">

                    </div>
                </td>
            </tr>
            <tr className="bg-gray-100 border-r border-l" >
                <td className="p-3 text-left text-blue-900">{"Data is not Available."}</td>
                <td className="p-3 text-right text-blue-900">
                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">

                    </div>
                </td>
            </tr>
        </>
    )
}

function createAbsAccessData(installations, user, accessObject) {
    let accessData = { user_id: "", user_type: "", action_notifications: 1, time_notifications: 1, duty_holders: [] }

    if (user && user._id) {

        const { user_type_display_name: userType = "" } = user.user_type ? user.user_type : {};

        if (installations.length && userType === "ABS" && accessObject) {
            let data = []
            let dHList = []

            installations.forEach(inst => {
                if (inst && inst.dh_id && inst.dh_id.dh_archived !== true && inst.dh_id.dh_status === "approved") {
                    let dHId = inst.dh_id._id
                    let dHName = inst.dh_id.dh_name;
                    let isExisting = dHList.some(d => d.id === dHId)
                    if (isExisting !== true) {
                        let restrictedDhIdList = accessObject.restricted_dh_ids ? accessObject.restricted_dh_ids : [];
                        let accessStatus = restrictedDhIdList.some(id => id === dHId)
                        let dHolder = { id: dHId, name: dHName, access: !accessStatus }
                        dHList.push(dHolder);
                    }
                }
            });

            dHList.forEach(dh => {
                let newInstallList = [];
                let installList = installations.filter(ins => ins.dh_id && ins.dh_id._id === dh.id && ins.installation_archived !== true && ins.installation_status === "approved");
                if (installList && installList.length) {

                    installList.forEach(ins => {
                        let instlId = ins._id;
                        let restrictedInstlIdList = accessObject.restricted_instl_ids ? accessObject.restricted_instl_ids : [];
                        let accessStatus = restrictedInstlIdList.some(id => id === instlId)

                        let instll = {
                            id: instlId,
                            name: ins.installation_title,
                            access: !accessStatus
                        }
                        newInstallList.push(instll);
                    })
                }
                dh.installations = newInstallList;
                data.push(dh);
            })

            accessData.id = accessObject.id;
            accessData.action_notifications = accessObject.action_notifications !== "" ? accessObject.action_notifications : 1;
            accessData.time_notifications = accessObject.time_notifications !== "" ? accessObject.time_notifications : 1;
            accessData.user_id = user._id;
            accessData.user_type = userType;
            accessData.duty_holders = data;
        }
    }

    return accessData;
}
function createDhAccessData(seces, user, accessObject) {
    let accessData = { user_id: "", user_type: "", action_notifications: 1, time_notifications: 1, installations: [] };

    if (user && user._id) {

        const { user_type_display_name: userType = "" } = user.user_type ? user.user_type : {};

        if (seces.length && userType === "Duty Holder" && accessObject) {
            let data = []
            let instllList = []

            seces.forEach(sec => {
                if (sec && sec.installation_id && sec.installation_id.installation_archived !== true && sec.installation_id.installation_status && sec.sece_archived !== true && sec.sece_status === "approved") {
                    let instId = sec.installation_id._id
                    let instName = sec.installation_id.installation_title;
                    let isExisting = instllList.some(ins => ins.id === instId)
                    if (isExisting !== true) {
                        let restrictedInstlIdList = accessObject.restricted_instl_ids ? accessObject.restricted_instl_ids : [];
                        let accessStatus = restrictedInstlIdList.some(id => id === instId)

                        let installation = { id: instId, name: instName, access: !accessStatus }
                        instllList.push(installation);
                    }
                }
            });

            instllList.forEach(inst => {
                let newSeceList = [];

                let seceList = seces.filter(sec => sec.installation_id && sec.installation_id._id === inst.id && sec.sece_archived !== true && sec.sece_status === "approved");

                if (seceList && seceList.length) {

                    seceList.forEach(sec => {
                        let seceId = sec._id;
                        let restrictedSeceIdList = accessObject.restricted_sece_ids ? accessObject.restricted_sece_ids : [];
                        let accessStatus = restrictedSeceIdList.some(id => id === seceId)
                        let sece = {
                            id: seceId,
                            name: sec.sece_title,
                            access: !accessStatus
                        }
                        newSeceList.push(sece);
                    })
                }

                inst.seces = newSeceList;
                data.push(inst);
            })

            accessData.id = accessObject.id;
            accessData.action_notifications = accessObject.action_notifications !== "" ? accessObject.action_notifications : 1;
            accessData.time_notifications = accessObject.time_notifications !== "" ? accessObject.time_notifications : 1;
            accessData.user_id = user._id;
            accessData.user_type = userType;
            accessData.installations = data;
        }
    };

    return accessData;
}

function formatABSRestrictedData(values) {
    let restrictedDhIdList = [];
    let restrictedInstlIdList = [];

    if (values) {
        values.forEach(dh => {
            if (!dh.access) {
                restrictedDhIdList.push(dh.id);
            }
            let installs = dh.installations ? dh.installations : [];
            installs.forEach(ins => {
                if (!ins.access) {
                    restrictedInstlIdList.push(ins.id);
                }
            })
        })
    }

    return {
        dhIdList: restrictedDhIdList,
        instlIdList: restrictedInstlIdList
    }
}

function formatClientRestrictedData(values) {
    let restrictedInstlIdList = [];
    let restrictedSeceIdList = [];

    if (values) {
        values.forEach(instl => {
            if (!instl.access) {
                restrictedInstlIdList.push(instl.id);
            }
            let seces = instl.seces ? instl.seces : [];
            seces.forEach(sece => {
                if (!sece.access) {
                    restrictedSeceIdList.push(sece.id);
                }
            })
        })
    }

    return {
        instlIdList: restrictedInstlIdList,
        seceIdList: restrictedSeceIdList,
    }
}

export default UserAccessControl;
