/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useState, useEffect, useContext } from 'react';
import Table from '../global/Table';
import { Link, useParams, useHistory } from 'react-router-dom';
import { gql, useLazyQuery} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { lowerCase } from 'lodash';
import { logoImage } from '../global/pdf/logoImage';
import PageSpinner from '../global/pageSpinner';
import { capitaliseFirstCase, createUserTypeAndRoleObject } from "../../utils/index";
import { AuthContext } from "../../context/authContext";
import { PageContext } from '../../context/pageContext';
import Comment from '../global/Comment';
import { sysObjects } from "../../constants/sysObjectList";
import {
  NOTIFICATION_STATUS_PENDING,
  NOTIFICATION_STATUS_OPEN,
  NOTIFICATION_STATUS_REVIEW,
  NOTIFICATION_STATUS_REVISION,
  NOTIFICATION_STATUS_APPROVED,
  SCHEDULE_FORM_ORIGIN_VS_AR_REGISTER,
  NOTIFICATION_STATUS_COMPLETE,
  NOTIFICATION_STATUS_REJECT,
  DEFAULT_CURRENT_ACTIVITY_STATUS,
  POSITIVE_CONCLUSION_STATEMENT,
  CURRENT_STATUS_SCHEDULE_SYS_VAR_CLOSED_ID,
  DH_FINDING_TYPE,
  INST_FINDING_TYPE,
  MULTI_VA_FINDING_TYPE,
  SINGLE_VA_FINDING_TYPE
} from '../../constants/';

import {NavContext} from '../../context/navContext';

const PAGE_NAME = "ActivityRegister";

const VERIFICATION_SCHEDULE_BY_ID_QUERY = gql`
query VerificationSchedule($verId: ID!) {
    verificationSchedule(_id: $verId){
        _id
        dh_id{
            _id
            dh_name
        }
        installation_ids{
            _id
            installation_title
        }
        sece_ids{
            _id
            sece_ref
            sece_client_ref
            sece_title
        }
        vs_title
        va_ids{
          _id
          va_ref
          va_client_ref
          va_short_desc
          va_onshore_offshore
          va_type
          va_archived
          va_status
          sece_id {
              _id
            sece_ref
            sece_client_ref
            sece_title
          }
          ps_id {
            _id
            ps_ref
            ps_title
          }
        }
        vs_current_status{
          _id
          sysvar_title
        }
        vs_start_date_time
        vs_end_date_time
        vs_archived
        vs_status
    }
}`;

const REPORTS_BY_VS_ID_QUERY = gql`
query ReportsByVerSchId($vsId: ID!) {
  reportsByVSId(vsId: $vsId){
        _id
        va_id{
            _id
        }
        report_status
        report_current_status{
          _id
          sysvar_title
        }
        report_conclusive_statement{
          _id
          sysvar_title
        }
        report_has_findings
    }
}`;

function ActivityRegister(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [activityList, setActivityList] = useState([]);
  const [reportList, setReportList] = useState([]);

  const [startLoading, setStartLoading] = useState(true);
  const [additionalTitle, setAdditionalTitle] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageName, setPageName] = useState("");
  const [vsStatus, setVsStatus] = useState("");

  const [isScheduleClosed, setIsScheduleClosed] = useState(false);
  const [headerChecked, setHeaderChecked] = useState(false);
  const [checkedActivities, setCheckedActivities] = useState([]);

  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

  const { verId = "" } = useParams();
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const pageContext = useContext(PageContext);
  const navContext = useContext(NavContext);

  const { location: { state: { vs_status = "" } = {} } } = props ? props : {};
  const history = useHistory();

  const [verificationSchedule, { error, data: vsData }] = useLazyQuery(VERIFICATION_SCHEDULE_BY_ID_QUERY);
  const [reportsByVSId, { error: rptError, data: rptData }] = useLazyQuery(REPORTS_BY_VS_ID_QUERY);

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (isInitialPageLoad) {
      setIsInitialPageLoad(false);
      return;
    }
    history.push('/');
  }, [navContext]);

  useEffect(() => {
    if (verId !== "") {
      reportsByVSId({ variables: { vsId: verId }, errorPolicy: 'all' });
    }
    setPageName(PAGE_NAME);
  }, []);

  useEffect(() => {
    if (headerChecked && activityList.length) {
      let vaIdList = []
      activityList.forEach(a => {
        if (a.id) {
          vaIdList.push(a.id);
        }
      });
      setCheckedActivities(vaIdList)
    }

    if (!headerChecked) {
      setCheckedActivities([]);
    }

  }, [headerChecked]);

  useEffect(() => {
    if (vs_status && vs_status !== "") {
      setVsStatus(vs_status);
    }
  }, [vs_status]);

  useEffect(() => {
    const { activityRegisterPage: { page = 0, parentId = "" } } = pageContext;
    if (parentId === verId) {
      setCurrentPage(page);
    }
  }, [pageContext]);

  useEffect(() => {
    if (rptData && rptData.reportsByVSId) {
      setReportList(rptData.reportsByVSId);
      verificationSchedule({ variables: { verId: verId }, errorPolicy: 'all' });
    }
  }, [rptData]);

  useEffect(() => {
    if (vsData && vsData.verificationSchedule) {
     
      let verificationSchedule = vsData.verificationSchedule;
      let additionalTitle = generateAdditionalTitle(verificationSchedule);
      const { _id: userId = "" } = authContext.user ? authContext.user : {};
      let activityList = formatVerActivityData(verificationSchedule, reportList, verId, capitaliseFirstCase, userId, userTypeAndRoleObject);
      let isScheduleClosed = checkScheduleStatus(verificationSchedule);

      setActivityList(activityList);
      setStartLoading(false);
      setAdditionalTitle(additionalTitle);
      setIsScheduleClosed(isScheduleClosed);
    }
  }, [vsData]);

  const handleActivityChange = (event, ckeckedVaList, vaId) => {
    let checked = event.target.checked;
    let tempIdList = [];
    if (checked) {
        tempIdList = [...ckeckedVaList, vaId];
    } else {
        tempIdList = ckeckedVaList.filter(id => id !== vaId)
    }
    setCheckedActivities(tempIdList);
  }

  const isActivityChecked = (checkedList, vaId) => {
    let result = checkedList.some(id => id === vaId)
    return result;
  };

  var pdfList = [];

  pdfList.push([{ text: 'SECE Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, { text: 'SECE Name', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#fff', '#000', '#000', '#fff'] }, { text: 'PS Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#fff', '#000', '#000', '#fff'] }, { text: 'VA Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#fff', '#000', '#000', '#fff'] }, { text: 'VA Short Description', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#fff', '#000', '#000', '#fff'] }, { text: 'Nature', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#fff', '#000', '#000', '#fff'] }, { text: 'Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#fff', '#000', '#000', '#fff'] }, { text: 'Onshore/Offshore', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#fff', '#000', '#000', '#000'] }]);

  activityList.length && activityList.map((al) => {
    pdfList.push([
      { text: al.sece_ref },
      { text: al.sece_title },
      { text: al.ps_ref },
      { text: al.va_ref },
      { text: al.va_short_descp },
      { text: al.type },
      { text: al.formatedStatus },
      { text: al.onshore_offshore }
    ])
  });

  //PDF
  var docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [30, 100, 40, 24],
    header: function (currentPage, pageCount) {

      var headerObj;
      if (currentPage % 2) {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['75%', '25%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: additionalTitle, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: 'VERIFICATION ACTIVITY LIST', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      } else {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['25%', '75%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: additionalTitle, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: 'VERIFICATION ACTIVITY LIST', font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      }

      return headerObj;
    },
    footer: function (currentPage, pageCount) {

      var footerObj;

      if (currentPage % 2) {
        footerObj = {
          table: {
            widths: [30, '*', 100, 28],
            body: [
              [
                { text: ' ', border: [false, false, false, false] },
                { text: additionalTitle + ' - VERIFICATION ACTIVITY LIST', style: 'footerText', border: [false, false, false, false] },
                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                { text: ' ', border: [true, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      } else {
        footerObj = {
          table: {
            widths: [30, 100, '*', 28],
            body: [
              [
                { text: ' ', border: [false, false, true, false] },
                { text: currentPage.toString(), border: [true, false, false, false] },
                { text: `© ${new Date().getFullYear()} American Bureau of Shipping. All rights reserved. CONFIDENTIAL.1`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                { text: ' ', border: [false, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      }

      return footerObj;

    },
    info: {
      title: additionalTitle + ' - Activity List',
    },
    content: [

      //CONTENT
      {
        table: {
          widths: ['10%', '20%', '10%', '15%', '15%', '10%', '10%', '10%'], //col widths
          headerRows: 0,
          body: pdfList
        },
        style: 'standardTable',
      },
      //CONTENT END
    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1.2
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }

  const pdfTab = () => {
    pdfMake.createPdf(docDefinition).open();
  }

  const columns = React.useMemo(
    () => [
      ...(isScheduleClosed ? [] :
        [
          {
            Header: "",
            id: 'cell_id',
            columns: [
              {
                id: "checkbox",
                accessor: originalRow => originalRow,
                Cell: ({ value: { id = "", verId = "", status = "", rptId = "", userId = "", isNagetiveConclusiveStatement, userTypeAndRoleObject } }) => {
                  return (
                    <div className={userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" ? "relative inline-block w-10 mr-2 align-left select-none transition duration-200 ease-in" : "hidden"}>
                      {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                        <>
                          <input
                            type="checkbox"
                            name={"va-" + id} id={"va-" + id}
                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                            checked={isActivityChecked(checkedActivities, id)}
                            onChange={(event) => { handleActivityChange(event, checkedActivities, id) }}
                          />
                          <label htmlFor={"va-" + id} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                        </>
                      }
                    </div>
                  );
                },
                Header: x => {
                  return (
                    <div className={userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" ? "relative inline-block w-10 mr-2 align-left select-none transition duration-200 ease-in" : "hidden"}>
                      {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                        <>
                          <input type="checkbox"
                            name={"header_id"} id={"header_id"}
                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                            checked={headerChecked}
                            onChange={(event) => { setHeaderChecked(!headerChecked) }}
                          />
                          <label htmlFor={"header_id"} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                        </>
                      }
                    </div>
                  );
                },
                disableSortBy: true,
                width: 45
              }
            ]
          }
        ]
      ),
      {
        Header: 'SECE Ref.',
        accessor: 'sece_ref',
      },
      {
        Header: 'SECE Name',
        accessor: 'sece_title',
      },
      {
        Header: 'PS Ref.',
        accessor: 'ps_ref',
      },
      {
        Header: 'VA Ref.',
        accessor: 'va_ref',
      },
      {
        Header: 'VA Short Description',
        accessor: 'va_short_descp',
      },
      {
        Header: 'Nature',
        accessor: 'type',
      },
      {
        Header: 'Onshore/Offshore',
        accessor: 'onshore_offshore',
      },
      {
        Header: 'Status',
        accessor: 'rpt_current_status',
      },
      {
        Header: 'Approval Status',
        accessor: 'formatedStatus',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: { id = "", verId = "", status = "", rptId = "", userId = "", isNagetiveConclusiveStatement, userTypeAndRoleObject, has_findings } }) => {
          switch (status) {
            case null:
            case NOTIFICATION_STATUS_PENDING:
              return (
                <div className="text-right">
                  
                  {(userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" && !isScheduleClosed) &&
                    // <Link to={{
                    //   pathname: '/reporting/verify',
                    //   state: { formMode: 'verify', actId: id, verId: verId }
                    // }} className="button-red mb-1 mr-1 text-xs">Verify</Link>

                    <Link to={{
                      pathname: `/reporting/verify-edit/${rptId}`,
                      state: { formMode: 'verify-edit', actId: id, verId: verId, status: status }
                    }} className="button-red mb-1 mr-1 pl-5 pr-5 text-xs">Verify</Link>
                  }

                  {/* {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    <Link to={"#"}
                      type="button"
                      className="button-red mb-1 mr-1 text-xs"
                      onClick={() => {
                        setRescheduleParam({
                          actId: id,
                          verId: verId,
                          userId: userId
                        }); toggleRescheduleVaModal()
                      }}
                    >Reschedule</Link>
                  } */}
                  {/* {(!isScheduleClosed) && (
                    <Link to={{
                      pathname: `/reporting/findings`,
                      state: { reportId: rptId },
                      search: `?vs_id=${verId}&va_id=${id}`
                    }} className="button-red mb-1 mr-1 text-xs">View Findings</Link>
                  )} */}
                  {(rptId && rptId !== "" && !isScheduleClosed) &&
                    <Comment sysObjName={sysObjects.report}
                      sysObjRecordId={rptId}
                      buttonName={"Comments"}
                      buttonClassName={"button-red mb-1 mr-1 text-xs"} />
                  }
                </div>
              )
            case NOTIFICATION_STATUS_OPEN:
            case NOTIFICATION_STATUS_REJECT:
            case NOTIFICATION_STATUS_REVISION:
              return (
                <div className="text-right">
                  {
                    /* {isNagetiveConclusiveStatement && (
                      <>
                        {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                          <div className="relative inline-block w-10 mr-2 align-left select-none transition duration-200 ease-in">
                            <input type="checkbox"
                              name={"va-" + "va.id"} id={"va-" + "va.id"}
                              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                              checked={""}
                              onChange={(event) => { }}
                            />
                            <label htmlFor={"va-" + "va.id"} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                          </div>
                        }
                      </>
                    )} */
                  }

                  {(userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" && !isScheduleClosed) &&
                    <Link to={{
                      pathname: `/reporting/verify-edit/${rptId}`,
                      state: { formMode: 'verify-edit', actId: id, verId: verId, status: status }
                    }} className="button-red mb-1 mr-1 pl-5 pr-5 text-xs">Edit</Link>
                  }
                  {(isNagetiveConclusiveStatement && !isScheduleClosed) && (
                    <>
                      {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                        <Link to={{
                          pathname: `/reporting/${rptId}/finding/verify`,
                          state: { 
                            formMode: 'verify', 
                            rptId: rptId, 
                            vs_id: verId,
                            title: "Add Finding",
                            type: SINGLE_VA_FINDING_TYPE
                          }
                        }} className="button-red mb-1 mr-1 text-xs">Add Finding</Link>
                      }

                      {has_findings &&
                        <Link to={{
                          pathname: `/reporting/${rptId}/findings`,
                          state: { reportId: rptId }
                        }} className="button-red mb-1 mr-1 text-xs">View Findings</Link>
                      }
                      
                    </>
                  )}
                  {/* {(isScheduleClosed) &&
                    <Link to={{
                      pathname: `/reporting/view/${rptId}`,
                      state: { reportId: rptId }
                    }} className="button-red mb-1 mr-1 text-xs">View</Link>
                  } */}
                  <Link to={{
                      pathname: `/reporting/view/${rptId}`,
                      state: { reportId: rptId }
                    }} className="button-red mb-1 mr-1 text-xs">View</Link>
                  {(rptId && rptId !== "" && !isScheduleClosed) &&
                    <Comment sysObjName={sysObjects.report}
                      sysObjRecordId={rptId}
                      buttonName={"Comments"}
                      buttonClassName={"button-red mb-1 mr-1 text-xs"} />
                  }
                </div>
              )

            case NOTIFICATION_STATUS_APPROVED:
            case NOTIFICATION_STATUS_REVIEW:
            case NOTIFICATION_STATUS_COMPLETE:
              return (
                <div className="text-right">
                  {(isNagetiveConclusiveStatement && !isScheduleClosed) && (
                    <>
                      {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                        <Link to={{
                          pathname: `/reporting/${rptId}/finding/verify`,
                          state: { 
                            formMode: 'verify', 
                            rptId: rptId, 
                            title: "Add Finding",
                            vs_id: verId,
                            type: SINGLE_VA_FINDING_TYPE 
                          }
                        }} className="button-red mb-1 mr-1 text-xs">Add Finding</Link>
                      }

                      {has_findings &&
                        <Link to={{
                          pathname: `/reporting/${rptId}/findings`,
                          state: { reportId: rptId }
                        }} className="button-red mb-1 mr-1 text-xs">View Findings</Link>
                      }
                    </>
                  )}

                  {<Link to={{
                    pathname: `/reporting/view/${rptId}`,
                    state: { reportId: rptId }
                  }} className="button-red mb-1 mr-1 text-xs">View</Link>
                  }

                  {(rptId && rptId !== "" && !isScheduleClosed) &&
                    <Comment sysObjName={sysObjects.report}
                      sysObjRecordId={rptId}
                      buttonName={"Comments"}
                      buttonClassName={"button-red mb-1 mr-1 text-xs"} />
                  }
                </div>
              )
            default:
              return null;
          }
        }
      },
    ],
    [headerChecked, setHeaderChecked, checkedActivities, userTypeAndRoleObject, isScheduleClosed]
  )

  if (startLoading) {
    return (
      <PageSpinner displayText = {"loading activity register"}/>
    )
  }

  if (rptError || error) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  //TITLE / INFO & MAIN BUTTON SECTION
  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">
        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Activity Register <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>
        {showHelp &&
          <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam condimentum a lectus non condimentum. Aliquam rhoncus, enim vitae condimentum pharetra, urna magna elementum sapien, non vehicula turpis tellus nec velit. Vivamus tristique venenatis neque, eget tristique mauris suscipit aliquet. Pellentesque et massa nunc.</p>
        }
      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <h6 className="mb-6 font-bold text-sm text-red-900">Dashboard - Activity Register - {additionalTitle}</h6>

      <div className="my-4">
        {/*
        <Link
          to={{
            pathname: `/verification/verification-register`,
            state: { vs_status: vsStatus }
          }} className="button-red mr-2"><FontAwesomeIcon icon={['fas', 'angle-left']} className="text-white" /> Back</Link> */}

        {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
          <>
            <Link
              to={{
                pathname: `/verification/edit/step1/${verId}`,
                state: { vs_status: vsStatus, origin: SCHEDULE_FORM_ORIGIN_VS_AR_REGISTER }
              }} className="button-red mr-2">Edit Schedule</Link>

            <Link style={{ cursor: (checkedActivities.length > 0 ? "pointer" : "not-allowed") }}
              to={checkedActivities.length > 0 ? {
                pathname: `/reporting/vs/${verId}/finding/verify`,
                state: {
                  formMode: 'verify',
                  type: MULTI_VA_FINDING_TYPE,
                  vaIds: checkedActivities,
                  rptIds: createReportIdsFrom(activityList, checkedActivities),
                  title: "Add Findings"
                }
              } : "#"}
              className="button-red mr-2 disabled">Add Findings</Link>

            <Link
              to={{
                pathname: '/reporting/finding/add',
                state: { 
                  formMode: 'add', 
                  type: INST_FINDING_TYPE, 
                  vs_id: verId,
                  title: "Create Installation Finding"
                }
              }}
              className="button-red mr-2 disabled">Add Installation Level Findings</Link>

            <Link
              to={{
                pathname: '/reporting/finding/add',
                state: {
                  formMode: 'add',
                  type: DH_FINDING_TYPE,
                  vs_id: verId,
                  title: "Create Duty Holder Finding"
                }
              }}
              className="button-red mr-2">Add Duty Holder Level Findings</Link>
          </>
        }

        <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>
      </div>

      {activityList.length !== 0 ?
        <Table columns={columns} data={activityList} currentPage={currentPage} pageName={pageName} parentId={verId} />
        : <h4 className="mt-4">There are currently no Activities</h4>
      }

    </div>
  )
  //TITLE / INFO & MAIN BUTTON SECTION - END
}

function createReportIdsFrom(activityList, checkedActivities) {
  let reportIds = [];
  if (activityList.length && checkedActivities.length) {
    checkedActivities.forEach(caId => {
      let act = activityList.find(ac => ac.id === caId);
      if (act && act.rptId && act.rptId !== "") {
        reportIds.push(act.rptId);
      }
    })
  }
  return reportIds;
}

function formatVerActivityData(verSchedule, reportList, verId, capitaliseFirstCase, userId, userTypeAndRoleObject) {
  let newActivityList = [];
  if (verSchedule && verSchedule.va_ids && reportList && verId !== "") {
    let filteredList = verSchedule.va_ids.filter(a => a.va_archived !== true);
    let activityList = filteredList && filteredList.length > 0 ? filteredList : [];
    
    activityList.forEach(act => {
      let status = "";
      let isNagetiveConclusiveStatement = false;

      let report = reportList.find(rpt => rpt.va_id && rpt.va_id._id === act._id);

      let rpt_current_status = report && report.report_current_status && report.report_current_status.sysvar_title ? report.report_current_status.sysvar_title : DEFAULT_CURRENT_ACTIVITY_STATUS;
 

      status = report ? report.report_status : NOTIFICATION_STATUS_PENDING;
      
      isNagetiveConclusiveStatement = false;
      
      if (report &&  
          report.report_conclusive_statement &&  
          report.report_conclusive_statement._id !== POSITIVE_CONCLUSION_STATEMENT) {
        isNagetiveConclusiveStatement = true;
      }

      let convertedStatus  = modifyReportStatus(status);
      let formatedStatus = capitaliseFirstCase(convertedStatus);
      let rptId = report ? report._id : "";

      const { sece_client_ref = "N/A", sece_title = "N/A" } = act.sece_id;
      const { ps_ref = "N/A" } = act.ps_id;

      let newActivity = {
        id: act._id,
        type: act.va_type,
        verId: verId,
        rpt_current_status : rpt_current_status,
        status: status,
        formatedStatus: formatedStatus,
        rptId: rptId,
        userId: userId,
        sece_ref: sece_client_ref,
        sece_title: sece_title,
        ps_ref: ps_ref,
        va_ref: act.va_client_ref ? act.va_client_ref : "N/A",
        va_short_descp: act.va_short_desc,
        onshore_offshore: act.va_onshore_offshore,
        isNagetiveConclusiveStatement: isNagetiveConclusiveStatement,
        userTypeAndRoleObject: userTypeAndRoleObject,
        has_findings: report.report_has_findings
      }     
      newActivityList.push(newActivity);
    })
  }
  return newActivityList;
}

function generateAdditionalTitle(verificationSchedule) {
  let tempTitle = "";
  if (verificationSchedule) {
    const { vs_title = "", dh_id } = verificationSchedule ? verificationSchedule : {};
    const { dh_name = "" } = dh_id ? dh_id : {};
    const installation_ids = verificationSchedule.installation_ids;

    tempTitle += dh_name;
    if (installation_ids.length) {
      tempTitle += " - ";
    }
    installation_ids.forEach((install, index) => {
      tempTitle += install.installation_title;
      if (index < (installation_ids.length - 1)) {
        tempTitle += ", ";
      }
    })
    if (vs_title && vs_title !== "") {
      tempTitle += " - " + vs_title;
    }
  }
  return tempTitle;
}

function checkScheduleStatus(verificationSchedule) {
  let isScheduleClosed = false;

  if (verificationSchedule) {
    const { vs_current_status } = verificationSchedule;
    const { _id = "" } = vs_current_status ? vs_current_status : {};
    isScheduleClosed = (_id === CURRENT_STATUS_SCHEDULE_SYS_VAR_CLOSED_ID);
  }

  return isScheduleClosed;
}

function modifyReportStatus(status) {
  let newStatus = "";
  if (status && status !== "") {
      status = lowerCase(status);
      switch (status) {
          case "open":
              newStatus = "unapproved";
              break;
          case "closed":
              newStatus = "approved";
              break;
          default:
              newStatus = status;
              break;
      }
  }
  return newStatus;
}

export default ActivityRegister;

