/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useContext} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import Table from '../global/Table';
import {gql, useLazyQuery, useMutation} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import {AuthContext} from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import BreadCrumb from "../global/Breadcrumb";
import {capitaliseFirstCase, getSectionName, createQueryObject, createUserTypeAndRoleObject} from "../../utils/index";
import {NOTIFICATION_STATUS_APPROVED, NOTIFICATION_STATUS_REJECT, NOTIFICATION_STATUS_REVISION} from "../../constants";
import Comment from '../global/Comment';
import {sysObjects} from "../../constants/sysObjectList";

const RA_SEARCH_QUERY = gql`
  query referenceActivitySearch ($param: ReferenceActivitySearchInput!){
    referenceActivitySearch (param: $param){
          _id
          ra_client_ref
          ra_ref
          ra_title
          ra_archived
          ra_archived_id
          ra_archived_date
          ra_status
      }
  }`;

const UPDATE_REFERENCE_ACTIVITY = gql`
  mutation UpdateReferenceActivity($refActId: String!, $userId: String, $ra: UpdateReferenceActivityInput!){
      updateReferenceActivity(_id: $refActId, userId: $userId, ra: $ra){
          _id
      }
  }`;

function ReferenceActivities(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [refActList, setRefActList] = useState([]);
  const [showArchivedRefActs, setShowArchivedRefActs] = useState(false);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const history = useHistory();

  const {id, assetGroupId, installationId, barrierId, seceId, mahId, psId, vaId} = useParams();
  const {location: {state = ""}, match: {url = ""}} = props ? props : {};

  useEffect(() => {
    if (authContext && authContext.user){
       let result = createUserTypeAndRoleObject(authContext);
       setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (isInitialPageLoad) {
      setIsInitialPageLoad(false);
      return;
    }
    history.push('/');
  }, [navContext]);

  const [referenceActivitySearch, {
    loading: refActSearchLoading,
    error: refActSearchError,
    data: refActSearchData,
    refetch: refetchRefActSearch
  }] = useLazyQuery(RA_SEARCH_QUERY);

  const [updateReferenceActivity, {data: updateRefActData}] = useMutation(UPDATE_REFERENCE_ACTIVITY);

  useEffect(() => {
    if (state === "archived") {
      setShowArchivedRefActs(true);
    };
  }, []);

  useEffect(() => {
    let queryObject = createQueryObject(url, 3);
    referenceActivitySearch({variables: { param: queryObject}, errorPolicy: 'all'});
  }, [showArchivedRefActs]);

  useEffect(() => {
    if (refActSearchData && refActSearchData.referenceActivitySearch) {
      const {_id: userId = ""} = authContext.user ? authContext.user : {};
      let tempRefActList = formatRefActsData(refActSearchData.referenceActivitySearch, showArchivedRefActs, userId, state, userTypeAndRoleObject);
      setRefActList(tempRefActList);
    }
  }, [refActSearchData]);

  useEffect(() => {
    if (updateRefActData && updateRefActData.updateReferenceActivity) {
        refetchRefActSearch();
    }
  }, [updateRefActData]);

  const archiveManager = (refActId, isArchived, archivedId, archivedDate, userId) => {
    if (refActId !== "") {
      const payload = {
        ra_archived: isArchived ? false : true,
        ra_archived_id: isArchived ? archivedId : userId,
        ra_archived_date: isArchived ? archivedDate : new Date()
      }
      updateReferenceActivity({variables: {refActId: refActId, userId: userId, ra: payload}});
    }
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Client Ref',
        accessor: 'ra_client_ref',
      },
      {
        Header: 'Title',
        accessor: 'ra_title',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({value: {_id, ra_archived, ra_archived_id, ra_archived_date, ra_status, user_id, state, userTypeAndRoleObject}}) => {
          return (
            <div className="text-right">

              <Comment sysObjName={sysObjects.referenceActivity}
                sysObjRecordId={_id}
                buttonName={"Comments"}
                buttonClassName={"button-red mb-1 mr-1 text-xs"} />

              {assetGroupId !== undefined && installationId === undefined && barrierId === undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/reference-activity/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {ra_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (ra_status === NOTIFICATION_STATUS_APPROVED ||
                      ra_status === NOTIFICATION_STATUS_REJECT ||
                      ra_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/reference-activity/edit/${_id}`,
                        state: { formMode: 'edit', archivedStatus: state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }

              {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/reference-activity/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {ra_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (ra_status === NOTIFICATION_STATUS_APPROVED ||
                      ra_status === NOTIFICATION_STATUS_REJECT ||
                      ra_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/reference-activity/edit/${_id}`,
                        state: { formMode: 'edit', archivedStatus: state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }

              {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/reference-activity/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {ra_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (ra_status === NOTIFICATION_STATUS_APPROVED ||
                      ra_status === NOTIFICATION_STATUS_REJECT ||
                      ra_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/reference-activity/edit/${_id}`,
                        state: { formMode: 'edit', archivedStatus: state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }

              {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId === undefined && mahId !== undefined && psId === undefined && vaId === undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mah/${mahId}/reference-activity/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {ra_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (ra_status === NOTIFICATION_STATUS_APPROVED ||
                      ra_status === NOTIFICATION_STATUS_REJECT ||
                      ra_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mah/${mahId}/reference-activity/edit/${_id}`,
                        state: { formMode: 'edit', archivedStatus: state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }

              {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId === undefined && vaId === undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/reference-activity/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {ra_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (ra_status === NOTIFICATION_STATUS_APPROVED ||
                      ra_status === NOTIFICATION_STATUS_REJECT ||
                      ra_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/reference-activity/edit/${_id}`,
                        state: { formMode: 'edit', archivedStatus: state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }

              {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId === undefined && vaId === undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/reference-activity/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {ra_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (ra_status === NOTIFICATION_STATUS_APPROVED ||
                      ra_status === NOTIFICATION_STATUS_REJECT ||
                      ra_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/reference-activity/edit/${_id}`,
                        state: { formMode: 'edit', archivedStatus: state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }

              {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId === undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/reference-activity/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {ra_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (ra_status === NOTIFICATION_STATUS_APPROVED ||
                      ra_status === NOTIFICATION_STATUS_REJECT ||
                      ra_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/reference-activity/edit/${_id}`,
                        state: { formMode: 'edit', archivedStatus: state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }

              {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId === undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/reference-activity/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {ra_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (ra_status === NOTIFICATION_STATUS_APPROVED ||
                      ra_status === NOTIFICATION_STATUS_REJECT ||
                      ra_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/reference-activity/edit/${_id}`,
                        state: { formMode: 'edit', archivedStatus: state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }

              {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId !== undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activity/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {ra_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (ra_status === NOTIFICATION_STATUS_APPROVED ||
                      ra_status === NOTIFICATION_STATUS_REJECT ||
                      ra_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activity/edit/${_id}`,
                        state: { formMode: 'edit', archivedStatus: state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }

              {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId !== undefined &&
                <div className="inline">
                  <Link
                    to={{
                      pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activity/view/${_id}`,
                      state: { archivedStatus: state }
                    }}
                    className="button-red mb-1 mr-1 text-xs"
                  >
                    View
                  </Link>
                  {ra_archived ? "" : userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                    (ra_status === NOTIFICATION_STATUS_APPROVED ||
                      ra_status === NOTIFICATION_STATUS_REJECT ||
                      ra_status === NOTIFICATION_STATUS_REVISION) &&
                    <Link
                      to={{
                        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activity/edit/${_id}`,
                        state: { formMode: 'edit', archivedStatus: state }
                      }}
                      className="button-red mb-1 mr-1 text-xs"
                    >
                      Edit
                    </Link>}
                </div>
              }


              {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                (ra_status === NOTIFICATION_STATUS_APPROVED ||
                  ra_status === NOTIFICATION_STATUS_REJECT ||
                  ra_status === NOTIFICATION_STATUS_REVISION) &&
                <Link
                  to="#"
                  className="button-red mb-1 mr-1 text-xs"
                  onClick={() => {
                    archiveManager(_id, ra_archived, ra_archived_id, ra_archived_date, user_id)
                  }}
                >
                  {ra_archived ? 'Unarchive' : 'Archive'}
                </Link>
              }

            </div>
          )
        }
      },
    ],
    []
  )

  if (refActSearchLoading) {
    return (
      <PageSpinner/>
    )
  }

  if (refActSearchError) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">{getSectionName(url, 3).replace("-", " ")} Reference Activities<FontAwesomeIcon
          icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer"
          onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
        <div>
          {showArchivedRefActs === false ?
            <div>
              <p className="mt-3">A table of active <span className={getSectionName(url, 3).replace("-", " ")==='mah' ? `uppercase`:`capitalize`}>{getSectionName(url, 3).replace("-", " ")}</span> Reference Activities, each entry can be viewed, edited and archived.</p>
              <p>Add a new Reference Activity by clicking 'Add Reference Activity'.</p>
            </div>
          :
          <div>
            <p className="mt-3">A table of archived <span className="capitalize">{getSectionName(url, 3).replace("-", " ")}</span> Reference Activities, each entry can be viewed and unarchived.</p>
          </div>
          }
        </div>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      <BreadCrumb payload={JSON.stringify({
        dh_id: id,
        ag_id: assetGroupId,
        installation_id: installationId,
        bar_id: barrierId,
        sece_id: seceId,
        mah_id: mahId,
        ps_id: psId,
        va_id: vaId,
        last_crumb: 'Reference Activities'
      })} />

      {assetGroupId !== undefined && installationId === undefined && barrierId === undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined &&
        <div className="inline">
          <Link
            to={`/dutyholder/${id}/asset-groups`}
            className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
              className="text-white" /> Back</Link>

          {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
            <Link
              to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/reference-activity/add`,
                state: { formMode: 'add', archivedStatus: state }
              }}
              className="button-red mr-2 mb-2 py-3 px-5"
            >
              Add Reference Activity
            </Link>
          }
        </div>
      }

      {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined &&
        <div className="inline">
          <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installations`}
            className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
              className="text-white" /> Back</Link>

          {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
            <Link
              to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/reference-activity/add`,
                state: { formMode: 'add', archivedStatus: state }
              }}
              className="button-red mr-2 mb-2 py-3 px-5"
            >
              Add Reference Activity
            </Link>
          }
        </div>
      }

      {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId === undefined && mahId === undefined && psId === undefined && vaId === undefined &&
        <div className="inline">
          <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barriers`}
            className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
              className="text-white" /> Back</Link>

          {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
            <Link
              to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/reference-activity/add`,
                state: { formMode: 'add', archivedStatus: state }
              }}
              className="button-red mr-2 mb-2 py-3 px-5"
            >
              Add Reference Activity
            </Link>
          }
        </div>
      }

      {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId === undefined && mahId !== undefined && psId === undefined && vaId === undefined &&
        <div className="inline">
          <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mahs`}
            className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
              className="text-white" /> Back</Link>

          {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
            <Link
              to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mah/${mahId}/reference-activity/add`,
                state: { formMode: 'add', archivedStatus: state }
              }}
              className="button-red mr-2 mb-2 py-3 px-5"
            >
              Add Reference Activity
            </Link>
          }
        </div>
      }

      {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId === undefined && mahId !== undefined && psId === undefined && vaId === undefined &&
        <div className="inline">
          <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barriers`}
            className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
              className="text-white" /> Back</Link>

          {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
            <Link
              to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/mah/${mahId}/reference-activity/add`,
                state: { formMode: 'add', archivedStatus: state }
              }}
              className="button-red mr-2 mb-2 py-3 px-5"
            >
              Add Reference Activity
            </Link>
          }
        </div>
      }


      {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId === undefined && vaId === undefined &&
        <div className="inline">
          <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/seces`}
            className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
              className="text-white" /> Back</Link>

          {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
            <Link
              to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/reference-activity/add`,
                state: { formMode: 'add', archivedStatus: state }
              }}
              className="button-red mr-2 mb-2 py-3 px-5"
            >
              Add Reference Activity
            </Link>
          }
        </div>
      }

      {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId === undefined && vaId === undefined &&
        <div className="inline">
          <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/seces`}
            className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
              className="text-white" /> Back</Link>

          {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
            <Link
              to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/reference-activity/add`,
                state: { formMode: 'add', archivedStatus: state }
              }}
              className="button-red mr-2 mb-2 py-3 px-5"
            >
              Add Reference Activity
            </Link>
          }
        </div>
      }

      {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId === undefined &&
        <div className="inline">
          <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standards`}
            className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
              className="text-white" /> Back</Link>

          {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
            <Link
              to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/reference-activity/add`,
                state: { formMode: 'add', archivedStatus: state }
              }}
              className="button-red mr-2 mb-2 py-3 px-5"
            >
              Add Reference Activity
            </Link>
          }
        </div>
      }

      {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId === undefined &&
        <div className="inline">
          <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standards`}
            className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
              className="text-white" /> Back</Link>

          {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
            <Link
              to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/reference-activity/add`,
                state: { formMode: 'add', archivedStatus: state }
              }}
              className="button-red mr-2 mb-2 py-3 px-5"
            >
              Add Reference Activity
            </Link>
          }
        </div>
      }

      {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId !== undefined &&
        <div className="inline">
          <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activities`}
            className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
              className="text-white" /> Back</Link>

          {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
            <Link
              to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activity/add`,
                state: { formMode: 'add', archivedStatus: state }
              }}
              className="button-red mr-2 mb-2 py-3 px-5"
            >
              Add Reference Activity
            </Link>
          }
        </div>
      }

      {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined && seceId !== undefined && mahId === undefined && psId !== undefined && vaId !== undefined &&
        <div className="inline">
          <Link to={`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activities`}
            className="button-red mr-2 mb-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']}
              className="text-white" /> Back</Link>

          {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
            <Link
              to={{
                pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/sece/${seceId}/performance-standard/${psId}/verification-activity/${vaId}/reference-activity/add`,
                state: { formMode: 'add', archivedStatus: state }
              }}
              className="button-red mr-2 mb-2 py-3 px-5"
            >
              Add Reference Activity
            </Link>
          }
        </div>
      }


      <Link to={{
        pathname: showArchivedRefActs ? url : (url + "/archived"),
        state: showArchivedRefActs ? "" : "archived"
      }}
        className="button-red mr-2 mb-2 py-3 px-5"
        onClick={() => {
          setShowArchivedRefActs(!showArchivedRefActs)
        }}
        style={{ backgroundColor: showArchivedRefActs ? '#a70000' : '' }}
      >
        {showArchivedRefActs ? "Active Reference Activities" : "Archived Reference Activities"}
      </Link>

      {refActList.length > 0 &&
        <Link to={`${url}/histories`} className="button-red mb-2 mr-2 py-3 px-5">History</Link>
      }

      {refActList.length !== 0 ?
        <Table columns={columns} data={refActList} />
        : <h4 className="mt-4">There are currently no Reference Activities</h4>}

    </div>
  )
}

function formatRefActsData(refActList, showArchivedRefActs, userId, state, userTypeAndRoleObject) {
  let newRefActList = [];

  if (refActList && refActList.length && userId !== "") {
    let tempRefActList = refActList.filter(refAct => showArchivedRefActs ? refAct.ra_archived === true : refAct.ra_archived !== true);
    tempRefActList.forEach(refAct => {
      let raClientRef = refAct.ra_client_ref ? refAct.ra_client_ref : "N/A";
      let formatedStatus = capitaliseFirstCase(refAct.ra_status);
      let newRefAct = { ...refAct, ra_client_ref: raClientRef, status: formatedStatus, user_id: userId, state: state, userTypeAndRoleObject: userTypeAndRoleObject };
      newRefActList.push(newRefAct);
    })
  };
  return newRefActList;
}

export default ReferenceActivities;