/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useContext} from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import {gql, useQuery} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';
import {AuthContext} from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import {parseJSONSafely, createUserTypeAndRoleObject} from "../../utils";

const {REACT_APP_FILE_MANAGER_API} = process.env;

const INSTALLATION_QUERY = gql`
    query Installation($installationId: ID!){
        installation(_id: $installationId) {
            installation_code
            dh_id{
                _id
                dh_name
            }
            ag_id{
                ag_title
            }
            installation_title 
            installation_class_no
            installation_imo_no
            installation_flag_state
            installation_type
            installation_business_state
            installation_abs_class_notations
            installation_additional_notations
            installation_other_services
            installation_notes
            installation_attachments
            installation_attmnts_dest_path
            installation_status
        }
    }`;

const ViewInstallation = (props) => {
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const [installation, setInstallation] = useState([]);
  const [instllAttachments, setInstllAttachments] = useState([]);
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);

  const { id, assetGroupId, installationId = "" } = useParams();
  const { location: { state = "" } } = props ? props : {};

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const history = useHistory();

  const { loading, error, data } = useQuery(INSTALLATION_QUERY, {
    variables: { installationId },
    errorPolicy: 'all'
  });

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (isInitialPageLoad) {
      setIsInitialPageLoad(false);
      return;
    }
    history.push('/');
  }, [navContext]);

  useEffect(() => {
    if (data && data.installation) {
      let installationData = data.installation;
      let attachments = installationData.installation_attachments && installationData.installation_attachments !== "" ? installationData.installation_attachments : "[]";
      let attachmentList = parseJSONSafely(attachments) ? parseJSONSafely(attachments) : [];
      setInstllAttachments(attachmentList);
      setInstallation(data.installation)
    }
  }, [data]);

  var docDefinition = {
    pageSize: 'A4',
    pageMargins: [40, 100, 40, 24],
    header: function (currentPage, pageCount) {

      var headerObj;
      if (currentPage % 2) {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['75%', '25%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: installation.dh_id && installation.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: "Installation Details", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      } else {
        headerObj = {
          table: {
            headerRows: 0,
            widths: ['25%', '75%'],
            body: [
              [
                {
                  border: [false, false, true, false],
                  fillColor: '#b40c1e',
                  image: logoImage,
                  width: 100
                },
                {
                  border: [true, false, false, false],
                  fillColor: '#002a4e',
                  stack: [
                    { text: installation.dh_id && installation.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                    [{
                      canvas: [
                        {
                          type: 'rect',
                          x: 0,
                          y: 0,
                          w: 30,
                          h: 0.5,
                          r: 0,
                          lineWidth: 1,
                          lineColor: 'red',
                        }
                      ]
                    }],
                    { text: "Installation Details", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                  ]
                },
              ]
            ],
          },
          layout: {
            vLineColor: function (i, node) { return 'white'; },
            vLineWidth: function (i) { return 4; },
            paddingLeft: function (i, node) { return 30; },
            paddingRight: function (i, node) { return 10; },
            paddingTop: function (i, node) { return 20; },
            paddingBottom: function (i, node) { return 10; },
          }
        };
      }

      return headerObj;
    },
    footer: function (currentPage, pageCount) {

      var footerObj;

      if (currentPage % 2) {
        footerObj = {
          table: {
            widths: [30, '*', 100, 28],
            body: [
              [
                { text: ' ', border: [false, false, false, false] },
                { text: 'INSTALLATION DETAILS', style: 'footerText', border: [false, false, false, false] },
                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                { text: ' ', border: [true, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      } else {
        footerObj = {
          table: {
            widths: [30, 100, '*', 28],
            body: [
              [
                { text: ' ', border: [false, false, true, false] },
                { text: currentPage.toString(), border: [true, false, false, false] },
                { text: `© ${new Date().getFullYear()} American Bureau of Shipping. All rights reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                { text: ' ', border: [false, false, false, false] }
              ]
            ]
          },
          layout: {
            vLineColor: function (i, node) { return '#b40c1e'; },
          }
        }
      }

      return footerObj;

    },
    info: {
      title: installation.dh_id && installation.dh_id.dh_name + ' - ' + installation.installation_title,
    },
    content: [

      { text: installation.installation_title, style: 'title' },
      { text: installation.dh_id && installation.dh_id.dh_name, style: 'redTitle', bold: true },
      {
        table: {
          widths: ['30%', '70%'], 
          headerRows: 0,
          body: [
            [{ text: 'Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, installation.installation_status],
            [{ text: 'Code', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, installation.installation_code],
            [{ text: 'Class No.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, installation.installation_class_no],
            [{ text: 'IMO No.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, installation.installation_imo_no],
            [{ text: 'Flag State', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, installation.installation_flag_state],
            [{ text: 'Installation Type', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, installation.installation_type],
            [{ text: 'Business State', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, installation.installation_business_state],
            [{ text: 'Class Notations', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, installation.installation_abs_class_notations],
            [{ text: 'Additional Notations', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, installation.installation_additional_notations],
            [{ text: 'Other Services', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{ text: installation.installation_other_services ? installation.installation_other_services : 'n/a', alignment: 'left', colSpan: 2 }, {}],
            [{ text: 'Attachments', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
            [{
              text: [
                {text: instllAttachments.length?instllAttachments.map(att => { 
                  return ( 
                    "File Name: \n" +
                    att.name + '\n' +
                    "File Description: \n" +
                    att.description + '\n \n'
                  )
                 }): "No Attachments to Display"           
                },
              ], colSpan: 2
            }, {}],
          ]
        },
        style: 'standardTable',
      },

    ],
    defaultStyle: {
      font: 'Tinos',
      fontSize: 10,
      color: '#231f20',
      lineHeight: 1.4
    },
    styles: {
      title: {
        marginTop: 10,
        font: 'Montserrat',
        fontSize: 14,
        bold: true,
        color: '#002a4e'
      },
      redTitle: {
        marginBottom: 10,
        font: 'Montserrat',
        color: '#b40c1e'
      },
      standardTable: {
        margin: [0, 5, 0, 20],
        lineHeight: 1.2
      },
      StandardTableHeader: {
        fillColor: '#002a4e',
        color: '#fff',
        lineHeight: 1
      },
      footerTitle: {
        font: 'Montserrat',
        color: '#002a4e',
      },
      footerText: {
        font: 'Montserrat',
        fontSize: 10
      }
    }
  }

  const pdfTab = () => {
    pdfMake.createPdf(docDefinition).open();
  }

  const displayBackLink = (state, id, assetGroupId) => {
    if (state && state.origin && state.origin === "notifications") {
      return (
        <Link to={{
          pathname: `/notifications`
        }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
      )
    };
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installations` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</Link>
    )
  }

  if (loading) {
    return (
      <PageSpinner />
    )
  }

  if (error) {
    return (
      <span>Something went wrong retrieving the installation data</span>
    )
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="mb-6 pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">Installation Details</h1>
      </div>

      {displayBackLink(state, id, assetGroupId)}

      <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      <div className="w-full lg:w-1/2">

        <div className="my-6">
          <h2 className="font-bold text-2xl text-blue-900">{installation.installation_title}</h2>
          <h3 className="font-bold text-red-900">{installation.dh_id && installation.dh_id.dh_name}</h3>
        </div>

        <table className="w-full mb-5 table-auto">
          <tbody>
            <tr>
              <th className="p-3 text-left bg-white border">Status</th>
              <td className="p-3 border">{installation.installation_status}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Code</th>
              <td className="p-3 border">{installation.installation_code}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Class No.</th>
              <td className="p-3 border">{installation.installation_class_no}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">IMO No.</th>
              <td className="p-3 border">{installation.installation_imo_no}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Flag State</th>
              <td className="p-3 border">{installation.installation_flag_state}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Installation Type</th>
              <td className="p-3 border">{installation.installation_type}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Business State</th>
              <td className="p-3 border">{installation.installation_business_state}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Class Notations</th>
              <td className="p-3 border">{installation.installation_abs_class_notations}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border">Additional Notations</th>
              <td className="p-3 border">{installation.installation_additional_notations}</td>
            </tr>
            <tr>
              <th className="p-3 text-left bg-white border" colSpan="2">Other Services</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">{installation.installation_other_services}</td>
            </tr>
            {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
              <tr>
                <th className="p-3 text-left bg-white border" colSpan="2">Notes</th>
              </tr>
            }
            {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
              <tr>
                <td className="p-3 border" colSpan="2">{installation.installation_notes}</td>
              </tr>
            }
            <tr>
              <th className="p-3 text-left bg-white border" colSpan="2">Attachments</th>
            </tr>
            <tr>
              <td className="p-3 border" colSpan="2">{createFileLinks(instllAttachments, installation)}</td>
            </tr>
          </tbody>
        </table>

        {displayBackLink(state, id, assetGroupId)}

        <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

      </div>

    </div>
  )


}

function createFileLinks(attachments, installation) {
  let linkList = "";
  let fileDestPath = installation.installation_attmnts_dest_path ? installation.installation_attmnts_dest_path : "";
  if (attachments && attachments.length && fileDestPath !== "") {
    let fullPath = `${REACT_APP_FILE_MANAGER_API}/download-file?path=/${fileDestPath}/`;
    let numberOfAttachments = attachments.length;

    let newAttachments = <React.Fragment>{attachments.map((att, index) =>
      <React.Fragment key={index}>
        <div className="text-blue-900 font-sans text-sm font-bold">File Name:</div>
        <a href={fullPath + att.name} className="mb-1 hover:underline" key={index} target={"_blank"} rel="noopener noreferrer" download={true}>
          {att.name}{((index + 1) < numberOfAttachments) ? <br /> : ""}
        </a>
        <div className="text-blue-900 font-sans text-sm font-bold">File Description:</div>
        <div>
          {att.description}
        </div>
        {((index + 1) < numberOfAttachments) ? <br /> : ""}
      </React.Fragment>
    )}
    </React.Fragment>
    linkList = newAttachments;
  } else {
    linkList = <Link to="#" className="hover:underline">No File Attachment</Link>
  }

  return linkList;
}

export default ViewInstallation;
