/* eslint-disable react-hooks/exhaustive-deps */

import React, {useState, useEffect, useContext} from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import Sanitiser from "../global/sanitiser";
import DatePicker from 'react-datepicker';
import TableDropDown from '../global/TableDropDown';
import "react-datepicker/dist/react-datepicker.css";
import Table from '../global/Table';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from '../global/pageSpinner';
import { AuthContext } from "../../context/authContext";
import { NavContext } from '../../context/navContext';

import {
    REPORT_ACTION_REVIEW,
    FINDING_LEVEL_SYSTEM_VARIABLE_TYPE_ID,
    NOTIFICATION_STATUS_PENDING,
    NOTIFICATION_STATUS_APPROVED,
    NOTIFICATION_STATUS_REVIEW
} from '../../constants/';
import { capitaliseFirstCase, createUserTypeAndRoleObject, truncateString } from "../../utils/index";
import Comment from '../global/Comment';
import {sysObjects} from "../../constants/sysObjectList";

const dropdownListItems = ({ id, is_archived, archived_id, archived_date, isPeriodic, user_id, status, archiveManager}) => {
    let listItems = [
        {
            id: 1,
            name: 'View Report',
            link: `/reporting/vs-report/view/${id}`,
            search: `?periodic=${isPeriodic}`,
            //state: { isPeriodic: isPeriodic }
        },
        ...(( status === NOTIFICATION_STATUS_REVIEW || 
            status === NOTIFICATION_STATUS_PENDING || 
            status === NOTIFICATION_STATUS_APPROVED ) ? [] :
            [
                {
                    id: 2,
                    name: 'Edit',
                    link: `/reporting/vs-report/report-index/edit/${id}`,
                    state: { formMode: 'edit' }
                },
                {
                    id: 3,
                    name: is_archived ? 'Unarchive' : 'Archive',
                    link: "",
                    callback: () => { archiveManager(id, is_archived, archived_id, archived_date, user_id) }
                }
            ]
        )
    ];

    return listItems;
}

const VS_REPORT_SEARCH = gql`
query VerificationScheduleReportSearch($param: VerificationScheduleReportSearch!){
    verificationScheduleReportSearch(param: $param) {
        _id
        dh_id{
          _id
          dh_name
        }
        installation_ids{
          _id
          installation_title
        }
        sece_ids{
          _id
          sece_title
        }
        vs_rpt_ref
        vs_rpt_is_periodic
        vs_rpt_title
        vs_rpt_description
        vs_rpt_summary
        vs_rpt_scope
        vs_rpt_statement
        vs_rpt_improvements
        vs_rpt_concerns
        vs_rpt_positives
        vs_rpt_payment_details
        vs_ids{
           _id
           vs_title
           vs_periodicity{
              _id
           }
        }
        vs_rpt_start_date
        vs_rpt_end_date

        vs_rpt_added_date
        vs_rpt_added_id{
            _id
            user_fname
            user_lname
        }
        vs_rpt_archived
        vs_rpt_status
  }
}`;

const UPDATE_VS_REPORT = gql`
    mutation UpdateVerificationScheduleReport($vsRptId: String!, $userId: String, $vsRpt: UpdateVerificationScheduleReportInput!) {
        updateVerificationScheduleReport(_id: $vsRptId, userId: $userId, vsRpt: $vsRpt) {
            _id
        }
    }
`;

const DUTY_HOLDERS_QUERY = gql`
    query {
        dutyHolders {
            _id
            dh_name
            dh_status
            dh_added_date
            dh_archived
        }
}`;

const ASSETGROUPS_BY_DH_ID_QUERY = gql`
query AssetGroupsByDutyHolderId($dhId: ID){
    assetGroupsByDutyHolderId(dhId: $dhId) {
      _id
      ag_title
      ag_archived
      ag_status
  }
}`;

const BARRIERS_BY_DH_AG_INSLL_IDS_QUERY = gql`
query BarriersByDhIdAgIdInstId($dhId: ID, $agId: ID, $instId: ID){
    barriersByDhIdAgIdInstId(dhId: $dhId, agId: $agId, instId: $instId){
       _id
       bar_title
       bar_archived
       bar_status
    }
}`;

const INSTALLATIONS_BY_AG_ID_QUERY = gql`
query InstallationsByAssetGroupId($agId: ID){
    installationsByAssetGroupId(agId: $agId) {
      _id
      installation_title
      installation_archived
      installation_status
  }
}`;

const SECES_BY_DH_AG_INSTLL_IDS_QUERY = gql`
query SecesByDhAgInstllId($dhId: ID!, $agId: ID!, $instllId : ID!){
    secesByDhAgInstllId(dhId: $dhId, agId: $agId, instllId : $instllId){
       _id
       sece_title
       sece_order_no
       sece_archived
       sece_status
    }
}`;

const VA_BY_PS_ID_QUERY = gql`
query verificationActivitiesByPSId($psId: ID!){
    verificationActivitiesByPSId(psId: $psId){
        _id
        va_short_desc
        va_archived
        va_status
    }
}`;

const RA_BY_DH_ID_QUERY = gql`
query referenceActivitiesByDutyHolderId($dhId: ID!){
    referenceActivitiesByDutyHolderId(dhId: $dhId){
        _id
        ra_title
        ra_archived
        ra_status
    }
}`;

const PS_BY_SECE_ID_QUERY = gql`
query PerformanceStandardsBySeceId($seceId: ID!){
    performanceStandardsBySeceId(seceId: $seceId){
        _id
        ps_title
        ps_archived
        ps_status
    }
}`;

//const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
//    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
//        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
//            _id
//            sysvar_title
//            sysvar_archived
//            sysvartype_id{
//                _id
//               sysvartype_title
//            }
//        }
//}`;

const USERS_QUERY = gql`
query {
    users {
        _id
        user_fname
        user_lname
        user_archived
        user_duty_holder{
            _id
        }
        user_type {
            _id
            user_type_display_name
        }
        user_role{
            _id
            user_role_code
        }
    }
}`;

const formDefaultValue = {
    dh_id: "",
    ag_id: "",
    installation_id: "",
    bar_id: "",
    sece_id: "",
    ps_id: "",
    va_id: "",
    ra_id: "",
    author_id: "",
    report_type: "0",
    report_date: "",
}

function VerificationScheduleReports(props) {
    const [showHelp, setShowHelp] = useState(false);
    const [vsReportList, setVsReportList] = useState([]);
    const [showArchivedVsReports, setShowArchivedVsReports] = useState(false);

    const [selectedDhId, setSelectedDhId] = useState('');
    const [selectedAgId, setSelectedAgId] = useState('');
    const [selectedInstallId, setSelectedInstallId] = useState('');
    const [selectedSeceId, setSelectedSeceId] = useState('');
    const [selectedPsId, setSelectedPsId] = useState('');

    const [dutyHolderList, setDutyHolderList] = useState([]);
    const [assetGroupList, setAssetGroupList] = useState([]);
    const [barrierList, setBarrierList] = useState([]);
    const [installationList, setInstallationList] = useState([]);
    const [seceList, setSeceList] = useState([]);
    const [vaList, setVaList] = useState([]);
    const [raList, setRaList] = useState([]);
    const [psList, setPsList] = useState([]);
    const [authorList, setAuthorList] = useState([]);
    //const [findingLevelList, setFindingLevelList] = useState([]);

    const [formInitValues, setFormInitValues] = useState({});

    const [drDnDutyholder, setDrDnDutyholder] = useState({});
    const [drDnInstallation, setDrDnInstallation] = useState({});
    const [drDnSece, setDrDnSece] = useState({});
  
    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);

    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const { location: { state = "" }, match: { url = "" } } = props ? props : {};

    const [dutyHolders, {data: dutyHoldersData}] = useLazyQuery(DUTY_HOLDERS_QUERY);
    const [assetGroupsByDutyHolderId, {data: assetGroupsData}] = useLazyQuery(ASSETGROUPS_BY_DH_ID_QUERY);
    const [barriersByDhIdAgIdInstId, {data: barriersData}] = useLazyQuery(BARRIERS_BY_DH_AG_INSLL_IDS_QUERY);
    const [installationsByAssetGroupId, {data: installsData}] = useLazyQuery(INSTALLATIONS_BY_AG_ID_QUERY);
    const [secesByDhAgInstllId, {data: secesData}] = useLazyQuery(SECES_BY_DH_AG_INSTLL_IDS_QUERY);
    const [verificationActivitiesByPSId, {data: vaData}] = useLazyQuery(VA_BY_PS_ID_QUERY);
    const [referenceActivitiesByDutyHolderId, {data: raData}] = useLazyQuery(RA_BY_DH_ID_QUERY);
    const [performanceStandardsBySeceId, {data: psData}] = useLazyQuery(PS_BY_SECE_ID_QUERY);

    const [users, {data: usersData}] = useLazyQuery(USERS_QUERY);
    //const [systemVariablesBySysVarTypeIds, {error:sysError, data: sysVarsData}] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);

    const [verificationScheduleReportSearch, { loading, error, data: vsReportSearchData, refetch }] = useLazyQuery(VS_REPORT_SEARCH);
    const [updateVerificationScheduleReport, { data: updateVsReportData }] = useMutation(UPDATE_VS_REPORT);

    const sysVarTypeIds = [FINDING_LEVEL_SYSTEM_VARIABLE_TYPE_ID];

    useEffect(() => {
        if (authContext && authContext.user) {
            let result = createUserTypeAndRoleObject(authContext);
            setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        const { dutyHolder, installation, sece } = navContext;
        setDrDnDutyholder(dutyHolder);
        setDrDnInstallation(installation);
        setDrDnSece(sece);
      }, [navContext]);
    
    useEffect(() => {
        setFormInitValues({ ...formDefaultValue });
        if (state === "archived") {
            setShowArchivedVsReports(true);
        };
        dutyHolders({ variables: {}, errorPolicy: 'all' });
        setAuthorList([]) //Temp Measure
        //systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });
        users({ variables: {}, errorPolicy: 'all' });

        //This is a temporary measure to suppress errors
        if (sysVarTypeIds.length > 2) {
            referenceActivitiesByDutyHolderId({ variables: { dhId: "" }, errorPolicy: 'all' });
        }
    }, []);

    useEffect(() => {
        const payload = {
            ...( drDnDutyholder.id ? {dh_id: drDnDutyholder.id} : {}),
            ...( drDnInstallation.id ? {installation_id: drDnInstallation.id} : {}),
            ...( drDnSece.id ? {sece_id: drDnSece.id} : {})
         };
         verificationScheduleReportSearch({ variables: { param: {...payload} }, errorPolicy: 'all' });
    }, [showArchivedVsReports, url, drDnDutyholder, drDnInstallation, drDnSece]);

    //useEffect(() => {
    //    if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
    //        formatSysVarDataAndUpdateStates(
    //            sysVarsData.systemVariablesBySysVarTypeIds,
    //            setFindingLevelList,
    //            FINDING_LEVEL_SYSTEM_VARIABLE_TYPE_ID
    //        );
    //    }
    //}, [sysVarsData]);

    useEffect(() => {
        if (usersData && usersData.users) {
            let list = usersData.users;
            let authors = [];
            list.forEach(u => {
                const { _id: id = "", user_fname: firstName = "", user_lname: lastName = "" } = u ? u : {};
                let author = {
                    id: id,
                    name: firstName + " " + lastName
                }
                authors.push(author);
            });
            setAuthorList(authors);
        }
    }, [usersData]);

    useEffect(() => {
        if (vsReportSearchData && vsReportSearchData.verificationScheduleReportSearch) {
            const vsReport = vsReportSearchData.verificationScheduleReportSearch;
            const { _id: userId = "" } = authContext.user ? authContext.user : {};
            let formatedVsReportList = formatVsReportsData(vsReport, showArchivedVsReports, userId, state, userTypeAndRoleObject);
            setVsReportList(formatedVsReportList)
        }
    }, [vsReportSearchData]);

    useEffect(() => {
        if (selectedDhId !== '') {
            setAssetGroupList([]);
            assetGroupsByDutyHolderId({ variables: { dhId: selectedDhId }, errorPolicy: 'all' });
        }
    }, [selectedDhId]);

    useEffect(() => {
        if (selectedDhId !== '' && selectedAgId !== '') {
            setInstallationList([]);
            installationsByAssetGroupId({ variables: { agId: selectedAgId }, errorPolicy: 'all' });
        }
    }, [selectedAgId]);

    useEffect(() => {
        if (selectedDhId !== '' && selectedAgId !== '' && selectedInstallId !== '') {
            setBarrierList([]);
            setSeceList([]);
            barriersByDhIdAgIdInstId({ variables: { dhId: selectedDhId, agId: selectedAgId, instId: selectedInstallId }, errorPolicy: 'all' });
            secesByDhAgInstllId({ variables: { dhId: selectedDhId, agId: selectedAgId, instllId: selectedInstallId }, errorPolicy: 'all' });
        }
    }, [selectedInstallId]);

    useEffect(() => {
        if (selectedDhId !== '' && selectedAgId !== '' && selectedInstallId !== '' && selectedSeceId !== '') {
            setPsList([]);
            performanceStandardsBySeceId({ variables: { seceId: selectedSeceId }, errorPolicy: 'all' });
        }
    }, [selectedSeceId]);

    useEffect(() => {
        if (selectedDhId !== '' && selectedAgId !== '' && selectedInstallId !== '' && selectedSeceId !== '' && selectedPsId !== '') {
            setVaList([]);
            verificationActivitiesByPSId({ variables: { psId: selectedPsId }, errorPolicy: 'all' });
        }
    }, [selectedPsId]);

    useEffect(() => {
        if (dutyHoldersData && dutyHoldersData.dutyHolders) {
            let list = dutyHoldersData.dutyHolders;
            let newDutyHolderList = list.filter(dh => dh.dh_status === "approved" && dh.dh_archived !== true);
            setDutyHolderList(newDutyHolderList)
        }
    }, [dutyHoldersData]);

    useEffect(() => {
        if (assetGroupsData && assetGroupsData.assetGroupsByDutyHolderId) {
            let list = assetGroupsData.assetGroupsByDutyHolderId;
            let newAssetGroupList = list.filter(ag => ag.ag_status === "approved" && ag.ag_archived !== true);
            setAssetGroupList(newAssetGroupList);
        }
    }, [assetGroupsData]);

    useEffect(() => {
        if (barriersData && barriersData.barriersByDhIdAgIdInstId) {
            let list = barriersData.barriersByDhIdAgIdInstId;
            let newBarrierList = list.filter(bar => bar.bar_archived !== true);
            setBarrierList(newBarrierList);
        }
    }, [barriersData]);

    useEffect(() => {
        if (installsData && installsData.installationsByAssetGroupId) {
            let list = installsData.installationsByAssetGroupId;
            let newInstallList = list.filter(install => install.installation_status === "approved" && install.installation_archived !== true);
            setInstallationList(newInstallList);
        }
    }, [installsData]);

    useEffect(() => {
        if (secesData && secesData.secesByDhAgInstllId) {
            let list = secesData.secesByDhAgInstllId;
            let newSeceList = list.filter(sece => sece.sece_archived !== true);
            setSeceList(newSeceList);
        }
    }, [secesData]);

   //VA, RA, PS
    useEffect(() => {
        if (vaData && vaData.verificationActivitiesByPSId) {
            let list = vaData.verificationActivitiesByPSId;
            let verActList = list.filter(va => va.va_status === "approved" && va.va_archived !== true);
            setVaList(verActList);
        }
    }, [vaData]);

    useEffect(() => {
        if (raData && raData.verificationActivitiesByPSId) {
            let list = raData.verificationActivitiesByPSId;
            let refActList = list.filter(ra => ra.ra_archived !== true);
            setRaList(refActList);
        }
    }, [raData]);

    useEffect(() => {
        if (psData && psData.performanceStandardsBySeceId) {
            let list = psData.performanceStandardsBySeceId;
            let perfStanList = list.filter(ps => ps.ps_status === "approved" && ps.ps_archived !== true);
            setPsList(perfStanList);
        }
    }, [psData]);

    useEffect(() => {
        if (updateVsReportData && updateVsReportData.updateVerificationScheduleReport) {
            refetch();
        }
    }, [updateVsReportData]);

    const archiveManager = (reportId, isArchived, archivedId, archivedDate, userId) => {
        if (reportId !== "") {
            const payload = {
                vs_rpt_archived: isArchived ? false : true,
                vs_rpt_archived_id: isArchived ? archivedId : userId,
                vs_rpt_archived_date: isArchived ? archivedDate : new Date(),
                vs_rpt_action: REPORT_ACTION_REVIEW
            }
            updateVerificationScheduleReport({ variables: { vsRptId: reportId, userId: userId, vsRpt: payload } });
        }
    }

    const clearFilter = () => {
        setFormInitValues({...formDefaultValue});
        verificationScheduleReportSearch({ variables: { param: {} }, errorPolicy: 'all' });
    }

    function toggleModal() {
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    const columns = React.useMemo(
        () => [
            {
                Header: 'Duty Holder',
                accessor: 'dh_name', // accessor is the "key" in the data
            },
            {
                Header: 'Installation',
                accessor: 'installation_title', // accessor is the "key" in the data
            },
            {
                Header: 'Report Type',
                accessor: 'report_type', // accessor is the "key" in the data
            },
            {
                Header: 'Report Title',
                accessor: 'report_title', // accessor is the "key" in the data
            },
            {
                Header: 'Report Date',
                accessor: 'report_date', // accessor is the "key" in the data
            },
            {
                Header: 'Author',
                accessor: 'report_auth', // accessor is the "key" in the data
            },
            {
                Header: 'Status',
                accessor: 'formated_status', // accessor is the "key" in the data
            },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: ({ value: { id, is_archived, archived_id, archived_date, isPeriodic, user_id, status, userTypeAndRoleObject } }) => (
                    <div className="text-right" style={{ minWidth: '75px' }}>
                        <Comment sysObjName={sysObjects.verificationScheduleReport}
                            sysObjRecordId={id}
                            buttonName={"Comments"}
                            buttonClassName={"button-red mb-1 mr-1 text-xs"} />

                        {userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS" &&
                            <Link to={{
                                pathname: `/reporting/vs-report/view/${id}`,
                                search: `?periodic=${isPeriodic}`,
                            }}
                                className="button-red mb-1 mr-1 text-xs">
                                View
                            </Link>
                        }
                        {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                            <TableDropDown title="More" items={dropdownListItems({ id, is_archived, archived_id, archived_date, isPeriodic, user_id, status, archiveManager, userTypeAndRoleObject  })} />
                        }
                    </div>
                )
            },
        ],
        []
    )

    if (loading) {
        return (
            <PageSpinner />
        )
    }

    if (error) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">
        <div className="pb-6 border-b-2 border-gray-200">

            <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Reporting<FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

            {showHelp &&
            <div>
                {showArchivedVsReports === false ?
                <div>
                    <p className="mt-3">A table of active Asset / Periodic Reports. Click 'More' to view, edit and archive reports.  To filter the list shown, click 'Filter Reports'.</p>
                    <p>A new report can be added by clicking 'Add Report'.</p>
                </div>
                :
                <div>
                    <p className="mt-3">A table of archived Asset / Periodic Reports. Click 'More' to view, edit and unarchive reports.  To filter the list shown, click 'Filter Reports'.</p>
                </div>
                }
            </div>
            }

        </div>

        <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

        {(userTypeAndRoleObject &&
          userTypeAndRoleObject.userType === "ABS" && 
          drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Reporting - Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
        {(userTypeAndRoleObject &&
          userTypeAndRoleObject.userType === "Duty Holder" && 
          drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Reporting - Installation: {drDnInstallation.name}</h6>}
        {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Reporting - Duty Holder / Installation : All</h6>}

        {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                <Link
                    to={{
                        pathname: `/reporting/vs-report/report-index/add`,
                        state: { formMode: 'add' }
                    }}
                    className="button-red mr-2 mb-2 py-3 px-5"
                >
                    Add Report
                </Link>
        }

        <Link to={{
            pathname: showArchivedVsReports ? url : (url + "/archived"),
            state: showArchivedVsReports ? "" : "archived"
        }}
            className="button-red mr-2 mb-2 py-3 px-5"
            onClick={() => { setShowArchivedVsReports(!showArchivedVsReports) }}
        >
            {showArchivedVsReports ? "Active Reports" : "Archived Reports"}
        </Link>

        <Link to="#" className="button-red mr-2 mb-2 py-3 px-5 modal-button" onClick={() => toggleModal()} >Filter Reports</Link>
        <Link to="#" className="button-red mr-2 mb-2 py-3 px-5 modal-button" onClick={() => clearFilter()} >Clear Filter</Link>
        <Table columns={columns} data={vsReportList} />

        {/* SEARCH FILTER POP UP */}
            <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

                    <Formik
                        initialValues={formInitValues}
                        onSubmit={(values, action) => {
                            let filter = {
                                ...(values.dh_id !== "" && values.dh_id !== "Select Duty Holder" && { dh_id: values.dh_id }),
                                ...(values.ag_id !== "" && values.ag_id !== "Select Asset Group" && { ag_id: values.ag_id }),
                                ...(values.installation_id !== "" && values.installation_id !== "Select Installation" && { installation_id: values.installation_id }),
                                ...(values.bar_id !== "" && values.bar_id !== "Select Barrier" && { barrier_id: values.bar_id }),
                                ...(values.sece_id !== "" && values.sece_id !== "Select SECE" && { sece_id: values.sece_id }),
                                ...(values.va_id !== "" && values.va_id !== "Select Verification Activity" && { va_id: values.va_id }),
                                ...(values.ps_id !== "" && values.ps_id !== "Select Performance Standard" && { ps_id: values.ps_id }),
                                ...(values.ra_id !== "" && values.ra_id !== "Select Reference Activity" && { ra_id: values.ra_id }),
                                ...(values.author_id !== "" && values.author_id !== "Select Author" && { vs_rpt_added_id: values.author_id }),
                                ...(values.report_type !== "0" && values.report_type !== "Select Report Type" && { vs_rpt_is_periodic: (values.report_type === "1" ? true : false) }),
                                ...(values.report_date !== "" && values.report_date !== "Select Level" && { vs_rpt_added_date: values.report_date })
                            }
                            verificationScheduleReportSearch({ variables: { param: {...filter} }, errorPolicy: 'all' });
                            setFormInitValues({...values});
                            toggleModal();
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <form onSubmit={handleSubmit}>

                                <div className="mb-4 pb-2 border-b-2 border-gray-200">
                                    <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">Filter Reports</h2>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="dh_id" className="block mb-1 text-blue-900 font-semibold">Duty Holder</label>
                                    <select
                                        className="form-control block w-full"
                                        name="dh_id"
                                        id="dh_id"
                                        onChange={(event) => {
                                            setSelectedDhId(event.target.value);
                                            handleChange(event);
                                            setFieldValue("ag_id", "");
                                            setFieldValue("installation_id", "");
                                            setFieldValue("bar_id", "");
                                            setFieldValue("sece_id", "");
                                            setFieldValue("ps_id", "");
                                            setFieldValue("va_id", "");
                                            setFieldValue("ra_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.dh_id}
                                    >
                                        <option>Select Duty Holder</option>
                                        {dutyHolderList.map(dh =>
                                            <option key={dh._id} value={dh._id}>{dh.dh_name}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="ag_id" className="block mb-1 text-blue-900 font-semibold">Asset Group</label>
                                    <select
                                        className="form-control block w-full"
                                        name="ag_id"
                                        id="ag_id"
                                        onChange={(event) => {
                                            setSelectedAgId(event.target.value);
                                            handleChange(event);
                                            setFieldValue("installation_id", "");
                                            setFieldValue("bar_id", "");
                                            setFieldValue("sece_id", "");
                                            setFieldValue("ps_id", "");
                                            setFieldValue("va_id", "");
                                            setFieldValue("ra_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.ag_id}
                                    >
                                        <option>Select Asset Group</option>
                                        {assetGroupList.map(ag =>
                                            <option key={ag._id} value={ag._id}>{ag.ag_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="installation_id" className="block mb-1 text-blue-900 font-semibold">Installation</label>
                                    <select
                                        className="form-control block w-full"
                                        name="installation_id"
                                        id="installation_id"
                                        onChange={(event) => {
                                            setSelectedInstallId(event.target.value);
                                            handleChange(event);
                                            setFieldValue("bar_id", "");
                                            setFieldValue("sece_id", "");
                                            setFieldValue("ps_id", "");
                                            setFieldValue("va_id", "");
                                            setFieldValue("ra_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.installation_id}
                                    >
                                        <option>Select Installation</option>
                                        {installationList.map(inst =>
                                            <option key={inst._id} value={inst._id}>{inst.installation_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="bar_id" className="block mb-1 text-blue-900 font-semibold">Barrier</label>
                                    <select
                                        className="form-control block w-full"
                                        name="bar_id"
                                        id="bar_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.bar_id}
                                    >
                                        <option>Select Barrier</option>
                                        {barrierList.map(bar =>
                                            <option key={bar._id} value={bar._id}>{bar.bar_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="sece_id" className="block mb-1 text-blue-900 font-semibold">SECE</label>
                                    <select
                                        className="form-control block w-full"
                                        name="sece_id"
                                        id="sece_id"
                                        onChange={(event) => {
                                            setSelectedSeceId(event.target.value);
                                            handleChange(event);
                                            setFieldValue("ps_id", "");
                                            setFieldValue("va_id", "");
                                            setFieldValue("ra_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.sece_id}
                                    >
                                        <option>Select SECE</option>
                                        {seceList.map(sece =>
                                            <option key={sece._id} value={sece._id}>{sece.sece_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="ps_id" className="block mb-1 text-blue-900 font-semibold">Performance Standard</label>
                                    <select
                                        className="form-control block w-full"
                                        name="ps_id"
                                        id="ps_id"
                                        onChange={(event) => {
                                            setSelectedPsId(event.target.value);
                                            handleChange(event);
                                            setFieldValue("va_id", "");
                                            setFieldValue("ra_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.ps_id}
                                    >
                                        <option>Select Performance Standard</option>
                                        {psList.map(ps =>
                                            <option key={ps._id} value={ps._id}>{ps.ps_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="va_id" className="block mb-1 text-blue-900 font-semibold">Verification Activity</label>
                                    <select
                                        className="form-control block w-full"
                                        name="va_id"
                                        id="va_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.va_id}
                                    >
                                        <option>Select Verification Activity</option>
                                        {vaList.map(va =>
                                            <option key={va._id} value={va._id}>{va.va_short_desc}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="ra_id" className="block mb-1 text-blue-900 font-semibold">Reference Activity</label>
                                    <select
                                        className="form-control block w-full"
                                        name="ra_id"
                                        id="ra_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ra_id}
                                    >
                                        <option>Select Reference Activity</option>
                                        {raList.map(ra =>
                                            <option key={ra._id} value={ra._id}>{ra.ra_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="author_id" className="block mb-1 text-blue-900 font-semibold">Author</label>
                                    <select
                                        className="form-control block w-full"
                                        name="author_id"
                                        id="author_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.author_id}
                                    >
                                        <option>Select Author</option>
                                        {authorList.map(author =>
                                            <option key={author.id} value={author.id}>{author.name}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="report_type" className="block mb-1 text-blue-900 font-semibold">Report Type</label>
                                    <select
                                        className="form-control block w-full"
                                        name="report_type"
                                        id="report_type"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.report_type}
                                    >
                                        <option value={0}>Select Report Type</option>
                                        <option value={1}>Periodic</option>
                                        <option value={2}>Asset Verification</option>
                                    </select>
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="report_date" className="block mb-1 text-blue-900 font-semibold">Report Date</label>
                                    <DatePicker
                                        id="report_date"
                                        selected={values.report_date}
                                        dateFormat="d/MM/Y"
                                        name="report_date"
                                        onChange={date => setFieldValue('report_date', date)}
                                        onBlur={handleBlur}
                                        value={values.report_date}
                                        className="form-control block w-full"
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>

                                <button type="submit" className="mt-3 mb-4 button-red capitalize">Apply Filter</button>

                            </form>
                        )}
                    </Formik>

                </div>
            </div>
        {/* SEARCH FILTER POP UP END */}

    </div>
    );
}

function formatVsReportsData(vsReport, showArchivedVsReports, userId, state, userTypeAndRoleObject) {
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    let newReportList = [];

    if (vsReport && vsReport.length && userId !== "") {
        let tempVsReportList = vsReport.filter(vsRpt => showArchivedVsReports ? vsRpt.vs_rpt_archived === true : vsRpt.vs_rpt_archived !== true);
        tempVsReportList.forEach((report, index) => {

            let dutyHolderName = report.dh_id ? report.dh_id.dh_name : "N/A";
            let installation = report.installation_ids ? report.installation_ids[0] : [];
            let installTitle = installation ? installation.installation_title : "N/A";

            const { user_fname: authFirstName= "", user_lname: authLastName = "" } = report.vs_rpt_added_id ? report.vs_rpt_added_id : {};

            let reportDate = new Date(parseInt(report.vs_rpt_added_date));
            let reportDateString = reportDate.toLocaleDateString('en-GB', options);
            let formatedStatus = capitaliseFirstCase(report.vs_rpt_status);
            let newReport = {
                id: report._id,
                isPeriodic:report.vs_rpt_is_periodic,
                dh_name: dutyHolderName,
                installation_title: installTitle,
                report_type: report.vs_rpt_is_periodic ? "Periodic" : "Asset Verification",
                report_title: report.vs_rpt_title ? truncateString(report.vs_rpt_title, 25) : "N/A",
                report_description: report.vs_rpt_description ? <Sanitiser html={report.vs_rpt_description} />  : "N/A",
                report_date: reportDateString,
                report_auth: (authFirstName + " " + authLastName),
                user_id: userId,
                state: state,
                status: report.vs_rpt_status,
                is_archived: report.vs_rpt_archived,
                archived_id: report.vs_rpt_archived_id,
                archived_date: report.vs_rpt_archived_date,
                formated_status: formatedStatus,
                userTypeAndRoleObject: userTypeAndRoleObject
            };
            newReportList.push(newReport);
        })
    }

    return newReportList;
}

//function formatSysVarDataAndUpdateStates(list, setFindingLevelList, findingLevelTypeId) {
//    let findingLevelList = [];
//    findingLevelList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === findingLevelTypeId);
//    setFindingLevelList(findingLevelList);
//}

export default VerificationScheduleReports;