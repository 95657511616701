/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */

import React, {useEffect, useState, useContext} from 'react';
import ReactSelect from 'react-select';
import {Formik} from 'formik';
import * as Yup from 'yup';
import FormError from "../global/FormError";
import {Link, useParams, useHistory} from 'react-router-dom';
import {gql, useMutation, useLazyQuery} from '@apollo/client';
import {omit, lowerCase} from 'lodash';
import {AuthContext} from "../../context/authContext";
import TextEditor from '../../components/global/TextEditor';
import FileUpload from '../global/FileUpload';
import {parseJSONSafely, updateAttachmentData, removeLastNthParamFrmUrl, createUserTypeAndRoleObject} from "../../utils";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import makeAnimated from 'react-select/animated';
import {USER_NULL} from '../../constants';

const {REACT_APP_FILE_MANAGER_API} = process.env;

const validation = (formMode, installationId, barrierId, seceByOrderNoCount, seceByClientRefCount, anyEmptyPosition) => {
  let validation = {};
  let sharedValidationObject = {
    sece_title: Yup.string().label("sece_title").required("SECE Name is required"),
    sece_group: Yup.number()
      .default(1)
      .required("SECE Group is required")
      .integer("SECE Group must be an integer")
      .positive("SECE Group must be positive")
      .min(1, "SECE Group must be greater than 0"),
    sece_goal: Yup.string().label("sece_goal").required("Goal is required"),
    sece_comments: Yup.string().label("sece_comments").required("Comment is required"),
    sece_offshore_responsible: Yup.string()
      .label("sece_offshore_responsible")
      .required("SECE Offshore Responsible is required"),
    sece_onshore_responsible: Yup.string()
      .label("sece_onshore_responsible")
      .required("SECE Onshore Responsible is required"),
    sece_criticality: Yup.string().label("sece_criticality").required("Criticality is required"),
    sece_order_no_exist: Yup.boolean().default(seceByOrderNoCount > 0),
    sece_order_no: Yup.number()
      .integer("Order number must be an integer")
      .positive("Order number must be positive")
      .min(1, "Order number must be greater than 0")
      .when('sece_order_no_exist', {
        is: true,
        then: Yup.number().test("sece_order_no", "Order number already exists", function (value) {
          const { path, createError } = this;
          return createError({ path, message: "Order number already exists" });
        }),
      }),
    sece_client_ref_exist: Yup.boolean().default(seceByClientRefCount > 0),
    sece_client_ref: Yup.string()
      .label("sece_client_ref")
      .required("Client Ref is required")
      .when('sece_client_ref_exist', {
        is: true,
        then: Yup.string().test("sece_client_ref", "Client ref already exists", function (value) {
          const { path, createError } = this;
          return createError({ path, message: "Client ref already exists" });
        }),
      }),
    sece_mah_empty_position: Yup.boolean().default(anyEmptyPosition),
    mah_position_list: Yup.string()
      .when('sece_mah_empty_position', {
        is: true,
        then: Yup.string().test("mah_position_list", "All MAH must have their positions selected", function (value) {
          const { path, createError } = this;
          return anyEmptyPosition ? createError({ path, message: "All MAH must have their positions selected" }) : true;
        }),
      }),
  }

  if (installationId !== undefined && barrierId === undefined && formMode === 'add') {
    validation = Yup.object().shape({
      bar_id: Yup.string().label("bar_id").required("Barrier is required"),
      ...sharedValidationObject
    })
  }

  if (installationId !== undefined && barrierId !== undefined && formMode === 'add') {
    validation = Yup.object().shape({
      ...sharedValidationObject
    })
  }

  if (formMode === 'edit') {
    validation = Yup.object().shape({
      ...sharedValidationObject
    });
  }

  return validation;
}

const CREATE_SECE = gql`
    mutation AddSece($sece: CreateSece!) {
        createSece(sece: $sece) {
            _id
            dh_id{
                dh_name
            }
            ag_id{
                ag_title
            }
            installation_id{
                installation_title
            }
            bar_id{
                bar_title
            }
            sece_title
            sece_client_ref
            sece_criticality
            sece_ref
            sece_owner
            sece_offshore_responsible{
                user_fname
                user_lname
            }
            sece_attachment
            mah_ids{
                _id
                mah_title
            }
            sece_comments
            sece_order_no
            sece_position
            success
            status_code
            message
        }
    }
`;

const UPDATE_SECE = gql`
    mutation UpdateSece($id: String!, $sece: UpdateSeceInput!, $userId: String!) {
        updateSece(_id: $id, sece: $sece, userId: $userId) {
            _id
            dh_id{
                dh_name
            }
            ag_id{
                ag_title
            }
            installation_id{
                installation_title
            }
            bar_id{
                bar_title
            }
            sece_title
            sece_client_ref
            sece_criticality
            sece_ref
            sece_owner
            sece_offshore_responsible{
                user_fname
                user_lname
            }
            sece_attachment
            mah_ids{
                _id
                mah_title
            }
            sece_comments
            sece_order_no
            sece_position
            success
            status_code
            message
        }
    }
`;

const SECE_BY_ORDER_NO_QUERY = gql`
query SeceByOrderNo($orderNo: Int!, $installationId: ID, $barrierId: ID){
  seceByOrderNo(orderNo: $orderNo, installationId: $installationId, barrierId: $barrierId)
}`;

const SECE_BY_CLIENT_REF_QUERY = gql`
query SeceByClientRef($clientRef: String!, $dhId: ID!, $instId: ID!){
  seceByClientRef(clientRef: $clientRef, dhId: $dhId, instId: $instId)
}`;

const BARRIER_QUERY_2 = gql`
query BarrierSearch ($param: BarrierSearch!){
    barrierSearch (param: $param){
        _id
        bar_title
    }
}
`;

const SECE_QUERY = gql`
query Sece($seceId: ID!){
    sece(_id: $seceId) {
        sece_title
        sece_group
        sece_goal
        sece_ref
        sece_client_ref
        sece_criticality
        sece_owner
        mah_ids{
            _id
            mah_title
        }
        mah_positions
        dh_id{
            _id
            dh_name
        }
        ag_id{
            _id
            ag_title
        }
        installation_id{
            _id
            installation_title
        }
        bar_id{
            _id
            bar_title
        }
        sece_offshore_responsible{
            _id
            user_fname
            user_lname
        }
        sece_onshore_responsible{
          _id
          user_fname
          user_lname
        }
        sece_attachment
        sece_comments
        sece_order_no
        sece_position
        sece_conf_Level_ps_va_changed
        sece_conf_Level_sece_modified
    }
}
`;

const USER_QUERY = gql`
query {
    users{
        _id
        user_duty_holder{
          _id
        }
        user_fname
        user_lname
        user_type{
            user_type_display_name
        }
    }
}`;

const MAH_QUERY_2 = gql`
query MajorAccidentHazardSearch ($dhId: ID, $agId: ID, $instId: ID){
    majorAccidentHazardSearch (dhId: $dhId, agId: $agId, instId: $instId){
        _id
        mah_title
        mah_status
    }
}
`;

const AddEditSece = (props) => {
  const authContext = useContext(AuthContext);
  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);
  const history = useHistory();

  const [showHelp, setShowHelp] = useState(false);

  const [barriersQuery, setBarriersQuery] = useState([]);
  const [userList, setUsers] = useState([]);
  const [mahSelectOptions, setMahSelectOptions] = useState([]);

  const [seceByOrderNoCount, setSeceByOrderNoCount] = useState(undefined);
  const [enteredOrderNo, setEnteredOrderNo] = useState(undefined);
  const [seceByClientRefCount, setSeceByClientRefCount] = useState(undefined);
  const [enteredClientRef, setEnteredClientRef] = useState(undefined);
  const [mahPositionList, setMahPositionList] = useState([]);
  const [anyEmptyPosition, setAnyEmptyPosition] = useState(false);

  const { formMode, id, assetGroupId, installationId, barrierId, seceId } = useParams();
  const { location: { state = "" }, match: {url = ""} } = props ? props : {};

  const [formInitValues, setFormInitValues] = useState({
    dh_id: "",
    ag_id: "",
    bar_id: "",
    installation_id: "",
    sece_title: "",
    sece_group: 1,
    sece_goal: "",
    sece_client_ref: "",
    sece_criticality: "",
    sece_ref: "",
    sece_owner: "",
    sece_offshore_responsible: "",
    sece_onshore_responsible: "",
    sece_attachment: "[]",
    mah_ids: [],
    mah_positions: [],
    mah_position_list: "",
    sece_comments: "",
    sece_order_no: 0,
    sece_position: "",
    sece_conf_Level_ps_va_changed: false,
    sece_conf_Level_sece_modified: false
  });

  const [barrierSearch, { data: barrierSearchQueryData }] = useLazyQuery(BARRIER_QUERY_2);
  const [seceByOrderNo, { data: seceByOrderNoData }] = useLazyQuery(SECE_BY_ORDER_NO_QUERY);
  const [seceByClientRef, { data: seceByClientRefData }] = useLazyQuery(SECE_BY_CLIENT_REF_QUERY);
  const [sece, { data: SeceData }] = useLazyQuery(SECE_QUERY);
  const [users, { data: usersData }] = useLazyQuery(USER_QUERY);

  const [majorAccidentHazardSearch, { data: mahSearchQueryData }] = useLazyQuery(MAH_QUERY_2);

  const [createSece, { data: createSeceData }] = useMutation(CREATE_SECE);
  const [updateSece, { data: updateSeceData }] = useMutation(UPDATE_SECE);

  useEffect(() => {
  if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
  }
  }, [authContext]);

  useEffect(() => {
  if (userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS") {
      history.push('/access-permissions');
  }
  }, [userTypeAndRoleObject]);

  useEffect(() => {
    if (formMode === "edit" && seceId && seceId !== "") {
      sece({ variables: { seceId: seceId }, errorPolicy: "all" });
    }
    if (id && assetGroupId && installationId) {
      let queryParam = { dh_id: id, ag_id: assetGroupId, installation_id: installationId };
      barrierSearch({ variables: { param: queryParam }, errorPolicy: "all" });
      majorAccidentHazardSearch({
        variables: { dhId: id, agId: assetGroupId, instId: installationId },
        errorPolicy: "all"
      });
    }
    users({ variables: {}, errorPolicy: "all" });
  }, []);

  useEffect(() => {
    if (seceByOrderNoData) {
      if (formMode === "edit" && enteredOrderNo === formInitValues.sece_order_no) {
        setSeceByOrderNoCount(0);
      } else {
        setSeceByOrderNoCount(seceByOrderNoData.seceByOrderNo);
      }
    }
  }, [seceByOrderNoData]);

  useEffect(() => {
    if (seceByClientRefData) {
      if (formMode === "edit" && enteredClientRef === formInitValues.sece_client_ref) {
        setSeceByClientRefCount(0);
      } else {
        setSeceByClientRefCount(seceByClientRefData.seceByClientRef);
      }
    }
  }, [seceByClientRefData]);

  useEffect(() => {
    if (barrierSearchQueryData && barrierSearchQueryData.barrierSearch) {
      setBarriersQuery(barrierSearchQueryData.barrierSearch);
    }
  }, [barrierSearchQueryData]);

  useEffect(() => {
    if (usersData && usersData.users) {
      let tempUserList = usersData.users;
      let usersByDh = tempUserList.filter(u => u.user_duty_holder && u.user_duty_holder._id === id);
      setUsers(usersByDh);
    }
  }, [usersData]);

  useEffect(() => {
    if (mahSearchQueryData && mahSearchQueryData.majorAccidentHazardSearch) {
      setMahSelectOptions(mahSearchQueryData.majorAccidentHazardSearch.reduce(function (result, m) {
        if (m.mah_status === "approved" || m.mah_status === "pending") {
          result.push({ value: m._id, label: m.mah_title });
        }
        return result;
      }, []))
    }
  }, [mahSearchQueryData]);

  useEffect(() => {
    if (SeceData && SeceData.sece) {
      let seceObject = SeceData.sece;

      const { _id: offshore_responsible = USER_NULL } = seceObject.sece_offshore_responsible ? seceObject.sece_offshore_responsible : {};
      const { _id: onshore_responsible = USER_NULL } = seceObject.sece_onshore_responsible ? seceObject.sece_onshore_responsible : {};

      let mahPositionsString = seceObject.mah_positions && seceObject.mah_positions !== "" ? seceObject.mah_positions : "[]";
      let mahPositionList = parseJSONSafely(mahPositionsString);

      setFormInitValues({
        mah_ids: seceObject.mah_ids.map(mah => mah._id),
        mah_positions: mahPositionList,
        mah_position_list: "",
        dh_id: seceObject.dh_id ? seceObject.dh_id._id : "",
        ag_id: seceObject.ag_id ? seceObject.ag_id._id : "",
        installation_id: seceObject.installation_id ? seceObject.installation_id._id : "",
        bar_id: seceObject.bar_id ? seceObject.bar_id._id : "",
        sece_owner: seceObject.sece_owner ? seceObject.sece_owner : "",
        sece_title: seceObject.sece_title,
        sece_group: seceObject.sece_group ? seceObject.sece_group : 1,
        sece_goal: seceObject.sece_goal,
        sece_client_ref: seceObject.sece_client_ref,
        sece_criticality: seceObject.sece_criticality,
        sece_offshore_responsible: offshore_responsible,
        sece_onshore_responsible: onshore_responsible,
        sece_attachment: seceObject.sece_attachment !== null && seceObject.sece_attachment !== "" ? seceObject.sece_attachment : "[]",
        sece_comments: seceObject.sece_comments,
        sece_order_no: Number(seceObject.sece_order_no),
        sece_position: seceObject.sece_position,
        sece_conf_Level_ps_va_changed: seceObject.sece_conf_Level_ps_va_changed,
        sece_conf_Level_sece_modified: seceObject.sece_conf_Level_sece_modified
      })

      setMahPositionList(mahPositionList)
    }
  }, [SeceData]);

  useEffect(() => {
    if (mahPositionList && mahPositionList.length) {
      const emptyPosition = mahPositionList.some(mp => mp.position === "");
      setAnyEmptyPosition(emptyPosition);
    }
  }, [mahPositionList]);

  useEffect(() => {
    if (createSeceData) {
      const { success, message } = createSeceData.createSece;
      if (!success) {
        alert(message);
      }
      if (success) {
        if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined) {
          history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/seces`);
        }
        if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined) {
          history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/seces`);
        }
      }
    }
  }, [createSeceData, assetGroupId, installationId, barrierId]);

  useEffect(() => {
    if (updateSeceData) {
      const { success, message } = updateSeceData.updateSece;
      if (!success) {
        alert(message);
      }
      if (success) {
        if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined) {
          history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/seces`);
        }
        if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined) {
          history.push(`/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/seces`);
        }
      }
    }
  }, [updateSeceData, assetGroupId, installationId, barrierId]);

  let tempSeceAttachFileList = [];
  const handleSeceAttachFilesDescription = (item) => {
    let index = tempSeceAttachFileList.findIndex(f => f.name === item.name);
    if (index >= 0) {
      tempSeceAttachFileList[index] = item;
    } else {
      tempSeceAttachFileList.push(item);
    }
  };

  const handleOrderNoChange = (event) => {
    let orderNo = parseInt(event.target.value);
    setEnteredOrderNo(orderNo);
    seceByOrderNo({ variables: { orderNo: orderNo, installationId: installationId, barrierId: barrierId }, errorPolicy: 'all' });
  }

  const handleClientRefChange = (event, dhId, instId) => {
    let clientRef = event.target.value;
    setEnteredClientRef(clientRef);
    seceByClientRef({ variables: { clientRef: clientRef, dhId: dhId, instId: instId }, errorPolicy: 'all' });
  }

  const handleMahPositionAdd = (list, mahPositionList) => {
    if (list && list.length) {
      let tempMpList = [];
      let mpList = mahPositionList;

      if (mpList.length === 0) {
        list.map(l => {
          let tempMah = { mah_id: l.value, mah_title: l.label, position: "" };
          tempMpList.push(tempMah);
        });
        setMahPositionList([...tempMpList]);
      }

      if (mpList.length > 0 && list.length > mpList.length) {
        list.map(l => {
          let mahPosition = mpList.find(mp => mp.mah_id === l.value);
          if (!mahPosition) {
            let tempMah = { mah_id: l.value, mah_title: l.label, position: "" };
            tempMpList.push(tempMah);
          }
        });
        setMahPositionList([...mpList, ...tempMpList]);
      }

      if (mpList.length > 0 && list.length < mpList.length) {
        list.map(l => {
          let mahPosition = mpList.find(mp => mp.mah_id === l.value);
          if (mahPosition) {
            tempMpList.push(mahPosition);
          }
        });
        setMahPositionList([...tempMpList]);
      }

    } else {
      setMahPositionList([]);
    }
  }

  const handleMahPositionUpdate = (mpList, mahId, position) => {
    if (mahId !== "" && position !== "") {
      let index = mpList.findIndex(mp => mp.mah_id === mahId);
      let mahPosition = mpList.find(mp => mp.mah_id === mahId);
      if (index !== undefined && mahPosition) {
        mahPosition.position = position.target.value;
        mpList[index] = mahPosition;
        setMahPositionList([...mpList]);
      }
    }
  }

  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-3 border-b-2 border-gray-200">
        <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">{formMode} SECE <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
        <div>
            <p>Enter the details into the required fields, including adding attachments by clicking on 'Attach Files'.</p>
            <p>Once complete, click '<span className="capitalize">{formMode}</span> SECE' which will initiate a notification for review.</p>
        </div>
        }
      </div>

      <Formik
        enableReinitialize
        initialValues={omit(formInitValues, '__typename')}
        validationSchema={validation(
          formMode,
          installationId,
          barrierId,
          seceByOrderNoCount,
          seceByClientRefCount,
          anyEmptyPosition
        )}
        onSubmit={(values, actions) => {
          let newValues = omit(values, ['mah_position_list', 'sece_mah_empty_position']);
          let tempValues = { ...newValues };

          tempValues.mah_positions = JSON.stringify(mahPositionList);
          tempValues.sece_added_id = authContext.user._id;
          tempValues.sece_attachment = updateAttachmentData(tempValues.sece_attachment, tempSeceAttachFileList);

          tempValues.dh_id = id;
          tempValues.ag_id = assetGroupId;
          tempValues.installation_id = installationId;
          tempValues.sece_owner = id;

          if (barrierId && barrierId !== "") {
            tempValues.bar_id = barrierId;
          }

          if (formMode === 'add') {
            tempValues.sece_base_path = removeLastNthParamFrmUrl(url, 1);
            console.log("tempValues>>>>", tempValues);
            createSece({ variables: { sece: tempValues } })
          }

          if (formMode === 'edit') {
            updateSece({ variables: { id: seceId, sece: tempValues, userId: authContext.user._id} })
          }

        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          setFieldTouched,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>

            <div className="my-4">
              {displayBackLink(state, id, assetGroupId, installationId, barrierId)}
            </div>

            {barrierId === undefined &&
              <div>
                <div>
                  <label htmlFor="bar_id" className="block mb-1 text-blue-900 font-semibold">Barrier*</label>
                  <select
                    className="form-control block w-full md:w-1/2"
                    name="bar_id"
                    id="bar_id"
                    onChange={(e) => { handleChange(e) }}
                    onBlur={handleBlur}
                    value={values.bar_id}
                  >
                    <option value="">Select Barrier</option>
                    {barriersQuery.map((bar, index) => (
                      <option key={index} value={bar._id}>{bar.bar_title}</option>
                    ))}
                  </select>
                  <FormError touched={touched.bar_id} message={errors.bar_id} />
                </div>
              </div>
            }

            <div>
              <label htmlFor="sece_title" className="block mb-1 text-blue-900 font-semibold">SECE Name*</label>
              <input
                type="text"
                className="form-control block w-full md:w-1/2"
                name="sece_title"
                id="sece_title"
                placeholder="SECE Name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.sece_title}
              />
              <FormError touched={touched.sece_title} message={errors.sece_title} />
            </div>

            <div>
              <label htmlFor="sece_group" className="block mb-1 text-blue-900 font-semibold">SECE Group*</label>
              <input
                type="number"
                className="form-control block w-full md:w-1/2"
                name="sece_group"
                id="sece_group"
                placeholder="SECE Group"
                onChange={(event) => { handleChange(event) }}
                onBlur={handleBlur}
                value={values.sece_group}
              />
              <FormError touched={touched.sece_group} message={errors.sece_group} />
            </div>

            <div>
              <label htmlFor="sece_client_ref" className="block mb-1 text-blue-900 font-semibold">SECE Ref.*</label>
              <input
                type="text"
                className="form-control block w-full md:w-1/2"
                name="sece_client_ref"
                id="sece_client_ref"
                placeholder="Client Ref"
                onChange={(event) => { handleClientRefChange(event, id, installationId); handleChange(event) }}
                onBlur={handleBlur}
                value={values.sece_client_ref}
              />
              <FormError touched={touched.sece_client_ref} message={errors.sece_client_ref} />
            </div>

            <div>
              <label htmlFor="sece_criticality" className="block mb-1 text-blue-900 font-semibold">Criticality*</label>
              <select
                className="form-control block w-full md:w-1/2"
                name="sece_criticality"
                id="sece_criticality"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.sece_criticality}
              >
                <option value = {""}>Select Criticality</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="A">A</option>
                <option value="B">B</option>
                <option value="C">C</option>
                <option value="High">High</option>
                <option value="Medium">Medium</option>
                <option value="Low">Low</option>
              </select>
              <FormError touched={touched.sece_criticality} message={errors.sece_criticality} />
            </div>

            <div>
              <label htmlFor="sece_offshore_responsible" className="block mb-1 text-blue-900 font-semibold">SECE Offshore Responsible*</label>
              <select
                className="form-control block w-full md:w-1/2"
                name="sece_offshore_responsible"
                id="sece_offshore_responsible"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.sece_offshore_responsible}
              >
                <option value={""}>Select Offshore Responsible</option>
                {userList.map(user =>
                  <option value={user._id} key={user._id}>{user.user_fname} {user.user_lname}</option>
                )}
                <option value={String(USER_NULL)}>N/A</option>
              </select>
              <FormError touched={touched.sece_offshore_responsible} message={errors.sece_offshore_responsible} />
            </div>

            <div>
              <label htmlFor="sece_onshore_responsible" className="block mb-1 text-blue-900 font-semibold">SECE Onshore Responsible*</label>
              <select
                className="form-control block w-full md:w-1/2"
                name="sece_onshore_responsible"
                id="sece_onshore_responsible"
                onChange={(event) => { handleChange(event) }}
                onBlur={(event) => { handleBlur(event) }}
                value={values.sece_onshore_responsible}
              >
                <option value = {""}>Select Onshore Responsible</option>
                {userList.map(user =>
                  <option value={user._id} key={user._id}>{user.user_fname} {user.user_lname}</option>
                )}
                <option value={String(USER_NULL)}>N/A</option>
              </select>
              <FormError touched={touched.sece_onshore_responsible} message={errors.sece_onshore_responsible} />
            </div>

            <div className="mb-4">
              <label htmlFor="sece_attachment" className="block mb-1 text-blue-900 font-semibold">SECE attachment</label>
              {authContext.user && authContext.user.user_home_dir &&
                <FileUpload
                  onAttach={(files) => {
                    setFieldValue('sece_attachment', JSON.stringify(files))
                  }}
                  id='fileManager'
                  buttonText='Attach Files'
                  apiURL={REACT_APP_FILE_MANAGER_API}
                  toolbarText='Attach'
                  homeDir={`/${authContext.user.user_home_dir}/`}
                  showDescription={true}
                  handleFileDescription={handleSeceAttachFilesDescription}
                  tempFileList={tempSeceAttachFileList}
                  {...(formMode === 'edit' && { attachmentList: parseJSONSafely(values.sece_attachment ) ? parseJSONSafely(values.sece_attachment) : [] })}
                />
              }
              <FormError touched={touched.sece_attachment} message={errors.sece_attachment} />
            </div>

            <div className="mb-4">
              <label htmlFor="mah_ids" className="block mb-1 text-blue-900 font-semibold">Major Accident Hazards</label>
              <ReactSelect
                id="mah_ids"
                name="mah_ids"
                aria-label="mah_ids"
                className="w-full md:w-1/2 text-gray-900"
                classNamePrefix="react-select"
                options={mahSelectOptions}
                isMulti
                isSearchable
                components={makeAnimated()}
                onChange={selected => {
                  handleMahPositionAdd(selected, mahPositionList);
                  (setFieldValue('mah_ids', selected ? selected.map(item => item.value) : ''));
                }}
                onBlur={touched => setFieldTouched('mah_ids', touched)}
                closeMenuOnSelect={false}
                placeholder="Select Major Accident Hazards"
                value={mahSelectOptions.filter(mah => {
                  if (values.mah_ids) {
                    return values.mah_ids.find(m => mah.value === m)
                  }
                  return
                })}
              />
            </div>

            <input
              hidden
              name="sece_mah_empty_position"
              id="sece_mah_empty_position"
              value={anyEmptyPosition}
              aria-label="sece_mah_empty_position"
              readOnly
            />

            {mahPositionList && mahPositionList.length > 0 &&
              <table className="w-full md:w-1/2 mb-5 table-auto">
                <tbody>
                  <tr>
                    <th className="p-3 text-left text-blue-900 font-semibold border">MAH Title</th>
                    <th className="p-3 text-right text-blue-900 font-semibold border">Select Position</th>
                  </tr>

                  {mahPositionList.map((mp, index) =>
                    <tr key={index}>
                      <td className="p-3 border">{mp.mah_title}</td>
                      <td className="p-3 text-right border">
                        <div className="relative inline-block mr-2 align-middle select-none transition duration-200 ease-in">
                          <select
                            className="form-control block w-full md:w-full"
                            name={`mp-${index}`}
                            id={`mp-${index}`}
                            onChange={(position) => {
                              setFieldValue("mah_positions", position.target.value);
                              handleMahPositionUpdate(mahPositionList, mp.mah_id, position);
                              setFieldValue("sece_mah_empty_position", anyEmptyPosition);
                            }}
                            value={lowerCase((mahPositionList[index] && mahPositionList[index].position))}
                          >
                            <option value="select">Select</option>
                            <option value="left">Preventative (Left)</option>
                            <option value="right">Mitigation (Right)</option>
                            <option value="both">Both</option>
                          </select>
                        </div>
                      </td>
                    </tr>
                  )}
                  {anyEmptyPosition && <FormError touched={touched.mah_position_list} message={errors.mah_position_list} />}
                </tbody>
              </table>
            }

            <div>
              <label htmlFor="sece_goal" className="block mb-1 text-blue-900 font-semibold">Goal*</label>
              <TextEditor
                name="sece_goal"
                id="sece_goal"
                aria-label="sece_goal"
                onChange={details => setFieldValue('sece_goal', details)}
                onBlur={touched => setFieldTouched('sece_goal', touched)}
                value={values.sece_goal}
                wrapper="w-1/2"
              />
              <FormError touched={touched.sece_goal} message={errors.sece_goal} />
            </div>

            <div>
              <label htmlFor="sece_comments" className="block mb-1 text-blue-900 font-semibold">Comments*</label>
              <TextEditor
                name="sece_comments"
                id="sece_comments"
                aria-label="sece_comments"
                onChange={details => setFieldValue('sece_comments', details)}
                onBlur={touched => setFieldTouched('sece_comments', touched)}
                value={values.sece_comments}
                wrapper="w-1/2"
              />
              <FormError touched={touched.sece_comments} message={errors.sece_comments} />
            </div>

            <div>
              <label htmlFor="sece_order_no" className="block mb-1 text-blue-900 font-semibold">Order No.*</label>
              <input
                type="number"
                className="form-control block w-full md:w-1/2"
                name="sece_order_no"
                id="sece_order_no"
                placeholder="Order No."
                onChange={(event) => { handleOrderNoChange(event); handleChange(event) }}
                onBlur={handleBlur}
                value={values.sece_order_no}
              />
              <FormError touched={touched.sece_order_no} message={errors.sece_order_no} />
            </div>

            <table className="w-full md:w-1/2 mb-5 table-auto">
              <tbody>
                <tr>
                  <th className="p-3 text-left text-blue-900 font-semibold border">Confidence Level</th>
                  <th className="p-3 text-right text-blue-900 font-semibold border">Answer (N/Y)</th>
                </tr>

                <tr>
                  <td className="p-3 border">{"Any Significant changes to PS or Verification Activities in last 12 months?"}</td>
                  <td className="p-3 text-right border">
                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input type="checkbox"
                        name={"sece_conf_Level_ps_va_changed"} id={"sece_conf_Level_ps_va_changed"}
                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                        checked={values.sece_conf_Level_ps_va_changed}
                        onChange={(event) => { setFieldValue("sece_conf_Level_ps_va_changed", event.target.checked) }}
                      />
                      <label htmlFor={"sece_conf_Level_ps_va_changed"} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td className="p-3 border">{"Any Significant modifications to the SECE in last 12 months?"}</td>
                  <td className="p-3 text-right border">
                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                      <input type="checkbox"
                        name={"sece_conf_Level_sece_modified"} id={"sece_conf_Level_sece_modified"}
                        className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                        checked={values.sece_conf_Level_sece_modified}
                        onChange={(event) => { setFieldValue("sece_conf_Level_sece_modified", event.target.checked) }}
                      />
                      <label htmlFor={"sece_conf_Level_sece_modified"} className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <button type="submit" className="mt-3 button-red capitalize">{formMode} SECE</button>

          </form>
        )}
      </Formik>

    </div>
  )

}

function displayBackLink(state, id, assetGroupId, installationId, barrierId) {
  if (assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/seces` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']}/> Back</Link>
    )
  };
  if (assetGroupId !== undefined && installationId !== undefined && barrierId === undefined) {
    return (
      <Link to={{
        pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/seces` + (state && state.archivedStatus !== "" ? `/${state.archivedStatus}` : ""),
        state: state && state.archivedStatus
      }} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']}/> Back</Link>
    )
  };
}

export default AddEditSece;
