/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import DatePicker from 'react-datepicker';
import TableDropDown from '../global/TableDropDown';
import "react-datepicker/dist/react-datepicker.css";
import Table from '../global/Table';
import { gql, useMutation, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from '../global/pageSpinner';
import { AuthContext } from "../../context/authContext";
import Comment from '../global/Comment';
import {sysObjects} from "../../constants/sysObjectList";

import Sanitiser from "../global/sanitiser";

import {
    createUserTypeAndRoleObject, 
    capitaliseFirstCase, 
    valueFromInfoMatrixLevel,
    urlSearchParams,
    parseJSONSafely,
    removeEmptyProperties,
    formatDateToLocaleDateString
} from "../../utils/index";
import {NavContext} from '../../context/navContext';

import {
    CURRENT_STATUS_FINDINGS_SYSTEM_VARIABLE_TYPE_ID,
    STATUS_SYSTEM_VARIABLE_TYPE_ID,
    NATURE_SYSTEM_VARIABLE_TYPE_ID,
    ANSWERS_SYSTEM_VARIABLE_TYPE_ID,
    NOTIFICATION_STATUS_APPROVED,
    NOTIFICATION_STATUS_REVIEW,
    NOTIFICATION_STATUS_PENDING,
    SYS_VAR_ANSWER_YES_ID,
    FINDING_LEVEL_SYSTEM_VARIABLE_TYPE_ID,
    FINDING_ACTION_REVIEW,
    USER_TYPE_DUTY_HOLDER_ID,
    USER_TYPE_ABS_ID,
    NOTIFICATION_STATUS_CLOSED
} from '../../constants/';

import { lowerCase } from 'lodash';

const dropdownListItems = ({ 
    id, 
    is_archived, 
    archived_id, 
    archived_date, 
    rptId, 
    user_id, 
    workflow_status, 
    current_status, 
    archiveManager,
    recallManager 

   }) => {

    workflow_status =  lowerCase(workflow_status);

    let listItems = [
        {
            id: 1,
            name: 'View',
            link: `/reporting/finding/view/${id}`,
        },
        ...((
            workflow_status === NOTIFICATION_STATUS_PENDING 
            )  ? 
            [
                {
                    id: 3,
                    name: 'Recall',
                    link: "",
                    callback: () => { recallManager(id, user_id) }
                }
            ]:[]
        ),
        ...((
            workflow_status === NOTIFICATION_STATUS_REVIEW || 
            workflow_status === NOTIFICATION_STATUS_PENDING ||
            (workflow_status === NOTIFICATION_STATUS_APPROVED && current_status === "Closed")
            )  ? [] :
            [
                {
                    id: 2,
                    name: 'Edit',
                    link: rptId ? `/reporting/${rptId}/finding/edit/${id}` : `/reporting/finding/edit/${id}`,
                    state: rptId ? { formMode: 'edit', rptId: rptId} : { formMode: 'edit' }
                },
                {
                    id: 3,
                    name: is_archived ? 'Unarchive' : 'Archive',
                    link: "",
                    callback: () => { archiveManager(id, is_archived, archived_id, archived_date, user_id) }
                }
            ]
        )
    ]
    return listItems;
};

const FINDINGS_BY_FILTER_PARAMS_QUERY = gql`
query FindingSearch($param: FindingSearchInput!){
    findingSearch(filterParam: $param){
      _id
      dh_id{
        _id
        dh_name
      }
      ag_id{
        _id
        ag_title
      }
      installation_id{
        _id
        installation_title
      }
      barrier_id{
        _id
        bar_title
      }
      sece_ids{
        _id
        sece_title
        sece_ref
        sece_client_ref
      }
      va_id{
        _id
        va_ref
        va_client_ref
        va_type
        va_short_desc
      }
      va_ids{
        _id
        va_ref
        va_client_ref
        va_type
        va_short_desc
      }
      report_id{
          _id
      }
      finding_current_status{
        sysvar_title
      }
      finding_abs_responsible{
        _id
      }
      finding_dh_responsible{
        _id
      }
      finding_action_party{
        user_fname
        user_lname
      }
      finding_auth{
        _id
        user_fname
        user_lname
      }
      finding_ref
      finding_level{
        sysvar_title
      }
      finding_level_summary
      finding_infomatrix_level
      finding_synopsis
      finding_date

      finding_responses

      finding_closure_date
      finding_completion_date
      finding_added_date
      finding_archived
      finding_status

      finding_action_type

  }
}`;

const UPDATE_FINDING = gql`
    mutation UpdateFinding($fndId: String!, $userId: String, $fnd: UpdateFindingInput!) {
        updateFinding(_id: $fndId, userId: $userId, fnd: $fnd) {
            _id
        }
    }
`;

const SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS = gql`
    query SystemVariablesBySysVarTypeIds($sysVarTypeIds: [ID!]){
        systemVariablesBySysVarTypeIds(sysVarTypeIds: $sysVarTypeIds){
            _id
            sysvar_title
            sysvar_archived
            sysvartype_id{
                _id
                sysvartype_title
            }
        }
}`;

const DUTY_HOLDERS_QUERY = gql`
    query {
        dutyHolders {
            _id
            dh_name
            dh_status
            dh_added_date
            dh_archived
        }
}`;

const ASSETGROUPS_BY_DH_ID_QUERY = gql`
query AssetGroupsByDutyHolderId($dhId: ID){
    assetGroupsByDutyHolderId(dhId: $dhId) {
      _id
      ag_title
      ag_archived
      ag_status
  }
}`;

const BARRIERS_BY_DHID_AGID_INSTID_QUERY = gql`
query BarriersByDhIdAgIdInstId($dhId: ID, $agId: ID, $instId: ID){
    barriersByDhIdAgIdInstId(dhId: $dhId, agId: $agId, instId: $instId) {
       _id
       bar_title
       bar_archived
       bar_status
    }
}`;

const INSTALLATIONS_SEARCH_QUERY = gql`
query installationSearch($searchParam: InstallationSearchInput!){
    installationSearch(searchParam: $searchParam) {
      _id
      installation_title
      installation_archived
      installation_status
  }
}`;

const SECES_BY_DHID_AGID_INSTID_BARID_QUERY = gql`
query SecesByDhAgInstllId($dhId: ID, $agId: ID, $instllId : ID, $barId: ID){
    secesByDhAgInstllId(dhId: $dhId, agId: $agId, instllId : $instllId, barId: $barId) {
       _id
       sece_title
       sece_archived
       sece_status
    }
}`;

const VA_SEARCH_QUERY = gql`
query VerificationActivitySearch($param: VerificationActivitySearch!){
    verificationActivitySearch(param: $param){
        _id
        va_short_desc
        va_archived
        va_status
    }
}`;

const RA_SEARCH_QUERY = gql`
query ReferenceActivitySearch($param: ReferenceActivitySearchInput!){
    referenceActivitySearch(param: $param){
        _id
        ra_title
        ra_archived
        ra_status
    }
}`;

const PS_SEARCH_QUERY = gql`
query PerformanceStandardSearch($param: PerformanceStandardSearch!){
    performanceStandardSearch(param: $param){
        _id
        ps_title
        ps_archived
        ps_status
    }
}`;

const USERS_QUERY = gql`
query UserSearch($param: UserSearchInput!){
    user_search (param: $param){
        _id
        user_fname
        user_lname
        user_archived
        user_type {
            _id
            user_type_display_name
        }
        user_role{
            _id
            user_role_code
        }
    }
}`;

const DH_USERS_RESPONSIBLE_QUERY = gql`
query DhUsersResponsible($param: FindingPartyInput!){
    dh_users_responsible(param: $param) {
        _id
        user_fname
        user_lname
        user_type {
            _id
        }
    }
}`;

const ABS_USERS_RESPONSIBLE_QUERY = gql`
query ABSUsersResponsible($param: FindingPartyInput!){
    abs_users_responsible(param: $param) {
        _id
        user_fname
        user_lname
        user_type {
            _id
        }
    }
}`;

const ACTION_PARTIES_QUERY = gql`
query ActionParties($param: FindingPartyInput!){
    action_parties(param: $param) {
        _id
        user_fname
        user_lname
        user_type {
            _id
        }
    }
}`;

const RECALL_FINDING_FROM_APPROVAL = gql`
mutation RecallFindingFromApproval($fndId: String!, $userId: String){
    recallFindingFromApproval(_id: $fndId, userId: $userId) {
        success
        status_code
        message
    }
}`;

const formDefaultValue = {
    dh_id: "",
    ag_id: "",
    installation_id: "",
    bar_id: "",
    sece_id: "",
    va_id: "",
    ra_id: "",
    ps_id: "",

    author: "",
    action_party_id: "",
    abs_rep_id: "",
    dh_rep_id: "",

    status_id: "",
    level_id: "",
    nature_id: "",

    integrity: false,
    legislative: false,
    rogi: false,
    paperwork: false,
    actions_addressed: false,

    exam_date: "",
    finding_close_date: "",
    response_date: "",
    completion_date: "",

    last_modified_date: "",
    modifier_type: "",
    days_open: "",  
}

const findingModifiers = [
    { name: "ABS", id: USER_TYPE_ABS_ID },
    { name: "Duty Holder", id:USER_TYPE_DUTY_HOLDER_ID  },
]

function Findings(props) {
    const [showHelp, setShowHelp] = useState(false);
    const [findingList, setFindingList] = useState([]);
    const [showArchivedFindings, setShowArchivedFindings] = useState(false);
    const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

    const [selectedDutyHolderId, setSelectedDutyHolderId] = useState('Select Duty Holder');
    const [selectedAssetGroupId, setSelectedAssetGroupId] = useState('Select Asset Group');
    const [selectedInstallationId, setSelectedInstallationId] = useState('Select Installation');
    const [selectedBarrierId, setSelectedBarrierId] = useState('Select Barrier');
    const [selectedSeceId, setSelectedSeceId] = useState('Select SECE');
    const [selectedVAId, setSelectedVAId] = useState('Select Verification Activity');
    const [selectedRAId, setSelectedRAId] = useState('Select Reference Activity');
    const [selectedPSId, setSelectedPSId] = useState('Select Performance Standard');

    const [dutyHolderList, setDutyHolderList] = useState([]);
    const [assetGroupList, setAssetGroupList] = useState([]);
    const [installationList, setInstallationList] = useState([]);
    const [barrierList, setBarrierList] = useState([]);
    const [seceList, setSeceList] = useState([]);
    const [vaList, setVaList] = useState([]);
    const [raList, setRaList] = useState([]);
    const [psList, setPsList] = useState([]);
    const [modifierTypes, setModifierTypes] = useState([]);

    const [natureList, setNatureList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [findingLevelList, setFindingLevelList] = useState([]);
    const [answerOptionList, setAnswerOptionList] = useState([]);

    const [absRepList, setAbsRepList] = useState([]);
    const [dhRepList, setDhRepList] = useState([]);
    const [actionPartyList, setActionPartyList] = useState([]);

    const [authorList, setAuthorList] = useState([]);

    const [drDnDutyholder, setDrDnDutyholder] = useState({});
    const [drDnInstallation, setDrDnInstallation] = useState({});
    const [drDnSece, setDrDnSece] = useState({});
   // const [findingSearchParam, setFindingSearchParam] = useState({});

    const [formInitValues, setFormInitValues] = useState({});
    const [searchParams, setSearchParams] = useState({});
    const [filterModalParams, setFilterModalParams] = useState({});
    const [hasFilterParam, setHasFilterParam] = useState(false);

    const authContext = useContext(AuthContext);
    const navContext = useContext(NavContext);
    //const dashboardContext = useContext(DashboardContext);

    const [displayName, setDisplayName] = useState("Findings");

    const { reportId = "" } = useParams();
    const { location: {pathname = "", state = "", search = ""}} = props ? props : {};
    const queryString = urlSearchParams(search);

    const [user_search, {data: usersData}] = useLazyQuery(USERS_QUERY);
    const [systemVariablesBySysVarTypeIds, {data: sysVarsData}] = useLazyQuery(SYSTEM_VARIABLES_BY_SYS_VAR_TYPE_IDS);

    const [dutyHolders, {data: dutyHoldersData}] = useLazyQuery(DUTY_HOLDERS_QUERY);
    const [assetGroupsByDutyHolderId, {data: assetGroupsData}] = useLazyQuery(ASSETGROUPS_BY_DH_ID_QUERY);
    const [barriersByDhIdAgIdInstId, {data: barriersData}] = useLazyQuery(BARRIERS_BY_DHID_AGID_INSTID_QUERY);
    const [installationSearch, {data: installsData}] = useLazyQuery(INSTALLATIONS_SEARCH_QUERY);
    const [secesByDhAgInstllId, {data: secesData}] = useLazyQuery(SECES_BY_DHID_AGID_INSTID_BARID_QUERY);
    const [verificationActivitySearch, {data: vaData}] = useLazyQuery(VA_SEARCH_QUERY);
    const [referenceActivitySearch, {data: raData}] = useLazyQuery(RA_SEARCH_QUERY);
    const [performanceStandardSearch, {data: psData}] = useLazyQuery(PS_SEARCH_QUERY);

    const [dh_users_responsible, {data: dhUsersData}] = useLazyQuery(DH_USERS_RESPONSIBLE_QUERY);
    const [abs_users_responsible, {data: absUsersData}] = useLazyQuery(ABS_USERS_RESPONSIBLE_QUERY);
    const [action_parties, {data: actionPartiesData}] = useLazyQuery(ACTION_PARTIES_QUERY);

    const [findingSearch, { loading, error, data: findingSearchData, refetch }] = useLazyQuery(FINDINGS_BY_FILTER_PARAMS_QUERY);
    const [updateFinding, { data: updateFindingData }] = useMutation(UPDATE_FINDING);
    const [recallFindingFromApproval, { data: recallFindingData }] = useMutation(RECALL_FINDING_FROM_APPROVAL);

    const sysVarTypeIds = [
        CURRENT_STATUS_FINDINGS_SYSTEM_VARIABLE_TYPE_ID,
        STATUS_SYSTEM_VARIABLE_TYPE_ID,
                           NATURE_SYSTEM_VARIABLE_TYPE_ID,
                           ANSWERS_SYSTEM_VARIABLE_TYPE_ID,
                           FINDING_LEVEL_SYSTEM_VARIABLE_TYPE_ID];
                          
    useEffect(() => {
        console.log("selectedRAId>>>>", selectedRAId);
        if (state === "archived") {
            setShowArchivedFindings(true);
        }
        setModifierTypes(findingModifiers)
        setFormInitValues(formDefaultValue);
        systemVariablesBySysVarTypeIds({ variables: { sysVarTypeIds: sysVarTypeIds }, errorPolicy: 'all' });
        dutyHolders({ variables: {}, errorPolicy: 'all' });
        user_search({ variables: {param: {user_type : USER_TYPE_ABS_ID}}, errorPolicy: 'all' });
    }, []);

    useEffect(() => {
        const { dutyHolder, installation, sece } = navContext;
        setDrDnDutyholder(dutyHolder);
        setDrDnInstallation(installation);
        setDrDnSece(sece);
    }, [navContext]);

    //useEffect(() => {
    //  if (dashboardContext && dashboardContext.findingSearchParam) {
    //     setFindingSearchParam(dashboardContext.findingSearchParam)
    //  }
    //}, [dashboardContext]);

    useEffect(() => {
        if (authContext && authContext.user){
           let result = createUserTypeAndRoleObject(authContext);
           setUserTypeAndRoleObject(result);
        }
    }, [authContext]);

    useEffect(() => {
        fetchMultiSelectData(selectedDutyHolderId, selectedAssetGroupId, selectedInstallationId, selectedBarrierId, selectedSeceId, selectedPSId, selectedVAId)
    }, [selectedDutyHolderId, selectedAssetGroupId, selectedInstallationId, selectedBarrierId, selectedSeceId, selectedPSId, selectedVAId]);

    useEffect(() => {
        if (usersData && usersData.user_search) {           
            setAuthorList(usersData.user_search); //Temporary Measure
        }
    }, [usersData]);

    useEffect(() => {
        if (dhUsersData && dhUsersData.dh_users_responsible) {
            setDhRepList(dhUsersData.dh_users_responsible);
        }
    }, [dhUsersData]);

    useEffect(() => {
        if (absUsersData && absUsersData.abs_users_responsible) {
            setAbsRepList(absUsersData.abs_users_responsible);
        }
    }, [absUsersData]);

    useEffect(() => {
        if (actionPartiesData && actionPartiesData.action_parties) {
            setActionPartyList(actionPartiesData.action_parties);
        }
    }, [actionPartiesData]);

    useEffect(() => {
        if (dutyHoldersData && dutyHoldersData.dutyHolders) {
            let list = dutyHoldersData.dutyHolders;
            let newDutyHolderList = list.filter(dh => dh.dh_status === "approved" && dh.dh_archived !== true);
            setDutyHolderList(newDutyHolderList);
        }
    }, [dutyHoldersData]);

    useEffect(() => {
        if (assetGroupsData && assetGroupsData.assetGroupsByDutyHolderId) {
            let list = assetGroupsData.assetGroupsByDutyHolderId;
            let newAssetGroupList = list.filter(ag => ag.ag_status === "approved" && ag.ag_archived !== true);
            setAssetGroupList(newAssetGroupList);
        }
    }, [assetGroupsData]);

    useEffect(() => {
        if (barriersData && barriersData.barriersByDhIdAgIdInstId) {
            let list = barriersData.barriersByDhIdAgIdInstId;
            let newBarrierList = list.filter(bar => bar.bar_archived !== true);
            setBarrierList(newBarrierList);
        }
    }, [barriersData]);

    useEffect(() => {
        if (installsData && installsData.installationSearch) {
            let list = installsData.installationSearch;
            let newInstallList = list.filter(install => install.installation_archived !== true);
            setInstallationList(newInstallList);
        }
    }, [installsData]);

    useEffect(() => {
        if (secesData && secesData.secesByDhAgInstllId) {
            let list = secesData.secesByDhAgInstllId;
            let newSeceList = list.filter(sece => sece.sece_archived !== true);
            setSeceList(newSeceList);
        }
    }, [secesData]);

   //VA, RA, PS
    useEffect(() => {
        if (vaData && vaData.verificationActivitySearch) {
            let list = vaData.verificationActivitySearch;
            let verActList = list.filter(va => va.va_archived !== true);
            setVaList(verActList);
        }
    }, [vaData]);

    useEffect(() => {
        if (raData && raData.referenceActivitySearch) {
            let list = raData.referenceActivitySearch;
            let filteredList = list.filter(r => r.ra_archived !== true && r.ra_status === NOTIFICATION_STATUS_APPROVED);
            setRaList(filteredList);
        }
    }, [raData]);

    useEffect(() => {
        if (psData && psData.performanceStandardSearch) {
            let list = psData.performanceStandardSearch;
            let perfStanList = list.filter(ps => ps.ps_archived !== true);
            setPsList(perfStanList);
        }
    }, [psData]);

    useEffect(() => {
        let searchQueryParams = new URLSearchParams(search);

        let level = searchQueryParams.get("level");
        let completionStatus = searchQueryParams.get("completionStatus");
        let responseStatus = searchQueryParams.get("responseStatus");
        let userId = searchQueryParams.get("userId");
        let issueName = searchQueryParams.get("issueName");
        let barrierId = searchQueryParams.get("barrierId");
        let findingStatus = searchQueryParams.get("findingStatus");
        let examDate = searchQueryParams.get("examDate");
        let nature = searchQueryParams.get("nature");
        let actionPartyId = searchQueryParams.get("actionPartyId");
        let seceId = searchQueryParams.get("seceId");
        let seceTitle = searchQueryParams.get("seceTitle");
        let vsIdListString = searchQueryParams.get("vsIdList");
        let pageName = searchQueryParams.get("displayName");
        let archived = searchQueryParams.get("archived");
        let absDhResponsible = searchQueryParams.get("absDhResponsible");

        let vsId = searchQueryParams.get("vs_id");
        let vaId = searchQueryParams.get("va_id");

        let findingType = searchQueryParams.get("findingType");

        let origin = searchQueryParams.get("origin");
        let section = searchQueryParams.get("section");
        
        let vs_ids = [];
        let va_ids = [];
        let vsIdList = parseJSONSafely(vsIdListString);

        if (vsIdList && vsIdList.length) {
            vs_ids = vsIdList;
        }

        if (vsId && vsId !== "") {
            vs_ids = [...vs_ids, vsId]
        }

        if (vaId && vaId !== "") {
            va_ids = [...va_ids, vaId]
        }

        if (archived === null) {
            setShowArchivedFindings(false);
        }

        if (pageName && pageName !== "") {
            setDisplayName(pageName);
        } else {
            setDisplayName("Findings");
        }

        let dpDnSearchParams = {
            ...(drDnDutyholder.id && drDnDutyholder.id !== "" && { dh_id: drDnDutyholder.id }),
            ...(drDnInstallation.id && drDnInstallation.id !== "" && { installation_id: drDnInstallation.id }),
            sece_ids: drDnSece.id ? [drDnSece.id] : []    
        }

        let issuesQueryParam = generateIssuesQueryParam(issueName);

        if(issuesQueryParam.hasOwnProperty('finding_integrity') ||
           issuesQueryParam.hasOwnProperty('finding_legislative') || 
           issuesQueryParam.hasOwnProperty('finding_pw_process') ||
           issuesQueryParam.hasOwnProperty('finding_doc_update_required') ||
           issuesQueryParam.hasOwnProperty('finding_rogi')){
            issuesQueryParam =  {...issuesQueryParam, finding_status: 'open'};
        }

        let generalSearchParams = {
            report_id: reportId ? reportId : "",
            finding_added_id: userId ? userId : "",
            finding_level_summary: level ? capitaliseFirstCase(level) : "",
            completion_status: completionStatus ? completionStatus : "",
            response_status: responseStatus ? responseStatus : "",
            barrier_id: barrierId ? barrierId : "",
            finding_status: findingStatus ? findingStatus : "",
            exam_date: examDate ? examDate : "",
            finding_va_nature: nature ? nature.trim() : "",
            finding_action_party: actionPartyId ? actionPartyId : "",
            finding_abs_dh_responsible: absDhResponsible && absDhResponsible === "true" ? true : false,
            vs_ids: vs_ids,
            va_ids: va_ids,
            ...(findingType && findingType !== "" && { finding_type: findingType }),
            finding_archived: archived && archived === "true" ? true : false,
            ...(seceId && seceId !== "" && { sece_ids: [seceId] }),
            ...(seceTitle && seceTitle !== "" && { sece_title: seceTitle }),
            ...issuesQueryParam,
            ...(origin && origin !== "" && { finding_search_origin: origin }),
            ...(section && section !== "" && { finding_search_section: section })
        }
   
        let searchParams = {
            ...dpDnSearchParams,
            ...generalSearchParams
        }

        let payload = {
            ...filterModalParams,
            ...searchParams
        }

        setSearchParams(searchParams);
        findingSearch({ variables: { param: { ...payload } }, errorPolicy: 'all' });
        
    }, [search, drDnDutyholder, drDnInstallation, drDnSece]);

    useEffect(() => {
        if (findingSearchData && findingSearchData.findingSearch) {
            const { _id: userId = "" } = authContext.user ? authContext.user : {};

            let formatedfindingList = formatFindingsData(
                findingSearchData.findingSearch,
                userId,
                userTypeAndRoleObject);           
            setFindingList(formatedfindingList)
        }
    }, [findingSearchData]);

    useEffect(() => {
        if (updateFindingData && updateFindingData.updateFinding) {
            refetch();
        }
    }, [updateFindingData]);

    useEffect(() => {
        if (recallFindingData && recallFindingData.recallFindingFromApproval) {
            refetch();
        }
    }, [recallFindingData]);

    useEffect(() => {
        if (sysVarsData && sysVarsData.systemVariablesBySysVarTypeIds) {
            formatSysVarDataAndUpdateStates(sysVarsData.systemVariablesBySysVarTypeIds,
                setNatureList,
                setStatusList,
                setAnswerOptionList,
                setFindingLevelList,
                NATURE_SYSTEM_VARIABLE_TYPE_ID,
                CURRENT_STATUS_FINDINGS_SYSTEM_VARIABLE_TYPE_ID,
                ANSWERS_SYSTEM_VARIABLE_TYPE_ID,
                FINDING_LEVEL_SYSTEM_VARIABLE_TYPE_ID
            );
        }
    }, [sysVarsData]);

    const archiveManager = (findingId, isArchived, archivedId, archivedDate, userId) => {
        if (findingId !== "") {
            const payload = {
                finding_archived: isArchived ? false : true,
                finding_archived_id: isArchived ? archivedId : userId,
                finding_archived_date: isArchived ? archivedDate : new Date(),
                finding_action_type: FINDING_ACTION_REVIEW
            }
            updateFinding({ variables: { fndId: findingId, userId: userId, fnd: payload } });
        }
    }

    const recallManager = (findingId, userId) => {
        if (findingId !== "" && userId !== "") {
            recallFindingFromApproval({ variables: { fndId: findingId, userId: userId} });
        }
    }

    function toggleModal() {
        const modal = document.querySelector('.modal')
        modal.classList.toggle('opacity-0')
        modal.classList.toggle('pointer-events-none')
    }

    const fetchMultiSelectData = (dutyHolderId, assetGroupId, installationId, barrierId, seceId, psId, vaId) => {
        const installQueryParam = {
            ...(dutyHolderId !== "" && dutyHolderId !== "Select Duty Holder" && { dhId: dutyHolderId }),
            ...(assetGroupId !== "" && assetGroupId !== "Select Asset Group" && { agId: assetGroupId }),
        }

        const barQueryParam = {
            ...installQueryParam,
            ...(installationId !== "" && installationId !== "Select Installation" && { instId: installationId })
        }

        const seceQueryParam = {
            ...installQueryParam,
            ...(installationId !== "" && installationId !== "Select Installation" && { instllId: installationId }),
            ...(barrierId !== "" && barrierId !== "Select Barrier" && { barId: barrierId }),
        }

        const psQueryParam = {
            ...(dutyHolderId !== "" && dutyHolderId !== "Select Duty Holder" && { dh_id: dutyHolderId }),
            ...(assetGroupId !== "" && assetGroupId !== "Select Asset Group" && { ag_id: assetGroupId }),
            ...(installationId !== "" && installationId !== "Select Installation" && { installation_id: installationId }),
            ...(barrierId !== "" && barrierId !== "Select Barrier" && { bar_id: barrierId }),
            ...(seceId !== "" && seceId !== "Select SECE" && { sece_id: seceId })
        }

        const vaQueryParam = {
            ...psQueryParam,
            ...(psId !== "" && psId !== "Select Performance Standard" && { ps_id: psId }),
        }

        const raQueryParam = {
            ...vaQueryParam,
            ...(vaId !== "" && vaId !== "Select Verification Activity" && { va_id: vaId }),
        }
        
        if (dutyHolderId !== "" && dutyHolderId !== "Select Duty Holder") {
            assetGroupsByDutyHolderId({ variables: { dhId: dutyHolderId }, errorPolicy: 'all' });
        }

        installationSearch({
            variables: {
                searchParam: {
                    dh_id: installQueryParam.dhId,
                    ag_id: installQueryParam.agId,
                    installation_status: NOTIFICATION_STATUS_APPROVED
                }
            }, errorPolicy: 'all'
        });
        
        if (seceId !== "" && seceId !== "Select SECE") {
            let seceIdList = [seceId];
            dh_users_responsible({ variables: {param: { sece_ids: seceIdList }}, errorPolicy: 'all'});
        }

        if (installationId !== "" && installationId === "Select Installation") {
            setBarrierList([]);
            setSeceList([]);
            setVaList([]);
            //setAuthorList([]);
            setPsList([]);
        }

        if (installationId !== "" && installationId !== "Select Installation") {
            barriersByDhIdAgIdInstId({ variables: { ...barQueryParam }, errorPolicy: 'all' });
            secesByDhAgInstllId({ variables: { ...seceQueryParam }, errorPolicy: 'all' });
            performanceStandardSearch({ variables: { param: psQueryParam }, errorPolicy: 'all' });
            verificationActivitySearch({ variables: { param: vaQueryParam }, errorPolicy: 'all' });
            referenceActivitySearch({ variables: { param: raQueryParam }, errorPolicy: 'all' });

            abs_users_responsible({ variables: { param: { installation_id: installationId } }, errorPolicy: 'all' });
        }

        if (dutyHolderId !== "" && dutyHolderId !== "Select Duty Holder" &&
            installationId !== "" && installationId !== "Select Installation" &&
            seceId !== "" && seceId !== "Select SECE") 
        {
            let seceIdList = [seceId];           
            action_parties({ variables: { param: { dh_id: dutyHolderId, installation_id: installationId, sece_ids: seceIdList } }, errorPolicy: 'all' });
        }
    }

    const handleDutyHolderChange = (event) => {
        let value = event.target.value;
        setSelectedDutyHolderId(value);
    };

    const handleAssetGroupChange = (event) => {
        let value = event.target.value;
        setSelectedAssetGroupId(value);
    };

    const handleInstallationChange = (event) => {
        let value = event.target.value;
        setSelectedInstallationId(value);
    };

    const handleBarrierChange = (event) => {
        let value = event.target.value;
        setSelectedBarrierId(value);
    };

    const handleSeceChange = (event) => {
        let value = event.target.value;
        setSelectedSeceId(value);
    };

    const handlePSChange = (event) => {
        let value = event.target.value;
        setSelectedPSId(value);
    };

    const handleVAChange = (event) => {
        let value = event.target.value;
        setSelectedVAId(value);
    };

    const clearFilter = () => {
        setFormInitValues({ ...formDefaultValue });
        setSelectedDutyHolderId('Select Duty Holder');
        setSelectedAssetGroupId('Select Asset Group');
        setSelectedInstallationId('Select Installation');
        setSelectedBarrierId('Select Barrier');
        setSelectedSeceId('Select SECE');
        setSelectedVAId('Select Verification Activity');
        setSelectedRAId('Select Reference Activity');
        setSelectedPSId('Select Performance Standard');
        setDhRepList([]);
        setAbsRepList([]);
        setActionPartyList([]);
        setHasFilterParam(false);

        const additionalFindingSearchParam = {
            ...(drDnDutyholder.id && drDnDutyholder.id !== "" && { dh_id: drDnDutyholder.id }),
            ...(drDnInstallation.id && drDnInstallation.id !== "" && { installation_id: drDnInstallation.id }),
            sece_ids: drDnSece.id ? [drDnSece.id] : [],
            report_id: reportId && reportId !== "" ? reportId : "",
            ...searchParams
        };

        setFilterModalParams({});
        findingSearch({ variables: { param: { ...additionalFindingSearchParam } }, errorPolicy: 'all' });
        toggleModal();
    }

    const columns = React.useMemo(
        () => [
            // {
            //     Header: 'Duty Holder',
            //     accessor: 'dh_name',
            // },
            // {
            //     Header: 'Installation',
            //     accessor: 'installation_title',
            // },
            {
                Header: 'Finding Ref.',
                accessor: 'finding_ref',
            },
            {
                Header: 'SECE Ref.',
                accessor: 'sece_client_ref',
            },
            {
                Header: 'SECE Name',
                accessor: 'sece_title',
            },
            {
                Header: 'VA Ref.',
                accessor: 'va_ref',
            },
            // {
            //     Header: 'Verification Activity',
            //     accessor: 'verification_activity',
            // },
            {
                Header: 'Finding Level',
                accessor: 'finding_level',
            },
            {
                Header: 'Date Raised',
                accessor: 'finding_date',
                sortType: (a, b) => {
                    const { values: { finding_date: aDate = "" } } = a ? a : {};
                    const { values: { finding_date: bDate = "" } } = b ? b : {};
                    var a1 = new Date(aDate);
                    var b1 = new Date(bDate);
                    if (a1 < b1)
                      return 1;
                    else if (a1 > b1)
                      return -1;
                    else
                      return 0;
                }
            },
            {
                Header: 'Synopsis',
                accessor: 'finding_synopsis',
            },
            {
                Header: 'Action Party',
                accessor: 'finding_action_party',
            },
            {
                Header: 'Last Response',
                accessor: 'finding_last_response',
            },
            {
                Header: 'Date of Last Response',
                accessor: 'finding_date_of_last_response',
            },
            {
                Header: 'Last Responded to By',
                accessor: 'finding_last_responded_to_by',
            },
            {
                Header: 'Author',
                accessor: 'finding_auth',
            },
            {
                Header: 'Closure Date',
                accessor: 'finding_closure_date',
                sortType: (a, b) => {
                    const { values: { finding_closure_date: aDate2 = "" } } = a ? a : {};
                    const { values: { finding_closure_date: bDate2 = "" } } = b ? b : {};

                    if (aDate2 === "N/A" && bDate2 === "N/A") {
                        return 0; // Both are "N/A," consider them equal
                    } else if (aDate2 === "N/A") {
                        return 1; // a is "N/A," place it after b
                    } else if (bDate2 === "N/A") {
                        return -1; // b is "N/A," place it after a
                    }

                    var a2 = new Date(aDate2);
                    var b2 = new Date(bDate2);
                    if (a2 < b2)
                        return 1;
                    else if (a2 > b2)
                        return -1;
                    else
                        return 0;
                }
            },
            {
                Header: 'Current Status',
                accessor: 'current_status',
            },
            {
               Header: 'Approval Status',
               accessor: 'workflow_status',
            },
            {
                Header: '',
                id: 'buttons',
                accessor: originalRow => originalRow,
                Cell: ({ value: { id, is_archived, archived_id, archived_date, rptId, user_id, userTypeAndRoleObject, workflow_status, current_status } }) => (
                    <div className="text-right" style={{ minWidth: '75px' }}>
                       
                        {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                            <>
                                <Comment sysObjName={sysObjects.finding}
                                    sysObjRecordId={id}
                                    buttonName={"Comments"}
                                    buttonClassName={"button-red mb-1 mr-1 text-xs"} />
                                <TableDropDown title="More" items={dropdownListItems({ id, is_archived, archived_id, archived_date, rptId, user_id, workflow_status, current_status, archiveManager, recallManager })} />
                            </>
                        }

                        {/* {userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS" &&
                           lowerCase(workflow_status) === NOTIFICATION_STATUS_PENDING &&
                            <div className="inline">
                                <Link className="button-red mb-1 mr-1 text-xs" 
                                      to={{pathname: `/reporting/finding/view/${id}`}} >
                                    View
                                </Link>
                            </div>
                        } */}

                        {userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS" &&
                            <div className="inline">
                                <Link className="button-red mb-1 mr-1 text-xs" 
                                      to={{pathname: `/reporting/finding/view/${id}`}} >
                                    View
                                </Link>
                            </div>
                        }

                        {userTypeAndRoleObject && userTypeAndRoleObject.userType !== "ABS" &&
                            lowerCase(workflow_status) !== NOTIFICATION_STATUS_PENDING &&
                            lowerCase(current_status) !== NOTIFICATION_STATUS_CLOSED &&
                            <div className="inline">
                                <Link to={{
                                    pathname: rptId ? `/reporting/${rptId}/finding/edit/${id}` : `/reporting/finding/edit/${id}`,
                                    state: rptId ? { formMode: 'edit', rptId: rptId } : { formMode: 'edit' }
                                }}
                                    className="button-red mb-1 mr-1 text-xs">
                                    Respond
                                </Link>
                            </div>
                        }
                        
                    </div>
                )
            },
        ],
        []
    )

    if (loading) {
        return (
            <PageSpinner displayText = {"loading findings"}/>
        )
    }

    if (error) {
        return (
            <span>Something went wrong retrieving the table data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8 wide-table">

            <div className="pb-6 border-b-2 border-gray-200">
                <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">{displayName} <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

                {showHelp &&
                    <div>
                        {showArchivedFindings === false ?
                            <div>
                                <p className="mt-3">A table of Findings. Click 'More' to view, edit and archive a Finding.  To filter the Findings shown, click 'Filter Findings'.</p>
                                <p>To create a new Finding, click 'Add Finding'.</p>
                            </div>
                            :
                            <p className="mt-3">A table of archived Findings. Click 'More' to view, edit and unarchive a Finding.  To filter the Findings shown click 'Filter Findings'.</p>
                        }
                    </div>
                }

            </div>

            <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

            {(userTypeAndRoleObject &&
              userTypeAndRoleObject.userType === "ABS" && drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Findings - Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
            {(userTypeAndRoleObject &&
              userTypeAndRoleObject.userType === "Duty Holder" && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Findings - Installation: {drDnInstallation.name}</h6>}
            {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - Findings - Duty Holder / Installation : All</h6>}

            {/*
            <button type="button" onClick={() => { window.history.back() }} className="button-red mr-2 py-3 px-5"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button>
            */}

            {/* {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
                <Link
                    to={reportId === "" ? { pathname: '/reporting/finding/add', state: { formMode: 'add' } }
                        : { pathname: `/reporting/${reportId}/finding/verify`, state: { formMode: 'verify', rptId: reportId } }
                    }
                    className="button-red mr-2 mb-2 py-3 px-5"
                >
                    Add Finding
                </Link>
            } */}

            <Link to={{ pathname: pathname, search: getSearchParams(showArchivedFindings, queryString) }}
                className="button-red mr-2 mb-2 py-3 px-5"
                onClick={() => { setShowArchivedFindings(!showArchivedFindings) }}
            >
                {showArchivedFindings ? "Active Findings" : "Archived Findings"}
            </Link>



            <button className="button-red mr-2 mb-2 py-3 px-5 modal-button" 
                    onClick={() => toggleModal()} 
                    style={{ ...(hasFilterParam && { backgroundColor: '#a70000', textDecorationLine: 'underline' }) }}>Filter Findings</button>
            
            
            <Table columns={columns} data={findingList} />

            <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
                <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
                <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

                    <Formik
                        initialValues={formInitValues}
                        onSubmit={(values, action) => {

                            let filter = {
                                dh_id: values.dh_id !== "Select Duty Holder" ? values.dh_id : "",
                                ag_id: values.ag_id !== "Select Asset Group" ? values.ag_id : "",
                                installation_id: values.installation_id !== "Select Installation" ? values.installation_id : "",
                                barrier_id: values.bar_id !== "Select Barrier" ? values.bar_id : "",
                                sece_id: values.sece_id !== "Select SECE" ? values.sece_id : "",
                                va_id: values.va_id !== "Select Verification Activity" ? values.va_id : "",
                                ra_id: values.ra_id !== "Select Reference Activity" ? values.ra_id : "",
                                ps_id: values.ps_id !== "Select Performance Standard" ? values.ps_id : "",

                                report_id : reportId && reportId !== ""? reportId : "",

                                finding_auth: values.author !== "Select Author" ? values.author : "",

                                finding_action_party: values.action_party_id !== "Select Action Party" ? values.action_party_id : "",
                                finding_abs_responsible: values.abs_rep_id !== "Select ABS Responsible" ? values.abs_rep_id : "",
                                finding_dh_responsible: values.dh_rep_id !== "Select Duty Holder" ? values.dh_rep_id : "",

                                finding_current_status: values.status_id !== "Select Status" ? values.status_id : "",
                                //finding_level: values.level_id !== "Select Level" ? values.level_id : "",
                                finding_level: findingLevelMapper(values.level_id),
                                finding_nature: values.nature_id !== "Select Nature" ? values.nature_id : "",

                                finding_integrity: createFilterId(answerOptionList, values.integrity),
                                finding_legislative: createFilterId(answerOptionList, values.legislative),
                                finding_rogi: createFilterId(answerOptionList, values.rogi),
                                finding_pw_process: createFilterId(answerOptionList, values.paperwork),
                                finding_contingency_actions: createFilterId(answerOptionList, values.actions_addressed),

                                finding_exam_date: values.exam_date,
                                finding_closure_date: values.finding_close_date,
                                finding_response_date: values.response_date,
                                finding_completion_date: values.completion_date,

                                finding_modified_date: values.last_modified_date,
                                finding_days_open: values.days_open, 
                                finding_modifier_user_type_id: values.modifier_type   
                            }

                            removeEmptyProperties(searchParams);

                            let payload = {
                                ...filter,
                                ...searchParams
                            }

                            setFilterModalParams(filter)
                            findingSearch({ variables: { param: payload } });
                            setFormInitValues({ ...values });
                            setHasFilterParam(true);
                            toggleModal();
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            setFieldValue,
                            handleSubmit,
                            isSubmitting
                        }) => (
                            <form onSubmit={handleSubmit}>

                                <div className="mb-4 pb-2 border-b-2 border-gray-200">
                                    <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">Filter Findings</h2>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="dh_id" className="block mb-1 text-blue-900 font-semibold">Duty Holder</label>
                                    <select
                                        className="form-control block w-full"
                                        name="dh_id"
                                        id="dh_id"
                                        onChange={(event) => {
                                            handleChange(event);
                                            handleDutyHolderChange(event);
                                            setFieldValue("ag_id", "");
                                            setFieldValue("installation_id", "");
                                            setFieldValue("bar_id", "");
                                            setFieldValue("sece_id", "");
                                            setFieldValue("va_id", "");
                                            setFieldValue("ra_id", "");
                                            setFieldValue("ps_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.dh_id}
                                    >
                                        <option>Select Duty Holder</option>
                                        {dutyHolderList.map(dh =>
                                            <option key={dh._id} value={dh._id}>{dh.dh_name}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="ag_id" className="block mb-1 text-blue-900 font-semibold">Asset Group</label>
                                    <select
                                        className="form-control block w-full"
                                        name="ag_id"
                                        id="ag_id"
                                        onChange={(event) => {
                                            handleChange(event);
                                            handleAssetGroupChange(event);
                                            setFieldValue("installation_id", "");
                                            setFieldValue("bar_id", "");
                                            setFieldValue("sece_id", "");
                                            setFieldValue("va_id", "");
                                            setFieldValue("ra_id", "");
                                            setFieldValue("ps_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.ag_id}
                                    >
                                        <option>Select Asset Group</option>
                                        {assetGroupList.map(ag =>
                                            <option key={ag._id} value={ag._id}>{ag.ag_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="installation_id" className="block mb-1 text-blue-900 font-semibold">Installation</label>
                                    <select
                                        className="form-control block w-full"
                                        name="installation_id"
                                        id="installation_id"
                                        onChange={(event) => {
                                            handleChange(event);
                                            handleInstallationChange(event);
                                            setFieldValue("bar_id", "");
                                            setFieldValue("sece_id", "");
                                            setFieldValue("va_id", "");
                                            setFieldValue("ra_id", "");
                                            setFieldValue("ps_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.installation_id}
                                    >
                                        <option>Select Installation</option>
                                        {installationList.map(inst =>
                                            <option key={inst._id} value={inst._id}>{inst.installation_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="bar_id" className="block mb-1 text-blue-900 font-semibold">Barrier</label>
                                    <select
                                        className="form-control block w-full"
                                        name="bar_id"
                                        id="bar_id"
                                        onChange={(event) => {
                                            handleChange(event);
                                            handleBarrierChange(event);
                                            setFieldValue("sece_id", "");
                                            setFieldValue("va_id", "");
                                            setFieldValue("ra_id", "");
                                            setFieldValue("ps_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.bar_id}
                                    >
                                        <option>Select Barrier</option>
                                        {barrierList.map(bar =>
                                            <option key={bar._id} value={bar._id}>{bar.bar_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="sece_id" className="block mb-1 text-blue-900 font-semibold">SECE</label>
                                    <select
                                        className="form-control block w-full"
                                        name="sece_id"
                                        id="sece_id"
                                        onChange={(event) => {
                                            handleChange(event);
                                            handleSeceChange(event);
                                            setFieldValue("va_id", "");
                                            setFieldValue("ra_id", "");
                                            setFieldValue("ps_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.sece_id}
                                    >
                                        <option>Select SECE</option>
                                        {seceList.map(sece =>
                                            <option key={sece._id} value={sece._id}>{sece.sece_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="ps_id" className="block mb-1 text-blue-900 font-semibold">Performance Standard</label>
                                    <select
                                        className="form-control block w-full"
                                        name="ps_id"
                                        id="ps_id"
                                        onChange={(event) => {
                                            handleChange(event);
                                            handlePSChange(event);
                                            setFieldValue("va_id", "");
                                            setFieldValue("ra_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.ps_id}
                                    >
                                        <option>Select Performance Standard</option>
                                        {psList.map(ps =>
                                            <option key={ps._id} value={ps._id}>{ps.ps_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="va_id" className="block mb-1 text-blue-900 font-semibold">Verification Activity</label>
                                    <select
                                        className="form-control block w-full"
                                        name="va_id"
                                        id="va_id"
                                        onChange={(event) => {
                                            handleChange(event);
                                            handleVAChange(event);
                                            setFieldValue("ra_id", "");
                                        }}
                                        onBlur={handleBlur}
                                        value={values.va_id}
                                    >
                                        <option>Select Verification Activity</option>
                                        {vaList.map(va =>
                                            <option key={va._id} value={va._id}>{va.va_short_desc}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="ra_id" className="block mb-1 text-blue-900 font-semibold">Reference Activity</label>
                                    <select
                                        className="form-control block w-full"
                                        name="ra_id"
                                        id="ra_id"
                                        onChange={(event) => {
                                            handleChange(event);
                                        }}
                                        onBlur={handleBlur}
                                        value={values.ra_id}
                                    >
                                        <option>Select Reference Activity</option>
                                        {raList.map(ra =>
                                            <option key={ra._id} value={ra._id}>{ra.ra_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="author" className="block mb-1 text-blue-900 font-semibold">Author</label>
                                    <select
                                        className="form-control block w-full"
                                        name="author"
                                        id="author"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.author}
                                    >
                                        <option>Select Author</option>
                                        {authorList.map(author =>
                                            <option key={author._id} value={author._id}>{author.user_fname+" "+author.user_lname}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="abs_rep_id" className="block mb-1 text-blue-900 font-semibold">ABS Responsible</label>
                                    <select
                                        className="form-control block w-full"
                                        name="abs_rep_id"
                                        id="abs_rep_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.abs_rep_id}
                                    >
                                        <option>Select ABS Responsible</option>
                                        {absRepList.map(absRep =>
                                            <option key={absRep._id} value={absRep._id}>{absRep.user_fname+" "+absRep.user_lname}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="dh_rep_id" className="block mb-1 text-blue-900 font-semibold">Duty Holder Responsible</label>
                                    <select
                                        className="form-control block w-full"
                                        name="dh_rep_id"
                                        id="dh_rep_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.dh_rep_id}
                                    >
                                        <option>Select Duty Holder</option>
                                        {dhRepList.map(dhRep =>
                                            <option key={dhRep._id} value={dhRep._id}>{dhRep.user_fname+" "+dhRep.user_lname}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="action_party_id" className="block mb-1 text-blue-900 font-semibold">Action Party</label>
                                    <select
                                        className="form-control block w-full"
                                        name="action_party_id"
                                        id="action_party_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.action_party_id}
                                    >
                                        <option>Select Action Party</option>
                                        {actionPartyList.map(party =>
                                            <option key={party._id} value={party._id}>{party.user_fname+" "+party.user_lname}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="level_id" className="block mb-1 text-blue-900 font-semibold">Level</label>
                                    <select
                                        className="form-control block w-full"
                                        name="level_id"
                                        id="level_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.level_id}
                                    >
                                        <option>Select Level</option>
                                        {findingLevelList.map(level =>
                                            <option key={level._id} value={level.sysvar_title}>{level.sysvar_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="nature_id" className="block mb-1 text-blue-900 font-semibold">Nature</label>
                                    <select
                                        className="form-control block w-full"
                                        name="nature_id"
                                        id="nature_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.nature_id}
                                    >
                                        <option>Select Nature</option>
                                        {natureList.map(nature =>
                                            <option key={nature._id} value={nature.sysvar_title}>{nature.sysvar_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="status_id" className="block mb-1 text-blue-900 font-semibold">Status</label>
                                    <select
                                        className="form-control block w-full"
                                        name="status_id"
                                        id="status_id"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.status_id}
                                    >
                                        <option>Select Status</option>
                                        {statusList.map(status =>
                                            <option key={status._id} value={status._id}>{status.sysvar_title}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <p htmlFor="legislative" className="block mb-1 text-blue-900 font-semibold">Legislative Issues</p>
                                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox"
                                            name="legislative"
                                            id="legislative"
                                            checked={values.legislative}
                                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                                            onChange = {(event) => {setFieldValue('legislative', event.target.checked)}}
                                        />
                                        <label htmlFor="legislative" className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <p htmlFor="integrity" className="block mb-1 text-blue-900 font-semibold">Integrity Issues</p>
                                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox"
                                            name="integrity"
                                            id="integrity"
                                            checked={values.integrity}
                                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                                            onChange = {(event) => {setFieldValue('integrity', event.target.checked)}}
                                        />
                                        <label htmlFor="integrity" className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <p className="block mb-1 text-blue-900 font-semibold">Paperwork/Process Issues</p>
                                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox"
                                            name="paperwork"
                                            id="paperwork"
                                            checked={values.paperwork}
                                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                                            onChange = {(event) => {setFieldValue('paperwork', event.target.checked)}}
                                        />
                                        <label htmlFor="paperwork" className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <p htmlFor="rogi" className="block mb-1 text-blue-900 font-semibold">ROGI</p>
                                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox"
                                            name="rogi"
                                            id="rogi"
                                            checked={values.rogi}
                                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                                            onChange = {(event) => {setFieldValue('rogi', event.target.checked)}}
                                        />
                                        <label htmlFor="rogi" className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <p htmlFor="actions_addressed" className="block mb-1 text-blue-900 font-semibold">Actions Being Addressed</p>
                                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                        <input type="checkbox"
                                            name="actions_addressed"
                                            id="actions_addressed"
                                            checked={values.actions_addressed}
                                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-red-300 appearance-none cursor-pointer"
                                            onChange = {(event) => {setFieldValue('actions_addressed', event.target.checked)}}
                                        />
                                        <label htmlFor="actions_addressed" className="toggle-label block overflow-hidden h-6 rounded-full bg-red-300 cursor-pointer"></label>
                                    </div>
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="exam_date" className="block mb-1 text-blue-900 font-semibold">Exam Date</label>
                                    <DatePicker
                                        id="exam_date"
                                        selected={values.exam_date}
                                        dateFormat="d/MM/Y"
                                        name="exam_date"
                                        onChange={date => setFieldValue('exam_date', date)}
                                        onBlur={handleBlur}
                                        value={values.exam_date}
                                        className="form-control block w-full"
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="finding_close_date" className="block mb-1 text-blue-900 font-semibold">Close Down Date</label>
                                    <DatePicker
                                        id="finding_close_date"
                                        selected={values.finding_close_date}
                                        dateFormat="d/MM/Y"
                                        name="finding_close_date"
                                        onChange={date => setFieldValue('finding_close_date', date)}
                                        onBlur={handleBlur}
                                        value={values.finding_close_date}
                                        className="form-control block w-full"
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="response_date" className="block mb-1 text-blue-900 font-semibold">Response Date</label>
                                    <DatePicker
                                        id="response_date"
                                        selected={values.response_date}
                                        dateFormat="d/MM/Y"
                                        name="response_date"
                                        onChange={date => setFieldValue('response_date', date)}
                                        onBlur={handleBlur}
                                        value={values.response_date}
                                        className="form-control block w-full"
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="completion_date" className="block mb-1 text-blue-900 font-semibold">Completion Date</label>
                                    <DatePicker
                                        id="completion_date"
                                        selected={values.completion_date}
                                        dateFormat="d/MM/Y"
                                        name="completion_date"
                                        onChange={date => setFieldValue('completion_date', date)}
                                        onBlur={handleBlur}
                                        value={values.completion_date}
                                        className="form-control block w-full"
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="last_modified_date" className="block mb-1 text-blue-900 font-semibold">Last Modified Date</label>
                                    <DatePicker
                                        id="last_modified_date"
                                        selected={values.last_modified_date}
                                        dateFormat="d/MM/Y"
                                        name="last_modified_date"
                                        onChange={date => setFieldValue('last_modified_date', date)}
                                        onBlur={handleBlur}
                                        value={values.last_modified_date}
                                        className="form-control block w-full"
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="modifier_type" className="block mb-1 text-blue-900 font-semibold">Modified By</label>
                                    <select
                                        className="form-control block w-full"
                                        name="modifier_type"
                                        id="modifier_type"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.modifier_type}
                                    >
                                        <option>Select</option>
                                        {modifierTypes.map(mod =>
                                            <option key={mod.id} value={mod.id}>{mod.name}</option>
                                        )}
                                    </select>
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="days_open" className="block mb-1 text-blue-900 font-semibold">Days Open</label>
                                    <select
                                        className="form-control block w-full"
                                        name="days_open"
                                        id="days_open"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.days_open}
                                    >
                                        <option>Select</option>
                                        <option value="1">1 day</option>
                                        <option value="2">2 days</option>
                                        <option value="3">3 days</option>
                                        <option value="4">4 days</option>
                                        <option value="5">5 days</option>
                                        <option value="6">6 days</option>
                                        <option value="7">7+ days</option>
                                        <option value="14">14+ days</option>
                                        <option value="21">21+ days</option>
                                        <option value="28">28+ days</option>
                                    </select>
                                </div>

                                <button type="submit" className="mt-3 mb-4 button-red capitalize mr-4">Apply Filter</button>
                                <button type="button" onClick={() => { clearFilter() }} className="mt-3 mb-4 button-red capitalize">Clear Filter</button>

                            </form>
                        )}
                    </Formik>

                </div>
            </div>

        </div>
    )
}

const getSearchParams = (showArchieve, queryParam) => {
    if (showArchieve) {
        queryParam.delete("archived");
        return queryParam.toString();
    }
    queryParam.append("archived", true);
    return queryParam.toString();
}

function formatFindingsData(findingList, userId, userTypeAndRoleObject) {
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    let newFindingList = [];
    if (findingList && findingList.length && userId !== "" && userTypeAndRoleObject) {
        let tempFindingList = [];
        tempFindingList = findingList;

        // if (userTypeAndRoleObject.userType === "ABS") {
        //    tempFindingList = findingList.filter(fnd => fnd.finding_abs_responsible === userId);
        // }

        // if (userTypeAndRoleObject.userType === "Duty Holder") {
        //     tempFindingList = findingList.filter(fnd => fnd.finding_dh_responsible === userId );
        // }

        tempFindingList.forEach(finding => {
            let dutyHolderName = finding.dh_id ? finding.dh_id.dh_name : "N/A";
            let installTitle = finding.installation_id ? finding.installation_id.installation_title : "N/A";
           
            let tempSeceTitles = finding.sece_ids ? finding.sece_ids.map(s => s.sece_title) : [];
            let tempSeceRefs = finding.sece_ids ? finding.sece_ids.map(s => s.sece_ref) : [];
            let tempSeceClientRefs = finding.sece_ids ? finding.sece_ids.map(s => s.sece_client_ref) : [];

            let seceTitle = tempSeceTitles.length > 0 ? tempSeceTitles.join(", ") : "N/A";
            let seceRef = tempSeceRefs.length > 0 ? tempSeceRefs.join(", ") : "N/A";
            let seceClientRef = tempSeceClientRefs.length > 0 ? tempSeceClientRefs.join(", ") : "N/A";
            
            let verActDesList= finding.va_ids ? finding.va_ids.map(va => va.va_short_desc) : [];
            let vaClientRefList = finding.va_ids ? finding.va_ids.map(va => va.va_client_ref) : []; 

            let verActDes = formatVaDesc(verActDesList);
            let vaClientRef = formatVaRef(vaClientRefList);
           
            //let verActDes= finding.va_id ? finding.va_id.va_short_desc : "N/A";
            //let vaClientRef = finding.va_id && finding.va_id.va_client_ref ? finding.va_id.va_client_ref : "N/A"; 

            let currentStatus = finding.finding_current_status && finding.finding_current_status.sysvar_title ? finding.finding_current_status.sysvar_title : "N/A";

            //let findingStatus = finding.finding_status && finding.finding_status ? capitaliseFirstCase(finding.finding_status) : "N/A";
            let findingStatus = modifyFindingStatus(finding.finding_status);
            
            //let findingLevel = finding.finding_level&&finding.finding_level.sysvar_title? finding.finding_level.sysvar_title : "N/A";
            let findingLevel = valueFromInfoMatrixLevel(finding.finding_infomatrix_level);

            const { user_fname: partyFirstName = "", user_lname: partyLastName = "" } = finding.finding_action_party ? finding.finding_action_party : {};
            const actionPart = partyFirstName && partyLastName ? (partyFirstName + " " + partyLastName) : "N/A";
            
            const {user_fname: authFName, user_lname: authLName } = finding.finding_auth ? finding.finding_auth : {};
            const author = authFName && authLName ?  `${authFName} ${authLName}` : "N/A";

            let closureDateString = "N/A";

            if (finding.finding_closure_date !=="") {
                let closureDate = new Date(finding.finding_closure_date); //Need Updating with the correct date.
                closureDateString = closureDate.toLocaleDateString('en-GB', options);
            }
        
            let findingDate = new Date(finding.finding_added_date); //Need Updating with the correct date.
            let findingDateString = findingDate.toLocaleDateString('en-GB', options);

            let findingSynopsis = finding.finding_synopsis && finding.finding_synopsis !== "" ? finding.finding_synopsis : "N/A";
            let findingRef = finding.finding_ref && finding.finding_ref !== "" ? finding.finding_ref : "N/A";

            const { _id: rptId = "" } = finding.report_id ? finding.report_id : {};

            let findingResponses = finding.finding_responses ? finding.finding_responses : "[]";

            const responses = parseJSONSafely(findingResponses) ? parseJSONSafely(findingResponses) : [];
            const lastResponse = responses[responses.length - 1];
      
            const {response_note = "N/A", response_update_by = "N/A", response_update_date = "" } = lastResponse ?? {};

            let newFinding = {
                id: finding._id,
                dh_name: dutyHolderName,
                installation_title: installTitle,
                finding_ref: findingRef,
                rptId: rptId,
                sece_ref: seceRef,
                sece_client_ref: seceClientRef,
                sece_title: seceTitle,
                va_ref: vaClientRef  ? vaClientRef : "N/A",
                verification_activity: verActDes,
                finding_level: findingLevel,
                finding_date: findingDateString,
                finding_synopsis:findingSynopsis,
                finding_action_party: actionPart,
                finding_auth: author,
                finding_closure_date: closureDateString,
                current_status: currentStatus,
                user_id: userId,
                workflow_status: findingStatus,
                finding_status: finding.finding_status,
                is_archived: finding.finding_archived,
                archived_id: finding.finding_archived_id,
                archived_date: finding.finding_archived_date,
                userTypeAndRoleObject: userTypeAndRoleObject,

                finding_last_response: response_note ? <Sanitiser html={response_note} /> : "N/A",
                finding_date_of_last_response: formatDateToLocaleDateString(response_update_date) ?? "N/A",
                finding_last_responded_to_by: response_update_by,
            };

            newFindingList.push(newFinding);
        })
    };

    return newFindingList;
}

function formatVaRef(vaRefList) {
    let newVaRefList = "N/A";
    if (vaRefList && vaRefList.length) {
        let numberOfVaRefs = vaRefList.length;

        if (numberOfVaRefs > 1) {
            let tempVaRefs = "";
            vaRefList.forEach((ref, index) => {
                tempVaRefs += (ref === null? "N/A": ref) + ((index + 1) < numberOfVaRefs ? ", " : "") + "\n";
            });
            newVaRefList = tempVaRefs;
        } else {
            let ref = vaRefList[0]
            newVaRefList = ref;
        }
    }
    return newVaRefList;
}

function formatVaDesc(vaDescList) {
    let newVaDescList = "N/A";
    if (vaDescList && vaDescList.length) {
        let numberOfVaDescs = vaDescList.length;
        if (numberOfVaDescs > 1) {
            let tempVaDescs = "";
            vaDescList.forEach((desc, index) => {
                tempVaDescs += (desc === null ? "N/A" : desc) + ((index + 1) < numberOfVaDescs ? ", " : "") + "\n";
            });
            newVaDescList = tempVaDescs;
        } else {
            let desc = vaDescList[0];
            newVaDescList = desc;
        }
    }
    return newVaDescList;
}

function formatSysVarDataAndUpdateStates(list, setNatureList, setStatusList, setAnswerOptionList, setFindingLevelList, natureTypeId, statusTypeId, answerTypeId, findingLevelTypeId) {
    let natureList = [];
    let statusList = [];
    let answerList = [];
    let findingLevelList = [];

    natureList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === natureTypeId);
    statusList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === statusTypeId  && (sysVar.sysvar_title === "Open" || sysVar.sysvar_title === "Closed"));
    answerList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === answerTypeId);
    findingLevelList = list.filter(sysVar => sysVar.sysvartype_id && sysVar.sysvartype_id._id === findingLevelTypeId);

    setNatureList(natureList);
    setStatusList(statusList);
    setAnswerOptionList(answerList);
    setFindingLevelList(findingLevelList);
}

function createFilterId(answerList, checkValue) {
    let filterId = "";
    if (answerList && answerList.length) {
        if (checkValue) {
            let answer = answerList.find(ans => ans.sysvar_title === "Yes");
            filterId = answer ? answer._id : "";
        } else {
            //let answer = answerList.find(ans => ans.sysvar_title === "No");
            //filterId = answer ? answer._id : "";
            filterId = undefined;
        }
    }
    return filterId;
}

function generateIssuesQueryParam(name) {
    let issueParam = {};
    let list = createIssueNameList(name);
    if (list && list.length) {
        for (let index = 0; index < list.length; index++) {
            const issueName = list[index];
            switch (issueName) {
                case "Integrity Issue":
                    issueParam.finding_integrity = SYS_VAR_ANSWER_YES_ID;
                    break;
                case "Legislative Issue":
                    issueParam.finding_legislative = SYS_VAR_ANSWER_YES_ID;
                    break;
                case "Paperwork / Process Issue":
                    issueParam.finding_pw_process = SYS_VAR_ANSWER_YES_ID;
                    break;
                case "Document Update Required":
                    issueParam.finding_doc_update_required = SYS_VAR_ANSWER_YES_ID;
                    break;
                case "ROGI Findings":
                    issueParam.finding_rogi = SYS_VAR_ANSWER_YES_ID;
                    break;
                default:
                    break;
            }
        }
    }
    return issueParam;
}

function createIssueNameList(name) {
    let nameList = [];
    if (name && name !== "") {
        let names = name.split("&")
        let trimmedNames = names.map(n => n.trim());
        nameList = [...trimmedNames];
    }
    return nameList;
}

function modifyFindingStatus(status) {
    let newStatus = "";
    if (status && status !== "") {
        status = lowerCase(status);
        switch (status) {
            case "open":
                newStatus = "unapproved";
                break;
            case "closed":
                newStatus = "approved";
                break;
            default:
                newStatus = status;
                break;
        }
        newStatus = capitaliseFirstCase(newStatus);
    }
    return newStatus;
}

// function modifyFindingStatusAlt(status) {
//     let newStatus = "";
//     if (status && status !== "") {
//         status = lowerCase(status);
//         switch (status) {
//             case "approved":
//                 newStatus = status;
//                 break;
//             default:
//                 newStatus = "unapproved";
//                 break;
//         }
//         newStatus = capitaliseFirstCase(newStatus);
//     }
//     return newStatus;
// }

function findingLevelMapper(levelTitle) {
    let summaryLevel = "";
    if (levelTitle && levelTitle !== "") {
        switch (levelTitle) {
            case "1":
            case "A":
            case "High":
                summaryLevel = "High";
                break;
            case "2":
            case "B":
            case "Medium":
                summaryLevel = "Medium";
                break;
            case "3":
            case "C":
            case "Low":
                summaryLevel = "Low";
                break;
            default:
                break;
        }
    }
    return summaryLevel;
}

export default Findings;