/* eslint-disable no-multi-str */

import React, {Component} from 'react';
import { Editor } from '@tinymce/tinymce-react'; 

class TextEditor extends Component{
  
    render(){
      return <div className={this.props.wrapper}>
      <Editor
        apiKey="3n8nq2st3vx5watcn5i4uzeusgmrpdg075hspyhn06l84790"
        //initialValue={this.props.value}
        init={{
          content_style:"@import url('https://fonts.googleapis.com/css2?family=Maitree:wght@300;400;500;600;700&display=swap'); body{font-family: Maitree, serif;}",
          height: 350,
          menubar: 'edit format',
          menu : {
            edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall'},
            format: {title: 'Format', items: 'bold italic underline strikethrough | forecolor backcolor | removeformat'},
          },
          plugins: [
            'autolink lists link', 
            'charmap anchor',
            'searchreplace',
            'insertdatetime paste table wordcount'
          ],
          toolbar:
            'undo redo | bold italic backcolor | \
            alignleft aligncenter alignright | \
            bullist numlist outdent indent | table',
          table_advtab: true,
          table_cell_advtab: true, 
          table_row_advtab: true, 
        }}
        onBlur={this.props.onBlur}
        className="z-30"
        id={this.props.id}
        onEditorChange = {this.props.onChange}
        value = {this.props.value}
        readonly={this.props.readonly}
        disabled={this.props.disabled}
      />
    </div>
    }
  }

  export default TextEditor;