/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {gql, useLazyQuery} from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";
import Sanitiser from "../global/sanitiser";
import pdfMake from "pdfmake/build/pdfmake";
import '../global/pdf/customfonts';
import { logoImage } from '../global/pdf/logoImage';
import {parseJSONSafely} from "../../utils";
var htmlToPdfmake = require("html-to-pdfmake");

const {REACT_APP_FILE_MANAGER_API} = process.env;

const SECE_QUERY = gql`
query Sece($seceId: ID!){
    sece(_id: $seceId) {
      sece_title
      sece_group
      sece_goal
      sece_ref
      sece_client_ref
      sece_criticality
      dh_id{
          dh_name
      }
      ag_id{
          _id
          ag_title
      }
      installation_id{
          _id
          installation_title
      }
      bar_id{
          _id
        bar_title
      }
      mah_ids{
        _id
        mah_title
      }
      sece_onshore_responsible{
        _id
        user_fname
        user_lname
      }
      sece_offshore_responsible{
          _id
          user_fname
          user_lname
      }
      sece_attachment
      sece_attmnt_dest_path
      sece_comments
      sece_status
      sece_conf_Level_ps_va_changed
      sece_conf_Level_sece_modified
    }
}
`;

const ViewSece = (props) => {
    const [seceObject, setSeceObject] = useState({});
    const [seceAttachments, setSeceAttachments] = useState([]);

    const { id, assetGroupId, installationId, barrierId, seceId = "" } = useParams();

    const [sece , { loading, error, data }] = useLazyQuery(SECE_QUERY);

    useEffect(() => {
        if (seceId && seceId !== "") {
            sece({ variables: { seceId: seceId }, errorPolicy: 'all' });
        }
    }, []);

    useEffect(() => {
        if (data && data.sece) {
            let sece = data.sece;
            let attachments = sece.sece_attachment && sece.sece_attachment !== "" ? sece.sece_attachment : "[]";
            let parsedAttachments = parseJSONSafely(attachments) ? parseJSONSafely(attachments) : [];
            setSeceObject(data.sece);
            setSeceAttachments(parsedAttachments)
        }
    }, [data]);

    var docDefinition = {
        pageSize: 'A4',
        pageMargins: [40, 100, 40, 24],
        header: function (currentPage, pageCount) {

            var headerObj;
            if (currentPage % 2) {
                headerObj = {
                    table: {
                        headerRows: 0,
                        widths: ['75%', '25%'],
                        body: [
                            [
                                {
                                    border: [false, false, true, false],
                                    fillColor: '#002a4e',
                                    stack: [
                                        { text: seceObject.dh_id && seceObject.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                                        [{
                                            canvas: [
                                                {
                                                    type: 'rect',
                                                    x: 0,
                                                    y: 0,
                                                    w: 30,
                                                    h: 0.5,
                                                    r: 0,
                                                    lineWidth: 1,
                                                    lineColor: 'red',
                                                }
                                            ]
                                        }],
                                        { text: "SECE Details", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                                    ]
                                },
                                {
                                    border: [true, false, false, false],
                                    fillColor: '#b40c1e',
                                    image: logoImage,
                                    width: 100
                                },
                            ]
                        ],
                    },
                    layout: {
                        vLineColor: function (i, node) { return 'white'; },
                        vLineWidth: function (i) { return 4; },
                        paddingLeft: function (i, node) { return 30; },
                        paddingRight: function (i, node) { return 10; },
                        paddingTop: function (i, node) { return 20; },
                        paddingBottom: function (i, node) { return 10; },
                    }
                };
            } else {
                headerObj = {
                    table: {
                        headerRows: 0,
                        widths: ['25%', '75%'],
                        body: [
                            [
                                {
                                    border: [false, false, true, false],
                                    fillColor: '#b40c1e',
                                    image: logoImage,
                                    width: 100
                                },
                                {
                                    border: [true, false, false, false],
                                    fillColor: '#002a4e',
                                    stack: [
                                        { text: seceObject.dh_id && seceObject.dh_id.dh_name, font: 'Montserrat', fontSize: 10, color: '#fff' },
                                        [{
                                            canvas: [
                                                {
                                                    type: 'rect',
                                                    x: 0,
                                                    y: 0,
                                                    w: 30,
                                                    h: 0.5,
                                                    r: 0,
                                                    lineWidth: 1,
                                                    lineColor: 'red',
                                                }
                                            ]
                                        }],
                                        { text: "SECE Details", font: 'Montserrat', fontSize: 14, color: '#fff', bold: true, marginTop: 5 }
                                    ]
                                },
                            ]
                        ],
                    },
                    layout: {
                        vLineColor: function (i, node) { return 'white'; },
                        vLineWidth: function (i) { return 4; },
                        paddingLeft: function (i, node) { return 30; },
                        paddingRight: function (i, node) { return 10; },
                        paddingTop: function (i, node) { return 20; },
                        paddingBottom: function (i, node) { return 10; },
                    }
                };
            }

            return headerObj;
        },
        footer: function (currentPage, pageCount) {

            var footerObj;

            if (currentPage % 2) {
                footerObj = {
                    table: {
                        widths: [30, '*', 100, 28],
                        body: [
                            [
                                { text: ' ', border: [false, false, false, false] },
                                { text: 'SECE DETAILS', style: 'footerText', border: [false, false, false, false] },
                                { text: currentPage.toString(), border: [false, false, true, false], alignment: 'right' },
                                { text: ' ', border: [true, false, false, false] }
                            ]
                        ]
                    },
                    layout: {
                        vLineColor: function (i, node) { return '#b40c1e'; },
                    }
                }
            } else {
                footerObj = {
                    table: {
                        widths: [30, 100, '*', 28],
                        body: [
                            [
                                { text: ' ', border: [false, false, true, false] },
                                { text: currentPage.toString(), border: [true, false, false, false] },
                                { text: `© ${new Date().getFullYear()} American Bureau of Shipping. All rights reserved. CONFIDENTIAL.`, style: 'footerText', border: [false, false, false, false], alignment: 'right' },
                                { text: ' ', border: [false, false, false, false] }
                            ]
                        ]
                    },
                    layout: {
                        vLineColor: function (i, node) { return '#b40c1e'; },
                    }
                }
            }

            return footerObj;

        },
        info: {
            title: seceObject.dh_id && seceObject.dh_id.dh_name + ' - ' + seceObject.sece_title,
        },
        content: [

            { text: seceObject.sece_title, style: 'title' },
            {
                table: {
                    widths: ['30%', '70%'],
                    headerRows: 0,
                    body: [
                        [{ text: 'Status', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, seceObject.sece_status],
                        [{ text: 'Duty Holder', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, seceObject.dh_id && seceObject.dh_id.dh_name],
                        [{ text: 'Barrier', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, seceObject.bar_id && seceObject.bar_id.bar_title],
                        [{ text: 'Installation', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, seceObject.installation_id && seceObject.installation_id.installation_title],
                        [{ text: 'Client Ref.', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, seceObject.sece_client_ref],
                        [{ text: 'SECE Group', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] }, seceObject.sece_group],

                        [{ text: 'Criticality', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                        {
                            text: seceObject.sece_criticality,
                            color: seceObject.sece_criticality === 'High' || seceObject.sece_criticality === '3' || seceObject.sece_criticality === 'C' ? '#DA1F33' :
                            seceObject.sece_criticality === 'Medium' || seceObject.sece_criticality === '2' || seceObject.sece_criticality === 'B' ? '#FFAD0A' :
                            seceObject.sece_criticality === 'Low' || seceObject.sece_criticality === '1' || seceObject.sece_criticality === 'A' ? '#4CA342' : null
                        }],
                        
                        [{ text: 'SECE Onshore Responsbile', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                        { text: [seceObject.sece_onshore_responsible? `${seceObject.sece_onshore_responsible.user_fname} ${seceObject.sece_onshore_responsible.user_lname}`: "Not Available"] }],
                        
                        [{ text: 'SECE Offshore Responsbile', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },
                        { text: [seceObject.sece_offshore_responsible? `${seceObject.sece_offshore_responsible.user_fname} ${seceObject.sece_offshore_responsible.user_lname}`: "Not Available"] }],
                        
                        [{ text: 'SECE attachment', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'] },    
                        {text: seceAttachments.length?seceAttachments.map(att => { 
                            return (
                                "File Name: \n" +
                                att.name + '\n' +
                                "File Description: \n" +
                                att.description + '\n \n'
                             )
                           }): "No Attachments to Display"           
                        }],
                        [{ text: 'Major Accident Hazards', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
                        [{
                            text: [
                                {
                                    text: seceObject.mah_ids && seceObject.mah_ids.length !== 0 ? seceObject.mah_ids.map((m, index) => {
                                        return (m.mah_title + '\n')
                                    }) : "No Major Accident Hazards to Display"
                                },
                            ], colSpan: 2
                        }, {}],

                        [{ text: 'Goal', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
                        [{ text: htmlToPdfmake(seceObject.sece_goal), alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],

                        [{ text: 'Comments', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
                        [{ text: htmlToPdfmake(seceObject.sece_comments), alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],

                        [{ text: 'Any Significant changes to PS or Verification Activities in last 12 months?', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
                        [{ text: seceObject.sece_conf_Level_ps_va_changed === true ? "Yes" :  seceObject.sece_conf_Level_ps_va_changed === false && "No", alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],

                        [{ text: 'Any Significant modifications to the SECE in last 12 months?', style: 'StandardTableHeader', alignment: 'left', borderColor: ['#000', '#000', '#000', '#fff'], colSpan: 2 }, {}],
                        [{ text: seceObject.sece_conf_Level_sece_modified === true ? "Yes" :  seceObject.sece_conf_Level_sece_modified === false && "No", alignment: 'left', borderColor: ['#000', '#000', '#000', '#000'], colSpan: 2 }, {}],
                        
                    ]
                },
                style: 'standardTable',
            },

        ],
        defaultStyle: {
            font: 'Tinos',
            fontSize: 10,
            color: '#231f20',
            lineHeight: 1.4
        },
        styles: {
            title: {
                marginTop: 10,
                font: 'Montserrat',
                fontSize: 14,
                bold: true,
                color: '#002a4e'
            },
            redTitle: {
                marginBottom: 10,
                font: 'Montserrat',
                color: '#b40c1e'
            },
            standardTable: {
                margin: [0, 5, 0, 20],
                lineHeight: 1.2
            },
            StandardTableHeader: {
                fillColor: '#002a4e',
                color: '#fff',
                lineHeight: 1
            },
            footerTitle: {
                font: 'Montserrat',
                color: '#002a4e',
            },
            footerText: {
                font: 'Montserrat',
                fontSize: 10
            }
        }
    }

    const pdfTab = () => {
        pdfMake.createPdf(docDefinition).open();
    }

    if (loading) {
        return (
            <PageSpinner />
        )
    }

    if (error) {
        return (
            <span>Something went wrong retrieving the SECE data</span>
        )
    }

    return (
        <div className="w-full px-8 pb-8">

            <div className="mb-6 pb-3 border-b-2 border-gray-200">
                <h1 className="mb-3 text-blue-900 font-sans text-2xl font-bold uppercase">SECE Details</h1>
            </div>

            <button type="button" onClick={() => {window.history.back()}} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button> 

            <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

            <div className="w-full lg:w-1/2">

                <div className="my-6">
                    <h2 className="font-bold text-2xl text-blue-900">{seceObject.sece_title}</h2>
                </div>

                <table className="w-full mb-5 table-auto">
                    <tbody>
                        <tr>
                            <th className="p-3 text-left bg-white border">Status</th>
                            <td className="p-3 border">{seceObject.sece_status}</td>
                        </tr>
                        {seceObject.dh_id &&
                        <tr>
                            <th className="p-3 text-left bg-white border">Duty Holder</th>
                            <td className="p-3 border">{seceObject.dh_id.dh_name}</td>
                        </tr>
                        }
                        {seceObject.bar_id &&
                        <tr>
                            <th className="p-3 text-left bg-white border">Barrier</th>
                            <td className="p-3 border">{seceObject.bar_id.bar_title}</td>
                        </tr>
                        }
                        {seceObject.installation_id &&
                        <tr>
                            <th className="p-3 text-left bg-white border">Installation</th>
                            <td className="p-3 border">{seceObject.installation_id.installation_title}</td>
                        </tr>
                        }
                        <tr>
                            <th className="p-3 text-left bg-white border">SECE Ref.</th>
                            <td className="p-3 border">{seceObject.sece_client_ref && seceObject.sece_client_ref !== "" ? seceObject.sece_client_ref : "Not Available"}</td>
                        </tr>
                        <tr>
                            <th className="p-3 text-left bg-white border">SECE Group</th>
                            <td className="p-3 border">{seceObject.sece_group && seceObject.sece_group !== "" ? seceObject.sece_group : "Not Available"}</td>
                        </tr>
                        <tr>
                            <th className="p-3 text-left bg-white border">Criticality</th>
                            <td className="p-3 border font-bold text-xl">
                                <span
                                    className={
                                        seceObject.sece_criticality === 'Low' || seceObject.sece_criticality === '3' || seceObject.sece_criticality === 'C' ? 'text-green-900' :
                                        seceObject.sece_criticality === 'Medium' || seceObject.sece_criticality === '2' || seceObject.sece_criticality === 'B' ? 'text-yellow-900' :
                                        seceObject.sece_criticality === 'High' || seceObject.sece_criticality === '1' || seceObject.sece_criticality === 'A' ? 'text-red-900' : null
                                    }
                                >
                                    {seceObject.sece_criticality}
                                </span>
                            </td>
                        </tr>
                        {seceObject.sece_onshore_responsible !== undefined &&
                            <tr>
                                <th className="p-3 text-left bg-white border">SECE Onshore Responsible</th>
                                <td className="p-3 border">{seceObject.sece_onshore_responsible? `${seceObject.sece_onshore_responsible.user_fname} ${seceObject.sece_onshore_responsible.user_lname}`: "Not Available"}</td>
                            </tr>
                        }
                        {seceObject.sece_offshore_responsible !== undefined &&
                            <tr>
                                <th className="p-3 text-left bg-white border">SECE Offshore Responsible</th>
                                <td className="p-3 border">{seceObject.sece_offshore_responsible? `${seceObject.sece_offshore_responsible.user_fname} ${seceObject.sece_offshore_responsible.user_lname}`: "Not Available"}</td>
                            </tr>
                        }
                        <tr>
                            <th className="p-3 text-left bg-white border">SECE attachment</th>
                            <td className="p-3 border">
                                {createFileLinks(seceAttachments, seceObject)}
                            </td>
                        </tr>
                        <tr>
                            <th className="p-3 text-left bg-white border" colSpan="2">Major Accident Hazards</th>
                        </tr>
                        <tr>
                            <td className="p-3 border" colSpan="2">

                                {seceObject.mah_ids !== undefined &&
                                    <span>
                                        {seceObject.mah_ids.length !== 0 ? seceObject.mah_ids.map((m, index) => (
                                            <div key = {index}>
                                                {assetGroupId !== undefined && installationId !== undefined && barrierId !== undefined &&
                                                    <Link
                                                        key={index}
                                                        to={{
                                                            pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/barrier/${barrierId}/mah/view/${m._id}`,
                                                        }}
                                                        className="block hover:underline"
                                                    >
                                                        {m.mah_title}
                                                    </Link>
                                                }
                                                {assetGroupId !== undefined && installationId !== undefined && barrierId === undefined &&
                                                    <Link
                                                        key={index}
                                                        to={{
                                                            pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/installation/${installationId}/mah/view/${m._id}`,
                                                        }}
                                                        className="block hover:underline"
                                                    >
                                                        {m.mah_title}
                                                    </Link>
                                                }
                                                {assetGroupId !== undefined && installationId === undefined && barrierId !== undefined &&
                                                    <Link
                                                        key={index}
                                                        to={{
                                                            pathname: `/dutyholder/${id}/asset-group/${assetGroupId}/barrier/${barrierId}/mah/view/${m._id}`,
                                                        }}
                                                        className="block hover:underline"
                                                    >
                                                        {m.mah_title}
                                                    </Link>
                                                }
                                            </div>
                                        )) : <p>No Major Accident Hazards have been added yet</p>}
                                    </span>
                                }

                            </td>
                        </tr>
                        
                        {seceObject.sece_goal &&
                            <tr>
                                <th className="p-3 text-left bg-white border" colSpan="2">Goal</th>
                            </tr>
                        }
                        {seceObject.sece_goal &&
                            <tr>
                                <td className="p-3 border" colSpan="2">
                                   <Sanitiser html={seceObject.sece_goal} />  
                                </td>
                            </tr>
                        }

                        {seceObject.sece_comments &&
                            <tr>
                                <th className="p-3 text-left bg-white border" colSpan="2">Comments</th>
                            </tr>
                        }
                        {seceObject.sece_comments &&
                            <tr>
                                <td className="p-3 border" colSpan="2">
                                   <Sanitiser html={seceObject.sece_comments} />  
                                </td>
                            </tr>
                        }
                        <tr>
                            <th className="p-3 text-left bg-white border" colSpan="2">Any Significant changes to PS or Verification Activities in last 12 months?</th>
                        </tr>
                        <tr>
                            <td className="p-3 border" colSpan="2">
                                {seceObject.sece_conf_Level_ps_va_changed === true ? "Yes" :  seceObject.sece_conf_Level_ps_va_changed === false && "No"}
                            </td>
                        </tr>
                        <tr>
                            <th className="p-3 text-left bg-white border" colSpan="2">Any Significant modifications to the SECE in last 12 months?</th>
                        </tr>
                        <tr>
                            <td className="p-3 border" colSpan="2">
                                {seceObject.sece_conf_Level_sece_modified === true ? "Yes" :  seceObject.sece_conf_Level_sece_modified === false && "No"}
                            </td>
                        </tr>                        
                    </tbody>
                </table>

                <button type="button" onClick={() => {window.history.back()}} className="mr-2 button-red"><FontAwesomeIcon icon={['fas', 'angle-left']} /> Back</button> 

                <span className="button-red cursor-pointer" onClick={() => pdfTab()}>PDF</span>

            </div>

        </div>
    )

}

function createFileLinks(attachments, seceObject) {
    let linkList = "";
    let fileDestPath = seceObject.sece_attmnt_dest_path ? seceObject.sece_attmnt_dest_path : "";
    if (attachments && attachments.length && fileDestPath !== "") {
        let fullPath = `${REACT_APP_FILE_MANAGER_API}/download-file?path=/${fileDestPath}/`;
        let numberOfAttachments = attachments.length;
        let newAttachments = <React.Fragment>{attachments.map((att, index) =>
            <React.Fragment key={index}>
                <div className="text-blue-900 font-sans text-sm font-bold">File Name:</div>
                <a href={fullPath + att.name} className="mb-1 hover:underline" key={index} target={"_blank"} rel="noopener noreferrer" download={true}>
                    {att.name}{((index + 1) < numberOfAttachments) ? <br /> : ""}
                </a>
                <div className="text-blue-900 font-sans text-sm font-bold">File Description:</div>
                <div>
                    {att.description}
                </div>
                {((index + 1) < numberOfAttachments) ? <br /> : ""}
            </React.Fragment>
        )}
        </React.Fragment>
        linkList = newAttachments;
    } else {
        linkList = <Link to="#" className="hover:underline">No File Attachment</Link>
    }

    return linkList;
}

export default ViewSece;
