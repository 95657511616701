/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Table from '../global/Table';
import { gql, useLazyQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageSpinner from "../global/pageSpinner";

import Sanitiser from "../global/sanitiser";

import { AuthContext } from "../../context/authContext";
import { NavContext } from '../../context/navContext';
import { DashboardContext } from '../../context/dashboardContext';

import { PageContext } from '../../context/pageContext';
//import Comment from '../global/Comment';
import { sysObjects } from "../../constants/sysObjectList";
import {
  ACTION_STATUS_UNAPPROVED,
  NOTIFICATION_STATUS_PENDING
} from '../../constants/';

import {
  parseJSONSafely,
  formatDateToLocaleDateString,
  createUserTypeAndRoleObject,
  getBreadCrumbHeader,
  valueFromInfoMatrixLevel
} from "../../utils/index";

const PAGE_NAME = "ActionList";
const ACTIONS_SEARCH_QUERY = gql`
    query ActionSearch($param: ActionSearchInput!){
      actionSearch(param: $param) {
        _id
        type
        operation
        raised_by{
          _id
        }
        title
        task
        detail
        build_no
        priority
        date_added
        meta
        metadata
        view_path
        status 
        }
    }
`;

// const APPROVE_ACTION = gql`
//     mutation ApproveAction($_id: String!, $action: ActionInput!) {
//         approveAction(_id: $_id, action: $action) {
//             _id
//         }
//     }
// `;

// const REJECT_ACTION = gql`
//     mutation RejectAction($_id: String!, $action: ActionInput!) {
//         rejectAction(_id: $_id, action: $action) {
//             _id
//         }
//     }
// `;

function ActionList(props) {
  const [showHelp, setShowHelp] = useState(false);
  const [actionList, setActionList] = useState([]);
  const [actionStatus] = useState(ACTION_STATUS_UNAPPROVED) //setActionStatus
  const [startLoading, setStartLoading] = useState(true);
  const [modalAction] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageName, setPageName] = useState("");
  const [drDnDutyholder, setDrDnDutyholder] = useState({});
  const [drDnInstallation, setDrDnInstallation] = useState({});
  const [drDnSece, setDrDnSece] = useState({});
  const [actionPriority, setActionPriority] = useState("");
  const [actionSearchParam, setActionSearchParam] = useState({});

  const { location: { pathname = "", search = "" } } = props ? props : {};
  //const queryString = urlSearchParams(search);

  const authContext = useContext(AuthContext);
  const navContext = useContext(NavContext);
  const dashboardContext = useContext(DashboardContext);

  const [userTypeAndRoleObject, setUserTypeAndRoleObject] = useState(null);

  const pageContext = useContext(PageContext);

  const [actionSearch, { error: actionError, data: actionData }] = useLazyQuery(ACTIONS_SEARCH_QUERY);
  // const [approveAction, {data: approveActionData}] = useMutation(APPROVE_ACTION);
  // const [rejectAction, {data: rejectActionData}] = useMutation(REJECT_ACTION);

  useEffect(() => {
    if (authContext && authContext.user) {
      let result = createUserTypeAndRoleObject(authContext);
      setUserTypeAndRoleObject(result);
    }
  }, [authContext]);

  useEffect(() => {
    if (dashboardContext && dashboardContext.actionSearchParam) {
      setActionSearchParam(dashboardContext.actionSearchParam)
    }
  }, [dashboardContext]);

  useEffect(() => {
    if (pathname && pathname === "/reporting/action-list") {
      setActionPriority("");
    }
  }, [pathname]);

  useEffect(() => {
    setPageName(PAGE_NAME);
  }, []);

  useEffect(() => {
    const { actionListPage: { page = 0 } } = pageContext;
    setCurrentPage(page);
  }, [pageContext]);

  useEffect(() => {
    const { dutyHolder, installation, sece } = navContext;
    setDrDnDutyholder(dutyHolder);
    setDrDnInstallation(installation);
    setDrDnSece(sece);
  }, [navContext]);

  useEffect(() => {
    let searchQueryParams = new URLSearchParams(search);

    let priority = searchQueryParams.get("priority");
    let userId = searchQueryParams.get("userId");

    setActionPriority(priority);

    let dpDnSearchParams = {
      ...(drDnDutyholder.id ? { dh_id: drDnDutyholder.id } : {}),
      ...(drDnInstallation.id ? { installation_id: drDnInstallation.id } : {}),
      ...(drDnSece.id ? { sece_id: drDnSece.id } : {})
    };

    let generalSearchParams = {
      priority: priority,
      user_id: userId,
      status: actionStatus,
    }

    let searchParams = {
      ...dpDnSearchParams,
      ...generalSearchParams
    }

    //console.log("searchParams>>>>", searchParams)
    actionSearch({ variables: { param: { ...searchParams } }, errorPolicy: 'all' });
    setStartLoading(true);

  }, [actionStatus, actionSearchParam, drDnDutyholder, drDnInstallation, drDnSece])

  useEffect(() => {
    if (actionData && actionData.actionSearch) {
      let list = formatActionData(actionData.actionSearch, userTypeAndRoleObject);
      setActionList(list);
      setStartLoading(false);
    }
  }, [actionData]);

  // useEffect(() => {
  //   if (approveActionData && approveActionData.approveAction) {
  //     refetch();
  //   }
  // }, [approveActionData])

  // useEffect(() => {
  //   if (rejectActionData && rejectActionData.rejectAction) {
  //     refetch();
  //   }
  // }, [rejectActionData])

  // const handleApproval = (action) => {
  //   const payload = {
  //     status: ACTION_STATUS_APPROVED,
  //     meta: action.meta,
  //     type: action.type
  //   }
  //   approveAction({variables: { _id: action.id, action: payload}})
  // }

  // const handleReject = (action) => {
  //   const payload = {
  //     status: ACTION_STATUS_DECLINED,
  //     meta: action.meta,
  //     type: action.type,
  //   }
  //   rejectAction({variables: { _id: action.id, action: payload}})
  // }

  //const handleModalToggle = (action) => {
  //  setModalAction(action);
  //  toggleModal();
  //}

  function toggleModal() {
    const modal = document.querySelector('.modal')
    modal.classList.toggle('opacity-0')
    modal.classList.toggle('pointer-events-none')
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Task',
        accessor: 'action_task', // accessor is the "key" in the data 
      },
      // {
      //   Header: 'Duty Holder',
      //   accessor: 'dutyholder',
      // },
      // {
      //   Header: 'Installation',
      //   accessor: 'installation',
      // },
      {
        Header: 'Finding Ref',
        accessor: 'finding_ref',
      },
      {
        Header: 'Finding Level',
        accessor: 'finding_level',
      },
      {
        Header: 'Status',
        accessor: 'finding_current_status',
      },
      {
        Header: 'Last Response',
        accessor: 'finding_last_response',
      },
      {
        Header: 'Date of Last Response',
        accessor: 'finding_date_of_last_response',
      },
      {
        Header: 'Last Responded to By',
        accessor: 'finding_last_responded_to_by',
      },
      // {
      //   Header: 'Response Due',
      //   accessor: 'finding_response_due',
      // },
      {
        Header: 'Synopsis',
        accessor: 'finding_synopsis',
      },
      // {
      //   Header: 'Date Raised',
      //   accessor: 'date_raised',
      // },
      // {
      //   Header: 'Priority',
      //   accessor: 'action_priority',
      // },
      {
        Header: '',
        id: 'buttons',
        accessor: originalRow => originalRow,
        Cell: ({ value: action }) => (
          <div className="text-right">

            {/* {userTypeAndRoleObject &&
              userTypeAndRoleObject.userType === "ABS" &&
              action.recordId &&
              <Comment sysObjName={action.soName} id="comment"
                sysObjRecordId={action.recordId}
                buttonName={"Comments"}
                buttonClassName={"button-red mb-1 mr-1 text-xs"} />
            } */}
            {action.workflow_status && 
             action.workflow_status !== NOTIFICATION_STATUS_PENDING &&
              <Link
                to={{
                  pathname: action.report_id ? `/reporting/${action.report_id}/finding/edit/${action.findingId}` : `/reporting/finding/edit/${action.findingId}`,
                  state: { formMode: 'edit' }
                }}
                className="button-red mr-1 text-xs"
              >
                {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" ? "Edit" : " Respond"}
              </Link>
            }            

            <Link
              to={{
                pathname: `/reporting/finding/view/${action.findingId}`
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              View
            </Link>

            {/*<Link
              to="#"
              className="button-red mb-1 mr-1 text-xs"
              onClick={() => handleModalToggle(action)}
            >
              Details
            </Link>*/}

            {/*<Link
              to={{
                pathname: '#',
                state: { formMode: 'edit' }
              }}
              className="button-red mb-1 mr-1 text-xs"
            >
              Action
            </Link>*/}

            {/* {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
              <Link
                to={{ pathname: pathname, search: search }}
                className="button-red mr-1 text-xs"
                onClick={() => { handleApproval(action) }}
              >
                Approve
              </Link>
            } */}
            {/* {userTypeAndRoleObject && userTypeAndRoleObject.userType === "ABS" &&
              <Link
                to={{ pathname: pathname, search: search }}
                className="button-red mr-1 text-xs"
                onClick={() => { handleReject(action) }}
              >
                Decline
              </Link>
            } */}

          </div>
        ),
      },
    ],
    [userTypeAndRoleObject]
  )

  if (startLoading) {
    return (
      <PageSpinner />
    )
  }

  if (actionError) {
    return (
      <span>Something went wrong retrieving the table data</span>
    )
  }

  //TITLE / INFO & MAIN BUTTON SECTION
  return (
    <div className="w-full px-8 pb-8">

      <div className="pb-6 border-b-2 border-gray-200">

        <h1 className="text-blue-900 font-sans text-2xl font-bold uppercase">Action List <FontAwesomeIcon icon={['fas', 'info-circle']} className="ml-1 text-blue-900 cursor-pointer" onClick={() => setShowHelp(!showHelp)} /></h1>

        {showHelp &&
          <p className="mt-3">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam condimentum a lectus non condimentum. Aliquam rhoncus, enim vitae condimentum pharetra, urna magna elementum sapien, non vehicula turpis tellus nec velit. Vivamus tristique venenatis neque, eget tristique mauris suscipit aliquet. Pellentesque et massa nunc.</p>
        }

      </div>

      <h3 className="mt-4 text-blue-900 font-sans text-lg font-bold uppercase">Current View</h3>

      {getBreadCrumbHeader({
        userTypeAndRoleObject: userTypeAndRoleObject,
        drDnDutyholder: drDnDutyholder,
        drDnInstallation: drDnInstallation,
        drDnSece: drDnSece,
        section: "Dashboard",
        title: (actionPriority && actionPriority !== "" ? `My ${actionPriority !== "na" ? actionPriority + " Priority" : ""}  Action List` : "Action List")
      })
      }

      {/* {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "ABS" && 
        drDnDutyholder.id && drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - {actionPriority!== "" ? `My ${actionPriority !== "na"? actionPriority+ " Priority": ""}  Action List` : "Action List"} - Duty Holder :  {drDnDutyholder.name} - Installation: {drDnInstallation.name} </h6>}
      {(userTypeAndRoleObject &&
        userTypeAndRoleObject.userType === "Duty Holder" && 
        drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - {actionPriority!== "" ? `My ${actionPriority !== "na"? actionPriority + " Priority": ""}  Action List` : "Action List"} - Installation: {drDnInstallation.name}</h6>}
      {(!drDnDutyholder.id && !drDnSece.id && !drDnInstallation.id) && <h6 className="mb-6 font-bold text-sm text-red-900"><Link to="/" className="hover:underline">Dashboard</Link> - {actionPriority!== "" ? `My ${actionPriority !== "na"? actionPriority + " Priority": ""}  Action List` : "Action List"} - Duty Holder / Installation : All</h6>} */}

      {actionList.length ?
        <Table
          columns={columns}
          data={actionList}
          currentPage={currentPage}
          pageName={pageName}
          getRowProps={row => ({
            style: {
              background: row.original.action_priority === 'High' ? '#ffe7ea' : row.original.action_priority === 'Medium' ? '#fff3db' : row.original.action_priority === 'Low' ? '#e2ffd9' : null,
            },
          })}
        />
        : <h4 className="mt-4">There are currently no Actions</h4>}

      {/* ACTION DETAILS */}
      <div className="modal opacity-0 pointer-events-none fixed w-full h-full top-0 left-0 flex justify-center z-30">
        <div className="modal-overlay absolute w-full h-full bg-black opacity-50 top-0 left-0 cursor-pointer z-40" onClick={() => toggleModal()}></div>
        <div className="absolute w-full md:w-1/2 lg:w-1/3 my-6 p-4 bg-white rounded-sm shadow-lg overflow-y-auto z-50" style={{ maxHeight: 'calc(100% - 3.5rem)' }}>

          <div className="mb-4 pb-2 border-b-2 border-gray-200">
            <h2 className="text-blue-900 font-sans text-1xl font-bold uppercase">Action Details</h2>
          </div>

          {modalAction.action_task &&
            <h2 className="text-blue-900 font-sans text-1xl font-bold"> {modalAction.action_task}</h2>
          }
          {modalAction.action_build &&
            <h5 className="mb-2 font-bold text-sm text-red-900"> {modalAction.action_build}</h5>
          }

          {modalAction.action_priority &&
            <h5 className="mb-4 text-sm">Priority: <span className="font-bold text-red-900"> {modalAction.action_priority}</span></h5>
          }

          <p className="mb-4">{modalAction.action_task && modalAction.action_task}</p>

          {/*<Link to="#" className="mt-3 mr-2 mb-4 button-red capitalize">Action</Link> */}
          <button className="mt-3 mb-4 button-red capitalize" onClick={() => toggleModal()}>Close</button>
        </div>
      </div>
      {/* ACTION DETAILS END */}

    </div>
  )
  //TITLE / INFO & MAIN BUTTON SECTION - END
}

function formatActionData(list, userTypeAndRoleObject) {
  let newList = [];
  if (list && list.length) {

    list.forEach(action => {
      const metadata = parseJSONSafely(action.metadata) ? parseJSONSafely(action.metadata) : {};
      const {
        _id: findingId = "",
        dh_id,
        installation_id,
        finding_ref,
        finding_infomatrix_level,
        finding_current_status,
        finding_responses,
        finding_synopsis,
        report_id,
        finding_status
      } = metadata;

      const { dh_name: dhName = "N/A" } = dh_id ?? {};

      const { installation_title: instName = "N/A" } = installation_id ?? {};

      const { _id: fnd_status_id = "", sysvar_title: fnd_status = "N/A" } = finding_current_status ?? {};

      const responses = parseJSONSafely(finding_responses) ? parseJSONSafely(finding_responses) : [];
      const lastResponse = responses[responses.length - 1];

      const {response_note = "N/A", response_update_by = "N/A", response_update_date = "" } = lastResponse ?? {};

      let newAction = {
        id: action._id,
        findingId: findingId,
        recordId: findingId,
        soName: sysObjects.finding,
        action_task: action.task,
        dutyholder: dhName,
        installation: instName,
        finding_ref: finding_ref,
        finding_level: valueFromInfoMatrixLevel(finding_infomatrix_level),
        finding_current_status: fnd_status,
        finding_current_status_id: fnd_status_id,
        finding_last_response: response_note ? <Sanitiser html={response_note} /> : "N/A",
        finding_date_of_last_response: formatDateToLocaleDateString(response_update_date) ?? "N/A",
        finding_last_responded_to_by: response_update_by,
        //finding_response_due: formatDateToLocaleDateString(finding_response_date),
        finding_synopsis: finding_synopsis,
        report_id: report_id,
        finding_status: action.status,
        workflow_status: finding_status,
        //action_build: action.build_no,
        //action_priority: action.priority,
        meta: action.meta,
        type: action.type,
        date_raised: formatDateToLocaleDateString(action.date_added),
        userTypeAndRoleObject: userTypeAndRoleObject
      }
      
      newList.push(newAction);
    })
  }
  return newList
}

export default ActionList;